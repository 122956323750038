<template>
    <div class="container-fluid" id="category-lv1">
        <div class="top-category" style="margin-top: 10px">
            <ul class="menu">
                <li>
                    <router-link to="/">{{ $t('home')}}</router-link>
                </li>
                <li><a href="javascript:;">> {{ filters.category_id > 0? category.name: 'Tất cả sản phẩm'}}</a></li>
            </ul>
        </div>
        <div class="wrap-container" style="padding-top: 10px">
<!--            <div class="category-banner">-->
<!--                <img src="/assets/img/category/banner.png" alt="">-->
<!--            </div>-->

<!--            <div class="category-slider">-->
<!--                <div class="title">-->
<!--                    <div class="text-left">-->
<!--                        Shop uy tín-->
<!--                    </div>-->
<!--                    <div class="text-right">-->
<!--                        <a href="javascript:;"> Xem chi tiết</a>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="slider-category" ref="categorySlider">-->
<!--                    <div class="slider-items" v-for="i in 14" :key="'category-' + i">-->
<!--                        <div class="slider-box">-->
<!--                            <a href="javascript:;">-->
<!--                                <img style="margin: auto" :src="i < 11 ? '/assets/img/category/banner-' + i + '.png' : '/assets/img/category/banner-1.png'" alt="">-->
<!--                            </a>-->
<!--                        </div>-->

<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
            <div class="row">
                <div class="col-lg-3 col-md-3 col-12 hide-mobile">
                    <div class="filter-box">
                        <div class="title">
	                        {{ $t('allCategory')}}
                        </div>
                        <div class="just-padding">
                            <div class="list-group list-group-root well" v-for="(category, index) in categories" :key="index">
                                <a :href="'#item-'+category.id" class="list-group-item" data-toggle="collapse" @click="chooseCategory(category.slug)"
                                   :style="filters.category_id == category.slug ? 'color: #DB0000': ''">
                                    <i class="fa fa-angle-right"></i>
                                    {{ category.name }}
                                </a>
                                <div class="list-group collapse" :id="'item-'+category.id" v-for="(cate, idx) in category.children" :key="idx">
                                    <a :href="'#item-'+cate.id" class="list-group-item" data-toggle="collapse" @click="chooseCategory(cate.slug)"
                                       :style="filters.category_id == cate.slug ? 'color: #DB0000': ''">
                                        <i class="fa fa-angle-right"></i>
                                        {{ cate.name }}
                                    </a>
                                  <div class="list-group collapse" :id="'item-'+cate.id" v-for="(c, key) in cate.children" :key="key">
                                      <a href="#" @click="chooseCategory(c.slug)" class="list-group-item" :style="filters.category_id == c.id ? 'color: #DB0000': ''">{{ c.name }}</a>
                                  </div>
                                </div>
                            </div>
                        </div>

                        <div class="title" style="color: #383c4f; margin-top: 32px">
	                        {{ $t('saleAddress')}}
                        </div>

                        <ul id="place-filter" v-for="(province, idx ) in provinces" :key="idx">
                            <li>
                                <label class="custom-input-checkbox">{{ province.name }}
                                    <input type="checkbox" :value="province.id" v-model="selectedProvinces" @change="chooseProvince()">
                                    <span class="checkmark"></span>
                                </label>
                            </li>

                        </ul>
                        <a style="cursor: pointer" @click="onPageChangeProvinces()" class="view-more">Xem thêm <i class="fas fa-chevron-down"></i></a>

                        <div class="title" style="color: #383c4f; margin-top: 32px">
	                        {{ $t('priceMoney')}}
                        </div>

                        <ul id="price-filter">
                            <li>
                                <label class="custom-input-radio">{{ $t('all')}}
                                    <input type="radio" name="price" checked="checked" v-model="sortPrice" value="1">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="custom-input-radio">{{ $t('upto500')}}
                                    <input type="radio" name="price" v-model="sortPrice" value="2">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="custom-input-radio">500.000đ - 1.000.000đ
                                    <input type="radio" name="price" v-model="sortPrice" value="3">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="custom-input-radio">{{ $t('above1000')}}
                                    <input type="radio" name="price" v-model="sortPrice" value="4">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <div class="price-between">
	                                {{ $t('between')}}
                                </div>
                                <div class="input-box">
                                    <input type="text" name="price_min" placeholder="Từ" v-model="price_min" @blur="sortByPrice()">
                                    <span>-</span>
                                    <input type="text" name="price_max" placeholder="đến" v-model="price_max" @blur="sortByPrice()">
                                </div>
                            </li>

                        </ul>

<!--                        <div class="title" style="color: #383c4f; margin-top: 32px">-->
<!--                            Sizes-->
<!--                        </div>-->
<!--                        <ul id="size-filter">-->
<!--                            <li class="active"><a href="javascript:;">XS</a></li>-->
<!--                            <li><a href="javascript:;">S</a></li>-->
<!--                            <li><a href="javascript:;">M</a></li>-->
<!--                            <li class="active"><a href="javascript:;">L</a></li>-->
<!--                            <li><a href="javascript:;">XL</a></li>-->
<!--                        </ul>-->

<!--                        <div class="title" style="color: #383c4f; margin-top: 32px">-->
<!--                            Color-->
<!--                        </div>-->
<!--                        <ul id="color-filter">-->
<!--                            <li class="active">-->
<!--                                <div>-->
<!--                                    <a href="javascript:;" style="background: #F64E60"></a>-->
<!--                                </div>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <div>-->
<!--                                    <a href="javascript:;" style="background: #3699FF"></a>-->
<!--                                </div>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <div>-->
<!--                                    <a href="javascript:;" style="background: #1BC5BD"></a>-->
<!--                                </div>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <div>-->
<!--                                    <a href="javascript:;" style="background: #8950FC"></a>-->
<!--                                </div>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <div>-->
<!--                                    <a href="javascript:;" style="background: #1B283F"></a>-->
<!--                                </div>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <div>-->
<!--                                    <a href="javascript:;" style="background: #D6D6E0"></a>-->
<!--                                </div>-->
<!--                            </li>-->
<!--                        </ul>-->

                        <div class="mt-3" style="text-align: center;">
                            <button type="button" class="reset-btn" @click="resetFilter()">{{ $t('reOrder')}}</button>
                        </div>

                    </div>
                </div>
                <div class="col-lg-9 col-md-9 col-12">
                    <div class="product-box">
                        <div class="list-filter">
                        <span>
                            {{ $t('orderBy')}}
                        </span>
                            <ul class="nav nav-tabs">
                                <li class="nav-item">
                                    <a class="nav-link active" @click="sortBy('created_at')" data-toggle="tab" href="#new" id="product_new">{{ $t('newest')}}</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" @click="sortBy('num_sold')" data-toggle="tab" href="#hotdeal" id="hot_deal">{{ $t('bestSeller')}}</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" @click="sortBy('view_count')" data-toggle="tab" href="#view" id="hot_view">{{ $t('viewMany')}}</a>
                                </li>
<!--                                <li class="nav-item">-->
<!--                                    <a class="nav-link" data-toggle="tab" href="#increase" id="filter_increase">Tăng dần</a>-->
<!--                                </li>-->
<!--                                <li class="nav-item">-->
<!--                                    <a class="nav-link" data-toggle="tab" href="#decrease" id="filter_decrease">Giảm dần</a>-->
<!--                                </li>-->
                            </ul>
                        </div>
                        <div class="tab-content">
                            <div id="new" class="tab-pane active">
                                <div v-if="products.length">
                                    <div class="row">
                                        <div class="col-xl-3 col-sm-4 col-6 padding-mobile" v-for="(product, index) in products"
                                             :key="index + 1">
                                            <product-item :product="product" :show-detail="true"></product-item>
                                        </div>
                                    </div>
                                    <div class="paginate">
                                        <Paginate :value="paginate" :pagechange="onPageChange"></Paginate>
                                    </div>
                                </div>
                                <div v-if="!isLoading && products.length === 0">
                                    <p>{{ $t('noProductInCategory')}}</p>
                                </div>
                            </div>
                            <div id="hotdeal" class="tab-pane">
                                <div v-if="products.length">
                                    <div class="row">
                                        <div class="col-xl-3 col-sm-4 col-6 padding-mobile" v-for="(product, index) in products"
                                             :key="index + 1">
                                            <product-item :product="product" :show-detail="true"></product-item>
                                        </div>
                                    </div>
                                    <div class="paginate">
                                        <Paginate :value="paginate" :pagechange="onPageChange"></Paginate>
                                    </div>

                                </div>
                                <div v-if="!isLoading && products.length === 0">
                                    <p>{{ $t('noProductInCategory')}}</p>
                                </div>
                            </div>
                            <div id="view" class="tab-pane">
                                <div v-if="products.length">
                                    <div class="row">
                                        <div class="col-xl-3 col-sm-4 col-6 padding-mobile" v-for="(product, index) in products"
                                             :key="index + 1">
                                            <product-item :product="product" :show-detail="true"></product-item>
                                        </div>
                                    </div>
                                    <div class="paginate">
                                        <Paginate :value="paginate" :pagechange="onPageChange"></Paginate>
                                    </div>
                                </div>
                                <div v-if="!isLoading && products.length === 0">
                                    <p>{{ $t('noProductInCategory')}}</p>
                                </div>
                            </div>
<!--                            <div id="increase" class="tab-pane">-->
<!--                                <div class="row">-->
<!--                                    <div class="col-xl-3 col-sm-4" v-for="(product, index) in 10"-->
<!--                                         :key="index + 1">-->
<!--                                        <product-item :product="product" :show-detail="true"></product-item>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div id="decrease" class="tab-pane">-->
<!--                                <div class="row">-->
<!--                                    <div class="col-xl-3 col-sm-4" v-for="(product, index) in 3"-->
<!--                                         :key="index + 1">-->
<!--                                        <product-item :product="product" :show-detail="true"></product-item>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="category-description">
            <p>
                <span style="font-weight: 600">{{ category.description }}</span>
            </p>
        </div>

    </div>

</template>

<script>

import {getOption, siteSetTitle} from "@/lib/utils";

const $ = window.jQuery;

import CategoryRequest from "@/api/requests/CategoryRequest";
import ProductItem from "@/components/ProductItem";
import Paginate from "@/components/Paginate";

export default {
    name: "Category",
    props: ['id', 'name'],
    components: {
        ProductItem,
        Paginate
    },

    data() {
        return {
            selectedProvinces: [],
            products: [],
            categories: [],
            provinces: [],
            filters: {
                order: 'created_at',
                category_id: this.id || 0
            },
            sortPrice: 1,
            price_min: 0,
            price_max: 0,
            pageProvince: 1,
            category: '',
            page: this.$route.query.page || 1,
            paginate: {
                currentPage: 1,
                lastPage: 1
            },
            hasNextPage: false,
            isLoading: false,
        }
    },
    mounted() {
        window.scrollTo(0, 0);
        this.getData();
        this.initSlider();
        this.filterProducts();
        if (this.filters.order == 'created_at') {
            document.getElementById('new').classList.add('active');
        } else if (this.filters.order == 'number_sold') {
            document.getElementById('hotdeal').classList.add('active');
        } else if (this.filters.order == 'total_view') {
            document.getElementById('view').classList.add('active');
        }
    },
    methods: {
        async getData() {
            this.isLoading = true;
            const res = await CategoryRequest.getAllCategories();
            if (!res.categories) {
                this.$router.replace({name: 'PageNotFound'});

                return;
            }

            const response = await CategoryRequest.getProvinces();
            if (response.provinces) {
                this.provinces = response.provinces;
            }

            //document.title = 'Danh mục sản phẩm';
            this.categories = res.categories;

            this.isLoading = false;
        },
        initSlider() {
            $(this.$refs.categorySlider).show().slick({
                rows: 2,
                slidesToShow: 6,
                slidesToScroll: 1,
                arrows:true,
                prevArrow: false,
                nextArrow: "<img style='width: 50px; height: 50px; right: -40px' class='next slick-next' src='/assets/img/category/next-icon.png'>"
            });
        },
        async onPageChangeProvinces(){
          let page = this.pageProvince + 1;
          const response = await CategoryRequest.getProvinces(page);
          if (response.provinces) {
            this.pageProvince = page;
            this.provinces = this.provinces.concat(response.provinces);
          }
        },

        chooseCategory(category_id) {
              this.filters.category_id = category_id;
              this.filterProducts();
        },

        sortBy(col) {
            this.filters.order = col;
            this.filterProducts();
        },

        prevPage() {
            if (this.paginate.page > 1) {
                this.paginate.page = this.paginate.page -1;
                this.filterProducts();
            }
        },

        nextPage() {
            if (this.hasNextPage) {
                this.paginate.page = this.paginate.page +1;
                this.filterProducts();
            }
        },
        onPageChange(page) {
            this.page = page;
            this.$router.push({query: { page:page }});
            this.filterProducts();
            setTimeout(() => {
                window.jQuery("html, body").animate({ scrollTop: 0 }, 300);
            }, 500);
        },

        chooseProvince() {
            this.filterProducts();
        },

        sortByPrice() {
          this.filterProducts();
        },

        resetFilter() {
          this.selectedProvinces = [];
          this.sortPrice = 1;
          this.price_min = 0;
          this.price_max = 0;
        },

        async filterProducts() {
            let option = {
                sortPrice: {
                    price_min: parseInt(this.price_min),
                    price_max: parseInt(this.price_max)
                },
                provinces: this.selectedProvinces,
                order: this.filters.order,
                page: this.page
            }
            const res = await CategoryRequest.getProducts(this.filters.category_id, option);
            if (res.code !== 200) {
              this.$router.replace({name: 'PageNotFound'});

              return;
            }

            if (res.category) {
                siteSetTitle(res.category.name);
            }

            this.products = res.products;
            this.paginate = res.paginate;
            if (res.category) {
                this.category = res.category;
            }

            setTimeout(() => {
                window.jQuery("html, body").animate({ scrollTop: 0 }, 300);
            }, 500);
        }
    },

    watch: {
        sortPrice: function(newval) {
            switch (newval) {
                case '1':
                    this.price_min = 0;
                    this.price_max = 0;
                    break;
                case '2':
                    this.price_min = 0;
                    this.price_max = 500000;
                    break;
                case '3':
                    this.price_min = 500000;
                    this.price_max = 1000000;
                    break;
                case '4':
                    this.price_min = 1000000;
                    this.price_max = 0;
                    break;
                default:
                  this.price_min = 0;
                  this.price_max = 0;
            }

            this.filterProducts();
        }
    }
}
</script>

<style scoped lang="scss">
@import "../assets/css/category";
@media screen and (max-width: 768px){
  .hide-mobile {
    display: none;
  }
}
</style>
