<template>
  <div id="product-item" class="zoom-img">
    <router-link
      :to="product && product.slug ? '/san-pham/' + product.slug : ''"
    >
      <div class="product-thumb" :class="height ? 'custom-height' : ''">
        <img v-if="image" v-lazy="image" alt="" />
        <img
          v-else
          v-lazy="
            product && product.thumb
              ? product.thumb.uri
              : '/assets/img/category/demo-product.png'
          "
          alt=""
        />
      </div>
      <div class="product-info">
        <p
          class="product-name"
          :title="
            lang == 'cn' && product.name_cn
              ? product.name_cn
              : lang == 'en' && product.name_en
              ? product.name_en
              : product.name
          "
        >
          {{
            lang == "cn" && product.name_cn
              ? product.name_cn
              : lang == "en" && product.name_en
              ? product.name_en
              : product.name || "Tên sản phẩm"
          }}
        </p>
        <div class="price-wrap">
          <div class="price-item">
            <div
              class="price"
              v-if="
                product.price_discount && product.price > product.price_discount
              "
            >
              {{ product.type == 2 ? rangePrice : n(product.price_discount) }}đ
              <span v-if="product.unit"> / {{ product.unit }}</span>
            </div>
            <div class="price" v-else>
              {{
                product.type == 2 ? rangePrice : n(product.price_origin) + "đ"
              }}
              <span v-if="product.unit"> / {{ product.unit }}</span>
            </div>
            <div
              class="old-price"
              v-if="
                product.price_discount &&
                product.price_origin &&
                product.price_origin > product.price_discount
              "
            >
              <span class="price">{{
                product.type == 2 ? rangePrice : n(product.price_origin) + "đ"
              }}</span>
              <span class="discount-percent" v-if="checkDiscount() > 0">
                -{{ checkDiscount() }}%
              </span>
            </div>
          </div>
          <div class="favorite-item">
            <i
              v-if="product.is_favorite"
              class="fas fa-heart"
              style="color: #db0000"
              @click.prevent="setFavorite(product, false)"
            ></i>
            <i
              v-else
              class="far fa-heart"
              @click.prevent="setFavorite(product, true)"
            ></i>
          </div>
        </div>
        <div class="place-span" v-if="showDetail">
          <div class="company-name">
            {{ product.shop ? product.shop.name : "" }}
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import CartRequest from "@/api/requests/CartRequest";
import store from "@/store";
import { isEmpty, numberFormat } from "@/lib/utils";
import ProductRequest from "@/api/requests/ProductRequest";
import i18n from "@/main";

export default {
  name: "ProductItem",
  props: ["product", "showDetail", "height", "screen"],

  data() {
    let priceMin = 0;
    let priceMax = 0;
    let rangePrice = 0;
    if (this.product.type == 2) {
      if (this.product.skus && this.product.skus.length > 0) {
        priceMin = this.product.skus[0].price;
        this.product.skus.forEach((sku) => {
          if (sku.price > priceMax) {
            priceMax = sku.price;
          }
          if (sku.price < priceMin) {
            priceMin = sku.price;
          }
        });
      }
      rangePrice =
        priceMax > priceMin
          ? numberFormat(priceMin) + " đ ~ " + numberFormat(priceMax) + " đ"
          : numberFormat(priceMin) + " đ";
    }
    return {
      image: "",
      isFavorite: false,
      rangePrice: rangePrice,
      lang: store.state.locale,
    };
  },
  watch: {
    product: function (newProduct) {
      this.image = newProduct.thumb
        ? this.product.thumb.uri
        : "/assets/img/category/demo-product.png";
    },
  },
  methods: {
    async addCart() {
      const authData = store.state.Auth;
      if (authData) {
        const cartItem = {
          product: this.product,
          quantity: 1,
        };
        const res = await CartRequest.add(cartItem);
        alert(res.message);
      } else {
        alert(this.$t("plsLoginToShopping"));
      }
    },
    checkDiscount() {
      let value = this.product.price_origin - this.product.price_discount;

      if (value < 0) {
        return 0;
      }
      let percent = value / this.product.price_origin;

      return Math.round(percent * 100);
    },
    async setFavorite(product, isFavorite) {
      const authData = store.state.Auth;
      if (authData) {
        const res = await ProductRequest.setFavoriteProduct(
          product.id,
          isFavorite
        );
        product.is_favorite = isFavorite;
      } else {
        alert(this.$t("plsLoginToSetFavProd"));
      }
    },
  },
  mounted() {
    // this.getPrice();
  },
};
</script>

<style scoped lang="scss">
@import "../assets/css/productItem";
</style>
