<template>
    <div id="user-profile--info">
        <div class="content-box" style="padding: 24px">
            <div class="title">
	            {{ $t('seenProduct')}}
            </div>
            <div class="product-box">
                <div class="row mb-2">
                    <div class="col-md-3 col-6" style="margin-left: auto; text-align: right">
                        <button class="delete-btn" @click="deleteAll()" v-if="sProducts && sProducts.length > 0">{{ $t('deleteAll')}}</button>
                    </div>

                </div>

                <div class="row" v-if="sProducts && sProducts.length > 0">
                    <div class="col-xl-3 col-sm-4 col-6 product-items" v-for="(product, index) in sProducts"
                         :key="index + 1">
                        <product-item :product="product.product"></product-item>
                        <img class="delete-icon" src="/assets/img/profile/delete-icon.png" alt="" @click.prevent="deleteProduct(product.product)">
                    </div>
                </div>
                <div class="row" v-else>
	                {{ $t('noFavoriteProduct')}}
                </div>
                <div class="paginate">
                    <Paginate :value="paginate" :pagechange="onPageChange"></Paginate>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ProductItem from "@/components/ProductItem";
import ProductRequest from "@/api/requests/ProductRequest";
import Paginate from "@/components/Paginate";
import toastr from 'toastr';

export default {
    name: "SeenProduct",
    components: {ProductItem, Paginate},
    data() {
        return {
            sProducts: [],
            page: this.$route.query.page || 1,
            paginate: {
                currentPage: 1,
                lastPage: 1
            }
        }

    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            const res = await ProductRequest.getSeenProducts(this.page);
            if (res.err_code !== 0) {
                this.$router.replace({name: 'PageNotFound'});
                return;
            }

            this.sProducts = res.products;
            this.paginate = res.paginate;
        },
        onPageChange(page) {
            this.page = page;
            this.$router.push({query: { page:page }});
            this.getData();
            setTimeout(() => {
                window.jQuery("html, body").animate({ scrollTop: 0 }, 300);
            }, 500);
        },

        async deleteAll() {
            if(!confirm('Bạn có chắc chắn muốn xóa toàn bộ sản phẩm đã xem?')) {
                return true;
            }
            const res = await ProductRequest.deleteAllSeenProducts();
            if (res.err_code !== 0) {
                this.$router.replace({name: 'PageNotFound'});
                return;
            }
            if (res.err_code === 0) {
                toastr.success('Đã xóa toàn bộ sản phẩm đã xem');
                await this.getData();
            }
        },

        async deleteProduct(product) {
            if(!confirm('Bạn có muốn xóa sản phẩm: ' + product.name + ' ?')) {
                return true;
            }
            const res = await ProductRequest.deleteSeenProduct(product.id);
            if (res.err_code !== 0) {
                toastr.error(res.message);
                return;
            }
            if (res.err_code === 0) {
                toastr.success('Đã xóa');
                await this.getData();
            }
        }
    }
}
</script>

<style lang="scss">
@import '../../assets/css/userInfo';
.paginate {
  width: fit-content;
  margin: auto;
}
.delete-btn {
  background: #DB0000;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 10px;
  color: #ffffff;
  border: none;
  border-radius: 5px;
}

.product-items {
  position: relative;
  .delete-icon {
    position: absolute;
    z-index: 100;
    top: 3px;
    right: 18px;
    cursor: pointer;
  }
}
</style>
