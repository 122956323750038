<template>
    <div class="container layout-detai-craft">
        <div class="banner-craft">
            <img class="image" :src="CraftVillage.image ? CraftVillage.image.uri : ''" alt="">
        </div>
        <div class="title-craft mt-3">
            <div class="name-craft">
                {{ CraftVillage.name }}
            </div>
            <div class="description mt-2 p-2" v-html="CraftVillage.description">

            </div>
            <div class="shop-suggest">
                <div class="title-shop-suggest">
                   {{$t('recommend_store')}}
                </div>
                <div v-for="store in stores" :key="store.id">
                    <div class="shop mb-3 d-flex mt-2">
                        <div class="baner-shop" v-if="store.images && store.images[0].link && store.images[0].link.uri">
                            <img v-lazy="store.images[0].link.uri" alt="" @click="openModalSlickSlider(store.images)">
                            <div class="baner-child d-flex">
                                <div class="content" v-for="(image, index) in store.images" :key="image.id"
                                    @click="openModalSlickSlider(store.images)">
                                    <img v-if="index < 4 && store.images.length > 1" v-lazy="image.link.uri" alt="">
                                    <div v-if="index == 29" class="centered">
                                        {{$t('see_all')}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="logo-elim-default" v-else>
                            <img v-lazy="'/assets/img/header/logo.png'" alt="">
                        </div>
                        <div class="name-shop p-3">
                            <div :class="!store.rating ? 'box-title-shop-top' : ''">
                                <div class="title">
                                    <a :href="'/thong-tin-lang-nghe/cua-hang/'+ store.id">{{ store.name }}</a>
                                    </div>
                                <div class="address">
                                    {{$t('address')}}: {{ store.address }}
                                </div>
                                <div class="contract">
                                    {{$t('concat_info')}}: {{ store.phone }}
                                </div>
                            </div>
                            <div class="feddback d-flex" v-if="store.rating">
                                <div class="title-feedback">
                                    {{$t('evaluate')}}:
                                </div>
                                <div class="icon-feedback ml-3">
                                    <star-rating :rating="store.rating" v-bind:star-size="25" :show-rating="false"
                                        :read-only="true"></star-rating>
                                </div>
                            </div>
                            <a :href="'/thong-tin-lang-nghe/cua-hang/'+ store.id" class="btn btn-danger custom-butom-detail">Xem chi tiết</a>
                        </div>
                    </div>
                </div>
                <div class="paginate p-4">
                    <Paginate :value="paginate" :pagechange="onPageChange"></Paginate>
                </div>
            </div>
            <modal-slick-slider ref="modalSlickSlider"></modal-slick-slider>
        </div>
    </div>
</template>

<script>
import ModalSlickSlider from './ModalSlickSlider.vue';
import StarRating from 'vue-star-rating'
import CraftVillageRequest from '@/api/requests/CraftVillageRequest';
import Paginate from "@/components/Paginate";
import { cloneObject } from '@/lib/utils';
export default {
    components: { Paginate, ModalSlickSlider, StarRating },
    props: ["id"],
    name: 'DetailCraftVillage',
    data() {
        return {
            CraftVillage: {},
            imageModal: {},
            stores: [],
            page: this.$route.query.page || 1,
            paginate: {
                currentPage: 1,
                lastPage: 1
            },
        }
    },
    created() {
        this.getData()
    },
    methods: {
        async getData() {
            const res = await CraftVillageRequest.getCraftVillageById(this.id, this.page);
            if (res.code === 200) {
                this.CraftVillage = res.data
                this.stores = res.store
                this.paginate = res.paginate

            }
        },

        onPageChange(page) {
            this.page = page;
            this.$router.push({ query: { page: page } });
            this.getData();
            setTimeout(() => {
                window.jQuery("html, body").animate({ scrollTop: 850 }, 300);
            }, 500);
        },
        openModalSlickSlider(image) {
            this.$refs.modalSlickSlider.show(cloneObject(image));
        }
    }
}
</script>

<style scoped lang="scss">
.layout-detai-craft {
    .title-craft {
        .name-craft {
            font-weight: 600;
            color: rgb(99, 99, 98);
            font-size: 25px;
        }

        .description {
            color: #8f8f8f;
        }
    }

    .shop-suggest {
        .title-shop-suggest {
            font-weight: 600;
            font-size: 20px;
            margin-top: 2.5rem;
        }

        .shop {
            border: 1px solid #ccc;
            // height: 250px;
            border-radius: 5px;
            box-shadow: 2px 2px 2px 2px #d0d0d0;

            .baner-shop {
                width: 300px;
                height: 170px;
                img {
                    cursor: pointer;
                    border-top-left-radius: 5px;
                    width: 300px;
                    height: 170px;
                }

                .baner-child {
                    text-align: center;

                    img {
                        padding: 1px;
                        height: 45px;
                        width: 50px;
                    }

                    .content {
                        position: relative;
                        color: white;
                        background-size: cover;

                        img {
                            cursor: pointer;
                            width: 75px;
                        }

                        .centered {
                            position: relative;
                            font-size: 12px;
                            top: -50%;
                            color: #ffffff;
                            padding: 13px 5px 10px 8px;
                            cursor: pointer;
                            background: rgba(0, 0, 0, 0.5);
                        }
                    }
                }
            }

            .name-shop {
                .box-title-shop-top {
                    height: 132px;
                }

                .title {
                    font-weight: 600;
                    font-size: 20px;
                    margin-bottom: 1px !important;
                    font-weight: 600;
                    display: block;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    /* số dòng hiển thị */
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .address {
                    margin-top: 10px;
                    font-size: 14px;
                    color: #616161;
                }

                .contract {
                    margin-top: 10px;
                    font-size: 14px;
                    color: #616161;
                }

                .feddback {
                    margin-top: 10px;
                }

                .custom-butom-detail {
                    margin-top: 23px;
                    height: 43px;
                    line-height: 31px;
                }
            }

            .logo-elim-default {
                width: 300px;
                margin: 89px 13px 10px -10px;
                min-width: 300px;

                img {
                    min-width: 300px;
                    display: block;
                    margin: auto;
                }
            }
        }
    }

    .banner-craft {
        background: #dbdada;
        text-align: center;

        .image {
            height: auto;
        }
    }
}
</style>




