<template>
    <div id="user-profile--info">
        <div class="content-box" style="padding: 24px">
            <div class="title">
                {{ $t('favoriteProduct')}}
            </div>
            <div class="product-box">
                <div class="row">
                    <div class="col-xl-3 col-sm-4 col-6" v-for="(product, index) in fProducts"
                         :key="index + 1">
                        <product-item :product="product.product"></product-item>
                    </div>
                </div>
                <div class="paginate">
                    <Paginate :value="paginate" :pagechange="onPageChange"></Paginate>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ProductItem from "@/components/ProductItem";
import $ from 'jquery';
import ProductRequest from "@/api/requests/ProductRequest";
import Paginate from "@/components/Paginate";

export default {
    name: "FavoriteProduct",
    components: {ProductItem, Paginate},
    data() {
        return {
            fProducts: [],
            page: this.$route.query.page || 1,
            paginate: {
                currentPage: 1,
                lastPage: 1
            }
        }

    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            const res = await ProductRequest.getFavoriteProducts(this.page);
            if (res.err_code !== 0) {
                this.$router.replace({name: 'PageNotFound'});
                return;
            }

            this.fProducts = res.products;
            this.paginate = res.paginate;
        },
        onPageChange(page) {
            this.page = page;
            this.$router.push({query: { page:page }});
            this.getData();
            setTimeout(() => {
                window.jQuery("html, body").animate({ scrollTop: 0 }, 300);
            }, 500);
        },
    }
}
</script>

<style lang="scss">
@import '../../assets/css/userInfo';
.paginate {
  width: fit-content;
  margin: auto;
}
</style>
