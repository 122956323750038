<template>
    <div class="wrap-container">
        <div id="post-detail">
            <h3 class="post-title">{{post.title}}</h3>
            <p class="post-time">{{d(post.created_at)}}</p>
            <div class="post-image" v-if="post.thumbnail">
                <img :src="post.thumbnail.uri" alt="thumb-post">
            </div>
            <div v-html="post.content" class="post-content"></div>
        </div>

        <div class="related-post" v-if="relationPosts.length > 0">
            <div class="row post-title">
                <div class="col-lg-2 title-text">
	                {{ $t('relatedPost')}}
                </div>
                <div class="col-lg-10" style="margin: auto">
                    <div class="title-line"></div>
                </div>
            </div>
            <div class="row row-post">
                <div class="col-lg-3 col-sm-4" v-for="(post) in relationPosts" :key="post.id">
                    <router-link :to="{name: 'PostDetail', params: {id: post.slug}}">
                        <div class="post">
                            <div class="post-image" v-if="post.thumbnail">
                                <img :src="post.thumbnail.uri" alt="thumb-post">
                            </div>
                            <div class="post-content">
                                <div class="title">
                                    {{post.title}}
                                </div>
                                <div class="publish-time">
                                    {{d(post.created_at)}}
                                </div>
                            </div>

                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import HomeRequest from "@/api/requests/HomeRequest";
    import moment from 'moment'

    export default {
        name: "PostDetail",
        props: ['id'],
        data() {
            return {
                relationPosts: [],
                post: {title: ''},
            }
        },
        mounted() {
            window.scrollTo(0, 0);
            this.getData();

        },
        watch: {
            id: function () {
                window.scrollTo(0, 0);
                this.getData();
            },
        },
        methods: {
            async getData() {
                const res = await HomeRequest.getDetailPost(this.id);
                if (res.code !== 200) {
                    this.$router.replace({name: 'PageNotFound'});

                    return;
                }
                this.post = res.post;
                this.relationPosts = res.relationPosts;
                document.title = this.post.title;
            },
            d(tzTime) {
                if (!tzTime) {
                    return '';
                }
                const m =  moment(tzTime);
                // if (m.format('YYYY') == (new Date).getFullYear()) {
                //     return m.format('DD/MM HH:mm')
                // }

                return m.format('DD/MM/YYYY HH:mm')
            },
        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/css/post";
</style>
