<template>
  <div class="flex-row">
    <ul class="list-none d-flex container-tabs  text-center overflow-auto whitespace-nowrap">
      <li v-for="tab in tabList"
          :key="tab.property"
          class="w-full px-4 container-tab"
          :class="{'active': tab.property == activeTab}"
      >
        <label :for="`tab-${tab.property}`"
               v-text="tab.label"
               class="cursor-pointer text-tab d-block"
        />
        <input :id="`tab-${tab.property}`"
               type="radio"
               :value="tab.property"
               v-model="activeTab"
               class="d-none"
        />
      </li>
    </ul>

    <div class="flex-grow bg-white rounded-lg shadow-xl">
      <div class="bg-white p-3 rounded-circle">
        <header-classified-advertising :property="activeTab"/>
        <tab-news :property="activeTab"/>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderClassifiedAdvertising from "@/components/classified_advertising/HeaderClassifiedAdvertising";
import TabNews from "@/components/classified_advertising/TabNews";

export default {
  components: {TabNews, HeaderClassifiedAdvertising},
  props: {
    tabList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeTab: this.$route.query.property || 2,
    };
  },
};
</script>

<style lang="scss" scoped>
.container-tabs {
  border-bottom: #e2e2e2 2px solid;

  .container-tab {
    font-size: 15px;
    color: #575E6C;
    font-weight: 600;
    padding-top: 10px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    &.active {
      border-bottom: #FF0000 3px solid;
      color: #FF0000;
      border-radius: 1px;

    }

    .text-tab {
      cursor: pointer;
    }
  }
}
</style>
