<template>
  <button class="btn-login-limID" @click="doLogin()">
      {{ $t('loginWithLimID') }}
  </button>
</template>

<script>
    import config from "@/config";
    import toastr from "toastr";
    import store from "@/store";

    export default {
        name: 'LoginLimId',
        props: ['redirect'],
        data() {
            return {
                loading: false,
            }
        },
        methods: {
            async handleLogin(data) {
                try {
                    this.loading = true;

                    if (!data.err_code && data.user) {
                        store.commit('SetAuth', data);

                        toastr.success(
                            this.$t('loginSuccessDesc') + ": " + data.user.name,
                            this.$t('loginSuccessTitle')
                        )

                        if (this.redirect) {
                            setTimeout(() => {
                                location.replace(this.redirect);
                            }, 500)
                        }
                    } else {
                        toastr.error(data.message);
                    }
                } catch (error) {
                    toastr.error(
                        error.message || this.$t('sys.api.networkExceptionMsg'),
                        this.$t('sys.api.errorTip')
                    )
                } finally {
                    this.loading = false;
                }
            },
            doLogin() {
                window.onmessage = async (e) => {
                    if (e.data && e.data.userData) {
                        await this.handleLogin(e.data.userData)
                    }
                }

                let popup = window.open(
                    config.App.v1LoginLimUrl + `?method=login`,
                    '',
                    'width=700,height=500'
                )
            }
        }
    }

</script>

<style scoped lang="scss">
    .btn-login-limID {
        padding: 10px;
        border-radius: 5px;
        border: none;
        font-size: 15px;
        font-weight: 500;
        text-align: center;
        color: #fff;
        background-color: #dc3545;
        width: 100%;
    }
</style>