<template>
    <div id="top-campaign">
        <div class="wrap-container">
            <div class="row-campaign">
                <div class="row row-content no-margin-hoz">
                    <div v-for="(campaign, index) in topCampaigns" :key="campaign.id" class="col-lg-6 col-sm-12 no-padding" style="margin-bottom: 16px">
                        <special-campaign
                                :campaign="campaign"
                                v-if="campaign.type != listType.TYPE_PRODUCT_FLASH_SALE"
                                :index="index"
                        >
                        </special-campaign>
                    </div>
<!--                    <div class="col-4 no-padding" >-->
<!--                        <div class="top-campaign-item right">-->
<!--                            <div class="campaign-header">-->
<!--                                <img src="/assets/img/header/ic_play.svg" alt="">-->
<!--                                <h3>Video nhà xưởng</h3>-->
<!--                                <router-link class="viewmore" :to="'/'">Xem thêm</router-link>-->
<!--                            </div>-->
<!--                            <div class="campaign-content">-->
<!--                                <div class="row list-items">-->
<!--                                    <div class="col-6" v-for="(product, index) in 2" :key="index + 1">-->
<!--                                        <div id="product-item">-->
<!--                                            <a>-->
<!--                                                <div class="product-thumb zoom">-->
<!--                                                    <div class="video-info">-->
<!--                                                        <img src="/assets/img/header/ic_play_white.svg" alt=""/>-->
<!--                                                        <span>00:35</span>-->
<!--                                                    </div>-->
<!--                                                    <a class="video-play-btn">-->
<!--                                                        <img src="/assets/img/header/ic_play_white.svg" alt=""/>-->
<!--                                                    </a>-->
<!--                                                    <img :src="'./assets/img/header/video_thumb.png'" alt="">-->
<!--                                                </div>-->
<!--                                                <div class="product-info">-->
<!--                                                    <p class="product-name">{{'Tên sản phẩm'}}</p>-->
<!--                                                    <div class="ship-span">-->
<!--                                                        Sẵn sàng chuyển hàng-->
<!--                                                    </div>-->
<!--                                                    <div class="price-wrap">-->
<!--                                                        <div class="price">200000đ-->
<!--                                                        </div>-->
<!--                                                        <div class="old-price">-->
<!--                                                            <span class="price">263000đ</span>-->
<!--                                                            <span class="discount-percent"> -50% </span>-->
<!--                                                        </div>-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                                <div class="min-quantity">-->
<!--                                                    <span class="quantity">2 chiếc</span>-->
<!--                                                    <span class="sub-title">(Đơn hàng tối thiểu)</span>-->
<!--                                                </div>-->
<!--                                            </a>-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
            </div>
            <div v-for="(campaign) in flashSaleCampaigns" :key="campaign.id">
                <hot-campaign :campaign="campaign" v-if="campaign.type == listType.TYPE_PRODUCT_FLASH_SALE"></hot-campaign>
            </div>
        </div>
    </div>
</template>

<script>
    import ProductItem from "@/components/ProductItem";
    import CountDown from "@/components/CountDown";
    import SpecialCampaign from "@/components/campaign/SpecialCampaign";
    import HotCampaign from "@/components/campaign/HotCampaign";

    const listType = {
        'TYPE_PRODUCT_TOP' : 3,
        'TYPE_PRODUCT_NEW' : 4,
        'TYPE_PRODUCT_FLASH_SALE': 5
    }

    export default {
        name: "TopCampaign",
        components: {CountDown, ProductItem, SpecialCampaign, HotCampaign},
        props: ['topCampaigns', 'flashSaleCampaigns'],
        data() {
            return {
                listType: listType
            }
        },
    }
</script>

<style lang="scss">
    @import "../assets/css/topCampaign";
    @import "../assets/css/productItem";

    .video-info {
        background: rgba(0, 0, 0, 0.38);;
        border-radius: 4px;
        position: absolute;
        padding: 2px 5px;
        top: 15px;
        left: 15px;
        z-index: 5;

        span {
            padding-left: 5px;
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            color: #FFFFFF;
        }
    }

    .video-play-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
        z-index: 5;
        background: rgba(0, 0, 0, 0.38);;
        border: 1px solid #FFFFFF;
        border-radius: 20px;

        img {
            width: 20px;
            height: 20px;
            object-fit: contain;
        }
    }
</style>
