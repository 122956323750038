<template>
  <div class="top-header"><h6 class="font-weight-normal"><span class="font-weight-bolder">ELIM</span> - Sàn giao dịch
    B2B của người Việt</h6></div>
  <Header v-if="show" :is-cart="isCart"></Header>
  <HeaderMobile v-if="show" :is-cart="isCart"></HeaderMobile>

  <div class="main-content" :class="!show ? 'padding-header-login' : 'padding-header-mobile'">
    <router-view :key="$route.path"></router-view>
    <chat v-if="showChatBar && showChatBox"/>
  </div>

  <Footer v-if="show"></Footer>
</template>

<script lang="js">
import './assets/css/main.scss';

import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import CartRequest from "@/api/requests/CartRequest";
import Chat from "@/components/Chat";
import store from "@/store";
import HeaderMobile from "@/components/HeaderMobile";

export default {
  name: 'App',
  components: {HeaderMobile, Chat, Header, Footer},
  data() {
    return {
      cartItems: [],
      cartCount: null,
    }
  },
  mounted() {
    this.getQuantityCart();
  },
  methods: {
    async getQuantityCart() {
      const res = await CartRequest.get();
      this.cartItems = res.items;
      store.commit('SetCountCartItem', this.cartItems.length);
    },
  },
  computed: {
    auth: () => store.state.Auth,
    showChatBox: () => store.state.showChatBox,
    showChatBar: () => store.state.showChatBar,
    show() {
      const arrNotShowName = ['Login', 'Register', 'ResetPassword'];

      return !arrNotShowName.includes(this.$route.name);
    },
    isCart() {
      return this.$route.name === 'Cart';
    },
  },
}
</script>
<style lang="scss">
@import "assets/css/login";

.main-content{
  margin: 0 auto;
  padding: 25px 60px 0 60px;
  max-width: 1500px;
  min-width: 720px;
  height: 100%;
}
.top-header {
  background: linear-gradient(to right, #355c7d, #6c5b7b, #c06c84);
  padding: 16px 0;
  text-align: center;

  h6 {
    color: #fff;
  }
}

@media screen and (max-width: 992px) {
  .padding-header-mobile {
    padding-top: 150px;
  }
  .padding-header-login {
    padding-top: 0;
  }

}

#app {
  background-color: #fff;
}
</style>
