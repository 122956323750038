<template>
    <div style="padding-left:0" id="chat-message">
        <div v-if="currentConversation">
            <div class="title">
                <div class="info-shop">
                    <div class="avatar">
                        <img :src="currentConversation.avatar" class="avatar-content">
                    </div>
                    <b class="name">{{ currentConversation.name }}</b>
                </div>
                <router-link :to="{ name: 'ShopDetail', params: {id: currentShopId} }" style="margin: auto 0">
                    <button class="btn btn-sm btn-chat"><i class="btn-icon fas fa-store"></i>Đến cửa hàng</button>
                </router-link>
            </div>
            <div class="chat">
                <div class="chat-messages">
                    <ul class="messages small-scroll" ref="messages" id="messages">
                        <li v-for="(m, mIndex) in messages" class="message" :key="mIndex">
                            <div class="chat-avatar" v-if="m.type==='in'">
                                <img :src="m.target.avatar.uri">
                            </div>
                            <div class="chat-content" :class="'chat-'+m.type">
                                <div :class="'content-' + m.type">
                                    <div class="chat-message-content">{{m.message}}</div>
                                    <template v-if="m.images && m.images.length > 0">
                                      <ul class="chat-images" v-if="m.images.length > 1">
                                        <li class="chat-image" v-for="(img,index) in m.images" :key="index">
                                          <q-image :src="img.url" styles="width: 100%; max-height: 400px; margin-bottom: 11px"/>
                                        </li>
                                      </ul>
                                      <q-image v-else :src="m.images[0].url" class="chat-single-image" styles="width: 100%; max-height: 400px; margin-bottom: 11px"/>
                                    </template>

                                </div>
                              <div class="chat-time">
                                  {{d(m.created_at)}}
                              </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="chat-controls">
                    <div class="chat-input-wrapper">
                        <div class="chat-input">
                            <textarea @keypress="onKeyPress($event)" v-model="model.message" placeholder="Aa"></textarea>
                            <ul class="chat-images">
                              <li class="chat-image" v-for="(img,index) in model.images" :key="index">
                                  <img :src="img.url">
                                  <span class="close" @click.prevent="removeImage(index)">
                                      <i class="fa fa-remove"></i>
                                  </span>
                              </li>
                            </ul>
                        </div>
                        <div class="chat-send" @click="showUpload()">
                            <i class="fa fa-paperclip"></i>
                            <input ref="file" @change="onImageAdded($event)" accept="images/*" type="file" style="display: none;height: 1px;width: 1px"/>
                        </div>
                    </div>

                </div>
            </div>
            <ChatModalImage ref="chatModal"/>
        </div>
        <div class="col-lg-12 chat chat-empty" style="padding-left:0" v-else>
            <img src="/assets/img/header/ic_message.svg" width="100">
            <em>
	            {{ $t('chooseShopToChat')}}
            </em>
        </div>
    </div>
</template>

<script>
import {throttle} from "@/lib/utils";
import {chat$post, chat$upload} from "@/api/requests/ChatRequest";
import ChatModalImage from "@/components/chat/ChatModalImage";

import moment from "moment";
import QImage from "@/components/QImage";

let app;
export default {
    name: "ChatMessages",
    components: {QImage, ChatModalImage},
    props: ['defaultCid', 'shopId', 'shopName', 'shopAvatar'],
    data() {
        return {
            page: 1,
            isPageEnded: false,
            model: {
                cid: this.defaultCid,
                shop_id: this.shopId,
                message: '',
                images: [],
            },
            messages: [
            ],
            currentConversation: '',
            currentShopId: 0,
            firstLoad: true
        }
    },
    mounted() {
        app = this;
        // if (this.shopId) {
        //    this.currentConversation = {
        //       target: {
        //           name: this.shopName,
        //           avatar: {
        //               uri: this.shopAvatar
        //           }
        //       }
        //    }
        // }

    },
    updated() {
        if (this.firstLoad && this.messages.length > 0) {
            this.$refs.messages.addEventListener('scroll', this.onScrolledTop);
            this.firstLoad = false;
        }
    },
    methods: {
        showImage(images, index) {
            this.$refs.chatModal.show(images, index);
        },
        showUpload() {
            if (this.model.images.length > 5) {
                alert('Bạn được chọn tối đa 5 ảnh');
                return;
            }

            this.$refs.file.click();
        },
        async onImageAdded(ev) {
            const files = ev.target.files;
            if (files.length === 0) {
                alert('Vui lòng chọn file');
                return;
            }

            const res = await chat$upload('/v1/uploads', files[0]);
            ev.target.value = '';

            if (res.code !== 200) {
                alert(res.message);
                return;
            }

            this.model.images.push(res.image);
        },

        onScrolledTop() {
            if (this.isPageEnded) {
                console.log('No more data to load');
                this.$refs.messages.removeEventListener('scroll', this.onScrolledTop);
                return;
            }
            const el = this.$refs.messages;
            const maxScrollTop = el.clientHeight - el.scrollHeight;

            if (Math.abs(maxScrollTop - el.scrollTop) < 100) {
                console.log('Top reached');
                this.throttleLoad();
            }
        },
        throttleLoad: throttle(() => {
            app.page++;
            app.load(app.page);

        }, 1000),
        async load(page = 1) {

            const res = await chat$post('/v1/chat/messages', {
                cid: this.model.cid,
                page,
            });

          //    res.data.messages = res.data.messages.reverse();
            if (res.code === 200) {
                if (page === 1) {
                    this.messages = res.data.messages;

                    // this.$refs.messages.addEventListener('scroll', this.onScrolledTop);
                } else {
                    if (res.data.messages.length === 0) {
                        this.isPageEnded = true;
                        return;
                    }

                    const oldScrollHeight = this.$refs.messages.scrollHeight;

                    const messages = res.data.messages;
                    messages.forEach(m => {
                        this.messages.push(m);
                    });

                    //this.messages = messages;
                    this.$nextTick(() => {
                        console.log('keep scrolling:', page)
                      //  this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight - oldScrollHeight;
                    });
                }
                if (res.data.conversation) {
                    this.currentConversation = {
                        name: res.data.conversation.target.name,
                        avatar: res.data.conversation.target.avatar.uri
                    }
                    let cluxId = res.data.conversation.target.clux_id;
                    this.currentShopId = cluxId.slice(5, 7);
                }
            } else {
                this.messages = [];
            }
        },
        pushMessage(message) {
            if (message.cid === this.model.cid) {
                const exists = this.messages.find(m => m.id === message.id);
                if (!exists) {
                    this.messages.push(message);
                  //this.scrollToEnd();
                }
            }
        },
        getMessages() {
            return this.messages;
        },
        getCid() {
            return this.model.cid;
        },
        setCid(cid) {
            this.model.cid = cid;
            this.isPageEnded = false;
            this.page = 1;
            if (this.shopId) {
                this.currentConversation = {
                    name: this.shopName,
                    avatar: this.shopAvatar
                };
                this.currentShopId = this.shopId;
            }
            this.load();

        },
        scrollToEnd() {
            console.log('scroll to end');
            setTimeout(() => {
                const $target = window.$(this.$refs.messages);
                $target.animate({scrollTop: this.$refs.messages.scrollHeight}, 500);
            }, 100);
        },
        onKeyPress(ev) {
            if (ev.shiftKey || ev.ctrlKey || ev.altKey) {
                return;
            }

            if (ev.key === 'Enter') {
                this.send();
            }
        },
        async send() {
            const hasImage = this.model.images && this.model.images.length > 0;

            if (!this.model.message.trim() && !hasImage) {
                alert('Vui lòng nhập nội dung');
                this.model.message = '';
                return;
            }

            const res = await chat$post('/v1/chat/post', this.model);
            if (res.code !== 200) {
                alert(res.message);
                return;
            }

            this.$parent.$refs.chatConversation.setLastMessage(this.model.cid, res.data.message);
            if (this.model.cid === '') {
                this.$parent.$refs.chatConversation.load();
            }
            //this.messages.push(res.data);
            this.model.message = '';
            this.model.images = [];
            this.scrollToEnd();
        },
        removeImage(index) {
            this.model.images.splice(index, 1);
        },
        d(tzTime) {
            if (!tzTime) {
              return '';
            }
            const m =  moment(tzTime);

            return m.format('DD/MM HH:ss')
        },
    }
}
</script>

<style scoped lang="scss">
@import '../../assets/css/chat';
</style>