<template>
    <div class="row" id="chat-box">
        <template v-if="isLoading">
            <div class="d-flex align-items-center justify-content-center" style="width: 100%">
                <i class="fa fa-spinner fa-spin fa-3x elim-loading"></i>
            </div>
        </template>
        <template v-else>
            <div class="col-lg-4 sol-sm-5" style="padding: 0">
                <ChatConversations ref="chatConversation" :default-cid="currentCid" :shop-id="shopId"
                                   :shop-name="shopName" :shop-avatar="shopAvatar" @change="onConversationChange"/>
            </div>
            <div class="col-lg-8 sol-sm-7">
                <ChatMessages ref="chatMessage" :default-cid="currentCid" :shop-id="shopId"
                              :shop-name="shopName" :shop-avatar="shopAvatar"/>
            </div>
        </template>


    </div>
</template>

<script>
import ChatConversations from "@/components/chat/ChatConversations";
import ChatMessages from "@/components/chat/ChatMessages";
// import $router from "../../lib/SimpleRouter";
import {chatAddMessageListener} from "@/api/requests/ChatRequest";

export default {
    name: "ChatIndex",
    props: ['shopId', 'shopName', 'shopAvatar'],
    data() {
        return {
            currentCid: null,
            isLoading: false,
        }
    },
    components: {ChatMessages, ChatConversations},
    mounted() {
        window.scrollTo(0, 0);
        const sound = new Audio('/assets/notify.mp3');
        const onMessage = (message) => {
            if (message.type === 'in') {
                this.$refs.chatConversation.increaseBadge(message.cid);
                try {
                    sound.play();
                } catch (e) {
                    console.log(e);
                }

            }

            this.$refs.chatMessage.load(1);
            this.$refs.chatConversation.setLastMessage(message.cid, message.message);
        };

        const onReload = (currentCid) => {
            const $ref = this.$refs.chatMessage;

            if (currentCid === $ref.getCid()) {
                $ref.load(1);
                console.log('Some messages exists but not showing. Trying refresh message');
            }
        };

        chatAddMessageListener(onMessage);



        // this.$router.push('ho-so-cua-toi/cid/{cid}',  (params) => {
        //     this.$refs.chatConversation.setCid(params.cid);
        //     this.$refs.chatMessage.setCid(params.cid);
        // });

        // this.$router.init();
    },
    methods: {
        onConversationChange(conversation) {
            this.$refs.chatConversation.setCid(conversation.cid);
            this.$refs.chatMessage.setCid(conversation.cid);
        }
    }
}
</script>

<style scoped lang="scss">
@import '../../assets/css/chat';
</style>