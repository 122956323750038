<template>
    <div class="container pb-3">
        <p class="widget-title">{{$t('all_village')}}</p>
        <div class="row campaign-content mt-3">
            <div class="col-xl-3 col-md-3 col-md-6 padding-mobile" v-for="item in CraftVillage" :key="item.id">
                <div id="product-item" class="zoom-img craft-icon" style="border: 1px solid #f0f0f0" v-if="item.image">
                    <a :href="'/thong-tin-lang-nghe/' + item.id">
                        <div class="product-thumb craft-thumb">
                            <img v-lazy="item.image.uri" alt="">
                        </div>
                        <div class="product-info" style="padding: 0 5px">
                            <p class="craft-name" :title="item.name">
                                {{ item.name }}
                            </p>
                        </div>
                        <div class="total-shop">
                            <div class="total-item ml-2">{{ item.countStore }} {{$t('shop')}}
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="paginate">
            <Paginate :value="paginate" :pagechange="onPageChange"></Paginate>
        </div>
    </div>
</template>

<script>
import CraftVillageRequest from '@/api/requests/CraftVillageRequest';
import Paginate from "@/components/Paginate";
export default {
    name: 'List',
    components: { Paginate },
    data() {
        return {
            CraftVillage: {},
            page: this.$route.query.page || 1,
            paginate: {
                currentPage: 1,
                lastPage: 1
            }
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        async getData() {
            const res = await CraftVillageRequest.getCraftVillageAll(this.page);
            if (res.code === 200) {
                this.CraftVillage = res.data
                this.paginate = res.paginate
            }
        },
        onPageChange(page) {
            this.page = page;
            this.$router.push({ query: { page: page } });
            this.getData();
            setTimeout(() => {
                window.jQuery("html, body").animate({ scrollTop: 0 }, 300);
            }, 500);
        },

    }
}
</script>

<style scoped lang="scss">
.widget-title {
    width: 100%;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #383C4F;
    margin-bottom: 15px
}


.total-shop {
    .total-item {
        color: #ccc;
    }
}

.craft-name {
    margin-bottom: 1px !important;
    font-weight: 600;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* số dòng hiển thị */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.craft-thumb {
    background: #dbdada;
    height: 150px !important;
}

.product-info {
    height: 27px;
    margin-left: 5px;
}

.product-thumb {
    border: none !important;
}

.craft-icon {
    height: 235px;
}

@media screen and (max-width: 1024px) {
    .craft-icon .product-thumb {
        height: auto !important;
    }
}
</style>