import config from "../config";
import {uuidv4} from "@/lib/utils";
import storage from "@/lib/storage";
import store from "@/store";

export function getUUID(): string {
    let uuid = storage.get<string>('uuid');
    if (!uuid) {
        uuid = uuidv4();
        storage.set('uuid', uuid);
    }

    return uuid;
}

export default class BaseRequest {
    private readonly className: string;
    private  uuid: string = '';
    private  token: string = '';

    constructor(className: string) {
        this.className = className;
        if (!config.App.v1ApiUrl.endsWith('/api.php')) {
            alert('End point must ends with api.php')
        }
    }

    dispatch(method: string, ...args: any): Promise<any> {
        const $args: Array<any> = [];
        for (const a of args) {
            $args.push(a);
        }

        return new Promise<any>(((resolve, reject) => {

            const authData  = store.state.Auth;
            this.uuid = getUUID();

            const data = {
                className: this.className,
                method: method,
                args: $args,
                province: 18,
                uuid:  this.uuid
            };

            fetch(config.App.v1ApiUrl + '?' + this.className+'::'+method, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':  authData ? authData.token : ''
                },
                body: JSON.stringify(data),
            })
                .then((response: any) => {
                    try {
                        const obj =  response.json();
                        return obj;
                    } catch (e1) {
                        console.error(response);
                        reject(e1);
                    }

                })
                .then((data: any) => {
                    if (data.code === 200) {
                        resolve(data.data)
                    } else {
                        // alertWarning(data.message);
                        reject(data.message);
                    }
                }).catch(err => {
                reject(err);
                if (config.App.debug) {
                    console.error(err.message);
                } else {
                    console.error('Không thể kết nối đến máy chủ');
                }
            });

        }))
    }

    upload(uri: string, files: any): Promise<any> {
        return new Promise<any>(((resolve, reject) => {
            const authData  = store.state.Auth;
            const formData = new FormData();
            formData.append('_token', authData ? authData.token : '');
            /// const fileField = document.querySelector('input[type="file"]');

            // forEach(params, (v,k) => {
            //     formData.append(k ,v);
            // });

            for (let i = 0; i< files.length; i++) {
                formData.append('photo', files[i]);
            }

            return fetch(uri, {
                method: 'POST',
                body: formData
            })
                .then((response: any) => {
                    try {
                        const obj =  response.json();
                        return obj;
                    } catch (e1) {
                        console.error(response);
                        reject(e1);
                    }

                })
                .then((data: any) => {
                    if (data.code === 200) {
                        resolve(data.image)
                    } else {
                        // alertWarning(data.message);
                        reject(data.message);
                    }
                }).catch(err => {
                    reject(err);
                    if (config.App.debug) {
                        console.error(err.message);
                    } else {
                        console.error('Không thể kết nối đến máy chủ');
                    }
                });

        }))
    }
}
