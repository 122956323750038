import {botErr, botInfo} from "./utils";

let doc = window.document;

declare global {
    interface Window { firebase: any; }
}

export async function initFirebase(params:any = {}) {
    console.log('initFirebase:');
    let noop = () => {};
    const onReceived = params.onReceived || noop;
    const onRefreshed = params.onRefreshed || noop;
    const onMessage = params.onMessage || noop;
    const onError = params.onError || noop;


    let firebaseHost = 'https://www.gstatic.com/firebasejs/7.6.1/';
    let scripts = [
        'firebase-app.js',
        //   'firebase-analytics.js',
        'firebase-auth.js',
        //    'firebase-firestore.js',
        'firebase-messaging.js'
    ];

    for (let i = 0; i < scripts.length; i++) {
        await loadScript(firebaseHost + scripts[i]);
    }

    if (!window.firebase) {
        botErr('No firebase sdk found')
        return;
    }

    if (!window.firebase.messaging) {
        botErr('No firebase.messaging sdk found')
        return;
    }

    let firebase = window.firebase;

    const firebaseConfig = {
        apiKey: "AIzaSyAnvlFRik6jR9aYnxssI5saqZ3KZeQEJzE",
        authDomain: "elimvn-6cc86.firebaseapp.com",
        projectId: "elimvn-6cc86",
        storageBucket: "elimvn-6cc86.appspot.com",
        messagingSenderId: "1041750975312",
        appId: "1:1041750975312:web:548f34264b5ae00b7d0255",
        measurementId: "G-X0LRTW6MX2"
    };

    firebase.initializeApp(firebaseConfig);


    const messaging = firebase.messaging();
    messaging.usePublicVapidKey("BOEV9KkgHMjh2Pf0Z5d3siUAAxC_GngWljnnOCyEP1AYL4q78YXMwA7TWr5AkxhQYH8MiWQfT2KFCDuOLfHG2lk");


    messaging.requestPermission().then(function() {
        botInfo('Notification permission granted.');
        // TODO(developer): Retrieve an Instance ID token for use with FCM.
        messaging.getToken().then(function(currentToken: any) {

            if (currentToken) {
                // sendTokenToServer(currentToken);
                ///updateUIForPushEnabled(currentToken);
                onReceived(currentToken);
            } else {
                // Show permission request.
                botInfo('No Instance ID token available. Request permission to generate one.');
                // Show permission UI.
                //updateUIForPushPermissionRequired();
                ///etTokenSentToServer(false);
            }
        }).catch(function(err: Error) {
            botErr(err);
            onError(err);
            // console.log('An error occurred while retrieving token. ', err);
            // showToken('Error retrieving Instance ID token. ', err);
            ///setTokenSentToServer(false);
        });
    }).catch(function(err: Error) {
        onError(err);
        botErr(err);
    });

    messaging.onTokenRefresh(function() {
        messaging.getToken().then(function(refreshedToken: any) {
            botErr('Token refreshed.');
            onRefreshed(refreshedToken);
            // Indicate that the new Instance ID token has not yet been sent to the
            // app server.
            //   setTokenSentToServer(false);
            // Send Instance ID token to app server.
            /// sendTokenToServer(refreshedToken);
            // ...
        }).catch(function(err: Error) {
            onError(err);
            //  console.log('Unable to retrieve refreshed token ', err);
            botErr(err);
        });
    });

    messaging.onMessage(function(payload: any) {
        //console.log('Message received. ', payload);
        onMessage(payload)
        // ...
    });
}


// https://www.gstatic.com/firebasejs/7.6.0/firebase-app.js
// https://www.gstatic.com/firebasejs/7.6.0/firebase-analytics.js
// https://www.gstatic.com/firebasejs/7.6.0/firebase-auth.js
// https://www.gstatic.com/firebasejs/7.6.0/firebase-firestore.js
// https://www.gstatic.com/firebasejs/7.6.0/firebase-messaging.js

function loadScript(link: string) {
    console.log('loadScript:', link);
    return new Promise((resolve, reject) => {
        let script = doc.createElement('script');

        script.src = link;
        script.onload = function() {
            resolve(true)
        }
        script.onerror = function() {
            reject()
        }
        doc.head.appendChild(script)
    })

}
