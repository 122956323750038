<template>
  <div class="container-header row ">
    <div class="container-dropdown">
      <q-tree-select-input class="content-dropdown"
                           :value="selectedCategories"
                           placehoder="Tất cả danh mục"
                           @input="onInput"
                           v-if="categories?.length"
                           :options="categories"
      />
    </div>

    <div class="container-input">
      <input v-model="keyword"
             :placeholder="'Nhập vào thông tin cần tìm kiếm'"
             class="input-search txt-value"
             @keyup.enter="onSearch"
      />
    </div>

    <button @click="onSearch" class="btn-search">
      <i class="fa fa-search" aria-hidden="true"></i>
    </button>
  </div>
</template>
<script>
import Dropdown from "../Dropdown.vue";
import CrawlRequest from "@/api/requests/CrawlRequest";
import QTreeSelectInput from "@/components/qtreeselect/QTreeSelectInput";

const TAB_B2B = 3;

export default {
  components: {
    QTreeSelectInput,
    Dropdown,
  },
  props: ['property'],
  data() {
    return {
      keyword: this.$route.query.keyword || '',
      categories: [],
      selectedCategories: this.$route.query.categories?.split(',') || [],
    };
  },
  methods: {
    onInput(value) {
      this.selectedCategories = value;
      this.$router.replace({
        query: Object.assign({ ...this.$route.query }, {
          keyword: this.keyword,
          categories: this.selectedCategories?.join(',')
        })
      });
      this.$emit('onSearch');

    },
    onSearch() {
      this.$router.replace({
        query: Object.assign({ ...this.$route.query }, {
          keyword: this.keyword,
          categories: this.selectedCategories?.join(',')
        })
      });
      this.$emit('onSearch');

    },
    // onReset() {
    //   this.keyword = ''
    // },
    async getCategories() {
      const res = await CrawlRequest.getCategories(this.property == TAB_B2B);
      // console.log("-> res", res);

      if (res) {
        this.categories = res;
      }

    },
  },
  mounted() {
    this.getCategories();
  },
  watch: {
    'property': async function (newVal, oldVal) {
      let query = Object.assign({}, this.$route.query);
      const queryCategories = query.categories;

      query.property = this.property;
      if (newVal == TAB_B2B || oldVal == TAB_B2B) {
        this.getCategories();
        this.selectedCategories = [];
        delete query.categories;
      }

      await this.$router.replace({ query });

      if (!queryCategories || (newVal != TAB_B2B && oldVal != TAB_B2B)) {
        this.$eventBus.emit('LOAD_CRAWL_POST_DATA');
      }
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/css/headerClassifiedAdvertising";

@import "@/assets/css/dropdown";
</style>
