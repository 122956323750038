import {buildQuery, isEmpty} from "@/lib/utils";
import config from "@/config";
import store from "@/store";
const CHAT_URL = config.App.chatUrl;

export function chatAddMessageListener(cb: any, cbReload: any = null, getMessages: any = null) {
    let errorCount = 0;
    const loop = async () => {
        try {
            const messages = getMessages ? getMessages() : [];
            const cid = messages.length > 0 ? messages[0].cid : '';
            const last10 = messages.slice(Math.max(messages.length - 10, 1)).reverse().map((m: { id: any; }) => m.id);
            const res = await chat$post('/v1/polling', {timeout: 30000, hash: last10.join(','), cid});
            if (res.code === 301) {
                console.log('Polling: no data, next run');
                setTimeout(loop, res.nextRun);
            } else if (res.code === 200) {
                cb(res.data);
                setTimeout(loop, 100);
            } else if (res.code === 201) {
                if (cbReload) {
                    cbReload(res.cid);
                }
                setTimeout(loop, 100);
            } else {
                throw new Error('Invalid polling response')
            }
        } catch (err) {
            console.error(err);
            errorCount++;
            if (errorCount > 100) {
                alert('Please reload and retry');
                return;
            }
            setTimeout(loop, 5000);
        }
    };

    loop();
}

export async function chat$get(uri: string, params = {}) {

    uri = CHAT_URL + uri;
    let url =  uri + '?' + buildQuery(params);

    return fetch(url).then(response => {
        return response.json();
    });
}

export async function chat$post(uri: string, params = {}, query = {}) {

    let url =  CHAT_URL + uri;
    if (!params) {
        params = {};
    }

    let authData  = store.state.Auth;

    const authTemp = localStorage.getItem('auth_temp');

    if (isEmpty(authData) && authTemp) {
        // @ts-ignore
        authData = JSON.parse(authTemp);
    }
    if (!isEmpty(query)) {
        url +=  '?' + buildQuery(query);
    }

    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({...params, '_token': authData?.token}),
    })
        .then((response) => {
            return response.json();
        })
}

export function chat$upload(uri: string, photo: any, params = {}) {
    let url =  CHAT_URL + uri;

    const formData = new FormData();

    const authData  = store.state.Auth;

    const authTemp = localStorage.getItem('auth_temp');

    if (isEmpty(authData) && authTemp) {
        // @ts-ignore
        authData = JSON.parse(authTemp);
    }

    for (const [k,v] of Object.entries(params)) {
        // @ts-ignore
        formData.append(k, v);
    }

    formData.append('photo', photo);
    // @ts-ignore
    formData.append('_token', authData?.token);

    return fetch(url, {
        method: 'POST',
        body: formData
    })
        .then((response) => response.json())
        .catch((error) => {
            console.error('Error:', error);
        });
}

