import BaseRequest from "../BaseRequest";

class SectorYearBookRequest extends BaseRequest {
    constructor() {
        super('SectorYearbookRequest');
    }

    async getAllSectorYearBook() {
        return super.dispatch('getAllSectorYearbook');
    }
    async getAllCategory(page: string, search: string) {
        return super.dispatch('getAllCategory', page, search);
    }
    async detailCategories(id: string) {
        return super.dispatch('detailCategories', id);
    }

}

export default new SectorYearBookRequest()
