<template>
  <div class="col-xl-3 banner-content1 col-sm-4">
    <div class="category-title">
      <span>{{ $t('productCategory') }}</span>
      <a @click="onSlick(0)">
        <i class="fas fa-chevron-left"></i>
      </a>
      <a @click="onSlick(1)">
        <i class="fas fa-chevron-right"></i>
      </a>
    </div>
    <div class="category-wrap">
      <div class="slide-category" ref="categorySlider">
        <div class="slider-items" :key="cID" v-for="(cGroup,cID) in getJsonData('categoryGroup', [])">
          <ul class="list-category">
            <li v-for="(menu,index) in cGroup"
                :key="index + 1"
                v-on:mouseover="mouseover(menu)"
                v-on:mouseleave="mouseleave"
            >
              <img class="category-icon" v-if="menu.icon && menu.icon.uri" :src="menu.icon.uri" :alt="menu.name">
              <img class="category-icon" v-else src="/assets/img/header/ic_category.svg">
              <router-link :to="'/danh-muc/' + menu.slug">{{ lang == 'cn' && menu.name_cn ? menu.name_cn : lang == 'en' && menu.name_en ? menu.name_en : menu.name }}</router-link>
              <i class="fas fa-chevron-right"></i>
            </li>
          </ul>
        </div>
      </div>
      <div class="category-detail" v-if="currentMenu">
        <div class="row no-margin-hoz">
          <div class="detail-content col-4" v-for="(item, itemIndex) in currentMenu.data_children" :key="itemIndex + 1">
            <h4>
              <router-link :to="'/danh-muc/' + item.slug">{{ lang == 'cn' && item.name_cn ? item.name_cn : lang == 'en' && item.name_en ? item.name_en : item.name }}</router-link>
            </h4>
            <p>{{ item.summary }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getOption, getJsonData} from "@/lib/utils";
import store from "@/store";

const $ = window.jQuery;

export default {
  name: "BannerHome",
  props: ['sliders'],
  updated() {

  },
  data() {

    return {
      currentMenu: null,
      showCategory: false,
	    lang: store.state.locale
    }
  },

  methods: {
    getJsonData,
    initSlider() {
      $(this.$refs.categorySlider).show().not('.slick-initialized').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        infinite: true,
        autoplay: false,
      });
    },

    onSlick(index) {
      if (index === 0) {
        $(this.$refs.categorySlider).slick("slickPrev");
      } else {
        $(this.$refs.categorySlider).slick("slickNext");
      }
    },
    mouseover(menu) {
      this.currentMenu = menu;
    },
    mouseleave() {

    }
  },
  mounted() {
    this.initSlider();
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/bannerHome";
</style>
