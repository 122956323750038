<template>
    <div v-if="isLoading">
        <div class="d-flex align-items-center justify-content-center p-5" style="width: 100%">
            <i class="fa fa-spinner fa-spin fa-3x elim-loading"></i>
        </div>
    </div>
    <div class="layout-company" v-else>
        <div class="box-top d-flex">
            <div class="card col-9">
                <div class="card-header" style="background-color: #ffff;">
                    <div class="name-company">
                        {{ company.name }}
                    </div>
                    <div class="rating">
                        <star-rating :rating="company.star" v-bind:star-size="20" :show-rating="false"
                            :read-only="true"></star-rating>
                    </div>
                    <div class="company d-flex mt-2">
                        <div class="logo" v-if="company.logo">
                            <img v-lazy="company.logo" alt="">
                        </div>
                        <div class="information">
                            <div class="address" v-if="company.location">
                                <span class="dc">D/c:</span>
                                <span class="ml-3">{{ company.location }}</span>
                            </div>
                            <div class="phone" v-if="company.phone">
                                <span class="dc">Tel:</span>
                                <span class="ml-3">{{ company.phone }}</span>
                            </div>
                            <div class="fax" v-if="company.fax">
                                <span class="dc">Fax:</span>
                                <span class="ml-3">{{ company.fax }}</span>
                            </div>
                        </div>
                        <div class="verify ml-5" v-if="company.is_verify === 1">
                            <img v-lazy="'/assets/logo-xacthuc.png'" alt="">
                        </div>
                    </div>
                    <div class="email_website mt-5">

                        <div class="box_email_website" v-if="company.email">
                            <div class="icon_email_website"><i class="fa fa-envelope"></i></div>
                            <div class="text_email">
                                <p><a style="color:#3B65A7" :href="'mailto:' + company.email">{{ company.email }}</a></p>
                            </div>
                        </div>

                        <div class="box_email_website  d-flex" v-if="company.website && company.website.length">
                            <div class="web" v-for="web in company.website" :key="web.label">
                                <div class="icon_email_website"><i class="fas fa-globe-europe"></i>
                                </div>
                                <div class="text_website">
                                    <p>
                                        <a rel="nofollow" target="_blank" :href="web.link">{{
                                            web.label
                                        }}</a>
                                    </p>
                                </div>
                            </div>

                        </div>
                        <p style="clear:both"></p>
                    </div>
                    <div class="market mt-5" v-if="company.main_marketing && company.company_type">
                        <div class="main-market d-flex" v-if="company.main_marketing">
                            <div class="title-market">
                                <img v-lazy="'https://trangvangvietnam.com/images/icon_4squares.gif'" alt="">
                                <span>Thị trường chính:</span>
                            </div>
                            <div class="text ml-5">{{ company.main_marketing }}</div>
                        </div>
                        <div class="main-market d-flex" v-if="company.company_type">
                            <div class="title-market">
                                <img v-lazy="'https://trangvangvietnam.com/images/icon_4squares.gif'" alt="">
                                <span>Loại hình công ty:</span>
                            </div>
                            <div class="text ml-5">{{ company.company_type }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ccontract -->
            <div class="contract border ml-2">
                <div>
                    <div
                        style="width:280px; height:26px; background:#DB0000; margin:auto; margin-top:13px; border-radius:4px 4px 4px 4px">
                        <p
                            style="font-size:12px; letter-spacing:1px; color:#ffffff; padding-left:10px; padding-top:5px; font-weight:700">
                            {{ $t('contract') }}</p>
                    </div>
                    <div style="max-height:58px; width:280px; margin:auto; margin-top:18px">
                        <div style="max-height:58px; width:58px; float:left; margin-left:2px; text-align:center"
                            v-if="company.logo">

                            <img style="max-width:52px; max-height:52px; border-color: #DDD; border-style:solid; border-width: 1px; padding:3px; border-radius:2px 2px 2px 2px"
                                :v-lazy="company.logo">

                        </div>
                        <div style="max-height:58px; width:138px; margin-left:15px">
                            <p style="color:#333; padding-top:1px; line-height:19px">Chúng tôi có thể giúp gì được bạn?
                            </p>
                        </div>
                    </div>
                    <div class="info-contract" v-for="contract in company.contract" :key="contract.id">
                        <p style="clear:both; height:18px"></p>

                        <div style="min-height:26px; width:280px; background:#f3f3f3; margin:auto; margin-bottom:6px; border-radius:4px 4px 4px 4px"
                            v-if="contract.contact_name">
                            <div style="height:23px; width:31px; float:left"><img style="padding-top:5px; padding-left:10px"
                                    v-lazy="'https://trangvangvietnam.com/images/contact_name_icon.png'"></div>
                            <div style="height:23px; width:68px; float:left">
                                <p style="font-size:12px; color:#555; padding-top:7px">Họ tên:</p>
                            </div>
                            <div style="height:23px;float:left">
                                <p
                                    style="font-size:12px; font-weight:700; color:#CC3366; padding-top:7px; padding-right:10px">
                                    {{ contract.contact_name }}</p>
                            </div>
                        </div>

                        <div style="min-height:26px; width:280px; background:#f3f3f3; margin:auto; margin-bottom:6px; border-radius:4px 4px 4px 4px"
                            v-if="contract.contact_position">
                            <div style="height:23px; width:31px; float:left"><img style="padding-top:5px; padding-left:10px"
                                    v-lazy="'https://trangvangvietnam.com/images/contact_position_icon.png'"></div>
                            <div style="height:23px; width:68px; float:left">
                                <p style="font-size:12px; color:#555; padding-top:7px">Chức vụ:</p>
                            </div>
                            <div style="height:23px;float:left">
                                <p style="font-size:12px; font-weight:700; color:#333; padding-top:7px; padding-right:10px">
                                    {{ contract.contact_position }}</p>
                            </div>
                        </div>
                        <!-- mobile -->
                        <div style="min-height:26px; width:280px; background:#f3f3f3; margin:auto; margin-bottom:6px; border-radius:4px 4px 4px 4px"
                            v-if="contract.contact_mobile">
                            <div style="height:23px; width:31px; float:left"><img style="padding-top:2px; padding-left:7px"
                                    v-lazy="' https://trangvangvietnam.com/images/contact_mobile_icon.png'"></div>
                            <div style="height:23px; width:68px; float:left">
                                <p style="font-size:12px; color:#555; padding-top:7px">Di động:</p>
                            </div>
                            <div style="height:23px;float:left">
                                <p style="font-size:12px; font-weight:700; color:#333; padding-top:7px; padding-right:10px">
                                    {{ contract.contact_mobile }}</p>
                            </div>
                        </div>
                        <!-- phone -->
                        <div style="min-height:26px; width:280px; background:#f3f3f3; margin:auto; margin-bottom:6px; border-radius:4px 4px 4px 4px"
                            v-if="contract.contact_phone">
                            <div style="height:23px; width:31px; float:left"><img style="padding-top:5px; padding-left:10px"
                                    v-lazy="'https://trangvangvietnam.com/images/contact_tel_icon.png'"></div>
                            <div style="height:23px; width:68px; float:left">
                                <p style="font-size:12px; color:#555; padding-top:7px">Điện thoại:</p>
                            </div>
                            <div style="height:23px;float:left">
                                <p style="font-size:12px; font-weight:700; color:#333; padding-top:7px; padding-right:10px">
                                    {{ contract.contact_phone }}</p>
                            </div>
                        </div>
                        <div style="min-height:26px; width:280px; background:#f3f3f3; margin:auto; margin-bottom:6px; border-radius:4px 4px 4px 4px"
                            v-if="contract.contact_email">
                            <div style="height:23px; width:31px; float:left"><img style="padding-top:8px; padding-left:9px"
                                    v-lazy="'https://trangvangvietnam.com/images/contact_email_icon.png'"></div>
                            <div style="height:23px; width:68px; float:left">
                                <p style="font-size:12px; color:#555; padding-top:7px">Email:</p>
                            </div>
                            <div style="height:23px;float:left">
                                <p style="font-size:11px; padding-top:7px">
                                    <a style="color:#0099CC" :href="'mailto:' + contract.contact_email">{{
                                        contract.contact_email }}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- product -->
        <div class="blaspheme" v-if="products.length">
            <ul class="nav nav-tabs mt-4">
                <li class="nav-item">
                    <span class="nav-link active" style="color: #CC0033;" href="#">{{ $t('showingProducct') }}</span>
                </li>
            </ul>
            <div class="describe p-3 border">
                <template v-if="isLoadingShowProduct">
                    <div class="d-flex align-items-center justify-content-center" style="width: 100%">
                        <i class="fa fa-spinner fa-spin fa-3x elim-loading"></i>
                    </div>
                </template>
                <div class="p-5 t-3 row" v-else>
                    <div class="products_detail_box col-4 p-3 d-flex" v-for="product in products" :key="product.id">
                        <div class="products_detail_image p-2 border">
                            <a :href="'/nganh-nghe/san-pham/' + product.id">
                                <img class="piczoom" v-lazy="product.image">
                            </a>

                        </div>
                        <div class="products_detail_title ml-3">
                            <div class="products_detail_line d-flex">
                                <div class="products_detail_line1">
                                    <p>Sản phẩm:</p>
                                </div>
                                <div class="products_detail_line2">
                                    <p>
                                        <strong>
                                            <a :href="'/nganh-nghe/san-pham/' + product.id"
                                                style="text-transform:capitalize;">{{ product.name }}
                                            </a>
                                        </strong>
                                    </p>
                                </div>
                            </div>

                            <div class="products_detail_line d-flex">
                                <div class="products_detail_line1">
                                    <p>Xuất xứ:</p>
                                </div>
                                <div class="products_detail_line2">
                                    <p>{{ product.origin }}</p>
                                </div>
                            </div>

                            <div class="products_detail_line d-flex">
                                <div class="products_detail_line1">
                                    <p>Sử dụng:</p>
                                </div>
                                <div class="products_detail_line2">
                                    <p style="font-size: 13px;">{{ product.application }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="paginate p-4">
                    <Paginate :value="paginate" :pagechange="onPageChange"></Paginate>
                </div>
            </div>
        </div>
        <!-- profession -->
        <div class="profession" v-if="company.description">
            <ul class="nav nav-tabs mt-4">
                <li class="nav-item">
                    <span class="nav-link active" style="color: #CC0033; text-transform: uppercase;" href="#">{{
                        $t('profession') }}</span>
                </li>
            </ul>
            <div class="describe p-3 border">
                <div class="title">
                    <span style="background: none;">{{ $t('companyIntroduction') }}</span>
                </div>
                <div class="text">
                    <span v-html="company.description">
                    </span>
                </div>
                <div class="title">
                    <span>{{ $t('business') }}</span>
                    <div class="row">
                        <div class="profession mt-2 col-6 ml-3" v-for="profession in company.categories"
                            :key="profession.id">
                            <img v-lazy="'https://trangvangvietnam.com/images/icon_4squares.gif'" alt=""
                                style="width: 10px">
                            <strong class="ml-2">
                                <a :href="'/nganh-nghe/' + profession.id">
                                    {{ profession.name }}</a>
                            </strong>
                        </div>
                    </div>
                </div>
                <div class="title mt-2">
                    <span>{{ $t('productsServices') }}</span>
                    <div class="profession mt-2 ml-3" v-for="(services, index) in profession" :key="services.id">
                        <img v-lazy="'https://trangvangvietnam.com/images/icon_4squares.gif'" alt="" style="width: 10px">
                        <span class="ml-2">{{ index }}</span>
                        <ul class="ml-5 row">
                            <li class="col-6 ucfirst" v-for="item in services" :key="item.id">{{ item.name }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- image -->
        <div class="image" v-if="images && Object.keys(images).length">
            <ul class="nav nav-tabs mt-4">
                <li class="nav-item">
                    <span class="nav-link active" style="color: #CC0033;" href="#">{{ $t('imageGallery') }}</span>
                </li>
            </ul>
            <div class="describe p-3 border">
                <!--  -->
                <div class="title ml-4 mt-3" v-for="(image, index) in images" :key="image.id">
                    <span>{{ index }}</span>
                    <div class="image d-flex mt-3 row">
                        <div class="box-image ml-4 border p-3 col-2 mt-2 text-center" v-for="(item, index1) in image"
                            :key="item.id" @click="showModalImage(image, index1)" style="cursor: pointer;">
                            <img v-lazy="item.src" alt="">
                            <div class="name text-center" style="display: inline-block; text-transform:capitalize;">{{
                                item.title }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- company -->
        <ul class="nav nav-tabs mt-4">
            <li class="nav-item">
                <span class="nav-link active" style="color: #CC0033;" href="#">{{ $t('contractCompany') }}</span>
            </li>
        </ul>
        <div class="describe p-3 border mb-4">
            <div class="market">
                <div class="main-market d-flex" style="border: none;">
                    <div class="title-market" v-if="company.name">
                        <img v-lazy="'https://trangvangvietnam.com/images/icon_4squares.gif'" alt="">
                        <span>Tên công ty:</span>
                    </div>
                    <div class="text ml-5" style="text-transform:capitalize;">{{ company.name }}</div>
                </div>
                <div class="main-market d-flex" v-if="company.company_type">
                    <div class="title-market">
                        <img v-lazy="'https://trangvangvietnam.com/images/icon_4squares.gif'" alt="">
                        <span>Loại hình công ty:</span>
                    </div>
                    <div class="text ml-5">{{ company.company_type }}</div>
                </div>
                <div class="main-market d-flex" v-if="company.main_marketing">
                    <div class="title-market">
                        <img v-lazy="'https://trangvangvietnam.com/images/icon_4squares.gif'" alt="">
                        <span>Thị trường chính:</span>
                    </div>
                    <div class="text ml-5">{{ company.main_marketing }}</div>
                </div>
                <div class="main-market d-flex" v-if="company.tax_code">
                    <div class="title-market">
                        <img v-lazy="'https://trangvangvietnam.com/images/icon_4squares.gif'" alt="">
                        <span>Mã số thuế:</span>
                    </div>
                    <div class="text ml-5">{{ company.tax_code }}</div>
                </div>
                <div class="main-market d-flex" v-if="company.establishment_year">
                    <div class="title-market">
                        <img v-lazy="'https://trangvangvietnam.com/images/icon_4squares.gif'" alt="">
                        <span>Năm thành lập:</span>
                    </div>
                    <div class="text ml-5">{{ company.establishment_year }}</div>
                </div>
                <div class="main-market d-flex" v-if="company.employee_number">
                    <div class="title-market">
                        <img v-lazy="'https://trangvangvietnam.com/images/icon_4squares.gif'" alt="">
                        <span>Số lượng nhân viên:</span>
                    </div>
                    <div class="text ml-5">{{ company.employee_number }}</div>
                </div>
                <div class="main-market d-flex" v-if="company.certifications">
                    <div class="title-market">
                        <img v-lazy="'https://trangvangvietnam.com/images/icon_4squares.gif'" alt="">
                        <span>Chứng nhận:</span>
                    </div>
                    <div class="text ml-5">{{ company.certifications }}</div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <Splide :options="sliderMainOption" ref="mainSlider" class="text-center">
                            <SplideSlide class="text-center" v-for="item in imageModal" :key="item">
                                <img class="image-modal" :src="item.v - lazy" alt="">
                                <div class="numbertext">{{ item.title }}</div>
                            </SplideSlide>
                        </Splide>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/dist/css/splide.min.css';
import Paginate from "@/components/Paginate";
import StarRating from 'vue-star-rating'
import CompanyCategoriesRequest from '@/api/requests/CompanyCategoriesRequest'
export default {
    props: ['id'],
    components: {
        StarRating,
        Splide,
        SplideSlide,
        Paginate
    },
    data() {
        return {
            company: [],
            profession: [],
            images: [],
            page: this.$route.query.page || 1,
            paginate: {
                currentPage: 1,
                lastPage: 1
            },
            products: [],
            isLoadingShowProduct: true,
            isLoading: true,
            sliderMainOption: {
                type: 'fade',
                perPage: 1,
                perMove: 1,
                rewind: true,
                rewindByDrag: true,
                paginationKeyboard: false,
                pagination: false,
                autoplay: true,
            },
            imageModal: []
        }
    },
    created() {
        this.getData()
    },
    methods: {
        async getData() {
            const res = await CompanyCategoriesRequest.getAllComapny(this.id, this.page);
            if (res.code === 200) {
                this.isLoadingShowProduct = false;
                this.isLoading = false;
                this.company = res.company
                this.profession = res.profession
                this.images = res.image
                this.products = res.products
                this.paginate = res.paginate
                document.title = this.company.name
            }
        },
        onPageChange(page) {
            this.page = page;
            this.isLoadingShowProduct = true;
            this.$router.push({ query: { page: page } });
            this.getData();
            setTimeout(() => {
                window.jQuery("html, body").animate({ scrollTop: 500 }, 300);
            }, 500);
        },
        async showModalImage(value, index) {
            this.imageModal = value;
            await window.$(this.$refs.modal).modal('show');
            this.$refs.mainSlider.go(index)
        }
    }
}
</script>

<style lang="scss">
.layout-company {
    .contract {
        border-radius: 4px;
        width: 100%;
    }

    .name-company {
        font-size: 18px;
        font-weight: bold;
        color: #222;
        text-align: left;
        text-transform: capitalize;
        margin-bottom: 3px;
    }

    .market {
        .main-market {
            height: auto;
            border-top-color: #ddd;
            border-top-style: dotted;
            border-top-width: 1px;
            margin: auto;
            padding-top: 21px;

            .title-market {
                width: 200px;

                & span {
                    font-size: 12px;
                    height: 23px;
                    font-weight: 700;
                    color: #999999;
                    padding-top: 3px;
                    margin-left: 5px;
                }
            }
        }
    }

    .company {

        .logo {
            width: 100px;
            height: 100px;
            line-height: 82px;
            border: 1px solid #ccc;
            padding: 5px;
        }

        .information {
            margin-left: 10px;

            .address {
                padding-bottom: 8px;
                line-height: 18px;
            }

            .phone {
                font-weight: bold;
                font-size: 14px;
                color: #383838;
                padding-bottom: 8px;
            }

            .dc {
                color: #999999;
                font-size: 11px;
                font-family: Verdana;
            }
        }

        .verify {
            width: 100px;
        }
    }

    .email_website {
        min-height: 39px;
        border-top-color: #DDD;
        border-top-style: dotted;
        border-bottom-style: dotted;
        border-top-width: 1px;
        border-bottom-width: 1px;
        border-bottom-color: #DDD;

        .box_email_website {
            min-width: 108px;
            margin-top: 7px;
            height: 25px;
            float: left;
            margin-left: 5px;
            margin-right: 33px;

            .icon_email_website {
                float: left;
                height: 25px;
                width: 25px;
                border-radius: 50%;
                // background: #e88989;
                text-align: center;
            }

            .text_email {
                height: 25px;
                min-width: 88px;
                float: left;
                margin-left: 8px;
            }

            .text_website {
                height: 25px;
                min-width: 88px;
                float: left;
                margin-left: 8px;
            }

        }
    }

    .describe {
        .title {
            & span {
                font-size: 14px;
                width: 150px;
                color: #CC3366;
                font-weight: 700;
                background: #FFFFCC;
            }

            & li {
                list-style-type: square;
            }

            .profession {
                & span {
                    font-size: 13px;
                }
            }
        }

        .image {

            & img {
                max-width: 116px;
                max-height: 100px;
            }

            & .name {
                width: 120px;
                margin-top: 10px;
            }
        }

        .products_detail_box {
            .products_detail_image {
                &:hover {
                    -ms-transform: scale(1.5);
                    /* IE 9 */
                    -webkit-transform: scale(1.5);
                    /* Safari 3-8 */
                    transform: scale(2.1, 1.3);
                    transition: transform .2s;
                }

                border-radius: 5px;
                background: #ffffff;

                & img {
                    max-width: 90px;
                    height: 90px;
                }
            }

            .products_detail_title {
                .products_detail_line {
                    .products_detail_line1 {
                        width: 70px;

                        & p {
                            font-size: 11px;
                            color: #666666;
                            padding-top: 1px;
                        }
                    }

                    .products_detail_line2 {
                        & p {
                            padding-top: 1px;
                            line-height: 16px;
                        }
                    }
                }
            }
        }
    }

    .image-modal {
        max-width: 350px;
        height: auto;
    }
}
</style>