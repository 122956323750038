<template>
    <div class="layout-product-detai">
        <div v-if="isLoading">
            <div class="d-flex align-items-center justify-content-center p-5" style="width: 100%">
                <i class="fa fa-spinner fa-spin fa-3x elim-loading"></i>
            </div>
        </div>
        <div class="row p-2" v-else>
            <div class="col-8">
                <div class="row border" style="border-radius: 7px;">
                    <div class="image col-5 pt-5 pb-5 pl-5">
                        <img :src="products.image" alt="">
                    </div>
                    <div class="introduce col-6">
                        <div class="title pt-5">
                            <span>{{ products.name }}</span>
                        </div>
                        <div class="origin d-flex p-1 row" v-if="products.origin">
                            <div class="title col-4">Xuất xứ:</div>
                            <div class="text col-8">{{ products.origin }}</div>
                        </div>
                        <div class="origin d-flex p-1 row" v-if="products.brand">
                            <div class="title col-4">Nhãn hiệu:</div>
                            <div class="text col-8">{{ products.brand }}</div>
                        </div>
                        <div class="origin d-flex p-1 row" v-if="products.application">
                            <div class="title col-4">Mô tả/ Ứng dụng:</div>
                            <div class="text col-8">{{ products.application }}</div>
                        </div>
                        <div class="origin d-flex p-1 row" v-if="products.minimum_order">
                            <div class="title col-4">Đơn hàng tối thiểu:</div>
                            <div class="text col-8">{{ products.minimum_order }}</div>
                        </div>
                        <!-- <div class="origin d-flex p-1 row">
                            <div class="title col-4">Năng lực cung cấp:</div>
                            <div class="text col-8">Liên hệ</div>
                        </div> -->
                        <div class="origin d-flex p-1 row" v-if="products.delivery_time">
                            <div class="title col-4">Thời gian cung cấp:</div>
                            <div class="text col-8">{{ products.delivery_time }}</div>
                        </div>
                        <div class="origin d-flex p-1 row" v-if="products.main_marketing">
                            <div class="title col-4">Thị trường chính:</div>
                            <div class="text col-8">{{ products.main_marketing }}</div>
                        </div>
                        <button class="btn btn-primary mb-3"
                            v-if="company.contract[0].contact_mobile && !company.contract[0].contact_phone">Gọi
                            mua: {{ company.contract[0].contact_mobile }}</button>
                        <button class="btn btn-primary mb-3"
                            v-if="!company.contract[0].contact_mobile && company.contract[0].contact_phone">Gọi
                            mua: {{ company.contract[0].contact_phone }}</button>
                    </div>
                </div>
                <div class="box-product pb-5" style="width: 100%;">
                    <ul class="nav nav-tabs mt-4">
                        <li class="nav-item">
                            <span
                                class="nav-link                                                                                                                                                active"
                                style="color: #CC0033;" href="#">THÔNG TIN SẢN PHẨM</span>
                        </li>
                    </ul>
                    <div class="describe border">
                        <div class="detail p-4">
                            <span v-html="products.description"></span>
                        </div>
                        <!-- <div class="describe-product p-4">
                            <div class="d-flex">
                                <div class="info title"><strong>THÔNG TIN SẢN PHẨM:</strong></div>
                                <div class="text" style="font-style: italic;"><strong>{{ products.name }}</strong></div>
                            </div>
                            <div class="d-flex">
                                <div class="info">Kích thước:</div>
                                <div class="text">34 x 25 x 34.5 cm</div>
                            </div>
                            <div class="d-flex">
                                <div class="info">Chất liệu:</div>
                                <div class="text">2 lớp sóng B-C</div>
                            </div>
                            <div class="d-flex">
                                <div class="info">Sản Xuất:</div>
                                <div class="text">In Flexo 1 màu, đóng ghim</div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-4 box-right">
                <div class="name-ccompany">
                    <strong>{{ company.name }}</strong>
                </div>
                <div class="logo-company">
                    <img :src="company.logo" alt="" width="100">
                </div>
                <div class="information">
                    <div class="sp_linediv d-flex" v-if="company.location">
                        <div class="title" style="width: 140px;">Địa chỉ:</div>
                        <div class="text">{{ company.location }}
                        </div>
                    </div>
                    <div class="sp_linediv d-flex" v-if="company.phone">
                        <div class="title">Điện thoại:</div>
                        <div class="text"><strong>{{ company.phone }}</strong>
                        </div>
                    </div>
                    <div class="sp_linediv d-flex" v-if="company.fax">
                        <div class="title">Số Fax:</div>
                        <div class="text">{{ company.fax }}
                        </div>
                    </div>
                    <div class="sp_linediv d-flex">
                        <div class="title">Email:</div>
                        <div class="text">{{ company.email }}
                        </div>
                    </div>
                    <div class="sp_linediv d-flex" v-if="company.website">
                        <div class="title">Website:</div>
                        <div class="text" v-for="web in company.website " :key="web.label"><a :href="web.label">{{ web.label
                        }}</a>
                        </div>
                    </div>
                </div>
                <div class="contract mt-5">
                    <div class="title">
                        <strong>THÔNG TIN LIÊN HỆ</strong>
                    </div>
                    <div class="box-contract" v-for="contract in company.contract" :key="contract.id">
                        <div class="sp_linediv d-flex" v-if="contract.contact_name">
                            <div class="title">Người liên hệ:</div>
                            <div class="text"><strong>{{ contract.contact_name }}</strong>
                            </div>
                        </div>
                        <div class="sp_linediv d-flex" v-if="contract.contact_position">
                            <div class="title">Chức vụ:</div>
                            <div class="text">{{ contract.contact_position }}
                            </div>
                        </div>
                        <div class="sp_linediv d-flex" v-if="contract.contact_mobile">
                            <div class="title">Di động:</div>
                            <div class="text">{{ contract.contact_mobile }}
                            </div>
                        </div>
                        <div class="sp_linediv d-flex" v-if="contract.contact_phone">
                            <div class="title">Điện thoại:</div>
                            <div class="text">{{ contract.contact_phone }}
                            </div>
                        </div>
                        <div class="sp_linediv d-flex" v-if="contract.contact_email">
                            <div class="title">Email:</div>
                            <div class="text">{{ contract.contact_email }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="relate mt-5">
                    <div class="title">
                        <strong>XEM THÊM CÁC SẢN PHẨM KHÁC</strong>
                    </div>
                    <div class="row mt-5" v-for="product in company.products" :key="product.id">
                        <div class="col-4 border text-center">
                            <a :href="'/nganh-nghe/san-pham/' + product.id"> <img v-lazy="product.image" alt=""
                                    width="100" style="margin-top: 20px;"></a>
                        </div>
                        <div class="col-8">
                            <div class="sp_xemthemspdiv_content">
                                <div class="sp_xemthemspdiv_line d-flex">
                                    <div class="sp_xemthemspdiv_line1">
                                        <p>Sản phẩm:</p>
                                    </div>
                                    <div class="sp_xemthemspdiv_line2">
                                        <p>
                                            <strong>
                                                <a :href="'/nganh-nghe/san-pham/' + product.id">
                                                    {{ product.name }}
                                                </a>
                                            </strong>
                                        </p>
                                    </div>
                                </div>

                                <div class="sp_xemthemspdiv_line  d-flex">
                                    <div class="sp_xemthemspdiv_line1">
                                        <p>Xuất xứ:</p>
                                    </div>
                                    <div class="sp_xemthemspdiv_line2">
                                        <p>{{ product.origin }}</p>
                                    </div>
                                </div>

                                <div class="sp_xemthemspdiv_line d-flex">
                                    <div class="sp_xemthemspdiv_line1">
                                        <p>Sử dụng:</p>
                                    </div>
                                    <div class="sp_xemthemspdiv_line2">
                                        <p>
                                            {{ product.application }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CompanyCategoriesRequest from '@/api/requests/CompanyCategoriesRequest'
export default {
    props: ['id'],
    name: 'ProductProfessionDetail',
    data() {
        return {
            products: [],
            company: [],
            isLoading: true,
        }
    },
    created() {
        this.getData()
    }, methods: {
        async getData() {
            const res = await CompanyCategoriesRequest.showProduct(this.id);
            if (res.code === 200) {
                this.products = res.products
                this.isLoading = false
                this.company = res.company
                document.title = this.products.name
            }
        },
    }
}
</script>

<style lang="scss">
.layout-product-detai {

    .introduce {
        .title {
            & span {
                font-weight: 600;
                font-size: 17px;
                margin: 0px;
                color: #DB0000;
                padding-top: 3px;
                padding-bottom: 16px;

            }
        }

        .origin {
            .title {
                color: #888;
                font-size: 12px;
            }
        }
    }

    .box-product {
        .describe {
            .describe-product {
                .info {
                    width: 200px;
                }

                .text {
                    line-height: 26px;
                    font-size: 13px;
                }
            }

            .contrac {
                .title {
                    color: #DB0000;
                }
            }
        }
    }

    .box-right {
        .information {
            .title {
                width: 100px;
                height: 23px;
                padding-top: 2px;
                color: #666666;
                font-size: 10px;
            }

            .text {
                font-size: 13px;
            }
        }

        .contract {
            .title {
                & strong {
                    padding: 1px;
                    color: #ffff;
                    background-color: #CC3333;
                }
            }

            .sp_linediv {
                .title {
                    padding-top: 5px;
                    font-size: 13px;
                    width: 98px;
                    color: #CC3333;
                    font-family: Verdana;
                }
            }

            .box-contract {
                border-bottom: 1px solid #ccc;
                padding: 10px 0 15px 0;
            }
        }

        .relate {
            .sp_xemthemspdiv_line1 {
                width: 105px;
                font-size: 11px;
                color: #888;
            }

            .sp_xemthemspdiv_line2 {
                width: 250px;
                font-size: 12px;
                height: 33px;
            }
        }
    }
}
</style>