<template>
  <div id="banner-home">
    <div class="wrap-container bg-white">
      <!--            <div class="row no-margin-hoz">-->
      <!--                <div class="col-xl-3 col-sm-4 banner-icon icon-1">-->
      <!--                    <img src="/assets/img/header/ic_banner1.svg" alt="">-->
      <!--                    <h1 v-if="currentLang == 'vi'">{{`Gian hàng\nVIP`}}</h1>-->
      <!--	                <h1 v-else>{{ $t('vipStore')}}</h1>-->
      <!--                </div>-->
      <!--                <div class="col-xl-6 col-sm-4 banner-icon icon-2">-->
      <!--                    <img src="/assets/img/header/ic_banner2.svg" alt="">-->
      <!--                    <h1 v-if="currentLang == 'vi'" >{{`24/7 \n Dịch vụ khách hàng`}}</h1>-->
      <!--	                <h1 v-else>{{ $t('service247')}}</h1>-->
      <!--                </div>-->
      <!--                <div class="col-xl-3 col-sm-4 banner-icon icon-3">-->
      <!--                    <img src="/assets/img/header/ic_banner3.svg" alt="">-->
      <!--                    <h1 v-if="currentLang == 'vi'">{{`Dịch vụ\n Gom hàng`}}</h1>-->
      <!--	                <h1 v-else>{{ $t('collectService')}}</h1>-->
      <!--                </div>-->
      <!--            </div>-->
      <div class="row no-margin-hoz container-banner">
        <category-home></category-home>
        <div class="col-xl-6 banner-content2 col-sm-12">
          <div v-if="sliders.length > 0" class="slide-category banner-slider" ref="bannerHomeSlider">
            <div class="slider-items" v-for="slider in sliders" :key="slider.id">
              <a :href="slider.url">
                <img :alt="slider.title" v-lazy="slider.image ? slider.image.uri : '/assets/img/header/banner-home.png'" >
              </a>
            </div>
          </div>
          <!--                    <img src="/assets/img/header/banner-home.png" alt="">-->
        </div>
        <div class="col-xl-3 banner-content3 col-sm-12">
          <div v-if="authData" class="container-auth">
            <div class="row align-items-center">

              <img src="/assets/img/ic_user.png" class="img-user"/>
              <div class="text-wellcome">Xin chào, {{ (authData && authData.user) ? authData.user.name : '' }}</div>
            </div>
            <div class="row justify-content-between mt-3 px-2">
              <router-link to="/trang-ca-nhan?tabMain=don-hang-cua-toi" class="text-center">
                <img src="/assets/img/received_goods.png"/>
                <div class="mt-1">
                  Theo dõi đơn hàng
                </div>
              </router-link>
              <router-link to="/trang-ca-nhan?tabMain=don-hang-cua-toi&type=1" class="text-center">
                <img src="/assets/img/delivery.png"/>
                <div class="mt-1">
                  Đang giao hàng
                </div>
              </router-link>
            </div>
          </div>
          <div v-else class="container-auth">
            <div class="row align-items-center">

              <img src="/assets/img/ic_user.png" class="img-user"/>
              <div class="text-wellcome">Chào mừng bạn đến với Elim</div>
            </div>
            <div class="row justify-content-between">
                <login-lim-id style="margin: 20px 20px 5px"></login-lim-id>
<!--              <router-link class="btn-danger button-auth text-center" :to="{name: 'UserInfo'}" @click="setBackUrl()">-->
<!--                Đăng nhập-->
<!--              </router-link>-->
<!--              <router-link class="button-auth button-register  text-center" to="/dang-ky"-->
<!--                           @click="setBackUrl()">-->
<!--                Đăng ký-->
<!--              </router-link>-->
            </div>
          </div>


          <ul class="row no-margin-hoz">
            <!--            <li class="col-xl-12 col-md-3 col-sm-12 box-col-1">-->
            <!--              <div class="fast-campaign">-->

            <!--                <a :href="getOption('right_menu_title_link')">-->
            <!--                  <h1 v-text="getOption('right_menu_title')"></h1>-->
            <!--                </a>-->

            <!--              </div>-->
            <!--            </li>-->
            <li class="col-xl-12 col-md-3 col-sm-12 pt-4" :key="index"
                v-for="(menu,index) in getOption('site_right_menu', [])">
              <div class="fast-campaign">
                <div class="campaign-content">
                  <div class="row align-items-center  pl-3">
                    <img v-if="menu.image" v-lazy="menu.image.uri"
                         style="height: 30px;width: 30px" alt="">
                    <router-link  :to="'tin-tuc/'+menu.url">
                      {{ menu.name }}
                    </router-link>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="row" style="padding: 15px; display: flex; background: #f5f5f5" v-if="listBannerCampaign && listBannerCampaign.length > 0">
        <template v-for="banner in listBannerCampaign" :key="banner.id">
          <router-link class="col-3" :to="{name: 'CampaignDetail', params: {slug: banner.slug, id: banner.id, name: banner.title}}">
              <div class="banner-home-icon">
                <div class="img">
                  <img v-lazy="banner.iconUrl">
                </div>
                <div class="title-cate">
                  <label v-text="banner.title"></label>
                </div>
              </div>
          </router-link>
<!--	        <a class="col-2" style="text-align: center" href="/xem/san-pham-quoc-te">-->
<!--		        <div class="img">-->
<!--			        <img src="/assets/">-->
<!--		        </div>-->
<!--		        <div class="title-cate">-->
<!--			        <label v-text="banner.title"></label>-->
<!--		        </div>-->
<!--	        </a>-->
        </template>

        
      </div>
    </div>
  </div>
</template>

<script>
import {getOption} from "@/lib/utils";

const $ = window.jQuery;

import CategoryHome from "@/components/home/CategoryHome";
import store from "@/store";
import LoginLimId from "@/components/login/LoginLimId";

export default {
  name: "BannerHome",
  props: ['sliders', 'listBannerCampaign'],
  components: {LoginLimId, CategoryHome},
  updated() {
    this.initSlider();
  },
  computed: {
    authData: () => store.state.Auth,
  },
  data() {
    let backgroundImage = '/assets/img/header/bg-banner.png';
    const bannerSetting = getOption('banner_home_background');

    if (bannerSetting && bannerSetting.uri) {
      backgroundImage = bannerSetting.uri;
    }
    let currentLang = store.state.locale || 'vi';

    return {
      showCategory: false,
      bannerStyle: `background-image: url('${backgroundImage}')`,
      currentLang
    }
  },
  methods: {
    setBackUrl() {
      if (!this.authData) {
        let currentUrl = this.$route.fullPath;
        localStorage.setItem("ElimBackUrl", currentUrl);
      }
    },
    getOption,
    initSlider() {
      $(this.$refs.bannerHomeSlider).show().not('.slick-initialized').slick({
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      });
    },
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
@import "../assets/css/bannerHome";
</style>
