<template>
    <div class="container">
        <div class="title-bar">

            <div class="title-bar-back">
                <router-link to="/">
                    <img src="../assets/svg/back.svg" alt="quay lai"/>
                </router-link>
            </div>
            <div class="title-bar-content">
	            {{ $t('payment')}}
            </div>

        </div>
        <div>
            <loading v-if="isLoading" :is-loading="true"></loading>
        </div>
        <div class="checkout">
            <div class="checkout-box">
                <div class="checkout-header">
	                {{ $t('shipInfo')}}
                </div>
                <div class="selected-address">
                    <strong>
                        {{ address.name }} - {{ address.phone }}
                    </strong>
                    <br/>
                    <em>{{ address.decoded }}</em>


                    <div class="float-right change-address">
                        <router-link to="/thong-tin-nhan-hang">
                            <a href='javascript:;'>{{ $t('shipInfo')}}</a>
                        </router-link>

                    </div>
                </div>
                <div class="clearfix"/>
            </div>
            <div class="splitter"/>
            <div class="checkout-box">
                <div class="checkout-header">
	                {{ $t('note2')}}
                </div>
                <div class="order-note">
                    <textarea v-model="order.note"
                              class="form-control" :placeholder="$t('typeNote2')"></textarea>
                </div>
            </div>
            <div class="splitter"/>
            <div class="checkout-box">
                <div class="checkout-header">
	                {{ $t('choosePaymentMethod')}}
                </div>
                <div class="choose-payment-method">
                    <label class="checkbox">
                        <input type="radio" style="width: 25px; height: 25px; margin-right: 15px" name="radio" id="payment-method" v-model="order.payment_method" :value="0" checked>
                        <label for="payment-method">
                            <strong>{{ $t('shipCOD')}}</strong>
                        </label>

                        <span class="checkmark"/>
                    </label>
                </div>
            </div>
            <div class="splitter"/>
            <div class="checkout-box">
                <div class="checkout-header mb-2">
	                {{ $t('order')}}
                </div>
                <div class="cart">
                    <div class="cart-group-wrapper" v-for="group in groupMap" :key="group.name">
                        <div class="cart-item-group">
                            <label>{{ group.name }} ({{ group.items.length }})</label>
                        </div>
                        <div v-if="group.items.length === 0">
	                        {{ $t('noProduct')}}
                        </div>
                        <div class="cart-item" v-else v-for="item in group.items" :key="item.id">
                            <div class="cart-item-thumb">
                                <router-link :to="{ name: 'ProductDetail', params: {slug: item.product.slug} }">
                                    <img :src="item.product.thumb.uri" :alt="item.product.name"/>
                                </router-link>
                            </div>
                            <div class="cart-item-quantity">
                                <div class="row">
                                    <div class="col-4">
                                        <div class="cart-item-product-name" v-text="stringTruncate(item.product.name)">
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <label>
                                            <strong>{{ $t('price')}}</strong>
                                        </label>
                                        <div class="cart-item-amounts">
                                            <div class="cart-item-amount-origin" v-if="item.price_origin > item.price">
                                                {{ numberFormat(item.price_origin) }}đ
                                            </div>

                                            <div class="cart-item-amount">
                                                {{ numberFormat(item.price) }} đ
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <label>
                                            <strong>{{ $t('unit')}}</strong>
                                        </label>
                                        <div class="adjust" v-if="readonly">
                                            {{ item.pack.name }}
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <label>
                                            <strong>{{ $t('quantity')}}</strong>
                                        </label>
                                        <div class="adjust" v-if="readonly">
                                            {{ item.quantity }}
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <label>
                                            <strong>{{ $t('sumAmount')}}</strong>
                                        </label>
                                        <div class="cart-item-amounts">
                                            <div class="cart-item-amount-origin" v-if="item.price_origin > item.price">
                                                {{ numberFormat(item.quantity * item.price_origin) }}đ
                                            </div>

                                            <div class="cart-item-amount">
                                                {{ numberFormat(item.quantity * item.price) }} đ
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div style="height: 30px"></div>
                </div>

            </div>

            <div class="splitter"/>
            <div class="checkout-box">
                <div class="checkout-header" style="margin-bottom: 10px">
                    Thanh toán
                </div>
                <div class="checkout-summary">
                    <div style="margin-right: 10px">
                        <strong>Tạm Tính: </strong>
                    </div>
                    <div>
                        {{ numberFormat(amountTotal) }} đ
                    </div>
                </div>

                <div class="checkout-summary">
                    <div style="margin-right: 10px">
                        <strong>Phí Vận Chuyển: </strong>
                    </div>
                    <div>
                        0 đ
                    </div>
                </div>
                <div style="height: 111px"/>
            </div>

        </div>
        <div class="cart-total" style="padding: 15px">
            <div class="cart-amount">
                <div class="cart-amount-label">
                    Tổng tiền
                </div>
                <div style="display: flex">
                    <span class="cart-amount-discount" v-if="amountOrigin > amountTotal">
                        {{ numberFormat(amountOrigin) }} đ
                    </span>
                    <span class="cart-amount-total">
                        {{ numberFormat(amountTotal) }} đ
                    </span>
                </div>
            </div>

            <div class="btn-buy-block">
                <button class="btn-buy-now" @click="save">Đặt hàng</button>
            </div>


        </div>

        <div class="modal" tabindex="-1" role="dialog" ref="SuccessModal">
                <div class="modal-dialog" role="document" style="max-width: 40%; top: 15%">
                    <div class="modal-content" style="margin: auto; border-radius: 30px; padding: 30px 0">
                        <div class="checkout-result">
                            <div class="result-title">
                                <div>Đặt hàng thành công</div>
                                <div>
                                    {{ order.code }}
                                </div>

                            </div>
                            <p>
                                Cảm ơn quý khách đã sử dụng dịch vụ của Elim.
                                Mọi thắc mắc vui lòng lien hệ tổng đài
                                <a :href="'tel:'+ app.hotline">{{ app.hotline }}</a> để được giải đáp
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        <div class="modal" tabindex="-1" role="dialog" ref="FailedModal">
            <div class="modal-dialog" role="document" style="max-width: 40%; top: 15%">
                <div class="modal-content" style="margin: auto; border-radius: 30px; padding: 0 0 30px">
                    <div class="checkout-result">
                        <div class="result-title">
                            Đặt hàng thất bại
                        </div>
                        <p>
                            Cảm ơn quý khách đã sử dụng dịch vụ của Elim.
                            Mọi thắc mắc vui lòng lien hệ tổng đài <a :href="'tel:'+ app.hotline">
                            {{ app.hotline }}</a> để được giải đáp
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from "@/config";
import CartRequest from "@/api/requests/CartRequest";
import {numberFormat, stringTruncate} from "@/lib/utils";
import AddressRequest from "@/api/requests/AddressRequest";
import OrderRequest from "@/api/requests/OrderRequest";
import Loading from "@/includes/Loading";

export default {
    name: "Checkout",
    data() {
        return {
            address: {},
            items: [],
            order: {
                addressId: '',
                receipt_type: 0,
                note: '',
            },
            cart: {},
            amountOrigin: 0,
            amountTotal: 0,
            readonly: true,
            isLoading: false,
            groupMap: [],
            app: [],
            showSuccessModal: false,
            showFailedModal: false

        }
    },
    mounted() {
        document.title = this.$t('payment')
        this.getCart();
        this.getAddress();
        this.app = config.App;
    },
    components: {
        Loading
    },
    methods: {
        config, numberFormat, stringTruncate,
        async getCart() {
            this.isLoading = true;
            const res = await CartRequest.get();
            this.isLoading = false;
            this.items = res.items;
            this.cart = res.cart;
            this.amountOrigin = res.cart.amount_origin;
            this.amountTotal = res.cart.amount;
            this.groupMap = this.getItemGroup(this.items);

        },
        async getAddress() {
            const data = await AddressRequest.get();
            this.address = data[0];
            this.order.addressId = this.address.id
        },

        getItemGroup(items) {
            let groups = [];
            items.forEach(item => {
                if (!groups[item.shop.id]) {

                    groups[item.shop.id] = {
                        items: [],
                        name: item.shop.name
                    }
                }

                groups[item.shop.id].items.push(item);
            })
            return Object.values(groups);
        },

        async save() {
            this.isLoading = true;
            if (this.items.length === 0) {
                alert('Vui lòng chọn sản phẩm trước khi thanh toán')
                this.isLoading = false;
                return;
            }

            const res = await OrderRequest.createV4(this.order);
            this.isLoading = false;
            if (res.err_code === 0) {
                this.order = res.order;
                alert('Đặt hàng thành công. Đơn hàng của quý khách là: ' + res.orderCode);
                location.href = '/don-hang';

            } else {
                alert('Đặt hàng thất bại!. ' + res.message);
            }

        }
    }
}
</script>

<style scoped lang="scss">
@import "../assets/css/cart";
@import "../assets/css/product";

.cart {
  padding-top: 20px;
  max-height: 600px;
  overflow-y: scroll;
}

.cart-total {
  position: relative;
  max-width: unset;
  padding: 10px 30px 10px 50px;
}

.title-bar {
  display: flex;
  background: #e02e24;;
  height: 50px;
  padding: 10px;

  .title-bar-back {
    margin-right: 10px;
  }

  .title-bar-content {
    font-size: 18px;
    color: #ffffff;
    font-weight: bold;
    margin: auto;
  }
}
.checkout {
  padding-top: 20px;
}
</style>