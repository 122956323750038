<template>
    <div>
        <div class="widget footer_nav-box mb-3">
            <ul class="footer_nav-list">
                <li>
                    <span class="icon"><img src="img/common/fnav-box-1.png" alt=""></span>
                    <a class="name" href="#">新手指南</a>
                    <div class="footer_nav-wrap">
                        <a href="#" class="items">了解1688</a>
                        <a href="#" class="items">买家入门</a>
                        <a href="#" class="items">买家保障</a>
                        <a href="#" class="items">买家客服</a>
                    </div>
                </li>
                <li>
                    <span class="icon"><img src="img/common/fnav-box-2.png" alt=""></span>
                    <a class="name" href="#">新手指南</a>
                    <div class="footer_nav-wrap">
                        <a href="#" class="items">了解1688</a>
                        <a href="#" class="items">买家入门</a>
                        <a href="#" class="items">买家保障</a>
                        <a href="#" class="items">买家客服</a>
                    </div>
                </li>
                <li>
                    <span class="icon"><img src="img/common/fnav-box-3.png" alt=""></span>
                    <a class="name" href="#">新手指南</a>
                    <div class="footer_nav-wrap">
                        <a href="#" class="items">了解1688</a>
                        <a href="#" class="items">买家入门</a>
                        <a href="#" class="items">买家保障</a>
                        <a href="#" class="items">买家客服</a>
                    </div>
                </li>
                <li>
                    <span class="icon"><img src="img/common/fnav-box-4.png" alt=""></span>
                    <a class="name" href="#">新手指南</a>
                    <div class="footer_nav-wrap">
                        <a href="#" class="items">了解1688</a>
                        <a href="#" class="items">买家入门</a>
                        <a href="#" class="items">买家保障</a>
                        <a href="#" class="items">买家客服</a>
                    </div>
                </li>
                <li>
                    <span class="icon"><img src="img/common/fnav-box-5.png" alt=""></span>
                    <a class="name" href="#">新手指南</a>
                    <div class="footer_nav-wrap">
                        <a href="#" class="items">了解1688</a>
                        <a href="#" class="items">买家入门</a>
                        <a href="#" class="items">买家保障</a>
                        <a href="#" class="items">买家客服</a>
                    </div>
                </li>
            </ul>
        </div>
        <div class="row row-16 mb-3">
            <div class="col-6">
                <a class="w-100 ads-thumb" href="#"><img src="/img/data/ads-1.jpg" alt=""></a>
            </div>
            <div class="col-6">
                <a class="w-100 ads-thumb" href="#"><img src="/img/data/ads-2.jpg" alt=""></a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FooterTips"
    }
</script>

<style scoped>

</style>
