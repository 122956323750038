<template>
    <div id="shop-detail--feedback">
        <div class="btn-tab-wrap">
            <a class="btn-tab-review" :class="{ active: tabIndex === 0 }" @click="setTab(0)">Tất cả</a>
            <a class="btn-tab-review" :class="{ active: tabIndex === 1 }" @click="setTab(1)">5 sao (40)</a>
            <a class="btn-tab-review" :class="{ active: tabIndex === 2 }" @click="setTab(2)">4 sao (10)</a>
            <a class="btn-tab-review" :class="{ active: tabIndex === 3 }" @click="setTab(3)">3 sao (0)</a>
            <a class="btn-tab-review" :class="{ active: tabIndex === 4 }" @click="setTab(4)">2 sao (0)</a>
            <a class="btn-tab-review" :class="{ active: tabIndex === 5 }" @click="setTab(5)">1 sao (0)</a>
            <a class="btn-tab-review" :class="{ active: tabIndex === 6 }" @click="setTab(6)">Có bình luận (26)</a>
            <a class="btn-tab-review" :class="{ active: tabIndex === 7 }" @click="setTab(7)">Có Hình ảnh / Video (23)</a>
        </div>
        <div class="row feedback-content">
            <div class="col-lg-3 col-md-4 col-item">
                <div class="content-item">
                    <div class="content-user">
                        <img class="user-image" src="/assets/img/shop/fake_user.png" alt="user-image">
                        <div>
                            <p>Kathryn Murphy</p>
                            <div class="user-review">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-dark.svg" alt="star-img">
                            </div>
                        </div>
                    </div>
                    <p class="text-content">
                        I found a 2007 study on effects of hand sanitizers on blood alcohol level in adults.
                        The 12 subjects applied 4 mL of hand sanitizer for 30 seconds per application,
                        20 applications over a 30 min period (total exposure time 10 min).
                    </p>
                    <div class="content-time">
                        <p>{{ $t('commentFrom')}}</p>
                        <p>{{ $t('useTime')}}</p>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p>Size M - Màu đen</p>
                            <p>Số lượng: 1</p>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-user">
                        <img class="user-image" src="/assets/img/shop/fake_user.png" alt="user-image">
                        <div>
                            <p>Kathryn Murphy</p>
                            <div class="user-review">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-dark.svg" alt="star-img">
                            </div>
                        </div>
                    </div>
                    <p class="text-content">
                        Ask CDCR San Quintin State Prison 2008.
                        We installed Purex dispensers throughout the prison to combat diseases…and it was a Roaring
                        Success (as in Roaring Drunk) I mean we had Long lines of prisoners fist fighting to use them.
                    </p>
                    <div class="content-time">
	                    <p>{{ $t('commentFrom')}}</p>
	                    <p>{{ $t('useTime')}}</p>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p>Size M - Màu đen</p>
                            <p>Số lượng: 1</p>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-user">
                        <img class="user-image" src="/assets/img/shop/fake_user.png" alt="user-image">
                        <div>
                            <p>Kathryn Murphy</p>
                            <div class="user-review">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-dark.svg" alt="star-img">
                            </div>
                        </div>
                    </div>
                    <p class="text-content">
                        Even factoring differences in body weight between children and adults into account,
                        it would still not reach an intoxicating level in children.
                    </p>
                    <div class="content-time">
	                    <p>{{ $t('commentFrom')}}</p>
	                    <p>{{ $t('useTime')}}</p>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p>Size M - Màu đen</p>
                            <p>Số lượng: 1</p>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-user">
                        <img class="user-image" src="/assets/img/shop/fake_user.png" alt="user-image">
                        <div>
                            <p>Kathryn Murphy</p>
                            <div class="user-review">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-dark.svg" alt="star-img">
                            </div>
                        </div>
                    </div>
                    <p class="text-content">
                        Even factoring differences in body weight between children and adults into account,
                        it would still not reach an intoxicating level in children.
                    </p>
                    <div class="content-time">
	                    <p>{{ $t('commentFrom')}}</p>
	                    <p>{{ $t('useTime')}}</p>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p>Size M - Màu đen</p>
                            <p>Số lượng: 1</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-item">
                <div class="content-item">
                    <div class="content-user">
                        <img class="user-image" src="/assets/img/shop/fake_user.png" alt="user-image">
                        <div>
                            <p>Kathryn Murphy</p>
                            <div class="user-review">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-dark.svg" alt="star-img">
                            </div>
                        </div>
                    </div>
                    <p class="text-content">
                        An average healthy 7 year old boy weighs about 50 lb (23 kg).
                        If we suppose the same amount of alcohol as in a 150 lb adult,
                        that might amount to 3.1 to 6.0 mg/L in the child’s body—still far below an intoxicating effect.
                    </p>
                    <div class="content-time">
	                    <p>{{ $t('commentFrom')}}</p>
	                    <p>{{ $t('useTime')}}</p>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p>Size M - Màu đen</p>
                            <p>Số lượng: 1</p>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-user">
                        <img class="user-image" src="/assets/img/shop/fake_user.png" alt="user-image">
                        <div>
                            <p>Kathryn Murphy</p>
                            <div class="user-review">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-dark.svg" alt="star-img">
                            </div>
                        </div>
                    </div>
                    <p class="text-content">
                        So yes, the alcohol (ethanol) in hand sanitizers can be absorbed through the skin,
                        but no, it would not cause intoxication.
                    </p>
                    <div class="content-time">
	                    <p>{{ $t('commentFrom')}}</p>
	                    <p>{{ $t('useTime')}}</p>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p>Size M - Màu đen</p>
                            <p>Số lượng: 1</p>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-user">
                        <img class="user-image" src="/assets/img/shop/fake_user.png" alt="user-image">
                        <div>
                            <p>Kathryn Murphy</p>
                            <div class="user-review">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-dark.svg" alt="star-img">
                            </div>
                        </div>
                    </div>
                    <p class="text-content">
                        So yes, the alcohol (ethanol) in hand sanitizers can be absorbed through the skin,
                        but no, it would not cause intoxication.
                    </p>
                    <div class="content-time">
	                    <p>{{ $t('commentFrom')}}</p>
	                    <p>{{ $t('useTime')}}</p>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p>Size M - Màu đen</p>
                            <p>Số lượng: 1</p>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-user">
                        <img class="user-image" src="/assets/img/shop/fake_user.png" alt="user-image">
                        <div>
                            <p>Kathryn Murphy</p>
                            <div class="user-review">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-dark.svg" alt="star-img">
                            </div>
                        </div>
                    </div>
                    <p class="text-content">
                        The study was repeated with three brands of hand sanitizers containing 55%, 85%, and 95% ethanol.
                        Their blood alcohol level was measured by gas chromatography 30 minutes after the last application.
                    </p>
                    <div class="content-time">
	                    <p>{{ $t('commentFrom')}}</p>
	                    <p>{{ $t('useTime')}}</p>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p>Size M - Màu đen</p>
                            <p>Số lượng: 1</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-item">
                <div class="content-item">
                    <div class="content-user">
                        <img class="user-image" src="/assets/img/shop/fake_user.png" alt="user-image">
                        <div>
                            <p>Kathryn Murphy</p>
                            <div class="user-review">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-dark.svg" alt="star-img">
                            </div>
                        </div>
                    </div>
                    <p class="text-content">
                        An interesting implication of the 2007 study concerns the use of hand sanitizers by observant Muslims.
                        Alcohol is forbidden (haram) to Muslims.
                        Since the alcohol in hand sanitizer is absorbed into the blood, does that make these hand sanitizers haram?
                    </p>
                    <div class="content-time">
	                    <p>{{ $t('commentFrom')}}</p>
	                    <p>{{ $t('useTime')}}</p>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p>Size M - Màu đen</p>
                            <p>Số lượng: 1</p>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-user">
                        <img class="user-image" src="/assets/img/shop/fake_user.png" alt="user-image">
                        <div>
                            <p>Kathryn Murphy</p>
                            <div class="user-review">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-dark.svg" alt="star-img">
                            </div>
                        </div>
                    </div>
                    <p class="text-content">
                        An average healthy 7 year old boy weighs about 50 lb (23 kg).
                        If we suppose the same amount of alcohol as in a 150 lb adult,
                        that might amount to 3.1 to 6.0 mg/L in the child’s body—still far below an intoxicating effect.
                    </p>
                    <div class="content-time">
	                    <p>{{ $t('commentFrom')}}</p>
	                    <p>{{ $t('useTime')}}</p>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p>Size M - Màu đen</p>
                            <p>Số lượng: 1</p>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-user">
                        <img class="user-image" src="/assets/img/shop/fake_user.png" alt="user-image">
                        <div>
                            <p>Kathryn Murphy</p>
                            <div class="user-review">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-dark.svg" alt="star-img">
                            </div>
                        </div>
                    </div>
                    <p class="text-content">
                        Twenty 30-second applications within half an hour is well in
                        excess of almost anyone’s use of a sanitizer.
                    </p>
                    <div class="content-time">
	                    <p>{{ $t('commentFrom')}}</p>
	                    <p>{{ $t('useTime')}}</p>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p>Size M - Màu đen</p>
                            <p>Số lượng: 1</p>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-user">
                        <img class="user-image" src="/assets/img/shop/fake_user.png" alt="user-image">
                        <div>
                            <p>Kathryn Murphy</p>
                            <div class="user-review">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-dark.svg" alt="star-img">
                            </div>
                        </div>
                    </div>
                    <p class="text-content">
                        Their blood alcohol levels rose to 0.007 to
                        0.02 o/oo (parts per thousand), or 0.7 to 2.0 mg/L.
                    </p>
                    <div class="content-time">
	                    <p>{{ $t('commentFrom')}}</p>
	                    <p>{{ $t('useTime')}}</p>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p>Size M - Màu đen</p>
                            <p>Số lượng: 1</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-item item-4">
                <div class="content-item">
                    <div class="content-user">
                        <img class="user-image" src="/assets/img/shop/fake_user.png" alt="user-image">
                        <div>
                            <p>Kathryn Murphy</p>
                            <div class="user-review">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-dark.svg" alt="star-img">
                            </div>
                        </div>
                    </div>
                    <p class="text-content">
                        Simultaneously we had a problem with prisoner drunkenness that we couldn’t figure out.
                        I mean , the guards searched cells multiple times to no avail.
                    </p>
                    <div class="content-time">
	                    <p>{{ $t('commentFrom')}}</p>
	                    <p>{{ $t('useTime')}}</p>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p>Size M - Màu đen</p>
                            <p>Số lượng: 1</p>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-user">
                        <img class="user-image" src="/assets/img/shop/fake_user.png" alt="user-image">
                        <div>
                            <p>Kathryn Murphy</p>
                            <div class="user-review">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-dark.svg" alt="star-img">
                            </div>
                        </div>
                    </div>
                    <p class="text-content">
                        So yes, the alcohol (ethanol) in hand sanitizers can be absorbed through the skin,
                        but no, it would not cause intoxication.
                    </p>
                    <div class="content-time">
	                    <p>{{ $t('commentFrom')}}</p>
	                    <p>{{ $t('useTime')}}</p>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p>Size M - Màu đen</p>
                            <p>Số lượng: 1</p>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-user">
                        <img class="user-image" src="/assets/img/shop/fake_user.png" alt="user-image">
                        <div>
                            <p>Kathryn Murphy</p>
                            <div class="user-review">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-dark.svg" alt="star-img">
                            </div>
                        </div>
                    </div>
                    <p class="text-content">
                        The study was repeated with three brands of hand sanitizers containing 55%, 85%, and 95% ethanol.
                        Their blood alcohol level was measured by gas chromatography 30 minutes after the last application.
                    </p>
                    <div class="content-time">
	                    <p>{{ $t('commentFrom')}}</p>
	                    <p>{{ $t('useTime')}}</p>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p>Size M - Màu đen</p>
                            <p>Số lượng: 1</p>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-user">
                        <img class="user-image" src="/assets/img/shop/fake_user.png" alt="user-image">
                        <div>
                            <p>Kathryn Murphy</p>
                            <div class="user-review">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-dark.svg" alt="star-img">
                            </div>
                        </div>
                    </div>
                    <p class="text-content">
                        However rare side effects observed among children can be metabolic acidosis,
                        coma, respiratory depression, and hypoglycemia-
                    </p>
                    <div class="content-time">
	                    <p>{{ $t('commentFrom')}}</p>
	                    <p>{{ $t('useTime')}}</p>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p>Size M - Màu đen</p>
                            <p>Số lượng: 1</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-item item-5">
                <div class="content-item">
                    <div class="content-user">
                        <img class="user-image" src="/assets/img/shop/fake_user.png" alt="user-image">
                        <div>
                            <p>Kathryn Murphy</p>
                            <div class="user-review">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-dark.svg" alt="star-img">
                            </div>
                        </div>
                    </div>
                    <p class="text-content">
                        An average healthy 7 year old boy weighs about 50 lb (23 kg).
                        If we suppose the same amount of alcohol as in a 150 lb adult,
                        that might amount to 3.1 to 6.0 mg/L in the child’s body—still far below an intoxicating effect.
                    </p>
                    <div class="content-time">
	                    <p>{{ $t('commentFrom')}}</p>
	                    <p>{{ $t('useTime')}}</p>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p>Size M - Màu đen</p>
                            <p>Số lượng: 1</p>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-user">
                        <img class="user-image" src="/assets/img/shop/fake_user.png" alt="user-image">
                        <div>
                            <p>Kathryn Murphy</p>
                            <div class="user-review">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-dark.svg" alt="star-img">
                            </div>
                        </div>
                    </div>
                    <p class="text-content">
                        The principal alcohol in Purell hand sanitizer
                        (to take the most talked-about brand) is 70% ethanol (ethyl alcohol),
                        with a smaller amount of isopropyl alcohol.
                    </p>
                    <div class="content-time">
	                    <p>{{ $t('commentFrom')}}</p>
	                    <p>{{ $t('useTime')}}</p>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p>Size M - Màu đen</p>
                            <p>Số lượng: 1</p>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-user">
                        <img class="user-image" src="/assets/img/shop/fake_user.png" alt="user-image">
                        <div>
                            <p>Kathryn Murphy</p>
                            <div class="user-review">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-dark.svg" alt="star-img">
                            </div>
                        </div>
                    </div>
                    <p class="text-content">
                        In most states, the legal limit in blood alcohol to not be considered DUI is 500 to 1,000 mg/L.
                        Therefore, this is way below a level considered to be intoxication.
                    </p>
                    <div class="content-time">
	                    <p>{{ $t('commentFrom')}}</p>
	                    <p>{{ $t('useTime')}}</p>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p>Size M - Màu đen</p>
                            <p>Số lượng: 1</p>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-user">
                        <img class="user-image" src="/assets/img/shop/fake_user.png" alt="user-image">
                        <div>
                            <p>Kathryn Murphy</p>
                            <div class="user-review">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-light.svg" alt="star-img">
                                <img src="/assets/img/detail/star-dark.svg" alt="star-img">
                            </div>
                        </div>
                    </div>
                    <p class="text-content">
                        Alcohol based exposures through inadvertently consuming hand sanitizer,
                        have been observed to produce more negative side effects for children than non-alcohol based.
                    </p>
                    <div class="content-time">
	                    <p>{{ $t('commentFrom')}}</p>
	                    <p>{{ $t('useTime')}}</p>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p>Size M - Màu đen</p>
                            <p>Số lượng: 1</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ShopRequest from "@/api/requests/ShopRequest";

    export default {
        name: "ShopFeedback",
        data() {
            return {
                tabIndex: 0,
            }
        },
        // mounted() {
        //     this.getData();
        // },
        methods: {
            // async getData() {
            //     const res = await ShopRequest.getHomeData(this.id);
            //     if (!res.err_code) {
            //         this.shop = res.shop;
            //         this.categories = res.categories;
            //     }
            // },
            setTab(index) {
                this.tabIndex = index
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/css/shopDetail";
</style>
