<template>
    <div id="product-detail">
        <div class="top-category">
            <ul class="menu">
                <li>
                    <router-link to="/">{{ $t('home')}}</router-link>
                </li>
                <li><a href="javascript:;">> {{ $t('product')}}</a></li>
                <li><a href="javascript:;">> {{ lang == 'cn' && product.name_cn ? product.name_cn : lang == 'en' && product.name_en ? product.name_en : product.name }}</a></li>
            </ul>
        </div>
        <div id="contents">
            <div class="wrap-container bg-white">
                <div class="row">
                    <div class="col-xl-9 col-sm-12 col-12">
                        <div class="row">
                            <div class="col-xl-5 col-sm-5 col-12 product-img">
                                <div class="slider-category mt-2" id="product-for">
                                    <div class="slider-items" v-for="(thumb, key) in product.thumbs" :key="'pfor-' + key">
                                        <div class="large-image">
                                            <img :src="thumb.link ? thumb.link.uri : ''" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="slider-category mt-2" id="product-nav">
                                    <div class="slider-items" v-for="(thumb, key) in product.thumbs" :key="'pnav-' + key">
                                        <div class="slider-box">
                                            <img class="slider-image" :src="thumb.link.uri" alt="" />
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div class="col-xl-7 col-sm-7 col-12 product-info">
                                <h1 class="product-name">
                                    {{ lang == 'cn' && product.name_cn ? product.name_cn : lang == 'en' && product.name_en ? product.name_en : product.name }}
                                    <i v-if="authData && authData.user.id == product.shop?.user_id" class="fa fa-pencil"
                                       style="color: red; cursor: pointer" @click="editProduct" />
                                </h1>

                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="product-review">
                                        <div class="d-flex">
                                            <span>{{ avgProductRate }}</span>
                                            <img src="/assets/img/detail/star-light.svg" alt="" class="mb-1">
                                            <!--                                <p>{{ // product.total_rate }} đánh giá</p>-->
                                            <p>{{ product.number_sold }} {{ $t('buyer')}}</p>
                                        </div>

                                        <div class="favorite-item">
                                            <i v-if="product.is_favorite" class="fas fa-heart" style="color:#db0000;" @click.prevent="setFavorite(product, false)"></i>
                                            <i v-else class="far fa-heart" @click.prevent="setFavorite(product, true)"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="product-price" v-if="showPriceQuantity && product.type == 1">
                                    <div v-for="(pq, index) in product.priceByQuantities" :key="index">
                                        <div class="col-price">
                                            <p>{{ pq.explain }}</p>
                                            <h3>{{ numberFormat(pq.price) }}đ</h3>
                                        </div>
                                    </div>
                                </div>

                                <div class="product-price" v-if="product.type == 2" style="display: block">
                                    <table class="range-price" style="width: 100%">
                                        <tr>
                                            <th style="width: 25%">{{ $t('price')}}</th>
                                            <td>
                                                <div class="value" v-text="rangePrice"></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th style="width: 25%">{{ $t('interShip')}}</th>
                                            <td>
                                                <select class="form-input" style="width: 60%; padding: 5px 10px; margin-top: 12px">
                                                    <option value="1">{{$t('expressShipping')}}</option>
                                                    <option value="2">{{$t('vipShipping')}}</option>
                                                    <option value="3">{{$t('normalShipping')}}</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th style="width: 25%">{{ $t('ship')}}</th>
                                            <td>
                                                <div class="content-ship">
                                                    <ul>
                                                        <li>
                                                            <div class="d-flex"  style="padding-top: 10px">
                                                                <div style="width: 20%"><img src="/assets/img/logo-mini.png"></div>
                                                                <div class="">
                                                                    {{$t('resolveByElim')}}
                                                                </div>
                                                            </div>


                                                        </li>
                                                        <li>
                                                            <div class="d-flex" style="padding-top: 10px">
                                                                <div class="icon-ship" style="width: 20%; text-align: center">
                                                                    <i class="fa fa-truck" style="font-size: 18px"></i>
                                                                </div>
                                                                <div class="content">
                                                                    <div class="address-ship">
                                                                        <label style="margin-right: 8px">{{$t('shipToAdd')}}: </label> <ModalAddress @setAddress="setAddress" v-if="product.type == 2"></ModalAddress>
                                                                    </div>
                                                                    <div class="price-ship">
                                                                        <label>{{$t('shipFee')}} <span v-text="n(100000) + 'đ'"></span> </label>
                                                                    </div>

                                                                </div>
                                                            </div>


                                                        </li>
                                                        <li>

                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="product-attribute" v-if="product.type == 1">
                                    <div class="row-attribute">
                                        <label>{{ $t('quantity')}}</label>
                                        <div class="product-quantity">
                                            <a class="btn-quantity" @click="addQuantity(-1)">
                                                <span>-</span>
                                            </a>
                                            <input
                                                v-model="quantity"
                                                type="text"
                                                @input="reCalculatePrice()"
                                                maxlength="6"
                                                placeholder="">
                                            <a class="btn-quantity dark" @click="addQuantity(1)">
                                                <span>+</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div v-for="(pack, idx) in product.packs2" :key="idx">
                                        <div class="row-attribute">
                                            <label>{{ pack.id }}</label>
                                            <div class="product-type d-flex" v-for="(p, i) in pack.packs" :key="i">
                                                <a @click="changeOption(pack, p)" :class="p.class">
                                                    <span :title="p.name">{{ stringTruncate(p.name, 40) }}</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="product-attribute international-product" v-if="product.type == 2" style="padding: 0">
                                    <div class="price-tab-bar">
                                        <div class="tab-bar" :class="{ active: tabPriceIndex === 1 }" @click="setTabPrice(1)">
                                            <p>{{ $t('tabNKTD')}}</p>
                                        </div>
                                        <div class="tab-bar" :class="{ active: tabPriceIndex === 2 }" @click="setTabPrice(2)">
                                            <p>{{ $t('tabNKTM')}}</p>
                                        </div>
                                    </div>
                                    <div class="price-tab-content">
                                        <div class="tab-content" :class="showMore == false ? 'tab-content-height' : ''">
                                            <table class="table table-price" v-if="product.skus && product.skus.length > 0">
                                                <thead>
                                                <tr style="text-align: center">
                                                    <th>{{$t('image')}}</th>
                                                    <th style="max-width: 40%">{{ $t('skuName')}}</th>
                                                    <th style="width: 20%">{{ $t('price')}}</th>
                                                    <th style="max-width: 10%">{{ $t('quantity')}}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="sku in product.skus" :key="sku.id">
                                                    <td>
                                                        <img :src="sku.image" :title="lang == 'cn' && sku.name_cn ? sku.name_cn : lang == 'en' && sku.name_en ? sku.name_en : sku.name" style="max-width: 60px; max-height: 60px">
                                                    </td>
                                                    <td v-text="lang == 'cn' && sku.name_cn ? sku.name_cn : lang == 'en' && sku.name_en ? sku.name_en : sku.name"></td>
                                                    <td style="text-align: right">
                                                        <span v-text="n(sku.price)" ></span>
                                                    </td>
                                                    <td>
                                                        <!--							                    <input type="number" class="form-control" v-model="sku.quantity" name="Số lượng" placeholder="Số lượng">-->
                                                        <div class="product-quantity">
                                                            <a class="btn-quantity" @click="addQuantitySPQT(sku, -1)">
                                                                <span>-</span>
                                                            </a>
                                                            <input
                                                                v-model="sku.quantity"
                                                                type="text"
                                                                @input="reCalculateInterProductPrice()"
                                                                maxlength="6"
                                                                placeholder="" style="margin: auto">
                                                            <a class="btn-quantity dark"  @click="addQuantitySPQT(sku, 1)">
                                                                <span>+</span>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="sku-wrapper-expend-button" :style="showMore == true ? 'transform: rotate(180deg); bottom: -2px' : ''" @click="showMore = !showMore"></div>
                                </div>
                                <div class="product-attribute" style="margin-top: 10px">
                                    <div class="row-attribute align-items-start" v-if="!isEmpty(product.delivery)">
                                        <label>{{ $t('timeToShip')}}</label>
                                        <table class="table table-bordered table-shipping">
                                            <thead>
                                            <tr>
                                                <th>{{ $t('quantity')}}</th>
                                                <th v-for="(delivery, idx) in product.delivery" :key="idx">{{ delivery.label }}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>{{ $t('timeCal')}}</td>
                                                <td v-for="(delivery, idx) in product.delivery" :key="idx">{{ delivery.time }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="row-attribute">
                                        <label>{{ $t('option')}}</label>
                                        <a class="text-customize" v-if="!isEmpty(product.customProduct)">
                                            <p v-for="custom in product.customProduct" :key="custom">{{ custom }}<br></p>
                                        </a>
                                        <a class="text-customize" v-else>
                                            {{ $t('contact')}}
                                        </a>
                                    </div>
                                </div>
                                <div class="mt-2" style="font-size: 16px">
                                    <label>{{ $t('receiveAddress')}}</label>
                                    <u class="ml-2" style="font-weight: bold">{{ userAddress? userAddress: $t('unspecified') }}</u>
                                </div>

                            </div>
                        </div>
                        <div class="row sub-info">
                            <div id="our-feature-segment" class="our-feature-segment" data-mz-stop-propagation="" @click="showInfo = !showInfo">
                                <div class="feature-list">
                                    <div class="feature-item">
                                        <div class="feature-item-inner">
                                            <div class="feature-item-icon">
                                                <i class="fas fa-hand-holding-usd"></i></div>
                                            <div class="feature-item-content">
                                                Giá bạn thấy bằng giá bạn trả
                                            </div>
                                        </div>
                                    </div>
                                    <div class="feature-item">
                                        <div class="feature-item-inner">
                                            <div class="feature-item-icon">
                                                <i class="fas fa-user-shield"></i></div>
                                            <div class="feature-item-content">
                                                Yên tâm mua sắm, không lo rủi ro
                                            </div>
                                        </div>
                                    </div>
                                    <div class="feature-item">
                                        <div class="feature-item-inner">
                                            <div class="feature-item-icon">
                                                <i class="fa fa-shield"></i></div>
                                            <div class="feature-item-content">
                                                Hàng chất lượng, rõ nguồn gốc
                                            </div>
                                        </div>
                                    </div>
                                    <div class="feature-item">
                                        <div class="feature-item-inner">
                                            <div class="feature-item-icon">
                                                <i class="fa fa-ship"></i>
                                            </div>
                                            <div class="feature-item-content">
                                                Sản phẩm nhập khẩu chính ngạch
                                            </div>
                                        </div>
                                    </div>
                                    <div class="feature-item">
                                        <div class="feature-item-inner">
                                            <div class="feature-item-icon">
                                                <i class="fas fa-map-marked-alt"></i>
                                            </div>
                                            <div class="feature-item-content">
                                                Liên tục cập nhật hành trình
                                            </div>
                                        </div>
                                    </div>
                                    <div class="feature-item">
                                        <div class="feature-item-inner">
                                            <div class="feature-item-icon">
                                                <i class="fa fa-hand-pointer-o"></i></div>
                                            <div class="feature-item-content">
                                                Giao hàng đến tận tay bạn
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="feature-des-list" :class="showInfo ? 'is-show' : ''">
                                    <div class="feature-des-item">
                                        Cam kết giá bán niêm yết chính xác trên website
                                    </div>
                                    <div class="feature-des-item">
                                        Bảo vệ quyền lợi Khách Hàng, hỗ trợ đổi trả hàng
                                    </div>
                                    <div class="feature-des-item">
                                        Chất lượng đảm bảo, nguồn gốc rõ ràng, có đánh giá từ người mua và
                                        uy tín người bán
                                    </div>
                                    <div class="feature-des-item">
                                        An toàn, minh bạch hợp pháp, không sợ rủi ro
                                    </div>
                                    <div class="feature-des-item">
                                        Theo dõi và cập nhật quá trình vận đơn thường xuyên
                                    </div>
                                    <div class="feature-des-item">
                                        Giao hàng đến tận tay người tiêu dùng
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row no-margin-hoz" style="margin-top: 20px">
                            <div class="col-12 detail-tab-bar">
                                <div class="tab-bar" :class="{ active: tabIndex === 0 }" @click="setTab(0)">
                                    <p>{{ $t('detailProdInfo')}}</p>
                                </div>
                            </div>
                            <div class="col-12 detail-tab-content">
                                <div class="tab-content">
                                    <p v-html="product.content"></p>

                                    <div class="row" >
                                        <div v-for="(pack, idx) in product.productAttributes" :key="idx" class="col-4">
                                            <div style="display: flex">
                                                <label style="color: gray; width: 30%">{{ lang == 'cn' && pack.attribute?.name_cn ? pack.attribute?.name_cn
                                                    : lang == 'en' && pack.attribute?.name_en ? pack.attribute?.name_en : pack.attribute?.name }}</label>
                                                <div style="width: 70%">
                                                    <div class="product-type" v-for="(p, i) in pack.values" :key="i" >
                                        <span :title="lang == 'cn' && p.value_cn ? p.value_cn
                                                        : lang == 'en' && p.value_en ? p.value_en : p.value">
                                            {{ stringTruncate(lang == 'cn' && p.value_cn ? p.value_cn
                                            : lang == 'en' && p.value_en ? p.value_en : p.value, 40) }}</span>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="row no-margin-hoz">
                            <div class="col-12 box-contact box-2">
                                <div class="d-flex align-items-center">
                                    <div class="shop-avatar">
                                        <img v-if="product.shop" :src="product.shop? product.shop.avatar: ''" alt="">
                                    </div>
                                    <div class="row no-margin-hoz" style="width: 100%">
                                        <div class="col-sm-6 d-flex flex-column align-items-start">
                                            <div class="shop-avatar">
                                                <p>{{ product.shop? product.shop.name: '' }}</p>
                                            </div>
                                            <div class="shop-contact" style="width: 100%">
                                                <div class="shop-btn">
                                                    <a class="row ml-2" :href="`/cua-hang/${product.shop ? product.shop.slug : ''}`">
                                                        <img src="/assets/img/detail/ic_home.svg" alt="">
                                                        <p>{{ $t('viewShop')}}</p>
                                                    </a>

                                                </div>
                                                <div class="shop-btn">
                                                    <a class="row ml-2" @click="showChat()">
                                                        <img src="/assets/img/detail/ic_chat.svg" alt="">
                                                        <p>{{ $t('chatNow')}}</p>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 d-flex justify-content-between">
                                            <div class="shop-info">
                                                <label>{{ $t('follower')}}</label>
                                                <p>4000</p>
                                            </div>
                                            <div class="shop-info">
                                                <label>{{ $t('product')}}</label>
                                                <p>53</p>
                                            </div>
                                            <div class="shop-info">
                                                <label>{{ $t('overview')}}</label>
                                                <p>4.6</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row no-margin-hoz">
                            <div class="box-content col-12">
                                <div class="box-header">
                                    <h2>{{ $t('productReview')}}</h2>
                                </div>
                                <div class="review-header">
                                    <div class="review-star">
                                        <p><span>{{ avgProductRate }} <img src="/assets/img/detail/star-light.svg" alt=""></span> trên 5</p>
                                    </div>
                                    <div>
                                        <div class="btn-tab-wrap">
                                            <a :class="filterType == 0? 'btn-tab-review active': 'btn-tab-review'" @click="filterReview(0)">Tất cả</a>
                                            <a :class="filterType == 5? 'btn-tab-review active': 'btn-tab-review'" @click="filterReview(5)">
                                                5 sao ({{ product.countReviews? product.countReviews.fiveStar: 0 }})
                                            </a>
                                            <a :class="filterType == 4? 'btn-tab-review active': 'btn-tab-review'" @click="filterReview(4)">
                                                4 sao ({{ product.countReviews? product.countReviews.fourStar: 0 }})
                                            </a>
                                            <a :class="filterType == 3? 'btn-tab-review active': 'btn-tab-review'" @click="filterReview(3)">
                                                3 sao ({{ product.countReviews? product.countReviews.threeStar: 0 }})
                                            </a>
                                            <a :class="filterType == 2? 'btn-tab-review active': 'btn-tab-review'" @click="filterReview(2)">
                                                2 sao ({{ product.countReviews? product.countReviews.twoStar: 0 }})
                                            </a>
                                            <a :class="filterType == 1? 'btn-tab-review active': 'btn-tab-review'" @click="filterReview(1)">
                                                1 sao ({{ product.countReviews? product.countReviews.oneStar: 0 }})
                                            </a>
                                        </div>
                                        <div class="btn-tab-wrap mt-top">
                                            <a :class="filterType == 6? 'btn-tab-review active': 'btn-tab-review'" @click="filterReview(6)">
                                                {{ $t('hasComment')}} ({{ product.countReviews? product.countReviews.hasContent: 0 }})
                                            </a>
                                            <a :class="filterType == 7? 'btn-tab-review active': 'btn-tab-review'" @click="filterReview(7)">
                                                {{ $t('hasImage')}} ({{ product.countReviews? product.countReviews.hasMedia: 0 }})
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="item-review" v-for="(review, index) in reviews" :key="index +1">
                                        <img :src="review.userInfo? review.userInfo.avatar.uri: ''" alt="" class="customer-avatar"/>
                                        <div class="review-content">
                                            <h3>{{ review.reviewer_name }}</h3>
                                            <div class="product-review">
                                                <img :src="review.rate >= 1? '/assets/img/detail/star-light.svg': '/assets/img/detail/star-dark.svg'" alt="">
                                                <img :src="review.rate >= 2? '/assets/img/detail/star-light.svg': '/assets/img/detail/star-dark.svg'" alt="">
                                                <img :src="review.rate >= 3? '/assets/img/detail/star-light.svg': '/assets/img/detail/star-dark.svg'" alt="">
                                                <img :src="review.rate >= 4? '/assets/img/detail/star-light.svg': '/assets/img/detail/star-dark.svg'" alt="">
                                                <img :src="review.rate >= 5? '/assets/img/detail/star-light.svg': '/assets/img/detail/star-dark.svg'" alt="">
                                            </div>
                                            <p class="text-type">{{ $t('productClassification')}}:
                                                <span v-for="(pack, idx) in review.productPacks" :key="idx+1">
                                        <span v-if="idx !== 0">, </span>
                                        <span>{{ pack.name }}</span>
                                    </span>
                                            </p>
                                            <p class="text-review">{{ review.content }}</p>
                                            <div class="d-flex align-items-center">
                                                <p class="text-time bordered">{{ $t('commentWhen')}}: {{ d(review.created_at) }} </p>
                                                <p class="text-time">{{ $t('buyDaily')}}: {{ d(review.orderDate) }}</p>
                                            </div>
                                            <div class="image-review">
                                                <div class="item-thumb" v-for="(img, i) in review.images" :key="i">
                                                    <img class="review-thumb" :src="img.url" alt="" />
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center mt-3">
                                                <div class="btn-like" :class="{active: review.hasFav}" @click="changeFav(review.id)">
                                                    <span>{{ $t('useful')}} ({{ review.countFavs }})</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="review-pagination">
                                    <Paginate :value="paginate" :pagechange="onPageChange"></Paginate>
                                </div>
                            </div>
                        </div>
                        <div class="row no-margin-hoz" v-if="product.hasOrder">
                            <div class="box-content col-12">
                                <div class="box-header">
                                    <h2>{{ $t('sendMessageToSupplier')}}</h2>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label class="label-input">{{ $t('fullName')}} <span>*</span></label>
                                        <input class="form-input" type="text" v-model="review.reviewer_name"/>
                                        <p style="color: red" v-if="errors.reviewer_name" v-text="errors.reviewer_name[0]"></p>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="label-input">{{ $t('phoneNumber')}} <span>*</span></label>
                                        <input class="form-input" type="text" v-model="review.reviewer_phone"/>
                                        <p style="color: red" v-if="errors.reviewer_phone" v-text="errors.reviewer_phone[0]"></p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label class="label-input">Email</label>
                                        <input class="form-input" type="text" v-model="review.reviewer_email"/>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="label-input">{{ $t('productReview')}}<span>*</span></label>
                                        <select class="form-input" type="text" v-model="review.rate" >
                                            <option value="5">5 sao</option>
                                            <option value="4">4 sao</option>
                                            <option value="3">3 sao</option>
                                            <option value="2">2 sao</option>
                                            <option value="1">1 sao</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <label class="label-input">{{ $t('content')}}</label>
                                        <textarea class="form-input" v-model="review.content"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="fmi-dropdown" ref="dropdown">
                                            <input @change="fileChanged()" accept="image/*" type="file" ref="uploader" style="display: none;width: 1px;height: 1px;"/>
                                            <button ref="button" @click="chooseFile()"  class="btn btn-danger btn-sm" type="button" :disabled="files.length >= 5">
                                                {{ $t('chooseImage')}}
                                            </button>
                                            <!--                                    <div>-->
                                            <!--                                      <span class="qinput-error-label" v-if="errorMessage">{{errorMessage}}</span>-->
                                            <!--                                    </div>-->
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="files.length">
                                    <div class="upload-review"  v-for="(file, idx) in files" :key="idx">
                                        <div class="upload-item-thumb">
                                            <img class="upload-review-thumb" :src="file.url" alt="" />
                                            <span @click="removeFile(idx)" class="fmi-close">
                                    <i class="fa fa-times"/>
                                </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    <a class="btn-submit" @click="addReview()">{{ $t('send')}}</a>
                                </div>
                            </div>
                        </div>
                        <div class="row no-margin-hoz">
                                <div class="box-content col-12">
                                    <div class="box-header">
                                        <h2>{{ $t('relateProduct')}}</h2>
                                        <router-link class="viewmore" :to="'/'">{{ $t('viewMore')}}</router-link>
                                    </div>
                                    <div class="row related-content">
                                        <div class="col-xl-3 col-sm-4 col-6 padding-mobile" v-for="(item, index) in product.relationProduct" :key="index+ 1">
                                            <product-item :product="item" screen="product"></product-item>
                                        </div>
                                    </div>
                                </div>
                            </div>

                    </div>
                    <div class="col-xl-3">
                        <div class="payment-count">
                            <div style="width: 100%; padding: 0 10px">
                                <div class="d-flex justify-content-between">
                                    <p class="payment-label">{{ $t('priceMoney')}}: </p>
                                    <div class="d-flex">
                                        <p class="payment-info"> {{ product.type == 2 ? numberFormat(amountPrice) + 'đ' : numberFormat(price) + 'đ' }}</p>
                                        <p class="old-price pl-2" v-if="product.price_discount && product.price > product.price_discount">{{ numberFormat(product.price) + 'đ'}}</p>
                                    </div>

                                </div>
	                            <div class="d-flex justify-content-between" v-if="product.type == 2">
		                            <p class="payment-label">{{ $t('vat')}}: </p>
		                            <div class="d-flex">
			                            <p class="payment-info"> {{ numberFormat(vat) + 'đ' }}</p>
		                            </div>
	
	                            </div>
	                            <div class="d-flex justify-content-between" v-if="product.type == 2">
		                            <p class="payment-label">{{ $t('shipQT')}}: </p>
		                            <div class="d-flex">
			                            <p class="payment-info"> {{ numberFormat(shipQT) + 'đ' }}</p>
		                            </div>
	
	                            </div>
                                <div>
                                    <p class="payment-label" style="width: 60%">{{ $t('shipTo')}}: </p>
                                    <p class="payment-info" :style="userAddress? 'font-size: 14px': 'font-size: 14px; color: #C0C0C0'"> {{ userAddress? userAddress: $t('unspecified') }}</p>
                                </div>
                                <ModalAddress @setAddress="setAddress" v-if="product.type == 1"></ModalAddress>
                                <p class="payment-label mt-2" v-if="quantity > 0 && deliveryDate != ''">{{ $t('shipTime')}} <span>{{ deliveryDate }}</span></p>
                                <p class="payment-label">{{ product.shipping_note }}</p>
                                <div class="count-price">
                                    <p class="text-price">{{ $t('sum')}}</p>
                                    <p class="text-price"> {{ numberFormat(amount) }}đ</p>
                                </div>
                            </div>
                            <div v-if="quantity > 0" class="btn-wrap">
                                <a class="btn-buy" @click="redirectCart()">
                                    <p>{{ $t('startOrder')}}</p>
                                </a>

                                <a class="btn-add-cart" @click="removeCartItem(product)" v-if="isInCart">
                                    <p>
                                        <i class="fas fa-check"/> {{ $t('cartAdded')}}
                                    </p>
                                </a>
                                <a class="btn-add-cart" @click="addCart()" v-else>
                                    <img src="/assets/img/detail/ic_cart.svg" alt="">
                                    <p>{{ $t('addToCart')}}</p>
                                </a>
                                <a class="btn-add-cart" data-toggle="modal" data-target="#exampleModal">
                                    <i class="fab fa-dropbox" style="color: #DB0000;font-size: 21px;"></i>
                                    <p>{{ $t('SignUpForDropship')}}</p> 
                                </a>
                            </div>
                        </div>
                        <div class="box-contact box-1">
                            <p class="contact-title">{{ $t('howToKnowPrice')}}</p>
                            <a class="btn-contact" @click.prevent="showChat()">
                                <p>{{ $t('contactSupplier')}}</p>
                            </a>
                            <div class="other-contact" v-if="product.shop && product.shop.zalo_url">
                                <a class="row" @click.prevent="showChat()">
                                  <img src="/assets/img/detail/icon_zalo.png" alt="">
                                  <p>{{ $t('contactToZalo')}}</p>
                                </a>
                            </div>
                            <div class="shop-avatar">
                                <img v-if="product.shop" :src="product.shop? product.shop.avatar: ''" alt="">
                                <p>{{ product.shop? product.shop.name: '' }}</p>
                            </div>
                            <div class="row no-margin-hoz justify-content-between">
                                <div class="shop-info">
                                    <label>{{ $t('follower')}}</label>
                                    <p>{{ product.shop? product.shop.number_follows: ''  }}</p>
                                </div>
                                <div class="shop-info">
                                    <label>{{ $t('product')}}</label>
                                    <p>{{ product.shop? product.shop.countProduct: ''  }}</p>
                                </div>
                                <div class="shop-info">
                                    <label>{{ $t('overview')}}</label>
                                    <p>{{ avgShopRate  }} <img src="/assets/img/detail/star-light.svg" alt="" class="mb-1"></p>
                                </div>
                            </div>
                            <div class="shop-contact" v-if="product.shop">
                                <div class="shop-btn">
                                    <router-link class="d-flex" style="margin: auto" :to="`/cua-hang/${product.shop.slug}`">
                                        <img src="/assets/img/detail/ic_home.svg" alt="">
                                        <p>{{ $t('viewShop')}}</p>
                                    </router-link>
                                </div>
                                <div class="shop-btn">                              
                                        <a class="d-flex" style="margin: auto" @click="showChat()">
                                            <img src="/assets/img/detail/ic_chat.svg" alt="">
                                            <p>{{ $t('chatNow')}}</p>
                                        </a>
                                </div>
                            </div>
                        </div>
                        <div class="box-more-info">
                            <label>Bạn cần hỗ trợ</label>
                            <div class="item">
                                <a href="javascript:;" target="_blank" rel="nofollow" class="sub-item">
                                    <div class="item-icon">
                                        <i class="fas fa-circle"></i>
                                    </div>
                                    <div class="item-text">Hướng dẫn mua hàng</div>
                                </a>
                                <a href="javascript:;" target="_blank" rel="nofollow" class="sub-item">
                                    <div class="item-icon">
                                        <i class="fas fa-circle"></i>
                                    </div>
                                    <div class="item-text">Phương thức thanh toán</div>
                                </a>
                                <a href="javascript:;" target="_blank" rel="nofollow" class="sub-item">
                                    <div class="item-icon">
                                        <i class="fas fa-circle"></i>
                                    </div>
                                    <div class="item-text">Chính sách đổi trả hàng</div>
                                </a>
                                <a href="javascript:;" target="_blank" rel="nofollow" class="sub-item">
                                    <div class="item-icon">
                                        <i class="fas fa-circle"></i>
                                    </div>
                                    <div class="item-text">Nguồn điện sử dụng</div>
                                </a>
                                <a href="javascript:;" target="_blank" rel="nofollow" class="sub-item">
                                    <div class="item-icon">
                                        <i class="fas fa-circle"></i>
                                    </div>
                                    <div class="item-text">Hướng dẫn chọn size</div>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div><!---/# CONTENTS--->
    </div>
    <!-- modal dropship -->

        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered ">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel" style="font-weight: 600;">Đăng ký Dropship</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form action="https://formsubmit.co/897f5da436e14ba6b77016c07861bc0f" method="POST">
                        <input type="hidden" name="_next" :value="'https://elim.vn' + urlCurentNextDropShip">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Họ và Tên</label>
                            <input type="text" class="form-control" name="Họ và Tên" id="exampleInputEmail1" aria-describedby="emailHelp">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Loại hình</label>
                            <select class="form-select form-control " placeholder="Loại hình">
                                <option value="1">Cá nhân</option>
                                <option value="2">Tổ chức</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Kênh phân phối chính</label>
                            <select class="form-select form-control" placeholder="Kênh phân phối chính">
                                <option value="Tiktok">Tiktok</option>
                                <option value="Page cá nhân">Page cá nhân</option>
                                <option value="Khác">Khác</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Link kênh</label>
                            <input type="text" class="form-control" name="Link kênh" id="exampleInputEmail1" aria-describedby="emailHelp">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Link shop</label>
                            <input type="text" class="form-control" name="Link shop" id="exampleInputEmail1" aria-describedby="emailHelp">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Sản phẩm chính quan tâm</label>
                            <input type="text" class="form-control" name="Sản phẩm chính quan tâm" id="exampleInputEmail1" aria-describedby="emailHelp">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Năng lực phân phối (sản lượng dự kiến  / tháng , / năm )</label>
                            <input type="text" class="form-control" name="Năng lực phân phối (sản lượng dự kiến  / tháng , / năm )" id="exampleInputEmail1" aria-describedby="emailHelp">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Mong muốn chiết khấu</label>
                            <input type="text" class="form-control" name="Mong muốn chiết khấu" id="exampleInputEmail1" aria-describedby="emailHelp">
                        </div>
                        <div class="form-group text-center d-flex flex-item-center" style="align-items: center;gap: 10px;justify-content: center;">
                            <button type="submit" class="btn btn-danger">Đăng ký</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal" aria-label="Close">Hủy</button>
                        </div>
                    </form>
                </div>
                </div>
            </div>
        </div>
    <chat ref="chatBox" v-if="showChatBox"/>
</template>

<script>
    import $ from 'jquery';
    // import 'slick-carousel';
    // import "slick-carousel/slick/slick-theme.css";
    import ProductRequest from "@/api/requests/ProductRequest";
    import CartRequest from "@/api/requests/CartRequest";
    import ReviewerRequest from "@/api/requests/ReviewerRequest";
    import store from "@/store";
    import ProductItem from "@/components/ProductItem";
    import {getOption, isEmpty, numberFormat, siteSetTitle, stringTruncate} from "@/lib/utils";
    import moment from "moment";
    import Paginate from "@/components/Paginate";
    import Chat from "@/components/Chat";
    import toastr from 'toastr';
    import ModalAddress from "@/views/ModalAddress";
    import QImage from "@/components/QImage.vue";

    export default {
        name: "ProductDetail",
        props: ['slug'],
        components: {QImage, Chat, ProductItem, Paginate, ModalAddress},
        data() {
            const user = store.state.Auth ? store.state.Auth.user : {
                name: '',
                email: '',
                phone: '',
            };


            return {
                product: {
                    cartItemId: null,
                },
                packs2: {},
                packsSelected: [],
	            attributesSelected: [],
                quantity: 1,
                amount: 0,
                price: 0,
	            rangePrice: '',
	            totalWeight: 0,
	            totalSize: 0,
	            vat: 0,
	            shipQT: 0,
                option: '',
                tabIndex: 0,
	            tabPriceIndex: 1,
                isActive: false,
                errorMessage: '',
                review: {
                    reviewer_name: user.name,
                    reviewer_email: user.email,
                    reviewer_phone: user.phone,
                },
                errors: {},
                reviews: {},
                filterType: 0,
                page: 1,
                paginate: {
                    currentPage: 1,
                    lastPage: 1
                },
                files: [],
                avgProductRate: 0,
                avgShopRate: 0,
                userAddress: '',
                deliveryDate: '',
                showPriceQuantity: true,
	            lang: store.state.locale,
                showMore: false,
                amountPrice: 0,
                showInfo: false,
                modalDataDropShip: {},
                urlCurentNextDropShip: '',
            }
        },
        created() {
            this.urlCurentNextDropShip = window.location.pathname;
            window.scrollTo(0, 0);
            console.log(this.product);
        },
        mounted() {
            this.getData();
            // this.initSlider();
        },
        updated() {
            this.initSlider();
        },
        computed: {
            authData: () => store.state.Auth,
            showChatBox: () => store.state.showChatBox,

            isInCart: function() {
                let packIds = this.getPackIds();
                if (this.product.cartItems && this.product.cartItems.length > 0) {
                    return this.product.cartItems.find(item => item.pack_ids == packIds);
                }

                return false;

            }
        },
        methods: {
            isEmpty,
            numberFormat,
            stringTruncate,
            showChat() {
                store.commit('SetShowChatBox', false);
                setTimeout(() => {
                    store.commit('SetShowChatBox', true);
                }, 100);
                setTimeout(() => {
                    this.$refs.chatBox.createConversation(this.product.shop);
                }, 100);
            },
            onPageChange(page) {
              this.page = page;
              this.getDataReviewer();
            },
            async getData() {
                const res = await ProductRequest.show(this.slug);
                if (!res.err_code) {
                    this.product = res.product;
                    siteSetTitle(res.product.name);
                    this.setSeenProduct(this.product);
                    this.userAddress = res.product.user_address;
                  //  document.title = this.product.name;

                    let price = this.product.priceByQuantities[0] || '';

                    if (price && price.quantity_from > 1 && (!this.product.price_discount || this.product.price_discount == this.product.price)) {
                        this.price = price.price;
                        this.quantity = price.quantity_from;
                    } else {
                        this.price = this.product.price_discount && this.product.price_discount < this.product.price? this.product.price_discount: this.product.price;
                        this.quantity = 1;
                    }

                    this.showPriceQuantity = !this.product.price_discount || this.product.price_discount == this.product.price;

                    if (!this.product.countdown_show && this.product.packs2) {
                        this.product.packs2.forEach(packs => {
                            packs.packs.forEach((pack, idx) => {
                                pack.parentId = packs.id;
                                if (idx === 0) {
                                    pack.class = 'active';
                                    this.packsSelected.push(pack);
                                    // if (this.price < pack.price) {
                                    //     this.price = pack.price;
                                    //     this.option = packs.id;
                                    //     this.amount = pack.price;
                                    // }
                                }
                            })
                        })
                    }
                    this.reCalculatePrice();
                    if (this.product.type == 2) {
                    	this.reCalculateInterProductPrice();
                    }

                    await this.getDataReviewer();
                } else if (res.err_code === 1) {
                    this.$router.push('/404');
                }
            },
            initSlider() {
                $(function () {
                    $('#product-for').not('.slick-initialized').show().slick({
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                        fade: true,
                        asNavFor: '#product-nav'
                    });
                    $('#product-nav').not('.slick-initialized').show().slick({
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        asNavFor: '#product-for',
                        centerMode: true,
                        focusOnSelect: true
                    });
                });
            },
            async removeCartItem(product) {
                let packIds = this.getPackIds();
                let itemId = null;
                if (product.cartItems && product.cartItems.length > 0) {
                    let index = product.cartItems.findIndex(item => item.pack_ids == packIds);
                    itemId = product.cartItems[index].id;
                }
                const res = await CartRequest.remove(itemId);
                if (res.err_code === 0) {
                    //toastr.success(res.message);
                    // product.cartItemId = null;
                    this.product.cartItems = res.items;
                    store.commit('SetCountCartItem', res.items.length);
                }
            },
            async addCart() {

                if (!isEmpty(this.product.priceByQuantities) && (!this.product.price_discount || this.product.price_discount == this.product.price)) {
                    let minQuantity = this.product.priceByQuantities[0].quantity_from;
                    if (this.quantity < minQuantity) {
                        toastr.error("Vui lòng đặt tối thiểu " + minQuantity + " " + this.product.unit);
                        return;
                    }
                }

                let hasInventory = true;
                if (this.packsSelected.length > 0) {
                    this.packsSelected.forEach((pack, idx) => {
                        if (pack.inventory_type == 2 && pack.inventory_quantity < this.quantity) {
                            hasInventory = false;
                        }
                    });
                }
                if (this.quantity > 0 && (!this.product.price_discount || this.product.price_discount == this.product.price)) {
                        if(this.product.priceByQuantities){
                            this.product.priceByQuantities.forEach((price, idx) => {
                                if (this.quantity >= price.quantity_from && (this.quantity <= price.quantity_to || idx == this.product.priceByQuantities.length - 1)) {
                                    if (price.inventory_type ==2 && price.inventory_quantity < this.quantity) {
                                        hasInventory = false;
                                    }
                                 }                
                               });
                        }
                    
                }
                if (!hasInventory) {
                    toastr.error('Không đủ số lượng sản phẩm. Bạn vui lòng chọn sản phẩm khác!');
                    return;
                }

                const cartItem = {
                    product: this.product,
                    quantity: this.quantity,
                    packs: this.packsSelected,
                    type: this.product?.type ?? 1,
                    type_international: this.tabPriceIndex,
                    skus: this.product.skus
                }

                const res = await CartRequest.add(cartItem);
                if (res.err_code === 0) {
                    this.product.cartItems = res.items;
                    toastr.success(this.$t('cartAdded'));
                    store.commit('SetCountCartItem', res.items.length);
                } else {
                    this.errorMessage = res.message;
                    toastr.error(res.message);
                }

            },
            async addReview() {
                if (this.checkData(this.review)) {
                    let review = {
                        ...this.review,
                        user_id: this.authData.user.id,
                        product_id: this.product.id,
                        shop_id: this.product.shop.id,
                        files: this.files,
                    };
                    const res = await ReviewerRequest.add(review);
                    if (res.err_code == 0) {
                        alert(res.message);
                        this.files = [];
                        let user = store.state.Auth  ? store.state.Auth.user : {
                                name: '', email: '', phone: ''
                        };


                        this.review = {
                            reviewer_name: user.name,
                            reviewer_email: user.email,
                            reviewer_phone: user.phone,
                            rate: 0,
                            content: ''
                        };
                        await this.getDataReviewer();
                    }
                }
            },
            async getDataReviewer() {
                let productId = this.product.id;
                let filter_type = this.filterType;
                let page = this.page;
                const res = await ReviewerRequest.getList(productId, filter_type, page);
                if (res.err_code == 0) {
                    this.reviews = res.reviews;
                    this.avgProductRate = res.avg_product_rate;
                    this.avgShopRate = res.avg_shop_rate;
                    this.paginate = res.paginate;
                }

            },
            checkData(review) {
                this.errors = {
                    reviewer_name: [],
                    reviewer_phone: [],
                };
                if (!review.reviewer_name) {
                    this.errors.reviewer_name.push('Vui lòng nhập họ và tên');
                }

                if (!review.reviewer_phone) {
                    this.errors.reviewer_phone.push('Vui lòng nhập số điện thoại');
                } else if (!this.validPhone(review.reviewer_phone)) {
                    this.errors.reviewer_phone.push('Vui lòng nhập đúng định dạng số điện thoại')
                }

                return !this.errors.reviewer_name.length && !this.errors.reviewer_phone.length;

            },
            validPhone: function (phone) {
                let rule = /^\d{8,13}$/;
                return rule.test(phone);
            },
            async redirectCart() {

                if (!isEmpty(this.product.priceByQuantities) && (!this.product.price_discount || this.product.price_discount == this.product.price)) {
                    let minQuantity = this.product.priceByQuantities[0].quantity_from;
                    if (this.quantity < minQuantity) {
                        toastr.error("Vui lòng đặt tối thiểu " + minQuantity + " " + this.product.unit);
                        return;
                    }
                }
                let hasInventory = true;
                if (this.packsSelected.length > 0) {
                    this.packsSelected.forEach((pack, idx) => {
                        if (pack.inventory_type == 2 && pack.inventory_quantity < this.quantity) {
                            hasInventory = false;
                        }
                    });
                }
                if (this.quantity > 0 && (!this.product.price_discount || this.product.price_discount == this.product.price)) {
                    this.product.priceByQuantities.forEach((price, idx) => {
                        if (this.quantity >= price.quantity_from && (this.quantity <= price.quantity_to || idx == this.product.priceByQuantities.length - 1)) {
                            if (price.inventory_type ==2 && price.inventory_quantity < this.quantity) {
                                hasInventory = false;
                            }
                        }
                    });
                }
                if (!hasInventory) {
                    toastr.error('Không đủ số lượng sản phẩm. Bạn vui lòng chọn sản phẩm khác!');
                    return;
                } else {
                    const cartItem = {
                        product: this.product,
                        quantity: this.quantity,
                        packs: this.packsSelected,
	                    attributes: this.attributesSelected,
                        type: this.product?.type ?? 1,
                        type_international: this.tabPriceIndex,
                        skus: this.product.skus
                    }

                    const res = await CartRequest.add(cartItem);
                    if (res.err_code === 0) {
                        this.product.cartItemId = res.item.id;
                        store.commit('SetCountCartItem', res.items.length);
                        this.$router.push('/gio-hang?type=' + this.product.type);
                    } else {
                        this.errorMessage = res.message;
                        toastr.error(res.message);
                    }
                }
            },

            addQuantity(value) {
                let count = parseInt(this.quantity) + value;
                if (count < 0) {
                    this.quantity = 0;
                } else {
                    this.quantity = parseInt(count);
                }
                this.amount = 0;
                this.reCalculatePrice()
            },
	
	        addQuantitySPQT(item, value) {
		        let count = parseInt(item.quantity) + value;
		        if (count < 0) {
			        item.quantity = 0;
		        } else {
			        item.quantity = parseInt(count);
		        }
		        this.amount = 0;
		        this.reCalculateInterProductPrice()
	        },

            reCalculatePrice() {
                if (this.quantity > 0) {
                    if (!isEmpty(this.product.priceByQuantities) && (!this.product.price_discount || this.product.price_discount == this.product.price)) {
                        this.product.priceByQuantities.forEach((price, idx) => {
                            if (this.quantity >= price.quantity_from && this.quantity <= price.quantity_to) {
                                this.price = price.price;
                            } else if (idx == this.product.priceByQuantities.length - 1 && this.quantity >= price.quantity_from) {
                                this.price = price.price;
                            }
                        });
                    }
                    this.amount = this.quantity * this.price;

                    if (!isEmpty(this.product.delivery)) {
                        this.product.delivery.forEach((val, i) => {
                            if (this.quantity >= val.quantity_from && this.quantity <= val.quantity_to) {
                                this.deliveryDate = val.time + ' ngày';
                            } else if (this.quantity >= val.quantity_from && i == this.product.delivery.length -1) {
                                this.deliveryDate = val.time;
                            }
                        });
                    }
                }
            },
	        reCalculateInterProductPrice() {
            	let sum = 0;
            	let totalQuantity = 0;
            	let totalWeight = 0;
		        let totalSize = 0;
            	let priceMin = 0;
            	let priceMax = 0;
            	let weight = 0;
            	let size = 0;
		        let vat = 0;
		        let shipQT = 0;
            	if (this.product.weight && this.product.weight > 0) {
            		weight = this.product.weight;
	            }
		        if (this.product.size && this.product.size > 0) {
			        size = this.product.size;
		        }
            	if (this.product.skus && this.product.skus.length > 0) {
            		priceMin = this.product.skus[0].price;
            		this.product.skus.forEach(sku => {
            			if (sku.price > priceMax) {
            				priceMax = sku.price;
			            }
            			if (sku.price < priceMin) {
            				priceMin = sku.price;
			            }
            			if (sku.quantity > 0) {
            				sum += sku.price*sku.quantity;
            				totalQuantity += sku.quantity;

            				if (weight > 0) {
            				    totalWeight += weight*sku.quantity;
				            }
				
				            if (size > 0) {
					            totalSize += size*sku.quantity;
				            }
            				
			            }
		            })
	            }
            	this.rangePrice = priceMax > priceMin ? numberFormat(priceMin) + ' đ ~ ' + numberFormat(priceMax) + ' đ' : numberFormat(priceMin) + ' đ';
            	this.amount = sum;
            	this.totalWeight = totalWeight;
		        this.totalSize = totalSize;
		        if (this.tabPriceIndex == 2) {
		        	vat = this.roundNumber((this.product.vat)/100 * this.amount);
		        	if (this.totalSize > 0) {
		        		shipQT = this.roundNumber(this.product?.ship_quocte_nktm * totalQuantity);
			        }
		        } else {
		        	vat = 0;
		        	if (this.totalWeight > 0) {

		        		shipQT = this.roundNumber(this.product?.ship_quocte_nktd * totalQuantity);
			        }
		        }
		        this.vat = vat;
		        this.shipQT = shipQT;
            this.amountPrice = this.amount;
		        
		        this.amount = this.amount + this.vat + this.shipQT;
          
	        },

            changeOption(data, info) {
                this.product.packs2.forEach(packs => {
                    if (data.id === packs.id) {
                        packs.packs.forEach((pack, idx) => {
                            if (pack.class === 'active') {
                                pack.class = '';
                            }
                            if (pack.id === info.id) {
                                pack.class = 'active';
                                if (this.packsSelected && this.packsSelected.length > 0) {
                                    this.packsSelected.forEach((p, index) => {
                                        if (p.parentId === packs.id) {
                                            this.packsSelected[index] = pack;
                                        }
                                    })
                                }
                                if (this.price < pack.price || packs.id === this.option) {
                                  this.price = pack.price;
                                  this.option = packs.id;
                                }
                            }
                        })
                    }
                })
                this.reCalculatePrice()
            },
	
	        changeAttribute(data, info) {
		        this.product.productAttributes.forEach(packs => {
			        if (data.id === packs.id) {
				        packs.values.forEach((pack, idx) => {
					        if (pack.class === 'active') {
						        pack.class = '';
					        }
					        if (pack.id === info.id) {
						        pack.class = 'active';
						        if (this.attributesSelected) {
						        	if (this.attributesSelected.length > 0) {
								        this.attributesSelected.forEach((p, index) => {
									        if (p.attribute_id === packs.id) {
										        this.attributesSelected[index] = info;
									        }
								        })
							        } else  {
								        this.attributesSelected.push(info);
							        }
						        }
						        if (this.price < pack.price || packs.id === this.option) {
							        this.price = pack.price;
							        this.option = packs.id;
						        }
					        }
				        })
			        }
		        })
		        this.reCalculatePrice()
	        },

            setTab(index) {
                this.tabIndex = index
            },
	        setTabPrice(index) {
		        this.tabPriceIndex = index;
                if (this.product.type == 2 && this.product.skus && this.product.skus.length > 0) {
                    this.product.skus.forEach(sku => {
                        sku.quantity = 0;
                    })
                }
                this.reCalculateInterProductPrice();

	        },
	        
            d(tzTime) {
                if (!tzTime) {
                  return '';
                }
                const m =  moment(tzTime);

                return m.format('DD/MM/YYYY')
            },
            async changeFav(reviewId) {
                const res = await ReviewerRequest.changeReviewFav(reviewId);
                if (res.err_code == 0) {
                    await this.getDataReviewer();
                }
            },
            filterReview(type) {
                this.filterType = type;
                this.page = 1;
                this.getDataReviewer();
            },
            chooseFile() {
                this.$refs.uploader.click();
            },
            async fileChanged() {
                const files = this.$refs.uploader.files;

                if (files.length > 0) {
                    const res = await ReviewerRequest.uploadFile(files);
                    if (res) {
                        this.files.push(res);
                    }
                }

            },
            removeFile(idx) {
                this.files.splice(idx, 1);
            },
            async setFavorite(product, isFavorite) {
                const authData = store.state.Auth;
                if (authData) {
                    const res = await ProductRequest.setFavoriteProduct(product.id, isFavorite);
                    product.is_favorite = isFavorite;
                } else {
                    alert('Vui lòng đăng nhập để thêm sản phẩm yêu thích!');
                }
            },
            setAddress(address) {
                this.userAddress = address?.decoded;
            },

            async setSeenProduct(product) {
                const authData = store.state.Auth;
                if (authData) {
                    const res = await ProductRequest.setSeenProduct(product.id);
                }
            },
            editProduct() {
                if (this.authData) {
                    console.log(process.env.VUE_APP_SHOP_URL)
                    const shopUrl = process.env.VUE_APP_SHOP_URL || 'http://shop.elim.vn';
                    window.open(shopUrl + '/manager/products/edit?id='+ this.product.id, '_blank');
                }
            },

            getPackIds () {
                let packIds = [];
                if (this.packsSelected && this.packsSelected.length > 0) {
                    this.packsSelected.forEach(p => {
                        packIds.push(p.id);
                    });
                } else {
                    packIds.push(0);
                }

                return packIds.toString();
            },
            roundNumber(number) {
                if (number < 1000) {
                    if (number < 500) {
                        return 0;
                    } else {
                        return 1000;
                    }
                } else {
                    let numMod = number % 1000;
                    let numDiv = number / 1000;
                    if (numMod < 500) {
                        return number - numMod;
                    } else {
                        return Math.ceil(numDiv) * 1000;
                    }
                }
            }
        },

    }
</script>

<style scoped lang="scss">
    @import "../assets/css/productDetail";
</style>
