<template>
  <div>
    <div v-if="isLoading" class="d-flex" style="height: 300px">
      <div class="spinner-border m-auto" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-else-if="!!posts.length">
      <div class="content-right">
        <table class="table">
        <thead>
        <tr>
          <th></th>
          <th class="text-uppercase">Tiêu đề tin</th>
          <th class="text-uppercase">Danh mục</th>
          <th class="text-uppercase">Tag</th>
          <th class="text-uppercase text-center">Đăng bởi</th>
          <th class="text-uppercase text-center">Thời gian</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in posts" :key="item.id">
          <td style="width:70px">
            <img v-if="item.image && item.image.src"
                 :src="item?.image?.src" :style="{height:'50px',width:'50px'}"
                 alt=""
                 @error="loadImgErr"/>
            <img v-else :src="defaultImg" alt="" :style="{height:'50px',width:'50px'}"/>
          </td>
          <td style="width: 40%">

            <div class="d-flex">
              <router-link :to="{path: item && item.id ? '/thong-tin-rao-vat/' + item.id : '', query:{
                 currentTab: property =='1'?'2':'1'
              }}">
                <div class="text-title  mr-2">{{ subStringTitle(item.title) }}</div>

              </router-link>
            </div>
          </td>
          <td v-text="item.categories.map(e=>e.name).join(',')"></td>
          <td v-text="item.tag"></td>
          <td>

            <router-link :to="{path: item.from ? '/thong-tin-nguoi-dang/' + item.from?.id : '', query: {
              currentTab:property =='1'?'2':'1'
            }}">
              <div class="content-tags">{{ item?.from?.name }}</div>
            </router-link>
          </td>
          <td>
            <div class="content-tags">{{ formatTime(item.updated_at) }}</div>

          </td>
        </tr>
        </tbody>
      </table>
      </div>

      <div class="paginate mt-3">
        <Paginate
            :value="paginate"
            :pagechange="onPageChange"
        ></Paginate>
      </div>
    </div>

    <div class="content-not-found h-300px" v-else>
      <span class="m-auto">{{ $t('notFoundPost') }}</span>
    </div>
  </div>
</template>

<script>
import CrawlRequest from "@/api/requests/CrawlRequest";
import Paginate from "@/components/Paginate";
import {defaultImg, stringTruncate} from "@/lib/utils";
import moment from "moment/moment";
import router from "@/router";

export default {
  name: "TabNews",
  components: {Paginate,},
  props: ['property'],
  data() {
    return {
      // provinces: [],
      page: this.$route.query.page || 1,
      isLoading: true,
      posts: [],
      hasNextPage: false,
      paginate: {
        currentPage: 1,
        lastPage: 0,
      },
      defaultImg: defaultImg()
    }
  },
  methods: {
    loadImgErr(e) {
      e.currentTarget.src = defaultImg();
    },
    subStringTitle(title) {
      return stringTruncate(title, 100)
    },
    formatDate(date) {
      if (!date) return "";
      return moment(date).format('DD/MM/YYYY');
    },
    formatTime(date) {
      if (!date) return "";
      return moment(date).fromNow();
    },

    async load() {
      this.isLoading = true;
      const query = this.$route.query || {};
      let option = {
        page: this.page,
        limit: 30
      }

      const res = await CrawlRequest.get(this.$route.params.id, query.categories, query.keyword, this.property, option);

      this.posts = res.data;
      this.paginate = res.paginate;

      this.isLoading = false;
    },
    onPageChange(page) {
      window.scrollTo(0, 0);
      this.page = page;
      this.$router.replace({
        query: Object.assign({...this.$route.query}, {
          page: page
        })
      });
      this.paginate.page = page;
      this.load();
    },

  },
  watch: {
    "$route.query.keyword": function (newValue) {
      this.paginate.page = 1;
      this.load();
    },
    "$route.query.categories": function (newValue) {
      this.paginate.page = 1;
      this.load();
    },
  },
  mounted() {
    document.title = this.$t('raoVat');
      this.load();
    let app = this;
    app.$eventBus.on('LOAD_CRAWL_POST_DATA', async () => {
      await app.load();
    });
  },
  unmounted() {
    this.$eventBus.off('LOAD_CRAWL_POST_DATA');
  }
}
</script>

<style scoped lang="scss">
.content-not-found {
  height: 300px;
  display: flex;
  font-size: 16px;
}

.content-tags {
  background-color: #bbbbbb40;
  padding: 4px 12px;
  border-radius: 20px;
  margin: 2px;
  text-align: center;
}
</style>
