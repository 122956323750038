import BaseRequest from "../BaseRequest";
import {Product, Campaign, Cart, CartItem, FeeData, GeneralResponse} from '../interfaces';

class VoucherRequest extends BaseRequest {

    constructor() {
        super('VoucherRequest');
    }

    async get(): Promise<any> {
        return super.dispatch('get');
    }

    async amount(): Promise<GeneralResponse> {
        return super.dispatch('amount');
    }
}

export default new VoucherRequest()
