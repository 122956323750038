<template>
  <div class="container-fluid" id="post-list">
      <div class="wrap-container" style="padding-top: 10px">
          <div class="row mb-5">
              <div class="col-lg-9 col-md-9 col-12">
                  <div class="news" v-if="featuredNews.length">
                      <router-link :to="{name: 'PostDetail', params: {id: firstFeaturedNew.slug}}">
                          <div class="row new-first">
                              <img :src="firstFeaturedNew.thumbnail.uri" class="img-first">
                              <div class="col-lg-6 info-post-first">
                                  <p class="mt-3" style="color: #A7A9B1">{{ d(firstFeaturedNew.created_at) }}</p>
                                  <b class="title-first">{{ firstFeaturedNew.title }}</b>
                                  <p class="mt-2 mb-7">
                                      {{ firstFeaturedNew.summary }}
                                  </p>
                                  <div style="margin-top: 9.5rem" class="mb-1">
                                    <a href="javascript:;">{{ $t('viewContinue')}}</a>
                                  </div>
                              </div>
                          </div>
                      </router-link>
                      <div class="row mt-4">
                          <div class="col-lg-4" v-for="(featured, index) in featuredNews" :key="index">
                              <router-link :to="{name: 'PostDetail', params: {id: featured.slug}}">
                                  <img :src="featured.thumbnail.uri" class="thumb-new">
                                  <p class="mt-3" style="color: #A7A9B1">{{ d(featured.created_at) }}</p>
                                  <b class="title-new">{{ featured.title }}</b>
                              </router-link>
                          </div>
                      </div>
                  </div>
                  <div class="mt-4">
                      <div class="category-post-text mb-1">
	                      {{ $t('newestPost')}}
                      </div>
                      <div class="category-post-line">
                      </div>
                  </div>
                  <div>
                      <div v-for="(post, idx) in news" :key="idx">
                          <router-link :to="{name: 'PostDetail', params: {id: post.slug}}">
                              <div class="row mt-3">
                                  <img :src="post.thumbnail.uri" class="thumb-post col-lg-4">
                                  <div class="col-lg-8 info-post-first">
                                      <p class="mt-3" style="color: #A7A9B1">{{ d(post.created_at) }}</p>
                                      <b class="title-new">{{ post.title }}</b>
                                      <div>
                                          <p class="mt-2">
                                              {{ post.summary }}
                                          </p>
                                          <div class="mt-5 mb-1">
                                              <a href="javascript:;">{{ $t('viewContinue')}}</a>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </router-link>
                      </div>
                      <div class="view-btn text-center mt-4" v-if="hasMorePage">
                          <button @click="changePage()">{{ $t('viewMore')}}</button>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-md-3 col-12">
                  <div class="news-discount">
                      <div class="mt-2">
                          <div class="post-discount-text mb-1">
	                          {{ $t('salePost')}}
                          </div>
                          <div class="category-post-line">
                          </div>
                      </div>
                      <div>
                          <div class="mt-3" v-for="(pro_new, i) in promotionalNews" :key="i">
                              <router-link :to="{name: 'PostDetail', params: {id: pro_new.slug}}">
                                  <img :src="pro_new.thumbnail.uri" class="thumb-discount">
                                  <p class="mt-3" style="color: #A7A9B1">{{ d(pro_new.created_at) }}</p>
                                  <b class="title-new">{{ pro_new.title }}</b>
                                  <div class="post-line mt-2">
                                  </div>
                              </router-link>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import PostRequest from "@/api/requests/PostRequest";
import moment from "moment";
export default {
    name: "PostList",
    data() {
        return {
            page: 1,
            featuredNews: {},
            firstFeaturedNew: {},
            news: [],
            hasMorePage: false,
            promotionalNews: {}
        }
    },
    mounted() {
        this.getData();
        this.getDataFeatured();
    },
    methods: {
        async getData() {
            const res = await  PostRequest.getList(this.page);
            if (res.err_code === 0) {
                this.news = this.news.concat(res.news);
                this.hasMorePage = res.has_more_page;
            }
          document.title = 'Tin tức';
        },
        async getDataFeatured() {
            const res = await PostRequest.getListAdditional();
            if (res.err_code === 0) {
                if (res.featured_news.length) {
                    this.firstFeaturedNew = res.featured_news[0];
                    this.featuredNews = res.featured_news;
                    this.featuredNews.splice(0, 1);
                }
                this.promotionalNews = res.promotion_news;
            }
        },
        changePage() {
            this.page = this.page + 1;
            this.getData();
        },
        d(tzTime) {
            if (!tzTime) {
              return '';
            }
            const m =  moment(tzTime);

            return m.format('DD/MM/YYYY HH:mm')
        },
    }
}
</script>

<style scoped lang="scss">
@import "../assets/css/postList";
</style>