<template>
    <div class="container" id="order-detail">
        <div class="delivery-progress">
            <div class="title-bar">
                <div class="title-bar-back">
                    <a href="javascript:;" @click="backToOrderList('OrderList', null)">
                        <img src="/assets/img/arrow-left.png" alt="quay lai"/> {{ $t('back1')}}
                    </a>
                </div>
                <div class="title-bar-content">
                    <span class="order-code">
                        {{ $t('orderId')}}: # <span style="color: #5D5FEF">{{ order.code }} </span>
                    </span>
                    <span class="order-status">
                        {{ order.statusText }}
                    </span>

                </div>

            </div>
            <div class="delivery-progress-items">
                <ul class="progress-step">
                    <li style="margin-left: auto">
                        <div class="progress-icon-active">
                            <img src="/assets/img/order/clipboard-tick.png" alt="da-dat-hang">
                            <span class="status-line-active"></span>
                        </div>
                        <div class="progress-content">
                             <div class="title">
	                             {{ $t('bookedOrder')}}
                             </div>
                            <div class="time" v-text="td(order.created_at)">
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="progress-icon-active">
                            <img src="/assets/img/order/dollar-square.png" alt="da-thanh-toan">
                            <span class="status-line-active"></span>
                        </div>
                        <div class="progress-content">
                            <div class="title">
	                            {{ $t('paidOrder')}}
                            </div>
                            <div class="time">
                                15:00 16-01-2021
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="progress-icon-active">
                            <img src="/assets/img/order/group.png" alt="da-dat-hang">
                            <span class="status-line"></span>
                        </div>
                        <div class="progress-content">
                            <div class="title">
	                            {{ $t('shippedOrder')}}
                            </div>
                            <div class="time">
                                15:00 16-01-2021
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="progress-icon">
                            <img src="/assets/img/order/convert-3d-cube-1.png" alt="da-dat-hang">
                            <span class="status-line"></span>
                        </div>
                        <div class="progress-content">
                            <div class="title">
	                            {{ $t('receivedOrder')}}
                            </div>
                            <div class="time">
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="progress-icon">
                            <img src="/assets/img/order/bag-tick-2-1.png" alt="da-dat-hang">
                        </div>
                        <div class="progress-content">
                            <div class="title">
	                            {{ $t('sentOrder')}}
                            </div>
                            <div class="time">
                            </div>
                        </div>
                    </li>

                </ul>
            </div>
        </div>

        <div class="container-detail-order">
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="detail-info-box">
                        <div class="detail-title">
                            <div class="left-title">
	                            {{ $t('receiverInfo')}}
                            </div>
                            <div class="right-title">
	                            {{ $t('changAddress')}}
                            </div>
                        </div>
                        <div class="detail-content">
                            <p>{{ $t('fullName')}}: {{ order.receiver_name }}</p>
                            <p>{{ $t('address')}}: {{ order.receiver_address }}</p>
                            <p>{{ $t('phone')}}: {{ order.receiver_phone }}</p>
                        </div>
                    </div>
                    <div class="detail-info-box">
                        <div class="detail-title">
                            <div class="left-title">
	                            {{ $t('shipMethod')}}
                            </div>
                        </div>
                        <div class="detail-content">
                            <p>{{ $t('shopDepartment')}}</p>
                            <p>{{ $t('timeToReceive')}}</p>
                            <p>{{ $t('freeShip')}}</p>
                        </div>
                    </div>
                    <div class="detail-info-box">
                        <div class="detail-title">
                            <div class="left-title">
	                            {{ $t('paymentMethod')}}
                            </div>
                        </div>
                        <div class="detail-content">
                            <p>{{ $t('shipCOD')}}</p>
                            <p v-if="order.payment_status === 1" class="success-payment"><img src="/assets/img/order/tick-circle.png" alt="da-thanh-toan"> Thanh toán thành công</p>
                            <p v-if="order.payment_status === 0" class="not-payment"><img src="/assets/img/order/info-circle.png" alt="chua-thanh-toan"> Chưa thanh toán</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="detail-info-box">
                        <div class="detail-title">
                            <div class="left-title">
	                            {{ $t('followOrder')}}
                            </div>
                            <div class="right-title" style="color: #626574">
                                Shopee Express <span class="order-code"> {{ order.code }}</span>
                            </div>
                        </div>
                        <div class="detail-content">
                            <ul class="progress-bar__container">
                                <li class="progress-bar__item current complete">
                                    <a href="#" class="link">
                                        <div class="status-name">{{ $t('shipping')}}</div>
                                        <div class="status-time">10:07 12-03-2021</div>
                                    </a>
                                </li>
                                <li class="progress-bar__item complete">
                                    <a href="#" class="link">
                                        <div class="status-name">{{ $t('orderIsGoneTo')}}</div>
                                        <div class="status-time">08:33 14-06-2021</div>
                                    </a>
                                </li>
                                <li class="progress-bar__item complete">
                                    <a href="#" class="link">
                                        <div class="status-name">{{ $t('orderIsExportIn')}}</div>
                                        <div class="status-time">23:16 14-06-2021</div>
                                    </a>
                                </li>
                                <li class="progress-bar__item complete">
                                    <a href="#" class="link">
                                        <div class="status-name">{{ $t('orderIsGoneTo')}}</div>
                                        <div class="status-time">08:33 14-06-2021</div>
                                    </a>
                                </li>
                                <li class="progress-bar__item complete">
                                    <a href="#" class="link">
                                        <div class="status-name">{{ $t('receiveSuccess')}}</div>
                                        <div class="status-time">16:21 13-06-2021</div>
                                    </a>
                                </li>
                                <li class="progress-bar__item complete">
                                    <a href="#" class="link">
                                        <div class="status-name">{{ $t('peopleSentInPostOffice')}}</div>
                                        <div class="status-time">16:16 13-06-2021</div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wrap-content">

                <div class="row-header">
                    <p class="header-label">
                        {{ $t('orderInfo')}}
                    </p>
                </div>
                <div class="row-item" v-for="(product, index) in order.OrderItem" :key="'product-' + index">
                    <div class="col-product">
                        <a :href="'/san-pham/' + product.product.slug" target="_blank">
                            <img class="img-cart" :src="product.thumb.uri" alt=""/>
                        </a>
                        <div >
                            <p class="product-name">
                                <a :href="'/san-pham/' + product.product.slug" target="_blank">
                                    {{ lang == 'cn' && product.product.name_cn ? product.product.name_cn : lang == 'en' && product.product.name_en ? product.product.name_en : product.product.name }}
                                </a>
                            </p>
                            <template v-if="product.packs && product.packs.length > 0">
                                <p class="product-pack" v-for="pack in product.packs" :key="'pack' + pack.id">
                                    {{ pack.type ? pack.type + ' - ' : ''}} {{ pack.name ? pack.name : '' }}

                                </p>
                            </template>
                            <p class="product-pack">
                                {{ product.quantity ? 'Số lượng: ' + n(product.quantity) : ''}}
                            </p>
                            <p class="product-pack">
                                {{ product.price ? 'Giá tiền: ' + n(product.price) + 'đ' : ''}}
                            </p>
                        </div>

                    </div>
                    <div class="col-price">
                        <p class="text-price-discount">{{ product.price_discount > 0 ? numberFormat(product.price_origin) + ' đ' : '' }}</p>
                    </div>

                    <div class="col-total">
                        <p class="text-price danger">{{product.price_origin > product.price ? numberFormat(product.quantity * product.price_origin)
                                : numberFormat(product.quantity * product.price)}} đ</p>
                    </div>
                </div>
                <div class="row-footer">
<!--                    <div class="footer-btn">-->
<!--                        <button class="buy-btn">Mua lần nữa</button>-->
<!--                        <button class="contact-shop">Liên hệ người bán</button>-->
<!--                    </div>-->
                    <div class="shop-amount">
                        <div class="amount-content">
                            <div>{{$t('preCalculate')}}</div>
                            <div>{{ numberFormat(order.amount) }}đ</div>
                        </div>
                        <div class="amount-content">
                            <div>{{$t('discount')}}</div>
                            <div>{{ numberFormat(order.amount_discount) }}đ</div>
                        </div>
                        <div class="amount-content">
                            <div>{{$t('shipFee')}}</div>
                            <div>{{ numberFormat(order.ship_fee) }}đ</div>
                        </div>
                        <div class="amount-content" style="margin-bottom: 0">
                            <div>{{$t('sum')}}</div>
                            <div class="total-amount">{{ numberFormat(order.amount_total) }}đ</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {numberFormat} from "@/lib/utils";
import OrderRequest from "@/api/requests/OrderRequest";
import {td} from '@/lib/utils';
import store from "@/store";

export default {
    name: "OrderDetail",
    props: ['code'],
    data() {
        return {
            order: {
                OrderItem: []
            },
            statusMap: [],
	        lang: store.state.locale
        }
    },
    // created() {
    //     this.code = this.$route.params.code;
    // },
    mounted() {
        document.title = this.$t('orderDetail');
        this.getData(this.code);
    },
    methods: {
        numberFormat,td,
        async getData(code) {
            this.order = await OrderRequest.show(code);

            if (this.order && this.order.order_status) {
                this.order.order_status.forEach(item => {
                    this.statusMap[item.value] = item;
                })
            }
        },
        async cancelOrder() {
            if (!window.confirm('Bạn có chắc chắn muốn hủy đơn hàng này?')) {
                return;
            }

            const res = await OrderRequest.cancel(this.order.code);
            if (res.code === 0) {
                alert(res.message);
                location.href = "/don-hang";
            } else {
                alert(res.message);
            }

        },
        async backToOrderList(component, code) {
            this.$parent.changeTab(component, code);
        },
        checkStatus(status) {
            return this.statusMap[status];
        }
    }
}
</script>

<style scoped lang="scss">
@import "../assets/css/orders";
.title-bar {
  display: flex;
  background: #e02e24;;
  height: 50px;
  padding: 10px;

  .title-bar-back {
    margin-right: 10px;
  }

  .title-bar-content {
    font-size: 18px;
    color: #ffffff;
    font-weight: bold;
    margin: auto;
  }
}

@media screen and (max-width: 768px){
  .delivery-progress {
    .title-bar {
      display: block;
      height: auto;
    }
  }
    .delivery-progress-items {
        display: none;
    }

  #order-detail {
    .col-price {
      display: none;
    }

    .container-detail-order {
      .row-footer {
        .shop-amount {
          width: 60%;
        }
      }
    }
  }
}
</style>