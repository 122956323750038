<template>
    <div class="container">
        <div class="title-bar">

            <div class="title-bar-back">
                <router-link to="/gio-hang">
                    <img src="../assets/svg/back.svg" alt="quay lai"/>
                </router-link>
            </div>
            <div class="title-bar-content">
	            {{ $t('receiveInfo')}}
            </div>

        </div>
        <div>
            <loading v-if="isLoading" :is-loading="true"></loading>
        </div>


        <div class="addresses">
            <div class="address-list">
                <div :key="address.id" class="address-item" v-for="address in addresses">
                    <div class="row" style="padding: 15px;">
                        <div class="col-10">
                            <div class="row">
                                <div class="col-2" style="padding: 10px 40px">
                                    <input type="radio" name="radio" style="width: 25px; height: 25px"
                                           @change="toggleDefaultAddr(address)"
                                           :checked="address.is_default"/>
                                    <span class="checkmark"/>
                                </div>
                                <div class="col-4">
                                    <div>
                                        <strong> {{ address.name }}</strong>
                                    </div>
                                    <div>
                                         {{ address.phone }}
                                    </div>
                                </div>
                                <div class="col-6">
                                    <em>
                                        {{ address.decoded }}
                                    </em>
                                </div>
                            </div>

                        </div>
                        <div class="col-2">
                            <router-link :to="{ name: 'AddressForm' , params: { id: address.id} }">
                                <span class="address-edit-btn">
                                    <img src="../assets/svg/edit.svg" alt="edit"/>
                                </span>
                            </router-link>

                        </div>
                    </div>


                </div>
            </div>
            <router-link :to="{ name: 'AddressForm', params: { id: null} }">
                <div class="addresses-add">
                    <button><i class="fas fa-plus"/> {{ $t('addReceiveAddress')}}</button>
                </div>
            </router-link>

            <div class="cart-total" style="padding: 15px">
                <div class="cart-amount">
                    <div class="cart-amount-label">
	                    {{ $t('sumAmount')}}
                    </div>
                    <div style="display: flex">
                        <span class="cart-amount-discount" v-if="amountOrigin > amountTotal">
                            {{ numberFormat(amountOrigin) }} đ
                        </span>
                        <span class="cart-amount-total">
                            {{ numberFormat(amountTotal) }} đ
                        </span>
                    </div>

                </div>
                <router-link :to="{name: 'Checkout'}">
                    <div class="btn-buy-block">
                        <button class="btn-buy-now">{{ $t('continue')}}</button>
                    </div>
                </router-link>


            </div>

        </div>
    </div>

</template>

<script>
import AddressRequest from "@/api/requests/AddressRequest";
import Loading from "@/includes/Loading";
import CartRequest from "@/api/requests/CartRequest";
import {numberFormat} from "@/lib/utils";
import storage from "@/lib/storage";

export default {
    name: "AddressSelect",
    components: {
        Loading
    },
    data() {
        return {
            addresses: [],
            cart: [],
            amountOrigin: 0,
            amountTotal: 0,
            isLoading: false
        }
    },
    mounted() {
        document.title = this.$t('receiveInfo');

        this.getData();
        this.getCart();
    },
    methods: {
        numberFormat,
        async getData() {
            this.isLoading = true;
            const data = await AddressRequest.get();

            if (!data) {
                alert(this.$t('createAddressToContinue'));
                this.isLoading = false;
                return;
            }
            this.isLoading = false;
            this.addresses = data;
        },
        async getCart() {
            const res = await CartRequest.get();
            this.cart = res.cart;
            this.amountOrigin = res.cart.amount_origin;
            this.amountTotal = res.cart.amount;

        },
        async toggleDefaultAddr(addr) {
            this.addresses.forEach((a) => {
                a.is_default = (a.id === addr.id);
            });

            const res = await AddressRequest.setDefaultAddress(addr.id.toString())

        }
    }

}
</script>

<style scoped lang="scss">

@import "../assets/css/address";
@import "../assets/css/cart";

.btn-buy-now {
  margin-top: 10px;
  font-weight: bolder;
  padding:10px;
  width: 100%;
  color:white;
  border: 1px solid $base-color;
  border-radius: 10px;
  background-color: $base-color;
  i {
    font-size: 16px;
  }
  &:hover {
    outline: 0;
  }
}
.cart-total {
  position: relative;
  max-width: unset;
  bottom: -5px;
  padding: 10px 30px 10px 50px;
}
.title-bar {
  display: flex;
  background: #e02e24;;
  height: 50px;
  padding: 10px;

  .title-bar-back {
    margin-right: 10px;
  }

  .title-bar-content {
    font-size: 18px;
    color: #ffffff;
    font-weight: bold;
    margin: auto;
  }
}


</style>