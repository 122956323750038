<template>
    <div id="header-mobile">
        <div class="header-mobile-top d-flex">
            <div class="header-mobile__left">
                <div id="header-mobile__left__nav" class="overlay">
                    <div class="header-mobile__left__nav__top">
                        <router-link @click="closeNav" :to="{name: 'UserInfo'}" class="header-link text-center">
                            <img class="logo-header" src="/assets/img/header/user-icon.svg" alt="">
                            <p class="menu-text" v-text="(authData && authData.user) ? authData.user.name : $t('account')"></p>
                        </router-link>
                        <a href="javascript:void(0)" class="closebtn" @click="closeNav()">&times;</a>
                    </div>
                    <nav id="sidebar">
                        <ul class="list-unstyled components">
                            <li>
                                <div class="list-unstyled__menu-item">
                                    <a  v-if="!useRouterLink"  class="header-logo" href="/">
                                        {{ $t('home')}}
                                    </a>
                                    <router-link @click="closeNav" v-else class="header-logo" to="/">{{ $t('home')}}</router-link>
                                </div>
                            </li>
                            <li v-for="(menu,index) in getJsonData('topCategories', [])"
                                :key="index + 1">
                                <div class="list-unstyled__menu-item">
                                    <img class="category-icon" v-if="menu.icon && menu.icon.uri" :src="menu.icon.uri" :alt="menu.name">
                                    <img class="category-icon"  v-else src="/assets/img/header/ic_category.svg">
                                    <a :href="'#submenu' + menu.id" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">{{menu.name}}</a>
                                    <!--                                <router-link :to="'/danh-muc/' + menu.slug">{{menu.name}}</router-link>-->
                                    <i class="fas fa-chevron-right"></i>
                                </div>

                                <ul v-if="menu.data_children" class="collapse list-unstyled" :id="'submenu' + menu.id">
                                    <li v-for="(item, itemIndex) in menu.data_children" :key="itemIndex">
                                        <router-link @click="closeNav" :to="'/danh-muc/' + item.slug">{{item.name}}</router-link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <div class="list-unstyled__menu-item">
                                    <router-link class="header-link" :to="{name: 'UserInfo', query: {tabMain: 'tin-nhan'}}" @click="changeTab()">
                                        <img class="logo-header" src="/assets/img/header/ic_message.svg" alt="">
	                                    {{ $t('message')}}
                                    </router-link>
                                </div>
                            </li>
                            <li>
                                <div class="list-unstyled__menu-item">
                                    <router-link @click="closeNav" to="/tin-tuc">{{ $t('news') }}</router-link>
                                </div>
                            </li>
                            <li>
                                <div class="list-unstyled__menu-item">
                                    <a class="header-link" @click="onBecomeShop" v-if="authData">
                                        <div class="to-shop">
                                            <img class="logo-header" src="/assets/img/header/ic_store.svg" alt=""> {{ $t('saleChannel') }}
                                        </div>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </nav>

                </div>
                <span class="header-mobile__left__icon" @click="openNav()">&#9776;</span>
            </div>
            <div class="header-mobile__center">
                <a  v-if="!useRouterLink"  class="header-logo" href="/">
                    <img style="width: 100px" src="/assets/img/header/logo.png" alt="Elim.vn">
                </a>
                <router-link v-else class="header-logo" to="/">
                    <img style="width: 100px" src="/assets/img/header/logo.png" alt="Elim.vn">
                </router-link>
            </div>
            <div class="header-mobile__right">
	            <div class="dropdown">
			          <span data-toggle="dropdown" class="btn btn-language" aria-expanded="true" style="cursor: pointer">
			          <img style="width: 50px; height: 30px" :src="currentLanguage == 'en' ? '/assets/img/lang/en.png' : (currentLanguage == 'cn' ? '/assets/img/lang/cn.png' : '/assets/img/lang/vi.png')" alt="">
			          </span>
		            <div class="dropdown-menu dropdown-menu-center" x-placement="bottom-end"
		                 style="position: absolute; transform: translate3d(-5px, 26px, 0px); top: 0px; left: 0px; will-change: transform;">
			            <ul>
				            <li class="dropLanguage"><a href="javascript:;" @click="changeLanguage('vi')">
					            <img src="assets/img/lang/vi.png" style="width: 50px; height: 30px" alt=""/>
				            </a></li>
				            <li class="dropLanguage"><a href="javascript:;" @click="changeLanguage('cn')">
					            <img src="assets/img/lang/cn.png" style="width: 50px; height: 30px" alt=""/>
				            </a></li>
				            <li class="dropLanguage"><a href="javascript:;" @click="changeLanguage('en')">
					            <img src="assets/img/lang/en.png" style="width: 50px; height: 30px" alt=""/>
				            </a></li>

			            </ul>
		            </div>
	            </div>
                <router-link class="header-link" to="/gio-hang">
                    <div class="badge-wrap">
                        <div class="item-badge" v-if="cartCount">
                            <span v-text="cartCount"></span>
                        </div>
                        <img class="logo-header" src="/assets/img/header/ic_cart.svg" alt="">
                    </div>
                </router-link>
            </div>
        </div>

        <div class="search-wrap">
            <div class="search-wrap-input">
                <i class="fas fa-search search-icon"></i>
                <input @keydown.enter="search(keyword)"
                       v-model="keyword" class="search-input"
                       type="text"
                       :placeholder="$t('searchProduct')">
                <button class="search-btn" type="button" @click="search(keyword)">
                    <span class="search-text">{{ $t('search')}}</span>
                    <i class="fas fa-search search-icon search-icon-show"></i>
                </button>
            </div>
            <div class="search_wrap-suggest">

            </div>
        </div>
    </div>
</template>

<script>
import store from "@/store";
import {getOption, getJsonData} from "@/lib/utils";
import i18n from "@/main";

export default {
    name: "HeaderMobile",
    props: ['isCart'],
    data() {
        let menus = [];

        try {
            menus = getOption('menu_left', [])[0].items
        } catch (e) {
            console.warn('Can not get menu')
        }

        return {
            useRouterLink: true,
            menus,
            keyword: '',
            products: [],
            rKeyword: '',
            currentMenu: null,
            showCategory: false,
	        currentLanguage: localStorage.getItem('elimLang') || 'vi'
        }
    },
    computed: {
        authData: () => store.state.Auth,
        cartCount: () => store.state.CountCartItem,
    },
    mounted() {
        setTimeout(() => {
            this.useRouterLink = false;
        }, 60000*15)
    },
    methods: {
        getJsonData,
        checkUrl(menu, $event) {
            if (!menu.url) {
                alert(this.$t('plsSetUrl'));
                $event.preventDefault();
            }
        },
        search(keyword) {
            this.$router.push({ name: 'Search', params: {keyword: keyword}, query: { keyword }});
        },
        onBecomeShop() {
            if (this.authData) {
                if (confirm(this.$t('changeToSaleChannel'))) {
                    console.log(process.env.VUE_APP_SHOP_URL)
                    const shopUrl = process.env.VUE_APP_SHOP_URL || 'http://shop.elim.vn';
                    window.open(shopUrl + '?token=' + this.authData.token, '_blank');
                }
            }
        },
        mouseover(menu){
            this.currentMenu = menu;
        },
        mouseleave(){
        },
        changeTab() {
            store.commit('SetShowChatBar', false);
            document.getElementById("header-mobile__left__nav").style.width = "0%";
        },
        openNav() {
            document.getElementById("header-mobile__left__nav").style.width = "80%";
        },
        closeNav() {
            document.getElementById("header-mobile__left__nav").style.width = "0%";
        },
        showMenu(id) {
            let menu = "sub-menu" + id;
            document.getElementById(menu).style.display = "block";
        },
	    changeLanguage(locale) {
		    this.currentLanguage = locale;
		    store.commit('SetLocale', locale);
		    localStorage.setItem("elimLang", locale);
		    location.reload();
	    }
    },
}
</script>
<style scoped lang="scss">
@import "../assets/css/header";
.dropLanguage {
	min-width: 34%;
	padding: 3px;
}

.dropdown-menu {
	background: #f5f5f5;
	min-width: 80px;
	padding: 4px 0;
	border: none;
	text-align: center;
	position: absolute;
	will-change: transform;
	top: 20px;
	left: 0;
	-webkit-transform: translate3d(-5px,26px,0);
	transform: translate3d(-5px,26px,0);
}
</style>
