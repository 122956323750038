<template>
    <div class="layout-detai-profession">
        <div class="row p-3">
            <div class="col-2">
                <ul class="category">
                    <li>Ngành nghề</li>
                    <template v-if="isLoading">
                        <div class="d-flex align-items-center justify-content-center custom-loading" style="width: 100%">
                            <i class="fa fa-spinner fa-spin fa-3x elim-loading"></i>
                        </div>
                    </template>
                    <li v-else>
                        <a href="#" style="color: rgb(179, 26, 75);font-weight: bold;">{{ profession.name }}</a>
                    </li>
                </ul>
                <ul class="category" v-if="profession.child && profession.child.length">
                    <li>{{ $t('groupProduct') }}</li>
                    <li v-for="product in profession.child" :key="product.id">
                        <a :href="'/nganh-nghe/' + product.id" style="color: rgb(126 86 99);">{{ product.name }}</a>
                    </li>
                </ul>
                <!-- <ul class="category location">
                    <span>Tỉnh/ Thành Phố</span>
                    <li v-for="location in provinces" :key="location.id">
                        <input type="checkbox" :value="location.id" v-model="location.id" :id="location.id">
                        <label :for="location.id">{{ location.name }}</label>
                    </li>
                </ul> -->
            </div>

            <div class="col-10 detail">
                <template v-if="isLoading">
                    <div class="d-flex align-items-center justify-content-center p-5 custom-loading" style="width: 100%">
                        <i class="fa fa-spinner fa-spin fa-3x elim-loading"></i>
                    </div>
                </template>
                <div class="title p-2 ucfirst" style="color: rgb(179 26 75);font-size: 15px;font-weight: 600;"
                    v-if="!isLoading">
                    {{ profession.name }}
                    <span class="total">(Có {{ profession.company_count }} công ty được tìm thấy)</span>
                </div>
                <div class="no-data ml-2" v-if="!companies.length && !isLoading">
                    {{ $t('emptyCompany') }}
                </div>

                <div class="card mb-3" v-for="(company, index) in companies" :key="company.id">
                    <div class="card-body d-flex" v-if="!isLoading">
                        <div class="p-2 category-forcus col-10">
                            <div class="d-flex">
                                <div class="index">
                                    <span>{{ index + 1 }}</span>
                                </div>
                                <div class="title">
                                    <a :href="'/cong-ty/' + company.id" class="ucfirst">
                                        {{ company.name }}
                                    </a>
                                </div>
                            </div>
                            <div class="rating">
                                <star-rating :rating="company.star" v-bind:star-size="20" :show-rating="false"
                                    :read-only="true"></star-rating>
                            </div>
                            <div class="company d-flex">
                                <div class="logo" v-if="company.logo">
                                    <img v-lazy="company.logo" alt="">
                                </div>
                                <div class="information">
                                    <div class="profession">
                                        Ngành:
                                        <span style="background: #FFFF99;color: #0066cc" class="ucfirst">{{ profession.name
                                        }}</span>
                                    </div>
                                    <div class="address">{{ company.location }}</div>
                                    <div class="phone">{{ company.phone }}</div>
                                </div>
                            </div>
                            <div class="describe p-3">
                                <span v-html="company.description"></span>
                            </div>
                            <div class="image-company d-flex p-3">
                                <img v-for="(image, index) in company.image" :key="image.id"
                                    :style="index > 6 ? 'display: none' : ''" v-lazy="image.src" alt="" class="border ml-2">
                                <div class="view-image">
                                    <a :href="'/cong-ty/' + company.id">+ chi tiết</a>
                                </div>
                            </div>
                            <div class="listings_phanduoi">

                                <div class="box_email">
                                    <div class="email_img">
                                        <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                    </div>
                                    <div class="email_text">
                                        <a :title="company.email" :href="'mailto:' + company.email">
                                            Gửi Email
                                        </a>
                                    </div>
                                </div>
                                <div class="website d-flex">
                                    <div class="box_website" v-for="web in company.website" :key="web.label">
                                        <div class="website_img">
                                            <i class="fa fa-globe" aria-hidden="true"></i>
                                        </div>
                                        <div class="website_text">
                                            <a rel="nofollow" target="_blank" :href="web.link">
                                                {{ web.label }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="verify mt-5 mr-2" v-if="company.is_verify == 1">
                            <img src="/assets/logo-xacthuc.png" alt="">
                        </div>
                    </div>

                </div>
                <div class="paginate p-4" v-if="!isLoading">
                    <Paginate :value="paginate" :pagechange="onPageChange"></Paginate>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Paginate from "@/components/Paginate";
import CompanyCategoriesRequest from '@/api/requests/CompanyCategoriesRequest'
import StarRating from 'vue-star-rating'
export default {
    props: ['id'],
    name: "CompanyCategories",
    components: {
        StarRating,
        Paginate,
    },
    data() {
        return {
            profession: [],
            page: this.$route.query.page || 1,
            paginate: {
                currentPage: 1,
                lastPage: 1
            },
            companies: [],
            provinces: [],
            isLoading: true,
        }
    },
    created() {
        this.getData()
    },
    methods: {
        async getData() {
            const res = await CompanyCategoriesRequest.getCompanyCategories(this.id, this.page);
            if (res.code === 200) {
                this.isLoading = false;
                this.profession = res.profession
                this.companies = res.company
                this.paginate = res.paginate
                document.title = this.profession.name
            }
        },
        onPageChange(page) {
            this.page = page;
            this.isLoading = true;
            this.$router.push({ query: { page: page } });
            this.getData();
            setTimeout(() => {
                window.jQuery("html, body").animate({ scrollTop: 0 }, 200);
            }, 500);
        },
    }
}
</script>

<style lang="scss">
.layout-detai-profession {
    .custom-loading {
        margin-top: 300px;
        margin-bottom: 300px;
    }

    .card {
        border: none !important;
    }

    .category {
        border-top: 2px solid red;
        height: auto;
        padding: 11px 1px 9px 1px;

        li a:hover {
            text-decoration: underline;
        }

        span {
            color: #333333;
            font-size: 13px;
            font-weight: 700;
            border-top-width: 3px;
        }

        li {
            // border-bottom: 1px solid #ccc;
            padding: 5px;
        }
    }

    .location {
        & input {
            margin-right: 5px;
            vertical-align: middle;
        }

        & label {
            cursor: pointer;
        }

    }

    .detail {
        border-radius: 15px;
        background: #ededed;
        height: auto;

        .title {
            margin: 0;
            font-size: 13px;
            color: #CC3366;
            text-transform: capitalize;

            .total {
                color: #666;
                font-size: 11px;
            }
        }

        .category-quick {
            padding: 0px 2px 4px 24px;

            a:hover {
                text-decoration: underline;
            }
        }

        .category-forcus {
            height: auto;

            .company {
                padding: 15px;

                .logo {
                    width: 100px;
                    height: 100px;
                    line-height: 82px;
                    border: 1px solid #ccc;
                    padding: 5px;
                }

                .information {
                    margin-left: 10px;

                    .address {
                        padding-bottom: 8px;
                        line-height: 18px;
                    }

                    .phone {
                        font-weight: bold;
                        font-size: 14px;
                        color: #383838;
                        padding-bottom: 8px;
                    }
                }
            }

            .title {
                color: #333333;
                font-weight: 700;
                margin-left: 20px;
                font-size: 15px;
            }

            .index {
                background: #DB0000;
                width: 27px;
                height: 24px;
                display: flex;
                justify-content: center;

                span {
                    // margin-left: 9px;
                    color: #ffffff;
                }
            }

            .image-company {

                img {
                    border-radius: 5px;
                    background: #ffffff;
                    width: 90px;
                    height: 85px;
                    padding: 5px;
                    transition: transform .2s;

                    &:hover {
                        -ms-transform: scale(1.5);
                        /* IE 9 */
                        -webkit-transform: scale(1.5);
                        /* Safari 3-8 */
                        transform: scale(2.5);
                    }
                }

                .view-image {
                    margin-top: 45px;
                    margin-left: 15px;
                    border: 1px solid #C4C4C4;
                    border-radius: 4px;
                    padding: 1px 3px;
                    font-size: 11px !important;
                    color: #111 !important;
                    font-weight: 100;
                    height: 20px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .describe {
                & span {
                    & p {
                        white-space: nowrap;
                        height: 111px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: rgb(137 38 22);
                    }
                }
            }

            .listings_phanduoi {
                height: 58px;
                border-top-color: #cecece;
                border-top-style: solid;
                border-top-width: 1px;

                .box_email {
                    height: 29px;
                    margin-top: 15px;
                    width: 118px;
                    float: left;
                    margin-left: 38px;

                    .email_img {
                        float: left;
                        height: 26px;
                        width: 26px;
                        border-radius: 50%;
                        border-color: #009999;
                        border-style: solid;
                        border-width: 1px;
                        text-align: center;

                        i {
                            color: #009999;
                        }
                    }
                }

                .email_text {
                    float: left;
                    height: 29px;
                    margin-left: 6px;
                    padding-top: 3px;
                    width: 68px;

                }

                .box_website {
                    height: 29px;
                    margin-top: 15px;
                    width: 338px;
                    float: left;
                    margin-left: 23px;

                    .website_img {
                        float: left;
                        height: 26px;
                        width: 26px;
                        border-radius: 50%;
                        border-color: #009999;
                        border-style: solid;
                        border-width: 1px;
                        text-align: center;

                        i {
                            color: #009999;
                        }
                    }

                    .website_text {
                        float: left;
                        height: 29px;
                        margin-left: 6px;
                        width: auto;
                        padding-top: 3px;
                    }
                }
            }
        }

        .card-body {
            padding: 0 !important;
        }
    }

    .verify {
        width: 100px;
    }
}
</style>