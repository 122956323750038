import BaseRequest from "../BaseRequest";
// import {Address, GeneralResponse} from '../interfaces';
import {Product} from "../interfaces";
export interface CalculatePriceResponse {
    err_code: number,
    price: number,
    amount: number,
    amount_origin: number,
}

interface ShowResponse {
    product: Product,
    err_code: number
}

class ProductRequest extends BaseRequest {
    constructor() {
        super('ProductRequest');
    }

    async show(id: number, type: string = 'id'): Promise<ShowResponse> {
        return super.dispatch('show', id, type)
    }

    async calculatePrice(productId: number, quantity: number, pack: any): Promise<CalculatePriceResponse>  {
        return super.dispatch('calculatePrice', productId, quantity, pack)
    }

    async getProducts(): Promise<any> {
        return super.dispatch('getProducts');
    }

    async setFavoriteProduct(productId: number, isFavorite: boolean): Promise<any> {
        return super.dispatch('setFavoriteProduct', productId, isFavorite)
    }
    async setSeenProduct(productId: number): Promise<any> {
        return super.dispatch('setSeenProduct', productId);
    }

    async getSeenProducts(page: number): Promise<any> {
        return super.dispatch('getSeenProducts', page);
    }

    async deleteSeenProduct(productId: number): Promise<any> {
        return super.dispatch('deleteSeenProduct', productId);
    }

    async deleteAllSeenProducts(): Promise<any> {
        return super.dispatch('deleteAllSeenProducts');
    }

    async getFavoriteProducts(page: number): Promise<any> {
        return super.dispatch('getFavoriteProducts', page);
    }
    async checkFavoriteProduct(productId: number): Promise<any> {
        return super.dispatch('checkFavoriteProduct', productId)
    }
    async getProductInternational(page: Number): Promise<any> {
        return super.dispatch('getProductInternational', page)
    }
    async getCampaignProducts(): Promise<any> {
        return super.dispatch('getCampaignProducts')
    }
}

export default new ProductRequest()
