import BaseRequest from "../BaseRequest";
import {GeneralResponse, User} from "../interfaces";


export class PasswordRequest extends BaseRequest {
    constructor() {
        super("PasswordRequest");
    }

    async forgot(email: string): Promise<GeneralResponse> {
        return super.dispatch('forgot', email)
    }

    async revert(email: string, code: string, newPassword: string): Promise<GeneralResponse> {
        return super.dispatch('revert', email, code, newPassword)
    }

    async resetPassword(verifyCode: string, phone: string, newPassword: string): Promise<GeneralResponse> {
        return super.dispatch('resetPassword', verifyCode, phone, newPassword)
    }

    async changePassword(password: string, oldPassword: string): Promise<GeneralResponse> {
        return super.dispatch('changePassword', password, oldPassword)
    }

    async changeWithoutOldPassword(type: string, password: string, code: string): Promise<GeneralResponse> {
        return super.dispatch('changeWithoutOldPassword', type, password, code)
    }

    async applyVerifyCode(type: string, value: string, code: string): Promise<GeneralResponse> {
        return super.dispatch('applyVerifyCode', type, value, code)
    }
}

export default new PasswordRequest()
