import BaseRequest from "../BaseRequest";
import {GeneralResponse, LoginResponse, RegisterPayload, User} from "../interfaces";


export class AuthRequest extends BaseRequest {
    constructor() {
        super("AuthRequest");
    }

    async forgot(email: string): Promise<LoginResponse> {
        return super.dispatch('forgot', email)
    }

    async login(phone: string, password: string): Promise<LoginResponse> {
        return super.dispatch('login', phone, password)
    }

    async loginWithVerifyCode(phone: string, code: string): Promise<LoginResponse> {
        return super.dispatch('loginWithVerifyCode', phone, code)
    }

    async checkPhoneReset(phone: string): Promise<LoginResponse> {
        return super.dispatch('checkPhoneReset', phone)
    }
    async checkPhoneToSendVerify(phone: string): Promise<LoginResponse> {
        return super.dispatch('checkPhoneToSendVerify', phone)
    }

    async verifyPhone(phone: string, code: string): Promise<LoginResponse> {
        return super.dispatch('verifyPhone', phone, code)
    }
    async phoneCheck(phone: string): Promise<LoginResponse> {
        return super.dispatch('phoneCheck', phone)
    }

    async checkEmailToChangeInfo(email: string, password: string): Promise<LoginResponse> {
        return super.dispatch('checkPasswordToChangeEmail', email, password)
    }

    async changeEmail(email: string, newEmail: string): Promise<LoginResponse> {
        return super.dispatch('changeEmail', email, newEmail)
    }
    async addNewPhone(phone: string, code: string): Promise<LoginResponse> {
        return super.dispatch('addNewPhone', phone, code)
    }

    async register(params: RegisterPayload): Promise<LoginResponse> {
        return super.dispatch('register', params)
    }

    async createTempAccount(params: RegisterPayload): Promise<LoginResponse> {
        return super.dispatch('createTempAccount', params)
    }

    async loginFacebook(accessToken: string): Promise<LoginResponse> {
        return super.dispatch('loginFacebook', accessToken)
    }

    async loginGoogle(idToken: string): Promise<LoginResponse> {
        return super.dispatch('loginGoogle', idToken)
    }

    async loginApple(token: string): Promise<LoginResponse> {
        return super.dispatch('loginApple', token);
    }

    async getProfile(): Promise<any> {
        return super.dispatch('getProfile')
    }

    async updateProfile(user: User): Promise<any> {
        return super.dispatch('updateProfile', user)
    }
}

export default new AuthRequest()
