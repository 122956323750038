<template>
    <div id="user-profile--info">
        <div class="row" style="margin: 0 -10px 30px -10px">
            <div class="col-lg-3 col-md-3 col-6 mb-2" style="padding: 0 10px">
                <div class="content-box box-count">
                    <img src="/assets/img/profile/icon_1.png" alt="icon">
                    <div class="count-info">
                        <p>{{ $t('favorite')}}</p>
                        <span v-text="countFavorite"></span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 mb-2" style="padding: 0 10px">
                <div class="content-box box-count">
                    <img src="/assets/img/profile/icon_2.png" alt="icon">
                    <div class="count-info">
                        <p>{{ $t('following')}}</p>
                        <span v-text="countShopFollow"></span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 mb-2" style="padding: 0 10px">
                <div class="content-box box-count">
                    <img src="/assets/img/profile/icon_3.png" alt="icon">
                    <div class="count-info">
                        <p>{{ $t('seen')}}</p>
                        <span v-text="countSeen"></span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 mb-2" style="padding: 0 10px">
                <div class="content-box box-count">
                    <img src="/assets/img/profile/icon_4.png" alt="icon">
                    <div class="count-info">
                        <p>Voucher</p>
                        <span>0</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-box" style="padding: 24px">
            <ul class="nav tab-nav">
                <li v-for="tab in tabs" :key="'tav-nav-' + tab.id" class="nav-item" @click="onChangeTab(tab)">
                    <a class="nav-link" :class="idActive == tab.id ? 'active' : ''" data-toggle="tab"
                       :href="'#' + tab.id" v-text="tab.label"></a>
                </li>
            </ul>
            <div class="tab-content">
                <div v-for="tab in tabs" :key="'tab-content-' + tab.id" :id="tab.id"
                     class="tab-pane" :class="tab.id == idActive ? 'active' : 'fade'">
                    <component v-bind:is="tab.component"></component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ProfileTab from "@/components/profile/ProfileTab";
import ChangePassword from "@/components/profile/ChangePassword";
import AddressTab from "@/components/profile/AddressTab";
import $ from 'jquery';
import ProductRequest from "@/api/requests/ProductRequest";

export default {
    name: "ProfileInfo",
    props: ['countFavorite', 'countSeen', 'countShopFollow'],
    components: {ChangePassword, ProfileTab, AddressTab},
    data() {
        return {
            tabs: [
                {
                    id: 'formChangeInfo',
                    label: 'Thông tin tài khoản',
                    component: 'profile-tab'
                },
                {
                    id: 'formChangePass',
                    label: 'Đổi mật khẩu',
                    component: 'change-password'
                },
                {
                    id: 'formAddress',
                    label: 'Địa chỉ',
                    component: 'AddressTab'
                }
            ],
            idActive: 'formChangeInfo',

        }
    },
    mounted() {
        const query = this.$route.query;
        if (query.tab) {
            this.idActive = query.tab;
        }
    },
    methods: {
        onChangeTab(tab) {
            this.idActive = tab.id;
            const query = {tab: tab.id};
            this.$router.replace({query: query});
        },
    }
}
</script>

<style lang="scss">
@import '../../assets/css/userInfo';
@media screen and (max-width: 768px){
    .tab-nav {
        .nav-item {
            margin-bottom: 10px;
        }
    }
;
}
</style>
