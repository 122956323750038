import { createWebHistory, createRouter } from 'vue-router';
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import UserInfo from "@/views/UserInfo.vue";
import ProductDetail from '@/views/ProductDetail.vue';
import ShopDetail from '@/views/ShopDetail.vue';
import CategoryDetail from '@/views/CategoryDetail.vue';
import Search from '@/views/Search.vue';
import Cart from "@/views/Cart.vue";
import AddressSelect from "@/views/AddressSelect.vue";
import AddressForm from "@/views/AddressForm.vue";
import Checkout from "@/views/Checkout.vue";
import OrderList from "@/views/OrderList.vue";
import OrderDetail from "@/views/OrderDetail.vue";
import PageNotFound from "./views/PageNotFound.vue";
import Category from "@/views/Category.vue";
import CampaignDetail from '@/views/CampaignDetail.vue';
import PostDetail from '@/views/PostDetail.vue';
import PostList from '@/views/PostList.vue';
import store from "@/store";
import Register from "@/views/Register.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import ClassifiedAdvertising from "@/views/ClassifiedAdvertising.vue";
import ClassifiedAdvertisingDetail from "@/views/ClassifiedAdvertisingDetail.vue";
import PostInformation from "@/views/PostInformation.vue";
import DetailCraftVillage from "@/components/craft_village/DetailCraftVillage.vue";
import ListCraftVillage from "@/components/craft_village/ListCraftVillage.vue";
import StoreCraftVillage from "@/components/craft_village/StoreCraftVillage.vue"
import TabelProperties from "@/components/profession/TableProfesstion.vue";
import DetailCategories from "@/components/profession/DetailCategories.vue"
import CompanyCategories from "@/components/profession/CompanyCategories.vue"
import CompanyDetail from "@/components/profession/CompanyDetail.vue"
import ProductProfessionDetail from "@/components/profession/ProductProfessionDetail.vue"


const routes: Array<any> = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/dang-nhap",
        name: "Login",
        component: Login,
        meta: {
            hideForAuth: true
        }
    },
    {
        path: "/quen-mat-khau",
        name: "ResetPassword",
        component: ResetPassword,
        meta: {
            hideForAuth: true
        }
    },
    // {
    //     path: "/dang-ky",
    //     name: "Register",
    //     component: Register,
    //     meta: {
    //         hideForAuth: true
    //     }
    // },
    {
        path: "/trang-ca-nhan",
        name: "UserInfo",
        component: UserInfo,
        props: true,
        meta: {
            requiredAuth: true
        }
    },
    {
        path: "/san-pham/:slug",
        name: "ProductDetail",
        component: ProductDetail,
        props: true
    },
    {
        path: "/tim-kiem",
        name: "Search",
        component: Search,
        props: true
    },
    {
        path: "/cua-hang/:id",
        name: "ShopDetail",
        component: ShopDetail,
        props: true
    },
    {
        path: "/tat-ca-san-pham",
        name: "AllProduct",
        component: Category,
        props: true
    },
    {
        path: "/xem/:slug",
        name: "CampaignDetail",
        component: CampaignDetail,
        props: true
    },
    {
        path: "/danh-muc/:id",
        name: "CategoryDetail",
        component: Category,
        props: true
    },
    {
        path: "/gio-hang",
        name: "Cart",
        component: Cart
    },
    {
        path: "/rao-vat",
        name: "ClassifiedAdvertising",
        component: ClassifiedAdvertising,
        meta: {
            breadCrumb: 'Rao vặt'
        },
        props: true,

    },
    {
        path: "/thong-tin-rao-vat/:id",
        name: "ClassifiedAdvertisingDetail",
        component: ClassifiedAdvertisingDetail,
        props: true,
        meta: {
            breadCrumb: 'Rao vặt'
        },
        children: [
            {
                path: ':slug',
                name: "ClassifiedAdvertisingDetail",
                component: ClassifiedAdvertisingDetail,
                props: true,
                meta: { breadCrumb: 'Chi tiết', isSlug: true },
            }
        ]
    },
    {
        path: "/thong-tin-nguoi-dang/:id",
        name: "PostInformation",
        component: PostInformation,
        props: true,
    },
    {
        path: "/thong-tin-nhan-hang",
        name: "AddressSelect",
        component: AddressSelect,
        meta: {
            requiredAuth: true
        }
    },

    {
        path: "/tai-khoan/sua-dia-chi-nhan/:id",
        name: "AddressForm",
        component: AddressForm,
        meta: {
            requiredAuth: true
        }
    },
    {
        path: "/thanh-toan",
        name: "Checkout",
        component: Checkout,
        meta: {
            requiredAuth: true
        }
    },
    {
        path: "/don-hang",
        name: "OrderList",
        component: OrderList,
        meta: {
            requiredAuth: true
        }
    },
    {
        path: "/chi-tiet-don-hang/:code",
        name: "OrderDetail",
        component: OrderDetail,
        meta: {
            requiredAuth: true
        }
    },
    {
        path: "/tin-tuc/:id",
        name: "PostDetail",
        component: PostDetail,
        props: true
    },
    {
        path: "/thong-tin/:id",
        name: "InfoDetail",
        component: PostDetail,
        props: true
    },
    {
        path: "/tin-tuc",
        name: "PostList",
        component: PostList,
        props: true
    },
    {
        path: "/thong-tin-lang-nghe/:id",
        name: "DetailCraftVillage",
        component: DetailCraftVillage,
        props: true,
    },
    {
        path: "/thong-tin-lang-nghe/cua-hang/:id",
        name: "StoreCraftVillage",
        component: StoreCraftVillage,
        props: true,
    },
    {
        path: "/lang-nghe",
        name: "ListCraftVillage",
        component: ListCraftVillage,
        props: true,
    },
    {
        path: "/nganh-nghe",
        name: "TabelProperties",
        component: TabelProperties,
        props: true,
    },
    {
        path: "/nganh-nghe/:id",
        name: "CompanyCategories",
        component: CompanyCategories,
        props: true,
    },
    {
        path: "/nganh-nghe/san-pham/:id",
        name: "ProductProfessionDetail",
        component: ProductProfessionDetail,
        props: true,
    },
    {
        path: "/muc-luc/nganh-nghe/:id",
        name: "DetailCategories",
        component: DetailCategories,
        props: true,
    },
    {
        path: "/cong-ty/:id",
        name: "CompanyDetail",
        component: CompanyDetail,
        props: true,
    },
    {
        path: "/404",
        name: "PageNotFound",
        component: PageNotFound,
    },
];

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        // return desired position
        const pos: any = { x: 0, y: 0 }
        return pos;
    },
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiredAuth)) {
        if (!store.state.Auth) {
            next({ name: 'Login' });
        }
    }

    if (to.matched.some(record => record.meta.hideForAuth)) {
        if (store.state.Auth) {
            next({ name: 'Home' });
        }
    }

    next();
});

export default router;
