<template>
    <div class="container layout-table-profession">
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link active" style="color: #CC0033;" href="#">MỤC LỤC NGÀNH NGHỀ</a>
            </li>
        </ul>
        <div class="box-title border p-5">
            <div class="input-group mb-3">
                <input type="text" class="form-control" v-model="searchInput" placeholder="Nhập ngành nghề cần tìm">
                <div class="input-group-append" @click="searchInputSubmit">
                    <span class="input-group-text"><i class="fa fa-search" style="cursor: pointer; margin-right: 5px;"><span
                                style="margin-left: 5px; font-weight: 600;">Tìm kiếm..</span></i></span>
                </div>
            </div>
            <div class="box-search-category d-flex">
                <div class="title">
                    Ngành theo ABC
                </div>
                <div class="text" v-for="item in text" :key="item.code" @click="searchText(item.name)">
                    <p class="a"><a href="#" :class="search == item.name ? 'activeText' : ''">{{ item.name }}</a></p>
                </div>
            </div>

            <template v-if="isLoading">
                <div class="d-flex align-items-center justify-content-center" style="width: 100%">
                    <i class="fa fa-spinner fa-spin fa-3x elim-loading"></i>
                </div>
            </template>

            <template v-else>
                <ul style="list-style-type: square;" v-for="categorie in categories" :key="categorie.id">
                    <li class="category" v-if="categorie.id !== categorie.parent_id">
                        <a class="link" :href="'/nganh-nghe/' + categorie.id" style="color: #00C; font-size: 15px">{{
                            categorie.name }}
                            <span style="color: #8e8d8d; font-size: 13px;">
                                ({{ categorie.company_count }})</span></a>
                        <ul class="pl-3">
                            <li class="child" v-for="item in categorie.child" :key="item.id">
                                <a class="link" :href="'/nganh-nghe/' + item.id">{{
                                    item.name
                                }}
                                    <span style="color: #8e8d8d; font-size: 13px;">({{ item.count_child_companies }})</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>

                <div class="paginate p-4">
                    <Paginate :value="paginate" :pagechange="onPageChange"></Paginate>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import Paginate from "@/components/Paginate";
import B2bCategoriesRequest from '@/api/requests/B2bCategoriesRequest'
export default {
    name: 'TabelProperties',
    components: {
        Paginate
    },
    data() {
        return {
            categories: {},
            text: [
                { name: 'A', code: 'U+0041' },
                { name: 'B', code: 'U+0042' },
                { name: 'C', code: 'U+0043' },
                { name: 'D', code: 'U+0044' },
                { name: 'E', code: 'U+0045' },
                { name: 'F', code: 'U+0046' },
                { name: 'G', code: 'U+0047' },
                { name: 'H', code: 'U+0048' },
                { name: 'I', code: 'U+0049' },
                { name: 'J', code: 'U+004A' },
                { name: 'K', code: 'U+004B' },
                { name: 'L', code: 'U+004C' },
                { name: 'M', code: 'U+004D' },
                { name: 'N', code: 'U+004E' },
                { name: 'O', code: 'U+004F' },
                { name: 'P', code: 'U+0050' },
                { name: 'Q', code: 'U+0051' },
                { name: 'R', code: 'U+0052' },
                { name: 'S', code: 'U+0053' },
                { name: 'T', code: 'U+0054' },
                { name: 'U', code: 'U+0055' },
                { name: 'V', code: 'U+0056' },
                { name: 'W', code: 'U+0057' },
                { name: 'X', code: 'U+0058' },
                { name: 'Y', code: 'U+0059' },
                { name: 'Z', code: 'U+005A' },
            ],
            page: this.$route.query.page || 1,
            search: this.$route.query.search || '',
            paginate: {
                currentPage: 1,
                lastPage: 1
            },
            searchInput: this.$route.query.search_input || '',
            isLoading: false,
        }
    },
    created() {
        document.title = 'Mục lục ngàng nghề'
        this.getData()
    },
    watch: {
        // 'searchInput'(value) {
        //     this.searchInput = value
        //     this.page = 1;
        //     this.$router.push({ query: { page: 1, search: this.search, search_input: value } });
        //     this.getData()
        // }
    },
    methods: {
        async getData() {
            this.isLoading = true;
            const res = await B2bCategoriesRequest.getAllCategory(this.page, this.search, this.searchInput);
            if (res.code === 200) {
                this.categories = res.categories
                this.paginate = res.paginate
            }
            this.isLoading = false;
        },
        searchText(text) {
            this.search = this.search !== text ? text : '';
            this.page = 1;
            this.$router.push({ query: { page: 1, search: text, search_input: this.searchInput } });
            this.getData()
        },
        onPageChange(page) {
            this.page = page;
            this.$router.push({ query: { page: page, search: this.search, search_input: this.searchInput } });
            this.getData();
            setTimeout(() => {
                window.jQuery("html, body").animate({ scrollTop: 850 }, 300);
            }, 500);
        },
        searchInputSubmit() {
            this.page = 1;
            this.$router.push({ query: { page: 1, search: this.search, search_input: this.searchInput } });
            this.getData()
        },
    }
}
</script>

<style lang="scss">
.layout-table-profession {
    .box-title {
        height: auto;

        .text {
            height: 23px;
            width: 23px;
            border-radius: 50%;
            border: #FF9900;
            border-style: solid;
            border-width: 1px;
            margin-left: 13px;

            .a {
                padding-top: 1px;
                text-align: center;
                font-size: 13px;
                font-weight: 700;
            }
        }

        .title {
            font-weight: 700;
            font-size: 13px;
        }

        .activeText {
            color: red;
        }
    }

    .category {
        .category-title {
            list-style-type: square;
        }

        .child {
            margin-left: 10px;
            font-size: 11px;
            color: #333;
            list-style-type: square;

        }

        .link:hover {
            text-decoration: underline;
        }
    }

    .custom-icon-plus {
        cursor: pointer;
        font-size: 12px;
    }
}
</style>