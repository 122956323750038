<template>
    <div>
        <!--<div id="head-banner">-->
            <!--<div class="wrap">-->
                <!--<div class="menu">-->
                    <!--<p class="menu-title"><span class="icon"><i class="fa fa-bars"></i></span> Danh mục</p>-->
                    <!--<ul class="gNavi">-->
                        <!--<li v-for="(category, index) in categories" :key="category.id">-->
                            <!--<router-link :to="{name: 'CategoryDetail', params: {id: category.id}}" v-if="index < 9">-->
                                <!--<img :src="category.thumb.uri" width="20" height="20" style="margin-bottom: 3px">-->
                                <!--{{ ' ' + category.name }}-->
                            <!--</router-link>-->
                        <!--</li>-->
                    <!--</ul>-->
                <!--</div>-->

                <!--<div class="head__user">-->
                    <!--<div class="head__user-top">-->
                        <!--<div class="user-base-info">-->
                            <!--<div class="user-wrap">-->
                                <!--<div class="user-thumb" @click="onShowInfo"><img :src="userAvatar" alt=""></div>-->
                                <!--<div class="user-info">-->
                                    <!--<span class="user-name" v-if="auth">-->
                                        <!--<router-link :to="{name: 'Info'}">{{ `Xin chào ${auth.user.name}` }}</router-link>-->
                                    <!--</span>-->
                                    <!--<span class="user-name" v-else>Chào mừng bạn đến với Elim</span>-->
                                    <!--<router-link to="/dang-nhap" class="user-requirements" v-if="!auth">Vui lòng đăng-->
                                        <!--nhập để mua hàng-->
                                    <!--</router-link>-->
                                    <!--<a v-else class="user-login" href="#!logout" @click.prevent="logout()"><i-->
                                            <!--class="fa fa-sign-in"></i> Đăng Xuất</a>-->
                                <!--</div>-->
                            <!--</div>-->
                            <!--<div class="login-btn-group" v-if="!auth">-->
                                <!--<router-link class="user-login" to="/dang-nhap"><i class="fa fa-sign-in"></i> Đăng nhập-->
                                <!--</router-link>-->
                                <!--<router-link class="user-register" to="/dang-ky"><i class="fa fa-sign-in"></i> Đăng ký-->
                                <!--</router-link>-->
                            <!--</div>-->
                            <!--<div class="login-btn-group" v-else>-->
                                <!--<router-link class="user-register" to="/gio-hang"><i class="fa fa-shopping-cart"></i> Giỏ hàng-->
                                <!--</router-link>-->
                                <!--<router-link class="user-register" to="/don-hang"><i class="fa fa-history"></i> Đơn hàng-->
                                <!--</router-link>-->
                            <!--</div>-->
                        <!--</div>-->
                        <!--<div class="promotion-container"><a href="#"><img src="img/data/promotion.png" alt=""></a></div>-->
                    <!--</div>-->
                    <!--<div class="head__user-bot" v-if="auth">-->
                        <!--<div style="display: flex;">-->
                                <!--<div class="your-orders">-->
                                    <!--<router-link to="/don-hang">-->
                                        <!--<strong>Đơn hàng của bạn</strong>-->
                                    <!--</router-link>-->
                                <!--</div>-->
                                <!--<div class="your-orders">-->
                                    <!--<router-link to="/gio-hang">-->
                                    <!--<strong>Giỏ hàng ({{ items.length }})</strong>-->
                                    <!--</router-link>-->
                                <!--</div>-->

                        <!--</div>-->

                        <!--<ul class="shortcut-list">-->
                            <!--<li v-for="oc in orderCounts" :key="oc.value">-->
                                <!--<router-link to="/don-hang">-->
                                    <!--<a href="#">-->
                                        <!--<span class="shortcut-item-value">{{oc.count}}</span>-->
                                        <!--<span class="shortcut-item-title">{{oc.name}}</span>-->
                                    <!--</a>-->
                                <!--</router-link>-->
                            <!--</li>-->
                        <!--</ul>-->

                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->
        <!--</div>-->
        <div id="contents" class="bg-white">
            <div >
                <BannerHome
                    :sliders="sliders" :list-banner-campaign="listBannerCampaign"
                />
                <!-- <categoryProductInfomation></categoryProductInfomation> -->
                <listProductInfomation></listProductInfomation>
                <post-list :posts="posts"></post-list>

            </div>
        </div><!---/# CONTENTS--->
    </div>
</template>

<script>
    import 'slick-carousel';
    import "slick-carousel/slick/slick-theme.css";

    import HomeRequest from "@/api/requests/HomeRequest";
    // import ProductItem from "@/components/ProductItem";
    import SlickSlider from "@/components/SlickSlider";
    import TopCampaign from "@/components/TopCampaign";
    import FooterTips from "@/components/FooterTips";
    import store from "@/store";
    import OrderHistoryRequest from "@/api/requests/OrderHistoryRequest";
    import CartRequest from "@/api/requests/CartRequest";
    // import ListCategory from "@/components/ListCategory";
    // import ListCampaign from "@/components/ListCampaign";
    import PostList from "@/components/ListPost";
    import BannerHome from "@/components/BannerHome";
    import {getJsonData, getOption, siteSetTitle} from "@/lib/utils";
    import listProductInfomation from "@/components/product_international/list.vue"
    import categoryProductInfomation from "@/components/product_international/category.vue"
    import NewsHome from "@/components/NewsHome";
    // import Banner from "@/components/Banner";
    // import CraftVillage from "@/components/craft_village/ListHome";
    // import SectorYearbook from "@/components/profession/SectorYearbook";

    export default {
        name: 'Home',
        components: {
        //   Banner,
          NewsHome,
            PostList,
            // ListCampaign,
            // ListCategory,
            FooterTips,
            TopCampaign,
            SlickSlider,
            // ProductItem,
            BannerHome,
            // CraftVillage,
            // SectorYearbook
            listProductInfomation,
            categoryProductInfomation
        },
        data() {
            return {
                categories: [],
                sliders: [],
                banners: [],
                listBannerCampaign: [],
                campaigns: [],
                topCampaigns: [],
                flashSaleCampaigns: [],
                orderCounts: [],
                keyword: '',
                items: [],
                posts: []
            }
        },
        computed: {
            userAvatar: function () {
                const auth = this.auth;

                if (auth && auth.user && auth.user.avatar && auth.user.avatar.uri) {
                    return this.auth.user.avatar.uri;
                }

                return '/img/data/user-img.png';
            }
        },
        mounted() {
            siteSetTitle('')
            this.getData();
            this.getCampaignData();
            this.getCategoryData();

            if (this.auth) {
                this.getOrderCounts();
                this.getCart();
            }

        },
        methods: {
            logout() {
                store.commit('RemoveAuth');
                location.reload();
            },
            onShowInfo() {
                if (store.state.Auth) {
                    this.$router.push({name: 'Info'})
                }
            },
            search(keyword) {
                this.$router.push({name: 'Search', query: {keyword}})
            },
            async getOrderCounts() {
                const r = await OrderHistoryRequest.counts();
                this.orderCounts = r.counts;
            },
            async getData() {
                const res = await HomeRequest.getFrontDataWeb();
                if (res.code === 200) {
                    this.sliders = res.sliders;
                    this.banners = res.banners;
                    this.posts = res.posts;

                    //  this.slickSlider();
                }
                // this.categories = getJsonData('homeCategories', []);
            },
            async getCampaignData() {
                const res = await HomeRequest.getCampaignData();

                if (res.code === 200) {
                    this.campaigns = res.campaigns;
                    this.topCampaigns = res.topCampaigns;
                    this.flashSaleCampaigns = res.flashSaleCampaigns;
                    this.listBannerCampaign = res.listBannerCampaign;
                }
            },
            async getCategoryData() {
                const res = await HomeRequest.getCategoryData();

                if (res.code === 200) {
                    this.categories = res.categories;
                }
            },
            async getCart() {
                const res = await CartRequest.get();
                this.items = res.items;
            }
        }
    }
</script>
