<template>
    <div>

        <div id="contents">
            <div class="wrap">

                <div class="widget mb-3" style="margin-top:25px">
                    <div class="widget-head">
                        <div class="widget-head-left">
                            <a href="#" class="widget-title">{{ $t('notFoundPage')}}</a>
                        </div>
                    </div>

                </div>
            </div>
        </div><!---/# CONTENTS--->
    </div>
</template>

<script>
    export default {
        name: "PageNotFound"
    }
</script>

<style scoped>

</style>
