import BaseRequest from "../BaseRequest";
import {Address, GeneralResponse} from '../interfaces';

class AddressRequest extends BaseRequest {
    constructor() {
        super('AddressRequest');
    }

    async get(): Promise<any> {
        return super.dispatch('get')
    }

    async getProvinces(): Promise<any> {
        return super.dispatch('getProvinces')
    }

    async getDistricts(provinceId: number): Promise<any> {
        return super.dispatch('getDistricts', provinceId)
    }
    async getWards(districtId: number): Promise<any> {
        return super.dispatch('getWards', districtId)
    }

    async remove(addressId: number): Promise<GeneralResponse> {
        return super.dispatch('remove', addressId)
    }

    async save(address: any): Promise<GeneralResponse> {
        return super.dispatch('save', address)
    }

    async show(id: string): Promise<GeneralResponse> {
        return super.dispatch('show', id)
    }

    async setDefaultAddress(addressId: string): Promise<boolean> {
        return super.dispatch('setDefaultAddress', addressId)
    }

}

export default new AddressRequest()
