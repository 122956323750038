<template>
    <div class="container" id="order-list">
        <div class="wrap-content">
            <div class="price-tab-bar">
                <div class="tab-bar" :class="{ active: tabPriceIndex === 1 }" @click="setTabPrice(1)">
                    <p>{{ $t('tabDHND')}}</p>
                </div>
                <div class="tab-bar" :class="{ active: tabPriceIndex === 2 }" @click="setTabPrice(2)">
                    <p>{{ $t('tabDHQT')}}</p>
                </div>
            </div>
        </div>
        <nav>
            <div class="nav nav-tabs">
                <template v-if="tabPriceIndex == 2">
                    <a v-for="s in statusListInterOrder"
                       :key="s.value"
                       @click.prevent="filter(s.value)"
                       :class="s.active ? 'active' : ''"
                       class="nav-item nav-link" role="tab">{{ lang == 'cn' ? s.name_cn : lang == 'en' ? s.name_en : s.name  }}</a>
                </template>
                <template v-else>
                    <a v-for="s in statusList"
                       :key="s.value"
                       @click.prevent="filter(s.value)"
                       :class="s.active ? 'active' : ''"
                       class="nav-item nav-link" role="tab">{{ lang == 'cn' ? s.name_cn : lang == 'en' ? s.name_en : s.name  }}</a>
                </template>


            </div>
        </nav>
        <div class="search-input">
            <div class="search-box">
                <img src="/assets/img/shop/search-icon.png" alt="">
                <input @keydown.enter="searchOrder(keyword)" class="search-input"
                       v-model="keyword"
                       :placeholder="$t('findByProductName')">
            </div>

        </div>
        <div class="tab-content">
            <loading :is-loading="isLoading"></loading>
            <label v-if="orders.length === 0">{{ $t('hasNotOrder')}}</label>
            <div v-else class="wrap-content" v-for="order in orders" :key="order.id">
                <div class="row-header">
                    <a class="header-label" :href="'/cua-hang/' + order.shop.slug">
                        {{ order.shop ? order.shop.name : '' }}
                        <i class="fas fa-angle-right"></i>
                    </a>
                    <a class="header-text">
                        <span class="status-title" @click="getOrderDetail('OrderDetail', order.code)">Trạng thái đơn hàng</span>
                        <span class="status-name" @click="getOrderDetail('OrderDetail', order.code)" v-text="order.statusText"></span>
                    </a>
                </div>

                <div class="row-item" v-for="(product, index) in order.OrderItem" :key="'product-' + index">
                    <div class="col-product">
                        <img :src="product.thumb.uri" alt=""/>
                        <div>
                            <p class="product-name">
                                <span style="color: #db0000; font-size: 13px" v-if="tabPriceIndex == 2" v-text="product.type_international == 2 ? '(' + $t('tabNKTM') + ')' : '(' + $t('tabNKTD') + ')'"></span><br/>
                                <a :href="product.product ? '/san-pham/' + product.product.slug : ''" target="_blank">
                                    {{ lang == 'cn' && product.name_cn ? product.name_cn : lang == 'en' && product.name_en ? product.name_en : product.name }}
                                </a>
                            </p>
                            <p class="product-pack">
                                {{ product.packs && product.packs[0] ? $t('productClassification') + ': ' : ''}} {{ product.packs && product.packs[0] && product.packs[0].type ? product.packs[0].type + ' - ' : ''}} {{ product.packs && product.packs[0] && product.packs[0].name ? product.packs[0].name : '' }}
                            </p>
                            <p class="product-pack">
                                {{ product.quantity ? $t('quantity') + ': ' + n(product.quantity) : ''}}
                            </p>
                            <p class="product-pack">
                                {{ product.price ? $t('price') + ': ' + n(product.price) + 'đ' : ''}}
                            </p>
                        </div>
                    </div>
                    <div class="col-price">
                        <p class="text-price-discount">{{ product.price_discount > 0 ? numberFormat(product.price_origin) + ' đ' : '' }}</p>
                    </div>

                    <div class="col-total">
                        <p class="text-price danger">{{product.price_origin > product.price ? numberFormat(product.quantity * product.price_origin)
                                : numberFormat(product.quantity * product.price)}} đ</p>
                    </div>
                </div>
                <div class="row-footer">
                    <div class="shop-amount">
                        <span class="footer-title">{{ $t('totalAmountInShop')}}</span>
                        <span class="footer-text"> {{ numberFormat(order.amount_total) }} đ</span>
                    </div>
                    <div class="btt-confirm" v-if="order.status == 2">
                        <button class="btn btn-info" @click="confirmOrder(order.id)">{{ $t('confirm')}}</button>
                    </div>

<!--                    <div class="footer-btn">-->
<!--                        <button class="buy-btn">Mua lần nữa</button>-->
<!--                        <button class="contact-shop">Liên hệ người bán</button>-->
<!--                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from "@/includes/Loading";
import OrderHistoryRequest from "@/api/requests/OrderHistoryRequest";
import {numberFormat} from "@/lib/utils";
import ProductInShop from "@/components/orders/ProductInShop";
import store from "@/store";
import toastr from "toastr";

export default {
    name: "OrderList",
    components: {
        ProductInShop,
        Loading
    },
    data() {
        return {
            statusList: [
                {value: -1, name: 'Tất cả', name_cn: '全部', name_en: 'All', active: true},
                {value: 0, name: 'Chờ phản hồi', name_cn: '加工', name_en: 'Waiting For Feedback', active: false},
                {value: 2, name: 'Chờ xác nhận', name_cn: '等待确认', name_en: 'Waiting For Confirm', active: false},
                {value: 4, name: 'Đã xác nhận', name_cn: '确认的', name_en: 'Confirmed', active: false},
                {value: 10, name: 'Chờ lấy hàng', name_cn: '等待货物', name_en: 'Waiting For ', active: false},
                {value: 1, name: 'Đang giao hàng', name_cn: '交货中', name_en: 'Shipping', active: false},
                {value: 2, name: 'Thành công', name_cn: '成功', name_en: 'Success', active: false},
                {value: 3, name: 'Đã hủy', name_cn: '取消', name_en: 'Canceled', active: false},
            ],
            statusListInterOrder: [
                {value: -1, name: 'Tất cả', name_cn: '全部', name_en: 'All', active: true},
                {value: 0, name: 'NCC Chờ phản hồi', name_cn: '认证国际供应商', name_en: 'Certified International Supplier', active: false},
                {value: 2, name: 'NCC Chờ xác nhận', name_cn: '认证国际供应商', name_en: 'Waiting For Confirmation Supplier', active: false},
                {value: 4, name: 'NCC Đã xác nhận', name_cn: '供应商已确认', name_en: 'Supplier Confirmed', active: false},
                {value: 8, name: 'Hàng tới kho trung chuyển TQ', name_cn: '货物发往中国中转仓库', name_en: 'China Warehouse', active: false},
                {value: 9, name: 'Hàng tới VN', name_cn: '发往越南的货物', name_en: 'Vietnam Warehouse', active: false},
                {value: 1, name: 'Đang giao nhận VN', name_cn: '越南交货', name_en: 'Vietnam Shipping', active: false},
                {value: 5, name: 'Đã giao', name_cn: '成功', name_en: 'Success', active: false},
                {value: 3, name: 'Đã hủy', name_cn: '取消', name_en: 'Canceled', active: false},
                {value: 7, name: 'Trả hàng / Hoàn tiền', name_cn: '退货退款', name_en: 'Refund', active: false}
            ],
            orders: [],
            keyword: '',
            currentStatus: '',
            isLoading: false,
            orderColors: [],
	        lang: store.state.locale,
            tabPriceIndex: 1
        }
    },

    mounted() {
        // document.title = "Danh sách đơn hàng";

        if (this.$route.query.type) {
            this.filter(this.$route.query.type)
        } else {
            this.load(-1);
        }
    },

    methods: {
        numberFormat,
        filter(value) {
            this.$router.push({query: {type: value}});
            if (this.tabPriceIndex == 1) {
                this.statusList.forEach(status => {
                    status.active = value == status.value;
                });
            } else {
                this.statusListInterOrder.forEach(status => {
                    status.active = value == status.value;
                });
            }

            this.load(value);
        },
        async load(status) {
            const res = await OrderHistoryRequest.get({
                status: status,
                typeOrder: this.tabPriceIndex,
                page: 1
            });
            this.currentStatus = status;
            this.orders = res.orders;

        },
        getOrderDetail(component, code) {
            this.$parent.changeTab(component, code);
        },
        async searchOrder(keyword) {
            const res = await OrderHistoryRequest.searchOrder({
                status: this.currentStatus,
                typeOrder: this.tabPriceIndex,
                keyword: keyword,
                page: 1
            });
            this.orders = res.orders;
        },
        goToShop(shop) {
            let link = shop.slug ? '/cua-hang/' + shop.slug : '';
            if (link) {
                location.href = link;
            }
        },
        async setTabPrice(index) {
            this.tabPriceIndex = index;
            await this.load(-1);
        },
        async confirmOrder(orderId) {
            const res = await OrderHistoryRequest.confirmOrder({
                orderId: orderId
            });
            if (res && res.code != 0) {
                toastr.error(res.message);
            } else {
                toastr.success(res.message);
            }
            await this.load(this.currentStatus);
        },
    }
}
</script>

<style scoped lang="scss">

@import "../assets/css/vars";

#order-list {
  font-family: 'Inter', sans-serif;
  font-style: normal;
}

.title-bar {
  display: flex;
  background: #e02e24;;
  height: 50px;
  padding: 10px;

  .title-bar-back {
    margin-right: 10px;
  }

  .title-bar-content {
    font-size: 18px;
    color: #ffffff;
    font-weight: bold;
    margin: auto;
  }
}

.nav-tabs {
  border-bottom: unset;
  background: #ffffff;
  box-shadow: 0 8px 32px -4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-bottom: 16px;

  .nav-link {
    //margin: 16px auto;
    border: unset;
    font-weight: 500;
    font-size: 16px;

    &:hover {
      color: #DB0000;
    }

  }

  .nav-link.active {
    color: #DB0000;
  }

  .nav-item {
    cursor: pointer;
  }
}

.order-shop-name {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 2px 5px;
  color: $base-color;
  background-color: white;
  border: 1px solid $base-color;
  display: inline-block;
  border-radius: 10px;
}

.search-input {
  margin-bottom: 16px;
  .search-box {
    display: flex;
    background: #ffffff;
    padding: 10px 15px;
    box-sizing: border-box;
    border-radius: 8px;
    img {
      width: 24px;
      height: 24px;
    }

    input {
      border: none;
      margin-left: 10px;
      margin-bottom: 0;
      width: 100%;

      &::placeholder {
        font-size: 16px;
        line-height: 19px;
        color: #A7A9B1;
      }
    }
  }
}
.row-header {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #EDEDEF;

  .header-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #383C4F;
    margin-bottom: 0;
    padding-right: 15px;
    cursor: pointer;
    flex: 1;

    i {
      font-size: 16px;
    }
  }

  .header-text {
    cursor: pointer;
    display: flex;
    align-items: center;

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      margin-bottom: 0;
      padding-left: 10px;
    }
    .status-title {
      font-weight: 400;
      color: #626574;
      padding-right: 10px;
      border-right: solid 1px #EDEDEF;
      @media screen and (max-width: 768px){
        display: none;
      }
    }
    .status-name {
      font-weight: 500;
      color: #DB0000;
    }
  }
}

.row-footer {
  background: #F8F8F8;
  align-items: center;
  padding: 15px 20px;
  margin: 0 -20px;
  text-align: right;
  .shop-amount {
    margin-bottom: 16px;

    .footer-title {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      margin-right: 10px;
      color: #626574;
    }

    .footer-text {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #383C4F;
    }

  }
  .footer-btn {
    .buy-btn {
      color: #ffffff;
      background: #DB0000;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      padding: 12px 20px;
      margin-right: 10px;
      border-radius: 6px;
      border: unset;

      &:hover {
        cursor: pointer;
      }
    }
    .contact-shop {
      padding: 10px 20px;
      border: 1px solid #A7A9B1;
      box-sizing: border-box;
      border-radius: 6px;
      background: #ffffff;
      font-size: 16px;
      font-weight: 500;
      color: #383C4F;

      &:hover {
        cursor: pointer;
      }

    }
  }
}
.wrap-content {
  background: #FFFFFF;
  box-shadow: 0 8px 32px -4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 0 20px;
  margin-bottom: 20px;
  overflow: hidden;
}
.row-item {
  display: flex;
  align-items: center;
  padding: 15px 0;

  .col-product {
    flex: 2;
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px){
      flex: unset;
      max-width: 65%;
    }

    img {
      width: 50px;
      height: 50px;
      border-radius: 5px;
    }

    .product-name {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #383C4F;
      margin-bottom: 0;
      padding-left: 10px;
    }
    .product-pack {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #626574;
      margin-bottom: 0;
      padding-left: 10px;
    }
  }

  .col-price, .col-total {
    flex: 1;
    padding: 0 15px;

    .text-price {
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      color: #383C4F;
      margin-bottom: 0;
      text-align: right;

      &.danger {
        color: #DB0000;
      }
    }

    .text-price-discount {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-decoration-line: line-through;
      color: #A7A9B1;
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 768px){
    .col-price {
      display: none;
    }
    .col-total {
      flex: unset;
    }
  }

  .col-quantity {
    width: 156px;

    .product-quantity {
      display: flex;
      align-items: center;
      border: 1px solid #DADBDE;
      border-radius: 6px;
      overflow: hidden;

      .btn-quantity {
        cursor: pointer;
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border-right: 1px solid #DADBDE;

        &.dark {
          background: #EDEDEF;
          border-left: 1px solid #DADBDE;

        }
      }

      input {
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #3F4254;
        border: none;
        width: 80px;

        &:focus {
          outline: none;
        }
      }
    }
  }
}

.price-tab-bar {
    display: flex;
    align-items: center;
    padding: 0;
    margin-top: 25px;
    width: 70%;

    .tab-bar {
        padding: 12px 15px;
        background: #EDEDEF;
        //border-top: 1px solid #DADBDE;
        box-sizing: border-box;
        box-shadow: 0px 8px 32px -4px rgba(0, 0, 0, 0.05);
        cursor: pointer;
        width: 50%;
        text-align: center;

        p {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 0;
            color: #626574;
        }

        &.active {
            background: #FFFFFF;
            box-shadow: 0px 8px 32px -4px rgba(0, 0, 0, 0.05);
            border-bottom: 2px solid #DB0000;


            p {
                color: #DB0000;
            }
        }
    }
}
</style>
