import BaseRequest from "../BaseRequest";
import {Address, CategoryProduct, GeneralResponse, Shop} from '../interfaces';
import {Product} from "../interfaces";

export interface ShopHomeData {
    sliders: Array<any>,
    shop: Shop,
    categories: Array<CategoryProduct>
}

export interface ShopProductData {
    products: Array<Product>,
    hasNextPage: boolean
}

export interface ShopCategoryData {
    categories: Array<CategoryProduct>
}

class ShopRequest extends BaseRequest {
    constructor() {
        super('ShopRequest');
    }

    async getCategories(shopId: number): Promise<ShopCategoryData> {
        return super.dispatch('getCategories', shopId)
    }

    async getProducts(page: number, type: number, shopId: number): Promise<ShopProductData> {
        return super.dispatch('getProducts', page, type, shopId)
    }

    async getHomeDataWeb(shopId: number, page: number, sort: number, type: number, search: string, userId: null): Promise<ShopHomeData> {
        return super.dispatch('getHomeDataWeb', shopId, page, sort, type, search, userId)
    }

    async follow(shopId: number, userId: number, type: boolean): Promise<ShopHomeData> {
        return super.dispatch('follow', shopId, userId, type)
    }

    async getShopFollow(userId: number, page: number): Promise<ShopHomeData> {
        return super.dispatch('getShopFollow', userId, page)
    }

    async quickUpdateImage(data: any): Promise<GeneralResponse> {
        return super.dispatch('quickUpdateImage', data)
    }

}

export default new ShopRequest()
