<template>
    <div class="addresses">
        <div class="container"  style="background: #ffffff; padding: unset" >
            <div class="title-bar">

                <div class="title-bar-back">
                    <router-link to="/thong-tin-nhan-hang">
                        <img src="../assets/svg/back.svg" alt="quay lai"/>
                    </router-link>
                </div>
                <div class="title-bar-content" v-if="id">
                    {{ $t('editAddress')}}
                </div>
                <div class="title-bar-content" v-else>
	                {{ $t('addAddress')}}
                </div>

            </div>
            <div class="row" style="padding: 15px;">
                <div class="col-lg-6 col-sm-12">
                    <input name="id" type="hidden" v-model="address.id"/>
                    <div class="form-group">
                        <label>{{ $t('fullName')}}<span style="color: red">*</span> </label>
                        <input class="form-control" type="text" name="name" v-model="address.name"
                               :placeholder="$t('fullName')">
                        <p style="color: red" v-if="errors.name" v-text="errors.name[0]"></p>
                    </div>
                    <div class="form-group">
                        <label>{{ $t('phoneNumber')}} <span style="color: red">*</span> </label>
                        <input class="form-control" type="text" name="phone" v-model="address.phone"
                               :placeholder="$t('editAddress')">
                        <p style="color: red" v-if="errors.phone" v-text="errors.phone[0]"></p>
                    </div>
                    <div class="form-group">
                        <input type="checkbox" id="is_default" v-model="address.is_default" :value="true" class="checkbox-input">
                        <label for="is_default" class="checkbox-label">{{ $t('setDefault')}}</label>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="form-group">
                        <label>{{ $t('chooseProvince')}} <span style="color: red">*</span></label>
                        <select
                                v-model="address.province_id"
                                @change="onProvinceChange(address.province_id)"
                                class="form-control">
                            <option v-for="province in provinces" v-text="province.name" :value="province.id"
                                    :key="'province' + province.id"></option>
                        </select>
                        <p style="color: red" v-if="errors.province_id" v-text="errors.province_id[0]"></p>
                    </div>
                    <div class="form-group">
                        <label>{{ $t('chooseDistrict')}}<span style="color: red">*</span> </label>
                        <select
                                v-model="address.district_id"
                                @change="onDistrictChange(address.province_id)"
                                class="form-control">
                            <option v-for="district in districts" v-text="district.name" :value="district.id"
                                    :key="'district' + district.id"></option>
                        </select>
                        <p style="color: red" v-if="errors.district_id" v-text="errors.district_id[0]"></p>
                    </div>
                    <div class="form-group">
                        <label>{{ $t('detailAddress')}} <span style="color: red">*</span></label>
                        <textarea class="form-control" name="address" v-model="address.address" placeholder="Địa chỉ nhận hàng"></textarea>
                        <p style="color: red" v-if="errors.address" v-text="errors.address[0]"></p>
                    </div>
                </div>

            </div>
            <div class="row mt-2 mb-3" style="text-align: right; padding: 15px">
                <div class="col-lg-4 col-xs-12" style="display: flex; margin-left: auto">
                    <button @click="save" type="button" class="btn btn-primary" style="margin-right: 10px; margin-left: auto">
	                    {{ $t('save')}}
                    </button>
                    <button @click="remove" type="button" class="btn btn-danger">
	                    {{ $t('deleteAddress')}}
                    </button>
                </div>
            </div>
        </div>




    </div>

</template>

<script>
import AddressRequest from "@/api/requests/AddressRequest";
import {pluck} from "@/lib/utils";
import toastr from 'toastr';

export default {
    name: "AddressForm",

    data() {
        return {
            address: {},
            id: null,
            location: {},
            errors: {},
            districts: [],
            provinces: []
        }
    },
    created() {
        this.id = this.$route.params.id;
    },
    mounted() {
        this.getData();
        this.loadProvinces();
    },
    methods: {
        async save() {
            if (this.checkData(this.address)) {

                const res = await AddressRequest.save(this.address);
                this.address = res.data;
                toastr.success(this.$t('createAddressSuccess'));
                location.href = '/thong-tin-nhan-hang';

                this.errors = {}
            }

        },
        checkData(address) {
            this.errors = {
                name: [],
                phone: [],
                address: [],
                province_id: [],
                district_id: []
            };
            if (!address.name) {
                this.errors.name.push(this.$t('nameRequired'));
            }

            if (!address.phone) {
                this.errors.phone.push(this.$t('phoneRequired'));
            } else if (!this.validPhone(address.phone)) {
                this.errors.phone.push(this.$t('phoneFormat'))
            }
            if (!address.address) {
                this.errors.address.push(this.$t('addressRequired'));
            }
            if (!address.province_id) {
                this.errors.province_id.push(this.$t('provinceRequired'));
            }
            if (!address.district_id) {
                this.errors.province_id.push(this.$t('districtRequired'));
            }
            return !this.errors.name.length && !this.errors.phone.length && !this.errors.address.length;

        },
        async remove() {
            if (this.address.id && window.confirm(this.$t('deleteAddress'))) {
                const res = await AddressRequest.remove(this.address.id);
                if (res.code === 200) {
                    toastr.success(this.$t('deleted'))
                } else {
                    toastr.error(this.$t('plsRecheck'));
                }
            }
        },
        validPhone: function (phone) {
            let rule = /^\d{8,13}$/;
            return rule.test(phone);
        },

        async getData() {
            if (this.id) {
                document.title = this.$t('editAddress');
                const res = await AddressRequest.show(this.id);
                this.address = res.address;
                this.onProvinceChange(this.address.province_id);

            } else {
                document.title = this.$t('addAddress');
                this.address = {
                    name: '',
                    is_default: false,
                    address: '',
                    district_id: 0,
                    province_id: 0,
                }
            }
        },
        async loadProvinces() {
            const res = await AddressRequest.getProvinces()
            res.data.unshift({
                id: 0,
                name: this.$t('chooseProvince')
            });

            this.provinces = res.data;
            this.provinceMap = pluck(this.provinces, 'id', 'name');
        },

        async onProvinceChange(province_id) {
            const res = await AddressRequest.getDistricts(province_id)

            res.data.unshift({
                id: 0,
                name: this.$t('chooseDistrict')
            });

            this.districts = res.data;
            this.districtMap = pluck(this.districts, 'id', 'name');

        },
        async onDistrictChange(district_id) {
            this.value.province_name = this.provinceMap[this.value.province_id] || '';
            this.value.district_name = this.districtMap[district_id] || '';

        }


    }
}
</script>

<style scoped lang="scss">
@import "../assets/css/address";

.title-bar {
  display: flex;
  background: #e02e24;;
  height: 50px;
  padding: 10px;
  margin-bottom: 10px;

  .title-bar-back {
    margin-right: 10px;
  }

  .title-bar-content {
    font-size: 18px;
    color: #ffffff;
    font-weight: bold;
    margin: auto;
  }
}
.checkbox-input {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-top: 10px;
}
.checkbox-label {
  font-size: 16px;
}
</style>