<template>
    <div class="conversations-root" id="chat-conversation">
        <div class="search-bar">
<!--            <div class="row">-->
                <input type="text" v-model="keyword"
                       @input="onKeywordInput"
                       class="form-control search-chat" :placeholder="$t('searchByName')">
<!--                <select class="form-control col-lg-4">-->
<!--                  <option>Tất cả</option>-->
<!--                  <option>Tất cả</option>-->
<!--                  <option>Tất cả</option>-->
<!--                </select>-->
<!--            </div>-->
        </div>
        <div v-if="hasCons" class="conversations small-scroll" ref="conversations">
            <ul>
                <li v-for="c in conversations" class="conversation"
                    :class="{active: c.cid === currentCid}"
                    @click="change(c)" :key="c">
                        <div class="chat-avatar">
                            <img :src="c.target.avatar.uri">
                        </div>
                        <div class="chat-info" :class="{'chat-new-message': c.unread > 0}">
                            <div class="username" v-text="c.target.name"></div>
                            <div class="last-message">
                                <div class="message" v-text="stringTruncate(c.last_message, 15)"></div>
                                <div class="time">{{d(c.updated_at)}}</div>
                            </div>
                        </div>
                        <div class="chat-right">
                          <span class="chat-badge" v-if="c.unread > 0">{{c.unread}}</span>
                        </div>
                </li>
            </ul>
        </div>
        <div class="mt-3 text-center" v-else>
            <p style="font-size: 1.5vh">{{ $t('notChat')}} <br/>{{ $t('findShopToChat')}}</p>
        </div>
    </div>
</template>

<script>
import {throttle, stringTruncate} from "@/lib/utils";
import {chat$post} from "@/api/requests/ChatRequest";
import moment from "moment";
let app;

export default {
    name: "ChatConversations",
    props: ['defaultCid', 'shopId', 'shopName', 'shopAvatar'],
    data() {
        const conversations = [];

        return {
          page: 1,
          hasNextPage: true,
          keyword: '',
          conversations,
          hasCons: true,
          currentCid: this.defaultCid || null
        }
    },
    mounted() {
        app = this;
        this.load();
        this.$refs.conversations.addEventListener('scroll', this.onScroll)
    },
    methods: {
        stringTruncate,
        onScroll() {
            if (!this.hasNextPage) {
                return;
            }

            const el =  this.$refs.conversations;
            const maxScrollTop = el.clientHeight - el.scrollHeight;
            if (el.scrollHeight - el.scrollTop === el.clientHeight) {
                this.throttleLoadScroll();
            }
        },
        onKeywordInput: throttle(() => {
            app.page = 1;
            app.load();
        }, 1000),
        throttleLoadScroll: throttle(() => {
            app.page++;
            app.load();
        }, 1000),
        setCid(cid) {
            this.currentCid = cid;
            const c = this.conversations.find(c => c.cid === cid);
            if (c) {
                c.unread = 0;
            }
        },
        increaseBadge(cid) {
            const c = this.conversations.find(c => c.cid === cid);
            console.log('increase badge,', cid)
            c.unread++;
        },
        setLastMessage(cid, message) {
            const index = this.conversations.findIndex(c => c.cid === cid);

            if (index === -1) {
                this.hasNextPage = true;
                this.page = 1;
                this.load(this.page);
                return;
            }

            const conversation = this.conversations[index];

            if (conversation) {
                conversation.last_message = message;

                if (index > 0) {
                    // Đẩy cuộc hội thoại lên đầu
                    const newConversations = [conversation];
                    this.conversations.forEach(c => {
                        if (c.cid !== cid) {
                            newConversations.push(c);
                        }
                    });
                    this.conversations = newConversations;
                }
            }
        },
        change(conversation) {
            this.currentCid = conversation.cid;
            this.$emit('change', conversation)
        },
        async load() {
            const res = await chat$post('/v1/chat/index', {page: this.page, keyword: this.keyword});

            if (this.page === 1) {
                this.conversations = res.data;
            } else {
                if (res.data.length === 0) {
                    this.hasNextPage = false;
                    return;
                }

                res.data.forEach( c => {
                    this.conversations.push(c);
                });
            }

            if (this.conversations.length <= 0) {
                this.hasCons = false;
            }
            if (this.shopId) {
                let hasConversation = false;
                let currentConversation = '';
                this.conversations.forEach(c => {
                    let cluxId = c.target.clux_id;
                    if (this.shopId == cluxId.slice(5,7)) {
                        hasConversation = true;
                        currentConversation = c;
                    }
                });

                if (hasConversation) {
                    const newConversations = this.conversations.filter(con => con.cid === currentConversation.cid);
                    this.conversations.forEach(c => {
                        if (c.cid !== currentConversation.cid) {
                          newConversations.push(c);
                        }
                    });
                    this.conversations = newConversations;
                } else {
                    currentConversation = {
                        cid: 'shop:'+ this.shopId,
                        last_message: '',
                        target: {
                            avatar: {
                                uri: this.shopAvatar
                            },
                            name: this.shopName,
                            clux_id: 'shop:'+ this.shopId,
                        },
                        type: 'out',
                        unread: 0,
                    }
                    this.conversations.splice(0,0, currentConversation);
                }

                this.change(currentConversation);
            }
        },
        d(tzTime) {
          if (!tzTime) {
            return '';
          }
          const m =  moment(tzTime);

          return m.format('DD/MM HH:ss')
        },
    }
}
</script>

<style scoped lang="scss">
@import '../../assets/css/chat';
</style>