import BaseRequest from "@/api/BaseRequest";
import {Post} from "@/api/interfaces";

export interface GetAllPostResponse {
    news: Array<Post>
}

class PostRequest extends BaseRequest {
    constructor() {
        super('PostRequest');
    }

    async getList(page: number): Promise<GetAllPostResponse> {
        return super.dispatch('getList', page);
    }

    async getListAdditional(): Promise<GetAllPostResponse> {
        return super.dispatch('getListAdditional');
    }
}

export default new PostRequest()