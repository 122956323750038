<template>
  <div class="d-flex align-items-center">

    <i class="fa fa-angle-double-left" aria-hidden="true"></i>
    <div class="filter-box">

      <ul class="place-filter" v-for="(province, idx) in provinces" :key="idx">
        <li>
          <label class="custom-input-checkbox"
          >{{ province.name }}
          </label>
        </li>
      </ul>


    </div>
    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
  </div>

</template>
<script>
import CategoryRequest from "@/api/requests/CategoryRequest";
import {getSearchQuery} from "@/lib/utils";

export default {
  props: [
    "chooseCategory",
    "chooseProvince",
    "sortByPrice",
    "resetFilter",
    "sortPrice",
  ],
  data() {
    const query = getSearchQuery();
    return {
      rKeyword: query.keyword || "",
      isLoading: false,
      products: [],
      shops: [],
      campaigns: [],
      categories: [],
      filters: {
        order: "created_at",
      },
      selectedProvinces: [],
      provinces: [],
      pageProvince: 1,
      price_max: 0,
      price_min: 0,
      selectedCategories: [],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getData();
    // this.keyword = this.$route.query.keyword || '';
    document.title = this.$t('raoVat');
  },

  methods: {
    async getData() {
      const res = await CategoryRequest.getAllCategories();

      if (res.categories) {
        this.categories = res.categories;
      }

      const response = await CategoryRequest.getProvinces();
      if (response.provinces) {
        this.provinces = response.provinces;
      }
    },
    async onPageChangeProvinces() {
      let page = this.pageProvince + 1;
      const response = await CategoryRequest.getProvinces(page);
      if (response.provinces) {
        this.pageProvince = page;
        this.provinces = this.provinces.concat(response.provinces);
      }
    },
  },
  watch: {
    "$route.query.keyword": function (newValue) {
      this.paginate.page = 1;
      this.paginate.shopPage = 1;
      this.rKeyword = newValue;
      this.load();
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/css/filter";
</style>
