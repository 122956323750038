
import NProgress from 'nprogress';
import "nprogress/nprogress.css";
import "toastr/toastr.scss";
import config from "../config";
import {deepFreeze} from "./deepfreeze";
import moment from "moment";
const selfApiUrl = config.App.selfApiUrl;

declare global {
    interface Window { getInlineJson: Function}
}


export function chunk(input: Array<any>, chunkSize: number): Array<any> {
    const result = [];
    let i,j,temparray,chunk = chunkSize;
    for (i=0,j=input.length; i<j; i+=chunk) {
        temparray = input.slice(i,i+chunk);
        result.push(temparray);
    }

    return result;
}

export function numberFormat(v: any ,n: any = 0, x = 3, s= ',', c = null ): string {
    if (!v) {
        return '0';
    }
    v = Number(v);
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = v.toFixed(Math.max(0, ~~n));

    const r = (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
    return r;
}

export function rand(min: number, max: number) : number {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export function arrayRand(arr: Array<any>): any {
    return arr[Math.floor(Math.random() * arr.length)]
}

export function range(len: number, step: number = 1, start: number = 0): Array<number> {
    const arr = [];
    for (let i = start; i < len; i+= step) {
        arr.push(i);
    }

    return arr;
}

export function forEach(obj:any, cb:any) {
    if (Array.isArray(obj)) {
        obj.forEach (cb);
        return;
    }

    for (var k in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(k)) {
            cb(obj[k], k);
        }
    }
}

export function clone(item: any): any {
    if (!item) { return item; } // null, undefined values check

    let types = [ Number, String, Boolean ];
    let result: any;

    // normalizing primitives if someone did new String('aaa'), or new Number('444');
    types.forEach(function(type) {
        if (item instanceof type) {
            result = type( item );
        }
    });

    if (typeof result == "undefined") {
        if (Object.prototype.toString.call( item ) === "[object Array]") {
            result = [];
            item.forEach(function(child: any, index: number, array: any) {
                result[index] = clone( child );
            });
        } else if (typeof item == "object") {
            // testing that this is DOM
            if (item.nodeType && typeof item.cloneNode == "function") {
                result = item.cloneNode( true );
            } else if (!item.prototype) { // check that this is a literal
                if (item instanceof Date) {
                    result = new Date(item);
                } else {
                    // it is an object literal
                    result = {};
                    for (var i in item) {
                        result[i] = clone( item[i] );
                    }
                }
            } else {
                // depending what you would like here,
                // just keep the reference, or create new object
                if (false && item.constructor) {
                    // would not advice to do that, reason? Read below
                    result = new item.constructor();
                } else {
                    result = item;
                }
            }
        } else {
            result = item;
        }
    }

    return result;
}

export function cloneObject(obj: any) {
    return JSON.parse(JSON.stringify(obj));
}

export function createDeepFreeze(object: any): any {
    const o = clone(object);
    return deepFreeze(o);
}

export function stringTruncate(str: string, length: number) {
    if (!str) {
        return '';
    }

    let dots = str.length > length ? '...' : '';
    return str.substring(0, length)+dots;
}

export function debounce(func: any, wait = 500, immediate = false) {
    let timeout: any;
    return function() {
        clearTimeout(timeout);
        timeout = setTimeout(func, wait);
    };
}

export function isStringNullOrWhiteSpace( value: any) {

    if (value== null) return true;

    return value.replace(/\s/g, '').length === 0;
}

export function parseQuery(queryString: any) {
    let query: any = {};
    let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}




/**
 * Performs Http GET request
 * @param uri
 * @param params
 * @param progress
 * @param headers
 * @param isFullUrl
 * @returns {Promise<TResult>}
 */
export async function $get(uri: string, params: any = {}, progress = true, headers: any = {}, isFullUrl = false) {
    if (progress) {
        ///NProgress.start();
    }

    let url;
    if (Object.keys(params).length) {
        if (uri.indexOf('?') >= 0) {
            url =  uri + '&' + buildQuery(params);
        } else {
            url =  uri + '?' + buildQuery(params);
        }
    } else {
        url = uri;
    }

    if (!isFullUrl) {
        url = selfApiUrl + url;
    }

    headers['X-Requested-With'] = 'fetch';
    headers['Content-Type'] = 'application/json';
    const options: any = {
        headers: headers
    };

    return fetch(url, options).then(response => {
        if (progress) {
         //   NProgress.done();
        }

        if (response.status !== 200) {
            response.text().then(v => {
                console.error(v);
            })
            return Promise.reject('Error from server. HTTP code: ' + response.status)
        }

        return response.json();
    });
}

/**
 * Performs Http POST request
 * @param uri
 * @param params
 * @param progress
 * @param headers
 * @param isFullUrl
 * @returns {Promise<TResult>}
 */
export async function $post(uri: string, params = {}, progress = true, headers: any = {}, isFullUrl = false) {

    if (progress) {
        NProgress.start();
    }

    if (!isFullUrl) {
        uri = selfApiUrl + uri;
    }

    headers['Content-Type'] = 'application/json';
    headers['X-Requested-With'] = 'fetch';

    return fetch(uri, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(params),
    })
        .then((response) => {
            if (progress) {
                NProgress.done();
            }

            if (response.status !== 200) {
                response.text().then(v => {
                    console.error(v);
                })
                return Promise.reject('Error from server. HTTP code: ' + response.status)
            }

            return response.json();
        })
}


/**
 * Encode object to x-www-form-urlencoded
 * @param data
 */
export function buildQuery(data: any): string {
    if (typeof data !== 'object') {
        return '';
    }

    var queries = [];
    for (var k in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(k)) {
            queries.push(k + '=' + encodeURIComponent(data[k]));
        }
    }
    return queries.join('&');
}

export function thumbUrl(url: string): string {


    if (url.startsWith('http://') || url.startsWith('https://')) {
        return url;
    }

    return config.App.imageBaseUrl + url;
}

export function uuidv4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function isEmpty(obj: any): boolean {
    if (!obj) {
        return true;
    }

    for (let k in obj) {
        return false;
    }

    return true;
}

export function ucfirst (string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


export function stringFormat(str: any, args: Array<number|string>) {
    return str.replace(/{(\d+)}/g, function(match:any, number:any) {
        return typeof args[number] != 'undefined'
            ? args[number]
            : match
            ;
    });
}


export function rtrim (str: string, charlist: string): string {
    //  discuss at: https://locutus.io/php/rtrim/
    // original by: Kevin van Zonneveld (https://kvz.io)
    //    input by: Erkekjetter
    //    input by: rem
    // improved by: Kevin van Zonneveld (https://kvz.io)
    // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
    // bugfixed by: Brett Zamir (https://brett-zamir.me)
    //   example 1: rtrim('    Kevin van Zonneveld    ')
    //   returns 1: '    Kevin van Zonneveld'

    charlist = !charlist ? ' \\s\u00A0' : (charlist + '')
        .replace(/([[\]().?/*{}+$^:])/g, '\\$1')

    var re = new RegExp('[' + charlist + ']+$', 'g')

    return (str + '').replace(re, '')
}

export function ltrim (str: string, charlist: string): string {
    //  discuss at: https://locutus.io/php/ltrim/
    // original by: Kevin van Zonneveld (https://kvz.io)
    //    input by: Erkekjetter
    // improved by: Kevin van Zonneveld (https://kvz.io)
    // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
    //   example 1: ltrim('    Kevin van Zonneveld    ')
    //   returns 1: 'Kevin van Zonneveld    '

    charlist = !charlist ? ' \\s\u00A0' : (charlist + '')
        .replace(/([[\]().?/*{}+$^:])/g, '$1')

    var re = new RegExp('^[' + charlist + ']+', 'g')

    return (str + '')
        .replace(re, '')
}

export function trim (str: string, charlist: string): string {
    //  discuss at: https://locutus.io/php/trim/
    // original by: Kevin van Zonneveld (https://kvz.io)
    // improved by: mdsjack (https://www.mdsjack.bo.it)
    // improved by: Alexander Ermolaev (https://snippets.dzone.com/user/AlexanderErmolaev)
    // improved by: Kevin van Zonneveld (https://kvz.io)
    // improved by: Steven Levithan (https://blog.stevenlevithan.com)
    // improved by: Jack
    //    input by: Erkekjetter
    //    input by: DxGx
    // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
    //   example 1: trim('    Kevin van Zonneveld    ')
    //   returns 1: 'Kevin van Zonneveld'
    //   example 2: trim('Hello World', 'Hdle')
    //   returns 2: 'o Wor'
    //   example 3: trim(16, 1)
    //   returns 3: '6'

    var whitespace = [
        ' ',
        '\n',
        '\r',
        '\t',
        '\f',
        '\x0b',
        '\xa0',
        '\u2000',
        '\u2001',
        '\u2002',
        '\u2003',
        '\u2004',
        '\u2005',
        '\u2006',
        '\u2007',
        '\u2008',
        '\u2009',
        '\u200a',
        '\u200b',
        '\u2028',
        '\u2029',
        '\u3000'
    ].join('')
    var l = 0
    var i = 0
    str += ''

    if (charlist) {
        whitespace = (charlist + '').replace(/([[\]().?/*{}+$^:])/g, '$1')
    }

    l = str.length
    for (i = 0; i < l; i++) {
        if (whitespace.indexOf(str.charAt(i)) === -1) {
            str = str.substring(i)
            break
        }
    }

    l = str.length
    for (i = l - 1; i >= 0; i--) {
        if (whitespace.indexOf(str.charAt(i)) === -1) {
            str = str.substring(0, i + 1)
            break
        }
    }

    return whitespace.indexOf(str.charAt(0)) === -1 ? str : ''
}

export function mb_strlen(str: any) {
    if (typeof str === 'string') {
        return str.length;
    }

    return 0;
}


export function arr(value: any) : Array<any> {
    if (Array.isArray(value)) {
        return value;
    }

    return value ? [value] : [];
}

export function flip(array: Array<any>, fillValue: any = null) {
    if (!Array.isArray(array)) {
        array = arr(array);
    }

    const n = array.length;
    const obj: any = {};
    if (fillValue) {
        for (let i = 0; i < n; i++) {
            obj[array[i]] = fillValue;
        }
    } else {
        for (let i = 0; i < n; i++) {
            obj[array[i]] = i;
        }
    }


    return obj;
}

export function percentage(partialValue: any, totalValue: any): number {
    if (!totalValue) {
        return 0;
    }

    return (100 * partialValue) / totalValue;
}

export function sleep(miliseconds: number): Promise<boolean> {
    return new Promise(((resolve, reject) => {
        setTimeout(() => {
            resolve(true);
        }, miliseconds)
    }))
}

/**
 *
 * @param arr Array
 * @param key string
 * @param value string | null
 * @returns {{}}
 */
export function pluck(arr: any, key = 'id', value: any = null) {
    if (!Array.isArray(arr)) {
        return {};
    }

    let map: any = {};
    const len = arr.length;
    for (let i = 0; i < len; i++) {
        map[arr[i][key]] = value === null ? arr[i] : arr[i][value];
    }

    return map;
}

export function getValidPage(page: any): number {
    const p = parseInt(page);
    if (!p) {
        return 1;
    }

    if (p < 1) {
        return 1;
    }

    return p;
}

export function intVal(n: any): number {
    const a = parseInt(n);

    return a ? a : 0;
}

export function getDefaultImage(): string {
    return 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
}

export function boolVal(a: any): boolean {
    return !!a;
}

export function throttle (callback: any, limit = 500) {
    var waiting = false;                      // Initially, we're not waiting
    return function () {                      // We return a throttled function
        if (!waiting) {                       // If we're not waiting
            callback.call(arguments);  // Execute users function
            waiting = true;                   // Prevent future invocations
            setTimeout(function () {          // After a period of time
                waiting = false;              // And allow future invocations
            }, limit);
        }
    }
}

let jsonData: any;

export function setJsonData(data: any ) {
    jsonData = data;
}

export function getJsonData(key: string, defaultValue: any = null) {
    try {
        return jsonData && jsonData[key] ? jsonData[key]: defaultValue;
    } catch (e) {
        console.warn('getJsonData failed: ', e);
    }

    return defaultValue;
}

export function siteSetTitle(title: string) {
    if (!title) {
        document.title = getOption('site_title');
    } else {
        document.title = title + ' | ' + getOption('site_title');
    }
}

export function getOption(key: string, defaultValue: any = null) {
    try {
        return (jsonData && jsonData.options[key]) ? jsonData.options[key]: defaultValue;
    } catch (e) {
        console.warn('getOption failed: ', e);
    }

    return defaultValue;
}

export function isScrolledIntoView(el: any) {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;

    // Only completely visible elements return true:
    var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
    // Partially visible elements return true:
    //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
}

export function botErr(message: any) {
    let m = message;
    if (message instanceof Error) {
        m = message.toString() + '\n' + message.stack;
    }
    console.log('%c🤖' + m, 'background:red;color:#fff;font-size:16px;')
}


export function botWarn(message: string) {
    const d = new Date;
    const time = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
    console.log('%c🤖' + '[' + time + '] ' + message, 'background:#fe9f10;color:#fff;font-size:14px;')
}

export function botInfo(message: string) {
    const d = new Date;
    const time = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
    console.log('%c🤖' + '[' + time + '] ' + message, 'background:rgb(0, 128, 129);color:#fff;font-size:14px;')
}

export function getDeviceId() {
    const key = 'uuid';
    let uuid = localStorage.getItem(key);

    if (!uuid) {
        uuid = uuidv4();
        localStorage.setItem(key, uuid);
    }

    return uuid;
}

export function getSearchQuery(): any {
    const query: any = {};
    const queryString = location.search;
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');

    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }

    return query;
}

export function d(tzTime: any) : any {
    if (!tzTime) {
        return '';
    }
    const m =  moment(tzTime);
    // if (m.format('YYYY') == (new Date).getFullYear()) {
    //     return m.format('DD/MM HH:mm')
    // }

    return m.format('DD/MM/YYYY HH:mm')
}

export function td(tzTime: any) : any {
    if (!tzTime) {
        return '';
    }
    const m =  moment(tzTime);
    // if (m.format('YYYY') == (new Date).getFullYear()) {
    //     return m.format('DD/MM HH:mm')
    // }

    return m.format('HH:mm DD/MM/YYYY')
}

export function defaultImg() {
    return config.App.defaultImg;
}
