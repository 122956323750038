<template>
    <div id="chat_button" v-if="! show">
        <div class="feedback" @click="showChat()">
            <img class="img-chat" src="/assets/img/common/messages.png" alt=""> <span>Chat</span>
            <i class="fas fa-angle-up" style="margin: auto 0 auto auto"></i>
        </div>
    </div>
    <div :class="showMessage? 'chat-box': 'chat-cons'" v-else>
        <chat-index v-if="authData || hasAuthTemp" ref="chatHome" @hidden="hiddenPopup()" @showMess="showMess" @hiddenMess="hiddenMess()" :show-message="showMessage"
                    :shop-id="shopInfo? shopInfo.id: 0" :shop-name="shopInfo? shopInfo.name: ''" :shop-avatar="shopInfo? shopInfo.avatar: ''"/>
        <div v-else>
            <QuickAddTempAccount @hidden="hiddenPopup()" />
        </div>
    </div>
</template>

<script>
    import ChatIndex from "@/components/chat_box/ChatIndex";
    import store from "@/store";
    import ChatConversations from "@/components/chat_box/ChatConversations";
    import QuickAddTempAccount from "@/components/QuickAddTempAccount";
    import {isEmpty} from "@/lib/utils";

    export default {
        name: "Chat",
        components: {ChatConversations, ChatIndex, QuickAddTempAccount},
        data() {
            return {
                show: false,
                showMessage: false,
                shopInfo: []
            }
        },
        created() {
            const authTemp = localStorage.getItem('auth_temp');
            if (authTemp) {
                store.commit('SetHasAuthTemp', true);
            }
        },
        mounted() {
        },
        computed: {
            authData: () => store.state.Auth,
            hasAuthTemp: () => store.state.hasAuthTemp,
        },
        methods: {
            createConversation(shop) {
                this.show = true;
                this.shopInfo = shop;
            },
            showChat() {
                this.show = true;
            },
            hiddenPopup() {
                this.showMessage = false;
                this.show = false;
            },

            hiddenMess() {
                this.showMessage = false;
            },
            showMess(conversation) {
                this.showMessage = true;
                setTimeout(() => { this.$refs.chatHome.onConversationChange(conversation); }, 10);

            }
        }
    }
</script>

<style scoped lang="scss">
    .feedback {
      background-color : white;
      padding: 10px 20px;
      border-color: white;
      border-radius: 8px;
      cursor: pointer;
      width: 125%;
    }

    .chat-box {
      z-index: 99;
      background-color : #ffffff;
      border-color: #ffffff;
      border-radius: 8px 0 0 8px;
      width: 40%;
      position: fixed;
      bottom: 0;
      right: 25px;
        @media screen and (max-width: 1199px){
            width: 85%;
        }
    }

    .chat-cons {
      z-index: 99;
      background-color : white;
      border-color: white;
      border-radius: 8px 0 0 8px;
      width: 15%;
      position: fixed;
      bottom: 0;
      right: 25px;
        @media screen and (max-width: 1199px){
            width: 50%;
        }
    }

    #chat_button {
        position: fixed;
        bottom: -5px;
        right: 60px;
        .feedback {
            display: flex;
            padding: 8px 16px;
            font-weight: 600;
            .img-chat {
                margin-right: 5px;
            }
            span {
                color: #db0000;
            }
        }
    }
</style>
