<template>
    <div>
        <div v-if="id" data-toggle="modal" :data-target="'#addressFormModal_' + id">
            <span class="address-edit-btn">
                <img src="@/assets/svg/edit.svg" alt="edit"/>
            </span>
        </div>
        <div class="delivery-address" v-else data-toggle="modal" data-target="#addressFormModal">
            <div class="btn-add-address">
                <div class="add-icon">
                    <i class="fas fa-plus"></i>
                </div>
                <span>{{$t('addAddress')}}</span>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" :id="id ? 'addressFormModal_' + id : 'addressFormModal'" tabindex="-1" role="dialog"
             aria-labelledby="addressFormModal" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg top-mobile" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle" v-text="title"></h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row" style="padding: 15px;">
                            <div class="col-lg-6 col-sm-12">
                                <input name="id" type="hidden" v-model="address.id"/>
                                <div class="form-group">
                                    <label>{{$t('fullName')}}<span style="color: red">*</span> </label>
                                    <input class="form-control" type="text" name="name" v-model="address.name"
                                           :placeholder="$t('fullName')">
                                    <p style="color: red" v-if="errors.name" v-text="errors.name[0]"></p>
                                </div>
                                <div class="form-group">
                                    <label>{{$t('phoneNumber')}} <span style="color: red">*</span> </label>
                                    <input class="form-control" type="text" name="phone" v-model="address.phone"
                                           :placeholder="$t('phoneNumber')">
                                    <p style="color: red" v-if="errors.phone" v-text="errors.phone[0]"></p>
                                </div>
                                <div class="form-group">
                                    <input type="checkbox" id="is_default" v-model="address.is_default" :value="true"
                                           class="checkbox-input">
                                    <label for="is_default" class="checkbox-label">{{$t('setDefault')}}</label>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <label>{{$t('chooseProvince')}} <span style="color: red">*</span></label>
                                    <select
                                            v-model="address.province_id"
                                            @change="onProvinceChange(address.province_id)"
                                            class="form-control">
                                        <option v-for="province in provinces" v-text="province.name"
                                                :value="province.id"
                                                :key="'province' + province.id"></option>
                                    </select>
                                    <p style="color: red" v-if="errors.province_id" v-text="errors.province_id[0]"></p>
                                </div>
                                <div class="form-group">
                                    <label>{{$t('chooseDistrict')}}<span style="color: red">*</span> </label>
                                    <select
                                            v-model="address.district_id"
                                            @change="onDistrictChange(address.district_id)"
                                            class="form-control">
                                        <option v-for="district in districts" v-text="district.name"
                                                :value="district.id"
                                                :key="'district' + district.id"></option>
                                    </select>
                                    <p style="color: red" v-if="errors.district_id" v-text="errors.district_id[0]"></p>
                                </div>
                                <div class="form-group">
                                    <label>{{$t('chooseDistrict')}}<span style="color: red">*</span> </label>
                                    <select
                                            v-model="address.ward_id"
                                            @change="onWardChange(address.ward_id)"
                                            class="form-control">
                                        <option v-for="ward in wards" v-text="ward.name"
                                                :value="ward.id"
                                                :key="'ward' + ward.id"></option>
                                    </select>
                                    <p style="color: red" v-if="errors.ward_id" v-text="errors.ward_id[0]"></p>
                                </div>
                                <div class="form-group">
                                    <label>{{$t('detailAddress')}}<span style="color: red">*</span></label>
                                    <textarea class="form-control" name="address" v-model="address.address"
                                              :placeholder="$t('detailAddress')"></textarea>
                                    <p style="color: red" v-if="errors.address" v-text="errors.address[0]"></p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="modal-footer">

                        <button type="button" class="btn btn-primary" @click="save">{{$t('save')}}</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="onClose">{{$t('close')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AddressRequest from "@/api/requests/AddressRequest";
import {pluck} from "@/lib/utils";
import $ from 'jquery';
import toastr from 'toastr';

export default {
    name: "AddressFormComponent",
    props: ['id'],
    data() {
        return {
            title: '',
            address: {},
            location: {},
            errors: {},
            districts: [],
            provinces: [],
            wards: [],
            districtMap: [],
            provinceMap: [],
            wardMap: []
        }
    },
    mounted() {
        this.getData();
        this.loadProvinces();
    },
    methods: {
        async save() {
            if (this.checkData(this.address)) {

                const res = await AddressRequest.save(this.address);
                if (res.err_code === 0) {
                    if (this.id) {
                        toastr.success(this.$t('createAddressSuccess'));
                        await this.$parent.getAddress();
                    } else {
                        toastr.success(this.$t('createAddressSuccess'));
                        this.address = {
                            name: '',
                            is_default: false,
                            address: '',
                            district_id: 0,
                            province_id: 0,
                            ward_id: 0
                        };
                        await this.$parent.getAddress();
                    }
                }

                window.$(this.id ? '#addressFormModal_' + this.id : '#addressFormModal').modal('hide');
                this.errors = {}
            }

        },
        checkData(address) {
            this.errors = {
                name: [],
                phone: [],
                address: [],
                province_id: [],
                district_id: [],
                ward_id: []
            };
            if (!address.name) {
                this.errors.name.push('Vui lòng nhập họ và tên');
            }

            if (!address.phone) {
                this.errors.phone.push('Vui lòng nhập số điện thoại');
            } else if (!this.validPhone(address.phone)) {
                this.errors.phone.push('Vui lòng nhập đúng định dạng số điện thoại')
            }
            if (!address.address) {
                this.errors.address.push('Vui lòng nhập địa chỉ nhận hàng');
            }
            if (!address.province_id) {
                this.errors.province_id.push('Vui lòng chọn tỉnh');
            }
            if (!address.district_id) {
                this.errors.province_id.push('Vui lòng chọn quận/huyện');
            }
            if (!address.ward_id) {
                this.errors.ward_id.push('Vui lòng chọn phường/xã');
            }
            return !this.errors.name.length && !this.errors.phone.length && !this.errors.address.length;

        },
        validPhone: function (phone) {
            let rule = /^\d{8,13}$/;
            return rule.test(phone);
        },

        async getData() {
            if (this.id) {
                this.title = "Sửa địa chỉ nhận hàng"
                const res = await AddressRequest.show(this.id);
                this.address = res.address;
                await this.onProvinceChange(this.address.province_id);
                await this.onDistrictChange(this.address.district_id);

            } else {
                this.title = "Thêm địa chỉ nhận hàng"
                this.address = {
                    name: '',
                    is_default: false,
                    address: '',
                    district_id: 0,
                    province_id: 0,
                    ward_id: 0
                }
            }
        },
        async loadProvinces() {
            let localProvinces = localStorage.getItem('provinces');

            if (localProvinces) {
                this.provinces = JSON.parse(localProvinces);
            } else {
                const res = await AddressRequest.getProvinces();
                res.data.provinces.unshift({
                    id: 0,
                    name: 'Chọn Tỉnh'
                });

                this.provinces = res.data.provinces;
                localStorage.setItem('provinces', JSON.stringify(this.provinces));
            }
            this.provinceMap = pluck(this.provinces, 'id', 'name');
        },

        async onProvinceChange(province_id) {
            let localDistricts = localStorage.getItem('province-' + province_id);

            if (localDistricts) {
                this.districts = JSON.parse(localDistricts);
            } else {
                const res = await AddressRequest.getDistricts(province_id)

                res.data.unshift({
                    id: 0,
                    name: 'Chọn Quận/Huyện'
                });
                this.districts = res.data;

                localStorage.setItem('province-' + province_id, JSON.stringify(this.districts));

            }
            this.districtMap = pluck(this.districts, 'id', 'name');

        },
        async onDistrictChange(district_id) {

            let localWards = localStorage.getItem('district-' + district_id);

            if (localWards) {
                this.wards = JSON.parse(localWards);
            } else {
                const res = await AddressRequest.getWards(district_id);

                res.data.unshift({
                    id: 0,
                    name: 'Chọn Phường/Xã'
                });

                this.wards = res.data;
                localStorage.setItem('district-' + district_id, JSON.stringify(this.wards));
            }

            this.wardMap = pluck(this.wards, 'id', 'name');

        },
        async onWardChange(ward_id) {
            this.value.province_name = this.provinceMap[this.value.province_id] || '';
            this.value.district_name = this.districtMap[this.value.district_id] || '';
            this.value.ward_name = this.wardMap[ward_id] || ''

        },
        onClose() {
            if (!this.id) {
                this.address = {
                    name: '',
                    is_default: false,
                    address: '',
                    district_id: 0,
                    province_id: 0,
                    ward_id: 0
                }
            }
        }
    }

}
</script>

<style scoped lang="scss">
.delivery-address {
    padding: 20px 0;
    &:hover {
        color: #ffffff;
        cursor: pointer;
    }

    .btn-add-address {
        display: flex;
        padding: 20px;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        border: 1px dashed #626574;
        box-shadow: 0 8px 32px -4px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        &:hover {
            background: #DB0000;
            cursor: pointer;
            span {
                color: #ffffff;
            }
        }

        span {
            font-family: Inter, sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #5D5FEF;
            padding-left: 10px;
        }
    }

    .add-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        background: #DB0000;
        border-radius: 20px;

        i {
            color: #ffffff;
            font-size: 10px;
        }
    }
}
.address-edit-btn {
    &:hover {
        cursor: pointer;
    }
}
.checkbox-input {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-top: 10px;
}
.checkbox-label {
    font-size: 16px;
}
@media screen and (max-width: 768px) {
    .top-mobile {
        top: 16%;
    }
}
</style>