import BaseRequest from "../BaseRequest";
import {Address, GeneralResponse, PaginateOption} from '../interfaces';
import {GetAllCategoriesResponse} from "@/api/requests/CategoryRequest";

class CrawlRequest extends BaseRequest {
    constructor() {
        super('CrawlRequest');
    }

    async get(id: string, categories: string, keyword: string, property: string, options: PaginateOption): Promise<any> {
        return super.dispatch('get', id, categories, keyword, property, options)
    }

    async getDetail(id: string, check: string): Promise<any> {
        return super.dispatch('getDetail', id, check)
    }

    getCategories(is_b2b: boolean = false): Promise<GetAllCategoriesResponse> {
        return super.dispatch('getCategories', is_b2b);
    }

}

export default new CrawlRequest()
