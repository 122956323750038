<template>
    <div class="container-fluid" id="footer">
        <div class="footer-content">
            <div class="top-content">
                <div class="top-title">
                    {{ $t('footerTitle')}}
                </div>
                <div class="mail-box">
                    <input type="text" name="email" id="" :placeholder="$t('yourEmail')" v-model="email">
                    <button type="button" class="btn-email" @click="sendMail()">{{ $t('dangKy')}}</button>
                </div>
                <div v-if="errors.emailResult">
                    <p class="error">{{ errors.emailResult }}</p>
                </div>
                <div class="note">
	                {{ $t('footerConfirm')}}
                </div>
            </div>
            <div class="main-content">
                <div class="col-item" :key="menu.id" v-for="menu in getOption('menu_footer', [])">
                    <div class="title" v-text="menu.name">
                    </div>
                    <ul>
                        <li :key="index" v-for="(item,index) in menu.items">
                            <a :href="item.url" v-text="item.name"></a>
                        </li>
                    </ul>
                </div>

            </div>
            <div class="bottom-content">
                <div class="left">
                    <div class="address" style="margin-bottom: 12px" v-html="getOption('company_name', 'CÔNG TY CỔ PHẦN CÔNG NGHỆ ELIM')">
                    </div>
                    <div class="address" style="margin-bottom: 12px">
	                    {{ $t('officeAddress')}}: {{getOption('site_address', 'Tầng 21, Tòa nhà Sông Đà, 18 Phạm Hùng, Hà Nội')}}
                    </div>
                    <div class="hotline">
	                    {{ $t('hotline')}}:  {{getOption('site_hotline', '1900-0000')}}
                    </div>
                    <div class="working-time">
                        {{ $t('workingTime')}}
                    </div>
                </div>
                <div class="right">
                    <div class="connect">
                        {{ $t('connectWithYou')}}
                    </div>
                    <div>
                        <a :href="getOption('facebook_url')" target="_blank">
                            <img src="/assets/img/facebook-icon.png" alt="facebook">
                        </a>
                        <a :href="getOption('zalo_url')" target="_blank" style="margin-left: 16px">
                            <img src="/assets/img/zalo-icon.png" alt="zalo">
                        </a>
                    </div>
                </div>
            </div>
            <div style="text-align: center" class="mobile-ccdv">
                <a href="http://online.gov.vn/Home/WebDetails/88676" target="_blank">
                    <img src="/assets/img/logoCCDV.png" width="150" style="background-color: white; padding-right: 6px"/>
                </a>
            </div>
        </div>
        <div class="modal fade" tabindex="-1" role="dialog" ref="SuccessEmailModal" id="SuccessEmailModal">
            <div class="modal-dialog modal-mail-position" role="document">
                <div class="modal-content" style="margin: auto; border-radius: 15px; padding: 25px 15px">
                    <div class="checkout-result">
                        <div class="result-title text-center">
                            <i class="far fa-check-circle" style="color: #27AE60; font-size: 5rem; margin-bottom: 15px"></i>
                            <p class="result-title-success">{{ $t('saveEmailSuccess')}}</p>
                        </div>
                        <div class="result-content text-center">
                            <p style="margin-bottom: 0">
	                            {{ $t('thankForUsing')}}
                            </p>
                            <p>
	                            {{ $t('contactAnswer')}}
<!--                                Mọi thắc mắc vui lòng liên hệ tổng đài-->
<!--                                <a :href="'tel: 096.274.1789'" style="font-weight: 700">096.274.1789</a> để được giải đáp-->
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import EmailRequest from "@/api/requests/EmailRequest";

    export default {
        name: "Footer",
        data() {
            return {
                errors: {
                    emailResult: ''
                },
                email: '',
            }
        },
        methods: {

            async sendMail() {
                if (!this.email) {
                    this.errors.emailResult = 'Vui lòng nhập email';
                    return;
                }

                const res = await EmailRequest.sendMailContact(this.email);

                if (res.err_code !== 0) {
                    this.errors.emailResult = res.message;
                    return;
                }

                if (res.err_code === 0) {
                    this.email = '';
                    this.errors.emailResult= '';
                    window.$('#SuccessEmailModal').modal('show');

                }
            }
        }
    }
</script>

<style scoped lang="scss">
@import "../assets/css/footer";

.modal-mail-position {
  max-width: 40%;
  top: 15%;
}

@media screen and (max-width: 768px) {
  .modal-mail-position {
    max-width: unset;
    top: 20%;
  }
}
</style>
