import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";
import store from "@/store";
import ConfigRequest from "@/api/requests/ConfigRequest";
import { clone, $get, getOption, setJsonData, getJsonData } from "@/lib/utils";
import { createI18n } from 'vue-i18n';
import mitt from 'mitt';
import * as viLang from './locales/vi.json';
import * as enLang from './locales/en.json';
import * as cnLang from './locales/cn.json';
import VueLazyload from 'vue-lazyload';


let jsonData: any = {};
const app: any = createApp(App);

const $eventBus = mitt();
app.config.globalProperties.$eventBus = $eventBus;


// lazy load
app.use(VueLazyload, {
    elements_selector: '.lazyload',
    threshold: 300,
});

app.directive('click-outside', {
    mounted(el: any, binding: any, vnode: any) {
        el.clickOutsideEvent = function (event: any) {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event, el);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el: any) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
});
app.mixin({
    methods: {
        jsonData(key: any = null) {
            return jsonData[key] ? jsonData[key] : jsonData;
        },
        getOption,
        getJsonData,
        n(num: any, n: any, x: any) {
            num = Number(num);

            if (!num) {
                return 0;
            }



            var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
            return num.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
        }
    },
    computed: {
        auth: () => store.state.Auth
    }
});

function loadLocaleMessages() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages: any = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key);
        }
    })
    return messages
}
const i18n = createI18n({
    locale: store.state.locale || 'vi',
    fallbackLocale: 'vi',
    messages: loadLocaleMessages()
});

export default i18n;

if (window.$json) {
    jsonData = window.$json;
    setJsonData(jsonData);
    app.use(router).use(i18n).mount('#app');
} else {
    $get('/configs')
        .then(r => {
            jsonData = r.data;
            window.$json = jsonData;
            setJsonData(jsonData);
            app.use(router).use(i18n).mount('#app');
        }).catch(err => {
            app.use(store);
            app.use(router).use(i18n).mount('#app');
        })
}
