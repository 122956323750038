<template>
    <div class="time-count-down">
        <div class="box-time first" v-if="day > 0">
            <span class="count-number">{{z(day)}}</span>
            <span v-if="showUnit" class="count-unit">{{'Ngày'}}</span>
        </div>
        <div class="box-time">
            <span class="count-number">{{z(hour)}}</span>
            <span v-if="showUnit" class="count-unit">{{'Giờ'}}</span>
        </div>
        <span class="count-space">:</span>
        <div class="box-time">
            <span class="count-number">{{z(minute)}}</span>
            <span v-if="showUnit" class="count-unit">{{'Phút'}}</span>
        </div>
        <span class="count-space">:</span>
        <div class="box-time">
            <span class="count-number">{{z(second)}}</span>
            <span v-if="showUnit" class="count-unit">{{'Giây'}}</span>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: "CountDown",
        data() {
            return {
                day: 0,
                hour: 0,
                minute: 0,
                second: 0
            }
        },
        props: [
            'end',
            'showUnit'
        ],
        methods: {
            z(n) {
                if (!n) {
                    return '00';
                }

                return n < 10 ? '0' + n : n.toString();
            }
        },
        beforeUnmount() {
            clearInterval(this.tid);
        },
        mounted() {

            this.tid = setInterval(() => {
                const diff = Math.max(0, this.end - Date.now());

                if (diff === 0) {
                    clearInterval(this.tid);
                    return;
                }

                let duration = moment.duration(diff);

                this.day = duration.days();
                this.hour = duration.hours();
                this.minute = duration.minutes();
                this.second = duration.seconds();
            });
        }
    }
</script>

<style scoped lang="scss">

    .time-count-down {
        display: flex;
        align-items: flex-start;

        .box-time {
            display: flex;
            flex-direction: column;
            align-items: center;

            &.first {
                margin-right: 15px;
            }
        }

        .count-number {
            font-family: Inter, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #DB0000;
            background: #FFFFFF;
            border-radius: 6px;
            padding: 5px;
            min-width: 40px;
            text-align: center;
        }

        .count-space {
            font-family: Inter, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #FFFFFF;
            padding: 5px;
        }

        .count-unit {
            font-family: Inter, sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #FFFFFF;
            padding-top: 5px;
        }
    }
</style>
