import BaseRequest from "@/api/BaseRequest";
import {Category, Reviewer} from "@/api/interfaces";
import {GetAllCategoriesResponse} from "@/api/requests/CategoryRequest";

export interface GetAllReviewerResponse {
    reviews: Array<Reviewer>
}

class ReviewerRequest extends BaseRequest
{
    constructor() {
        super('ReviewerRequest');
    }

    async add(reviewer: any): Promise<any> {
        return super.dispatch('add', reviewer);
    }

    async getList(productId: number, filter_type: number, page: number): Promise<GetAllReviewerResponse> {
        return super.dispatch('getList', productId, filter_type, page);
    }

    async changeReviewFav(reviewId: number): Promise<any> {
        return super.dispatch('changeReviewFav', reviewId);
    }

    async uploadFile(files: any): Promise<any> {
        return super.upload('https://chat.elim.vn/v1/uploads', files);
    }
}

export default new ReviewerRequest()