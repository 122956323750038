<template>
    <div id="user-profile">
        <div class="wrap-container">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-12">
                    <div class="box-content tab-nav">
                        <div class="tab-user">
                            <img :src="userAvatar" alt="user-avatar">
                            <div class="tab-user--info">
                                <p>{{ $t('account')}}</p>
                                <span>{{name}}</span>
                            </div>
                        </div>
                        <div class="list-nav">
                            <ul class="nav flex-column">
                                <li class="nav-item" v-for="tab in tabs" :key="'nav-item-' + tab.id" @click="onChangeTab(tab)">
                                    <a data-toggle="tab" :href="'#' + tab.id"
                                       :class="tabActive == tab.id ? 'nav-link ' + tab.classIcon + ' active' : 'nav-link ' + tab.classIcon">
	                                    {{ lang == 'cn' ? tab.label_cn : lang == 'en' ? tab.label_en : tab.label }}</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link item-5" @click="logout">{{ $t('signOut')}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-md-9 col-12">
                    <div class="tab-content" id="tab-content">
                        <div v-for="tab in tabs" :key="'tab-content-' + tab.id" :id="tab.id"
                             class="tab-pane" :class="tab.id == tabActive ? 'active' : 'fade'">
                            <component v-bind:is="tab.component"
                                       :countFavorite="countFavoriteProduct" :countSeen="countSeenProduct" :countShopFollow="countShopFollow"
                                       :shop-id="shopId" :data="shopFollow"
                            ></component>
                        </div>
<!--                        <div class="tab-pane fade" id="userVoucher">-->
<!--                            <p>info4</p>-->
<!--                        </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import store from "@/store";
    import ProfileInfo from "@/components/profile/ProfileInfo";
    import OrderList from "@/views/OrderList";
    import TabOrder from "@/views/TabOrder";
    import ChatIndex from "@/components/chat/ChatIndex";
    import ProductRequest from "@/api/requests/ProductRequest";
    import FavoriteProduct from "@/components/profile/FavoriteProduct";
    import SeenProduct from "@/components/profile/SeenProduct";
    import ShopFollow from "@/components/profile/ShopFollow";
    import ShopRequest from "@/api/requests/ShopRequest";
    import {$post} from "@/lib/utils";
    import config from "@/config";

    export default {
        name: "Info",
        props: ['tab', 'shopId', 'shopName', 'shopAvatar'],
        components: {
            FavoriteProduct,
            TabOrder,
            OrderList,
            ProfileInfo,
            ChatIndex,
            SeenProduct,
            ShopFollow
        },
        data() {
            document.title = this.$t('info');
            return {
                name: '',
                currentTab: this.tab || 'userInfo',
                currentShop: this.shop || {},
                countFavoriteProduct: 0,
                countSeenProduct: 0,
                user: store.state.Auth,
                shopFollow: {
                    shops: [],
                    paginate: {
                        currentPage: 1,
                        lastPage: 1
                    }
                },
                countShopFollow: 0,
                tabs: [
                    {
                        id: 'thong-tin',
                        label: 'Thông tin tài khoản',
	                    label_cn: '帐户信息',
	                    label_en: 'Account Information',
                        component: 'ProfileInfo',
                        classIcon: 'item-1',
                    },
                    {
                        id: 'san-pham-yeu-thich',
                        label: 'Sản phẩm yêu thích',
	                    label_cn: '喜爱产品',
	                    label_en: 'Favorite Product',
                        component: 'FavoriteProduct',
                        classIcon: 'item-6'
                    },
                    {
                        id: 'san-pham-da-xem',
                        label: 'Sản phẩm đã xem',
	                    label_cn: '已看产品',
	                    label_en: 'Viewed Products',
                        component: 'SeenProduct',
                        classIcon: 'item-7'
                    },
                    {
                        id: 'tin-nhan',
                        label: 'Tin nhắn',
	                    label_cn: '短信',
	                    label_en: 'Message',
                        component: 'ChatIndex',
                        classIcon: 'item-2'
                    },
                    {
                        id: 'don-hang-cua-toi',
                        label: 'Đơn hàng của tôi',
	                    label_cn: '我的订单',
	                    label_en: 'My Order',
                        component: 'TabOrder',
                        classIcon: 'item-3'
                    },
                    {
                        id: 'shop-theo-doi',
                        label: 'Shop đã theo dõi',
	                    label_cn: '已关注店铺',
	                    label_en: 'Followed Shop',
                        component: 'ShopFollow',
                        classIcon: 'item-8'
                    }
                ],
                tabActive: 'thong-tin',
	            lang: store.state.locale
            }
        },
        computed: {
            userAvatar: function () {
                const auth = store.state.Auth;

                if (auth && auth.user && auth.user.avatar && auth.user.avatar.uri) {
                    return this.auth.user.avatar.uri;
                }

                return '/assets/img/profile/demo_avatar.png';
            }
        },
        mounted() {
            const auth = store.state.Auth;
            if (auth && auth.user && auth.user.name ) {
                this.name = auth.user.name
            }
            // if (this.currentTab == 'userMessage') {
            //     document.getElementById('userMessage').classList.add('active');
            //     document.getElementById('user-message').classList.add('active');
            // } else {
            //     document.getElementById('userInfo').classList.add('active');
            //     document.getElementById('user-info').classList.add('active');
            // }

            const query = this.$route.query;
            if (query.tabMain) {
                this.tabActive = query.tabMain;
            }

            this.getProducts();
            this.getShopFollow();
        },
        methods: {
            async logout() {
                if (confirm('Bạn có muốn đăng xuất không?')) {
                    const res = await $post(config.App.v1LoginLimUrl + '?method=logout', {}, false, {
                        Authorization: store.state.Auth.limIDToken
                    }, true)

                    localStorage.removeItem('current_conversation')
                    store.commit('RemoveAuth');
                    window.location.href = '/';
                    // this.$router.push({name: 'Home'})
                }
            },
            async getProducts() {
                const res = await ProductRequest.getProducts();
                this.countFavoriteProduct = res.fProductCount;
                this.countSeenProduct = res.sProductCount;
            },
            async getShopFollow() {
                const res = await ShopRequest.getShopFollow(this.auth.user.id);
                if (!res.err_code) {
                    this.countShopFollow = res.count;
                    this.shopFollow.shops = res.shops;
                    this.shopFollow.paginate = res.paginate;
                }
            },
            loadShopFollows(data) {
                this.shopFollow.shops = data.shops;
                this.shopFollow.paginate = data.paginate;
            },

            onChangeTab(tab) {
                if (tab.id == 'tin-nhan') {
                    store.commit('SetShowChatBar', false);
                } else {
                    store.commit('SetShowChatBar', true);
                }
                this.tabActive = tab.id;
                const query = {tabMain: tab.id};
                this.$router.replace({query: query});
                if (window.screen.width <= 768 ) {
                    let element = window.$("#tab-content").offset().top;
                    window.scroll({
                        top: element - 150,
                        left: 0,
                        behavior: 'smooth'
                    });
                }


            },
        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/css/userInfo";
    .element-with-the-scrollbar {
      overflow-y: scroll;
      scroll-behavior: smooth;
    }
</style>
