<template>
    <div id="scroll-container">
      <div class="scroll-text">
      <slot/>
      <slot/>

      </div>
       </div>
</template>

<script>
export default {
  name: 'AutoScroll',
  props: {
    loop: {
      default: true,
      type: Boolean
    },
    loopUp: {
      default: false,
      type: Boolean
    },
    slow: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {}
  },

  mounted() {
  },

  methods: {}
}
</script>

<style lang="scss" scoped>
#scroll-container{
  overflow: hidden;
  max-height: 250px;
}
.scroll-text {

}

.scroll-text {

  /* animation properties */
  -moz-transform: translateY(10%);
  -webkit-transform: translateY(10%);
  transform: translateY(10%);


  -moz-animation: my-animation 100s infinite;
  -webkit-animation: my-animation 100s infinite;
  -o-animation: my-animation 100s infinite;
  animation: my-animation 100s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateY(0%);
  }
  to {
    -moz-transform: translateY(-50%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateY(0%);
  }
  to {
    -webkit-transform: translateY(-50%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateY(0%);
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  to {
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
</style>
