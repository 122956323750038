<template>
    <div>
        <div class="container-fluid" id="search-main" style="padding: 0">
            <div class="header">
                <ul class="menu">
                    <li>
                        <router-link to="/">{{ $t('home')}}</router-link>
                    </li>
                    <li><a href="javascript:;"> {{ $t('search')}}</a></li>
                </ul>
            </div>

            <div class="wrap-container">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-12 hide-mobile">
                        <div class="filter-box">
                            <div class="title">
	                            {{ $t('productCategory')}}
                            </div>
                            <ul class="place-filter">
                                <li>
                                    <label class="custom-input-checkbox">{{"Tất cả"}}
                                        <input type="checkbox" @change="chooseCategory('all')" :checked="selectedCategories.length == 0">
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li v-for="(category) in categories" :key="category.id">
                                    <label class="custom-input-checkbox">{{category.name}}
                                        <input type="checkbox" :value="category.id" v-model="selectedCategories" @change="chooseCategory()">
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                            </ul>

                            <div class="title" style="color: #383c4f; margin-top: 32px">
	                            {{ $t('saleAddress')}}
                            </div>

                            <ul class="place-filter" v-for="(province, idx ) in provinces" :key="idx">
                                <li>
                                    <label class="custom-input-checkbox">{{ province.name }}
                                        <input type="checkbox" :value="province.id" v-model="selectedProvinces" @change="chooseProvince()">
                                        <span class="checkmark"></span>
                                    </label>
                                </li>

                            </ul>
                            <a style="cursor: pointer" @click="onPageChangeProvinces()" class="view-more">{{ $t('viewMore')}} <i class="fas fa-chevron-down"></i></a>

                            <div class="title" style="color: #383c4f; margin-top: 32px">
	                            {{ $t('priceMoney')}}
                            </div>

                            <ul id="price-filter">
                                <li>
                                    <label class="custom-input-radio">{{ $t('all')}}
                                        <input type="radio" name="price" checked="checked" v-model="sortPrice" value="1">
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="custom-input-radio">{{ $t('upto500')}}
                                        <input type="radio" name="price" v-model="sortPrice" value="2">
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="custom-input-radio">500.000đ - 1.000.000đ
                                        <input type="radio" name="price" v-model="sortPrice" value="3">
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="custom-input-radio">{{ $t('above1000')}}
                                        <input type="radio" name="price" v-model="sortPrice" value="4">
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li style="display: unset; justify-content: unset">
                                    <div class="price-between">
	                                    {{ $t('between')}}
                                    </div>
                                    <div class="input-box">
                                        <input type="text" name="price_min" placeholder="Từ" v-model="price_min" @blur="sortByPrice()">
                                        <span>-</span>
                                        <input type="text" name="price_max" placeholder="đến" v-model="price_max" @blur="sortByPrice()">
                                    </div>
                                </li>

                            </ul>

<!--                            <div class="title" style="color: #383c4f; margin-top: 32px">-->
<!--                                Sizes-->
<!--                            </div>-->
<!--                            <ul id="size-filter">-->
<!--                                <li class="active"><a href="javascript:;">XS</a></li>-->
<!--                                <li><a href="javascript:;">S</a></li>-->
<!--                                <li><a href="javascript:;">M</a></li>-->
<!--                                <li class="active"><a href="javascript:;">L</a></li>-->
<!--                                <li><a href="javascript:;">XL</a></li>-->
<!--                            </ul>-->

<!--                            <div class="title" style="color: #383c4f; margin-top: 32px">-->
<!--                                Color-->
<!--                            </div>-->
<!--                            <ul id="color-filter">-->
<!--                                <li class="active">-->
<!--                                    <div>-->
<!--                                        <a href="javascript:;" style="background: #F64E60"></a>-->
<!--                                    </div>-->
<!--                                </li>-->
<!--                                <li>-->
<!--                                    <div>-->
<!--                                        <a href="javascript:;" style="background: #3699FF"></a>-->
<!--                                    </div>-->
<!--                                </li>-->
<!--                                <li>-->
<!--                                    <div>-->
<!--                                        <a href="javascript:;" style="background: #1BC5BD"></a>-->
<!--                                    </div>-->
<!--                                </li>-->
<!--                                <li>-->
<!--                                    <div>-->
<!--                                        <a href="javascript:;" style="background: #8950FC"></a>-->
<!--                                    </div>-->
<!--                                </li>-->
<!--                                <li>-->
<!--                                    <div>-->
<!--                                        <a href="javascript:;" style="background: #1B283F"></a>-->
<!--                                    </div>-->
<!--                                </li>-->
<!--                                <li>-->
<!--                                    <div>-->
<!--                                        <a href="javascript:;" style="background: #D6D6E0"></a>-->
<!--                                    </div>-->
<!--                                </li>-->
<!--                            </ul>-->

                            <div style="text-align: center;">
                                <button type="button" class="reset-btn" @click="resetFilter()">{{ $t('reOrder')}}</button>
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-9 col-md-9 col-12">
                        <div class="product-box">
                            <div class="search-box-title">
                                <span class="title">{{ $t('resultForKeyword')}} "<span class="keyword">{{ rKeyword ?? ''}}</span>"</span>
                            </div>
                            <div class="content-box pt-3" v-if="shops.length > 0">
                                <div class="title">
	                                {{ $t('listShop')}}
                                </div>
                                <div class="shop-box">
                                    <div class="row">
                                        <div class="col-xl-4 col-md-4 col-6" v-for="(shop, index) in shops"
                                             :key="index + 1">
                                            <Shop :shop="shop"></Shop>
                                        </div>
                                    </div>
                                    <div class="paginate mt-3">
                                        <Paginate :value="shopPaginate" :pagechange="onShopPageChange"></Paginate>
                                    </div>
                                </div>
                            </div>
                            <div class="list-filter">
                                <span>
                                   {{ $t('orderBy')}}
                                </span>
                                <ul class="nav nav-tabs">
                                    <li class="nav-item show">
                                        <a class="nav-link active"
                                           data-toggle="tab"
                                           href="#new" id="product_new"
                                           @click="sortBy('created_at')"
                                        >{{ $t('newest')}}</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link"
                                           data-toggle="tab"
                                           href="#hotdeal" id="hot_deal"
                                           @click="sortBy('num_sold')"
                                        >{{ $t('bestSeller')}}</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link"
                                           data-toggle="tab" href="#view"
                                           id="hot_view"
                                           @click="sortBy('view_count')"
                                        >{{ $t('viewMany')}}</a>
                                    </li>
<!--                                    <li class="nav-item">-->
<!--                                        <a class="nav-link" data-toggle="tab" href="#increase" id="filter_increase">Tăng dần</a>-->
<!--                                    </li>-->
<!--                                    <li class="nav-item">-->
<!--                                        <a class="nav-link" data-toggle="tab" href="#decrease" id="filter_decrease">Giảm dần</a>-->
<!--                                    </li>-->
                                </ul>
                            </div>
                            <div class="tab-content">
                                <div id="new" class="tab-pane fade show active">
                                    <div class="row" v-if="products.length > 0">
                                        <div class="col-xl-3 col-md-4 col-sm-4 col-6" v-for="(product, index) in products"
                                             :key="index + 1">
                                            <product-item :product="product"></product-item>
                                        </div>

                                        <div class="col-12 mt-3">
                                            <div class="paginate">
                                                <Paginate :value="productPaginate" :pagechange="onProductPageChange"></Paginate>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else-if="!isLoading && products.length === 0" style="margin-left: 10px">
                                        <p>{{ $t('notFoundProduct')}}</p>
                                    </div>
<!--                                    <div class="paginate">-->
<!--                                        <nav aria-label="Page navigation">-->
<!--                                            <ul class="pagination justify-content-center">-->
<!--                                                <li class="page-item"><a class="page-link" href="javascript:;"><img src="/assets/img/category/arrow-left.png" alt=""></a></li>-->
<!--                                                <li class="page-item"><a class="page-link" href="javascript:;">1</a></li>-->
<!--                                                <li class="page-item"><a class="page-link" href="javascript:;">2</a></li>-->
<!--                                                <li class="page-item"><a class="page-link" href="javascript:;">3</a></li>-->
<!--                                                <li class="page-item"><a class="page-link" href="javascript:;">4</a></li>-->
<!--                                                <li class="page-item"><a class="page-link" href="javascript:;">5</a></li>-->
<!--                                                <li class="page-item"><a class="page-link" href="javascript:;"><img src="/assets/img/category/arrow-right.png" alt=""></a></li>-->
<!--                                            </ul>-->
<!--                                        </nav>-->
<!--                                    </div>-->

                                </div>
                                <div id="hotdeal" class="tab-pane">
                                    <div class="row" v-if="products.length > 0">
                                        <div class="col-xl-3  col-md-4 col-sm-4 col-6" v-for="(product, index) in products"
                                             :key="index + 1">
                                            <product-item :product="product" :show-detail="true"></product-item>
                                        </div>
                                        <div class="col-12 mt-3">
                                            <div class="paginate">
                                                <Paginate :value="productPaginate" :pagechange="onProductPageChange"></Paginate>
                                            </div>

                                        </div>

                                    </div>
                                    <div v-else-if="!isLoading && products.length === 0" style="margin-left: 10px">
                                        <p>{{ $t('notFoundProduct')}}</p>
                                    </div>
                                </div>
                                <div id="view" class="tab-pane">
                                    <div class="row" v-if="products.length > 0">
                                        <div class="col-xl-3 col-md-4 col-sm-4 col-6" v-for="(product, index) in products"
                                             :key="index + 1">
                                            <product-item :product="product" :show-detail="true"></product-item>
                                        </div>
                                        <div class="col-12 mt-3">
                                            <div class="paginate">
                                                <Paginate :value="productPaginate" :pagechange="onProductPageChange"></Paginate>
                                            </div>

                                        </div>
                                    </div>
                                    <div v-else-if="!isLoading && products.length === 0" style="margin-left: 10px">
                                        <p>{{ $t('notFoundProduct')}}</p>
                                    </div>
                                </div>
<!--                                <div id="increase" class="tab-pane">-->
<!--                                    <div class="row">-->
<!--                                        <div class="col-xl-3 col-sm-4" v-for="(product, index) in 10"-->
<!--                                             :key="index + 1">-->
<!--                                            <product-item :product="product" :show-detail="true"></product-item>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div id="decrease" class="tab-pane">-->
<!--                                    <div class="row">-->
<!--                                        <div class="col-xl-3 col-sm-4" v-for="(product, index) in 3"-->
<!--                                             :key="index + 1">-->
<!--                                            <product-item :product="product" :show-detail="true"></product-item>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import HomeRequest from "@/api/requests/HomeRequest";
    import ProductItem from "@/components/ProductItem";
    import SearchRequest from "@/api/requests/SearchRequest";
    import CategoryRequest from "@/api/requests/CategoryRequest";
    import Shop from "@/components/shop/Shop";
    import {getSearchQuery} from "@/lib/utils";
    import Paginate from "@/components/Paginate";
    import i18n from "@/main";

    export default {
        name: "Search.vue",
        components: {ProductItem, Shop, Paginate},
        data() {
            const query = getSearchQuery();

            return {
                rKeyword: query.keyword || '',
                isLoading: false,
                products: [],
                shops: [],
                campaigns: [],
                categories: [],
                filters: {
                    order: 'created_at',
                },
                sortPrice: 1,
                price_min: 0,
                price_max: 0,
                selectedProvinces: [],
                provinces: [],
                pageProvince: 1,
                selectedCategories: [],
                hasNextPage: false,
                paginate: {
                    page: 1,
                    shopPage: 1,
                },
                shopPaginate: {
                    currentShopPage: 1,
                    lastShopPage: 1
                },
                shopHasNextPage: false,
                productPaginate: {
                    currentShopPage: 1,
                    lastShopPage: 1
                },
            }
        },
        mounted() {
            window.scrollTo(0, 0);
            this.getData();
            // this.keyword = this.$route.query.keyword || '';
            document.title = this.$t('searchProduct');
            this.load();
        },

        methods: {
            async getData() {
                const res = await CategoryRequest.getAllCategories();

                if (res.categories) {
                    this.categories = res.categories;
                }

                const response = await CategoryRequest.getProvinces();
                if (response.provinces) {
                    this.provinces = response.provinces;
                }
            },
            async onPageChangeProvinces(){
                let page = this.pageProvince + 1;
                const response = await CategoryRequest.getProvinces(page);
                if (response.provinces) {
                    this.pageProvince = page;
                    this.provinces = this.provinces.concat(response.provinces);
                }
            },

            chooseCategory(type) {
                this.paginate.page = 1;

                if (type) {
                    this.selectedCategories = [];
                }

                this.load();
            },

            chooseProvince() {
                this.paginate.page = 1;
                this.load();
            },

            async load() {
                this.isLoading = true;
                let option = {
                    sortPrice: {
                        price_min: parseInt(this.price_min),
                        price_max: parseInt(this.price_max)
                    },
                    provinces: this.selectedProvinces,
                    order: this.filters.order,
                    category_ids: this.selectedCategories,
                    ...this.paginate
                }

                let keyword = '';

                if (this.rKeyword) {
                    keyword = this.rKeyword.replace('+', ' ');
                }

                const res = await SearchRequest.search(keyword, option);
                this.products = res.products;
                this.shops = res.shops;
                //this.rKeyword = this.keyword;
                this.hasNextPage = res.hasNextPage;
                this.shopHasNextPage = res.shopHasNextPage;
                this.shopPaginate = res.shopPaginate;
                this.paginate.page = res.page;
                this.paginate.shopPage = res.shopPage;
                this.productPaginate = res.productPaginate;

                this.isLoading = false;
            },
            onShopPageChange(page) {
                this.paginate.shopPage = page;
                this.load();
            },
            onProductPageChange(page) {
                this.paginate.page = page;
                this.load();
            },

            sortBy(col) {
                this.filters.order = col;
                this.load();
            },

            sortByPrice() {
                this.load();
            },

            resetFilter() {
                this.paginate.page = 1;
                this.paginate.shopPage = 1;
                this.selectedProvinces = [];
                this.selectedCategories = [];
                this.sortPrice = 1;
                this.price_min = 0;
                this.price_max = 0;
                this.load();
            },
        },
        watch: {
            '$route.query.keyword': function (newValue) {
                this.paginate.page = 1;
                this.paginate.shopPage = 1;
                this.rKeyword = newValue;
                this.load();
            },
            sortPrice: function(newval) {
                switch (newval) {
                    case '1':
                        this.price_min = 0;
                        this.price_max = 0;
                        break;
                    case '2':
                        this.price_min = 0;
                        this.price_max = 500000;
                        break;
                    case '3':
                        this.price_min = 500000;
                        this.price_max = 1000000;
                        break;
                    case '4':
                        this.price_min = 1000000;
                        this.price_max = 0;
                        break;
                    default:
                        this.price_min = 0;
                        this.price_max = 0;
                }
                this.paginate.page = 1;
                this.load();
            }
        }
    }
</script>

<style scoped lang="scss">
@import "../assets/css/search";
@media screen and (max-width: 768px){
  .hide-mobile {
    display: none;
  }
}
</style>
