<template>
    <div id="shop-detail">
        <router-link :to="'/cua-hang/'+ shop.slug">
            <div class="shop-avatar text-center">
                <img :src="shop.avatar" :alt="shop.name">
                <p class="pt-1 font-weight-bold">{{shop.name}}</p>
            </div>
        </router-link>
        <div class="shop-info">
            <div class="item">
                <div class="title border-0 p-0">
                    {{ $t('follower')}}
                </div>
                <div class="value text-center">
                    {{shop.users_count}}
                </div>
            </div>
            <div class="item">
                <div class="title border-0 p-0">
	                {{ $t('product')}}
                </div>
                <div class="value text-center">
                    {{shop.products_count}}
                </div>
            </div>
            <div class="item">
                <div class="title border-0 p-0">
	                {{ $t('review')}}
                </div>
                <div class="value text-center">

                </div>
            </div>
        </div>

    </div>
</template>

<script>

import {isEmpty} from "@/lib/utils";

export default {
    name: "Shop",
    props: ['shop'],
    components: {},
    data() {
        return {
            isFollowed: false,
        }

    },
    mounted() {
    },
    methods: {

    }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/shop";
</style>
