<template>
    <div id="contents">
        <div class="login-screen">
            <div class="login-header">
                <router-link class="header-logo" :to="{name: 'Home'}">
                    <img src="/assets/img/header/logo.svg" alt="">
                </router-link>
            </div>
            <div class="login-body" :style="{background: background}">
                <form class="form-wrap-login" v-if="!isVerifyReset && !showVerifyReset && !isVerifyReset" style="height: 300px">
                    <div class="login-title">
                        <label>{{ $t('resetAccount')}}</label>
                    </div>
                    <div class="form-group has-float-label">
                        <input v-model="phone" name="phone" type="text" id="phone-number" :placeholder="$t('phoneNumber')"
                               class="form-control input-error wrap-input no-focus">
                        <label for="phone-number" class="label-input">{{ $t('phoneNumber')}}</label>
                    </div>
                    <div style="text-align: center">
                        <loading v-if="isLoading" :is-loading="true"></loading>
                    </div>
                    <div class="form-group d-flex-center" v-if="errors.loginResult">
                        <span class="error">{{ errors.loginResult }}</span>
                    </div>
                    <div class="d-flex pb-3 login-option">
                        <a class="type-login" href="/dang-nhap" style="margin-left: auto">{{ $t('signInByPassword')}}</a>
                    </div>
                    <div class="form-group">
                        <button @click.prevent="doResetStep($event)" class="btn btn-login">{{ $t('continue')}}</button>
                    </div>
                </form>
                <form class="form-wrap-register" v-if="showVerifyReset && !successReset">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link"
                               href="javascript:;"
                               :class="{active: showVerifyReset}">
                                <div class="nav-display">
                                    <div class="tab-index" v-if="!isVerifyReset">1</div>
                                    <div class="tab-index-verified" v-if="isVerifyReset"><i class="fas fa-check"></i></div>
                                    <div class="tab-name">{{ $t('phoneRegister')}}</div>
                                </div>

                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"
                               href="javascript:;"
                               :class="{active: showVerifyReset && isVerifyReset}">
                                <div class="nav-display">
                                    <div class="tab-index">2</div>
                                    <div class="tab-name">{{ $t('continue')}}Thiết lập mật khẩu</div>
                                </div>

                            </a>
                        </li>
                    </ul>
                    <div class="row mt-3">
                        <form class="form-wrap-login" style="box-shadow: unset" v-if="!isVerifyReset" @submit.prevent="verifyPhoneResetToken()">
                            <div class="login-title">
                                <label>{{ $t('continue')}}Nhập mã xác minh</label>
                            </div>
                            <div class="title-verify">
                                <p v-text="'Để xác minh số điện thoại là của bạn, vui lòng nhập mã gồm 6 chữ số vừa được gửi đến ' + replacePhone(phone)">
                                </p>

                            </div>
                            <div id="otp" class="inputs d-flex flex-row justify-content-center input-verify">
                                <input class="m-2 text-center form-control" type="text" id="box_1" maxlength="1"
                                       v-model="code_1" @keyup="onKeyUpEvent(1, $event)" @focus="onFocusEvent(1)"/>
                                <input class="m-2 text-center form-control" type="text" id="box_2" maxlength="1"
                                       v-model="code_2" @keyup="onKeyUpEvent(2, $event)" @focus="onFocusEvent(2)"/>
                                <input class="m-2 text-center form-control" type="text" id="box_3" maxlength="1"
                                       v-model="code_3" @keyup="onKeyUpEvent(3, $event)" @focus="onFocusEvent(3)"/>
                                <input class="m-2 text-center form-control" type="text" id="box_4" maxlength="1"
                                       v-model="code_4" @keyup="onKeyUpEvent(4, $event)" @focus="onFocusEvent(4)"/>
                                <input class="m-2 text-center form-control" type="text" id="box_5" maxlength="1"
                                       v-model="code_5" @keyup="onKeyUpEvent(5, $event)" @focus="onFocusEvent(5)"/>
                                <input class="m-2 text-center form-control" type="text" id="box_6" maxlength="1"
                                       v-model="code_6" @keyup="onKeyUpEvent(6, $event)" @focus="onFocusEvent(6)"/>
                            </div>
                            <div class="form-group d-flex-center" v-if="errors.loginResult">
                                <span class="error">{{ errors.loginResult }}</span>
                            </div>
                            <div class="form-group">
                                <button type="submit" class="btn btn-login">{{ $t('verify')}}</button>
                            </div>

                            <div class="title-verify">
                                <p style="margin-bottom: 0">{{ $t('continue')}}Bạn không nhận được mã xác minh?
                                    <a href="javascript:;" @click="checkPhoneReset" class="font-weight-bold" style="color: #db0000">&nbsp;Gửi
                                        lại
                                    </a>
                                </p>
                                <p>{{ $t('continue')}}Mã xác minh có hiệu lực trong 15 phút</p>
                            </div>
                        </form>
                        <form class="form-wrap-login" style="box-shadow: unset; padding: 30px 0" v-if="isVerifyReset" @submit="resetPassword($event)">
                            <div class="login-title">
                                <label>{{ $t('continue')}}Thiết lập mật khẩu</label>
                            </div>
                            <div class="title-verify">
                                <p v-text="$t('continue') + 'Tạo mật khẩu mới cho (+84)' + replaceCenterPhone(phone)">
                                </p>

                            </div>

                            <div class="form-group row">
                                <label for="password" class="col-sm-4 col-form-label label-new-input">{{ $t('password')}}</label>
                                <div class="col-sm-8">
                                    <input v-model="newPassword" name="password" :type="hideNewPass ? 'password' : 'text'"
                                           id="password"
                                           :placeholder="$t('password')"
                                           class="form-control input-error wrap-input input-custom"/>
                                    <span class="toggle-new-password" @click="hideNewPass = !hideNewPass">
                    <i class="fa" :class="hideNewPass ? 'fa-eye' : 'fa-eye-slash'"></i>
                </span>
                                </div>

                            </div>
                            <div class="form-group row mb-4">
                                <label for="confirmPassword" class="col-sm-4 col-form-label label-new-input">
	                                {{ $t('reTypePassword')}}</label>
                                <div class="col-sm-8">
                                    <input v-model="confirmNewPassword" name="password"
                                           :type="hideConfirmNewPass ? 'password' : 'text'" id="confirmPassword"
                                           :placeholder="$t('reTypePassword')"
                                           class="form-control input-error wrap-input input-custom"/>
                                    <span class="toggle-new-password" @click="hideConfirmNewPass = !hideConfirmNewPass">
                    <i class="fa" :class="hideConfirmNewPass ? 'fa-eye' : 'fa-eye-slash'"></i>
                </span>
                                </div>

                            </div>
                            <div class="form-group d-flex-center mb-2" v-if="errors.loginResult">
                                <span class="error">{{ errors.loginResult }}</span>
                            </div>
                            <div class="form-group row">
                                <button type="button" @click="backAction()" class="btn btn-back" style="width: 45%; margin-right: auto">{{ $t('back')}}</button>
                                <button type="submit" class="btn btn-login" style="width: 45%">{{ $t('continue')}}c</button>
                            </div>
                        </form>
                    </div>
                </form>
                <form class="form-wrap-register" v-if="successReset">
                    <div class="row mt-3">
                        <form class="form-wrap-login" style="box-shadow: unset; padding: 30px 10px" v-if="isVerifyReset" @submit="resetPassword($event)">
                            <div class="login-title">
                                <label>{{ $t('resetPasswordSuccess')}}</label>
                            </div>
                            <div class="title-verify mb-3" style="text-align: center">
                                <i class="far fa-check-circle" style="font-size:6rem; color: #27AE60;"></i>
                            </div>

                            <div class="title-verify">
                                <p >
	                                {{ $t('resetPasswordSuccessByPhone')}} <span style="font-weight: bold" v-text="' (+84) ' + replaceCenterPhone(phone)" ></span>
                                </p>
                                <p> {{ $t('redirectToLogin')}}</p>

                            </div>
                            <div class="form-group row">
                                <a type="button" href="/dang-nhap" class="btn btn-login">OK</a>
                            </div>
                        </form>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import AuthRequest from "@/api/requests/AuthRequest";
import {getOption} from "@/lib/utils";
import PasswordRequest from "@/api/requests/PasswordRequest";
import Loading from "@/includes/Loading";
import i18n from "@/main";

export default {
    name: "ResetPassword",
    data() {
        const banner = getOption('banner_login_page', {});

        return {
            background: `url("${banner ? banner.uri : ''}") no-repeat fixed`,
            errors: {
                username: '',
                password: '',
                loginResult: ''
            },
            phone: '',
            confirmPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            verifyPhoneResetCode: '',
            showVerifyReset: false,
            sentOtpResetPass: false,
            hideNewPass: true,
            isLoading: false,
            hideConfirmNewPass: true,
            showVerifyPhone: false,
            isVerifyReset: false,
            successReset: false,
            code_1: '',
            code_2: '',
            code_3: '',
            code_4: '',
            code_5: '',
            code_6: '',
        }
    },
    components: {
        Loading
    },
    mounted() {
        document.title = this.$t('resetElimAcc');
    },
    methods: {

        doResetStep(ev) {
            if (this.showVerifyReset) {
                this.verifyPhoneResetToken();
            } else {
                this.checkPhoneReset(ev);
            }
        },
        async checkPhoneReset(ev) {
            if (this.sentOtpResetPass) {
                return false;
            }
            this.sentOtpResetPass = true;

            ev.preventDefault();
            if (!this.phone) {
                this.errors.loginResult = this.$t('phoneRequired');
                return;
            }
            if (this.phone) {
                const isPhone = /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/g.test(this.phone);
                if (!isPhone) {
                    this.errors.loginResult = this.$t('phoneFormat');
                    return;
                }
            }
            this.isLoading = true;
            const res = await AuthRequest.checkPhoneReset(this.phone);

            this.isLoading = false;
            if (res.err_code === 0) {
                this.showVerifyReset = true;
                this.sentOtpResetPass = false;
                this.errors.loginResult = '';
            } else {
                this.errors.loginResult = res.message;
                return;
            }
        },
        async verifyPhoneResetToken() {
            if (!this.code_1 || !this.code_2 || !this.code_3 || !this.code_4 || !this.code_5 || !this.code_6) {
                this.errors.loginResult = this.$t('enterVerifyToContinue');
                return;
            }

            this.verifyPhoneResetCode = this.code_1 + this.code_2 + this.code_3 + this.code_4 + this.code_5 + this.code_6;
            const res = await AuthRequest.verifyPhone(this.phone, this.verifyPhoneResetCode);
            if (res.err_code === 0) {
                this.isVerifyReset = true;
                this.errors.loginResult = '';
            } else {
                this.errors.loginResult = res.message;
            }
        },
        async resetPassword(ev) {
            ev.preventDefault();
            if (!this.newPassword) {
                this.errors.loginResult = this.$t('enterNewPassword');
                return;
            }

            if (!this.confirmNewPassword) {
                this.errors.loginResult = this.$t('confirmNewPassword');
                return;
            }

            if (this.newPassword !== this.confirmNewPassword) {
                this.errors.loginResult = this.$t('passwordNotMatch');
                return;
            }

            const res = await PasswordRequest.resetPassword(this.verifyPhoneResetCode, this.phone, this.newPassword);
            if (res.err_code !== 0) {
                this.errors.loginResult = res.message;
                return;
            }

            this.errors.loginResult = '';
            this.successReset = true;

            setTimeout(function() {
                location.href = '/dang-nhap';
            }, 2000);

        },
        replacePhone(phone) {
            let phoneLength = phone.length;
            return phone.replace(phone.substring(4,phoneLength), 'xxxxxx');
        },
        replaceCenterPhone(phone) {
            let phoneLength = phone.length;
            return phone.replace(phone.substring(0,phoneLength - 2), '*******');
        },
        getCodeBoxElement(index) {
            return document.getElementById('box_' + index);
        },
        onKeyUpEvent(index, event) {
            const eventCode = event.which || event.keyCode;
            if (this.getCodeBoxElement(index).value.length === 1) {
                if (index !== 6) {
                    this.getCodeBoxElement(index+ 1).focus();
                } else {
                    this.getCodeBoxElement(index).blur();
                }
            }
            if (eventCode === 8 && index !== 1) {
                this.getCodeBoxElement(index - 1).focus();
            }
        },
        onFocusEvent(index) {
            for (let item = 1; item < index; item++) {
                const currentElement = this.getCodeBoxElement(item);
                if (!currentElement.value) {
                    currentElement.focus();
                    break;
                }
            }
        },
        backAction() {
            this.isVerifyReset = false;
            this.verifyPhoneResetCode = '';
        }
    },

}
</script>
<style scoped lang="scss">
.nav-display {
  display: flex;
  .tab-name {
    margin: auto 5px;
  }

}
.form-wrap-login {
  height: 470px!important;
}
@media screen and (max-width: 768px){
  .nav-display {
    display: block;
    .tab-index {
      width: fit-content;
      margin: auto!important;
    }
    .tab-index-verified {
      width: fit-content;
      margin: auto!important;
    }
  }
  .form-wrap-register {
    height: auto!important;
    padding: 30px 15px!important;
    .form-wrap-login {
      padding: 30px 5px!important;
    }
  }

}

</style>