<template>
    <div class="layout-detai-category">
        <div class="row p-3">
            <div class="col-2 category">
                <ul>
                    <li class="font-weight-bold" style="border-bottom: none">{{ $t('industryYarbook') }}</li>

                    <li v-for="item in sectorYearbooks " :key="item.id">
                        <a :href="'/muc-luc/nganh-nghe/' + item.id" :style="item.id == id ? 'color: #DB0000;' : ''">
                            {{ item.name }}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-10 detail">
                <template v-if="isLoading">
                    <div class="d-flex align-items-center justify-content-center mt-4 mb-4" style="width: 100%">
                        <i class="fa fa-spinner fa-spin fa-3x elim-loading"></i>
                    </div>
                </template>

                <template v-else>
                    <div class="title">
                        {{ detailCategory.name }}
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="title-focus">
                                <span>{{ $t('professionForcccus') }}</span>
                            </div>
                            <ul class="row p-2 category-focus">
                                <li class="col-6" v-for="focus in detailCategory.sector_yearbook_categories"
                                    :key="focus.id">
                                    <template v-if="focus.categories">
                                        <i class="fa fa-circle mr-2"
                                            style="font-size: 7px; color: #dc3545; vertical-align: middle;"></i>
                                        <a :href="'/nganh-nghe/' + focus.categories.id">
                                            {{ focus.categories.name }}
                                            <span style="color: #8e8d8d; font-size: 13px;">
                                                ({{ focus.categories.company_count }})
                                            </span>
                                        </a>
                                    </template>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card mt-3">
                        <div class="card-body">
                            <div class="title-focus">
                                <span>{{ $t('quick') }}</span>
                            </div>
                            <div class="category-quick mt-3 mb-3">
                                <a v-for="(quick, index) in quicks" href="javascript:void(0)"
                                    @click="onClickCategoryGroup('item-' + index)" :key="quick.group">
                                    {{ quick.group }}
                                    <span style="color: red; padding: 5px;">|</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card" v-for="(quick, index) in quicks" :key="quick.id" :id="quick.id">
                        <div class="card-body row" :id="'item-' + index">
                            <div class="col-4" style="height:auto; padding-left:33px; padding-right:13px">
                                <div style="height:25px; margin-bottom:3px; margin-top:11px">
                                    <p class="title-quicks">{{ quick.group }}</p>
                                </div>
                                <div style="height:auto; margin-bottom:10px">
                                    <div style="height:138px; width:108px; float:left">
                                        <img style="max-width:123px; max-height:123px; padding-top:20px;"
                                        v-lazy="quick.image">
                                    </div>
                                </div>
                            </div>
                            <div class="col-8">
                                <div class="row">
                                    <div class="col-6 mt-2" v-for="item in quick.categories" :key="item.id">
                                        <div class="title-child">
                                            <a :href="'/nganh-nghe/' + item.id" style="color: #0033FF;">
                                                <i class="fa fa-circle mr-2"
                                                    style="font-size: 7px; color: #dc3545; vertical-align: middle;"></i>
                                                {{ item.name }}
                                                <span style="color: #8e8d8d; font-size: 13px;">
                                                    ({{ item.company_count }})
                                                </span>
                                            </a>
                                        </div>
                                        <div class="child ml-2">
                                            <ul>
                                                <li v-for="child in item.children" :key="child.id" class="ml-4">
                                                    <a :href="'/nganh-nghe/' + child.id">
                                                        {{ child.name }}
                                                        <span style="color: #8e8d8d; font-size: 13px;">
                                                            ({{ child.company_count }})
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>

import SectorYearBookRequest from '@/api/requests/SectorYearBookRequest'
import $ from 'jquery'
export default {
    name: "DetailCategories",
    props: ['id'],
    data() {
        return {
            isLoading: true,
            sectorYearbooks: [],
            detailCategory: {},
            quicks: []
        }
    },
    created() {
        this.getData()
        this.detailCategories()
    },
    methods: {
        async getData() {
            const res = await SectorYearBookRequest.getAllSectorYearBook();
            if (res.code === 200) {
                this.sectorYearbooks = res.sectorYearbooks
            }
        },
        async detailCategories() {
            const res = await SectorYearBookRequest.detailCategories(this.id)
            if (res.code === 200) {
                this.detailCategory = res.detailCategory
                this.quicks = res.quick
                document.title = res.title
                this.isLoading = false
                console.log(this.detailCategory);
            }
        },
        onClickCategoryGroup(id) {
            $('html, body').animate({
                scrollTop: $('#' + id).offset().top - 155
            }, 'slow');
        }
    }
}
</script>

<style lang="scss" scoped>
.layout-detai-category {
    .category {

        // border-top: 2px solid red;
        & ul {
            border-top-color: #DB0000;
            border-top-style: solid;
        }

        li {
            border-bottom: 1px solid #ccc;
            padding: 12px 0px 5px 0px;
        }
    }

    .detail {
        top: -16px !important;
        height: auto;

        .title {
            color: #DB0000;
            font-size: 20px;
            height: 36px;
        }

        .title-child {
            color: rgb(0, 51, 255);
            font-size: 15px;
        }

        .title-focus {
            border-bottom: 1px solid #ccc;
            background-image: linear-gradient(#e8e7f5, #ffffff);
            padding: 10px;

            span {
                margin-bottom: 6px;
                font-size: 16px;
                font-weight: 700;
                color: #333333;
            }
        }

        .category-quick {
            padding: 0px 2px 4px 24px;

            a:hover {
                text-decoration: underline;
            }
        }

        .category-focus {
            img {
                padding: 9px 21px;
            }
        }

        .card-body {
            padding: 0 !important;
        }

        .child {
            li {
                list-style-type: square;
                font-size: 12px;
            }

            li a:hover {
                text-decoration: underline;
            }
        }

        .title-quicks {
            width: 210px;
            font-size: 13px;
            font-weight: 700;
            color: #333333;
        }
    }
}
</style>