import BaseRequest from "../BaseRequest";
import {Product, Campaign, Cart, CartItem, FeeData, GeneralResponse} from '../interfaces';




export interface UpdateQuantityResponse {
    err_code: number,
    message: string,
    amount: number,
    amount_origin: number,
    price: number,
    price_origin: number
}

class CartRequest extends BaseRequest {
    constructor() {
        super('CartRequest');
    }

    async get(type:number): Promise<any> {
        return super.dispatch('get', type);
    }

    async add(item: CartItem): Promise<any> {
        return super.dispatch('add', item);
    }

    async remove(cartItemId: number, type: number): Promise<GeneralResponse> {
        return super.dispatch('remove', cartItemId, type);
    }

    async updateQuantity(cartItemId: number, quantity: number, type: number): Promise<UpdateQuantityResponse> {
        return super.dispatch('updateQuantity', cartItemId, quantity, type);
    }

    async applyVoucher(code: any): Promise<GeneralResponse> {
        return super.dispatch('applyVoucher', code)
    }

    async updateItems(items: any, type:number): Promise<any> {
        return super.dispatch('updateItems', items, type)
    }

    async amount(): Promise<GeneralResponse> {
        return super.dispatch('amount');
    }
}

export default new CartRequest()
