<template>
  <div
    class="top-campaign-item"
    :class="{ left: index % 2 === 0, right: index % 2 === 1 }"
  >
    <div class="campaign-header">
      <img src="/assets/img/header/ic_stock_grow.svg" alt="" />
      <!--                <img src="/assets/img/header/ic_new-delivery.svg" alt="">-->
      <h3
        v-text="campaign['title_' + Language] || campaign.title"
      ></h3>
      <router-link
        :to="{
          name: 'CampaignDetail',
          params: {
            slug: campaign.slug,
            id: campaign.id,
            name: campaign.title,
          },
        }"
      >
        {{ $t("viewAll") }}
      </router-link>
    </div>

    <div class="container-fluid campaign-content">
      <div class="row list-items">
        <div
          class="col-lg-4 col-md-4 col-6 padding-mobile"
          v-for="product in campaign.products"
          :key="product.id"
        >
          <product-item :product="product"></product-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductItem from "@/components/ProductItem";
import CountDown from "@/components/CountDown";
import store from "@/store";
export default {
  name: "SpecialCampaign",
  components: { CountDown, ProductItem },
  props: ["campaign", "index"],
  data() {
    return {
      Language: store.state.locale,
    };
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/css/topCampaign";
@import "../../assets/css/productItem";

.video-info {
  background: rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  position: absolute;
  padding: 2px 5px;
  top: 15px;
  left: 15px;
  z-index: 5;

  span {
    padding-left: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
  }
}

.video-play-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  z-index: 5;
  background: rgba(0, 0, 0, 0.38);
  border: 1px solid #ffffff;
  border-radius: 20px;

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
}
</style>
