<template >
  <div class="header">
    <router-link class="txt-breadcrum" to="/">Trang chủ</router-link>
    <ul v-for="(crumb, index) in routers" :key="index" class="menu">
      <li>
        >
        <router-link class="txt-breadcrum" :to="crumb.to">{{
          crumb.name
        }}</router-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: ["routers"],
  // computed: {
  //   crumbs: function () {
  //     let pathArray = this.$route.path.split("/");
  //     pathArray.shift();
  //     let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
  //       if (path)
  //         breadcrumbArray.push({
  //           path: path,
  //           to: breadcrumbArray[idx - 1]
  //             ? "/" + breadcrumbArray[idx - 1].path + "/" + path
  //             : "/" + path,
  //           name:
  //             this.$route.matched[idx]?.meta?.isSlug && this.detail.name
  //               ? this.detail.name
  //               : this.$route.matched[idx]?.meta?.breadCrumb || path,
  //         });
  //       return breadcrumbArray;
  //     }, []);

  //     console.log("breadcrumbs: ", breadcrumbs);
  //     return breadcrumbs;
  //   },
  // },
};
</script>
<style lang="scss">
.header {
  box-sizing: content-box;
  margin: 0 auto;
  max-width: 1450px;
  min-width: 720px;
  padding: 12px 30px 12px 30px;
  display: flex;
  background: #ffffff;
  align-items: center;
  /* border-top: 1px solid #dadbde;
  border-bottom: 1px solid #dadbde; */
  .txt-breadcrum {
    padding: 0 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #626574;
    &:hover {
      color: #eb592d;
    }
  }
  .menu {
    display: flex;
  }
  @media screen and (max-width: 768px) {
    min-width: unset;
  }
}
</style>