import BaseRequest from "../BaseRequest";

class CompanyCategoriesRequest extends BaseRequest {
    constructor() {
        super('CompanyCategoriesRequest');
    }
    async getCompanyCategories(id: string, page: string) {
        return super.dispatch('getCompanyCategories', id, page);
    }
    async getAllComapny(id: string, page: string) {
        return super.dispatch('getAllComapny', id, page);
    }
    async showProduct(id: string) {
        return super.dispatch('showProduct', id );
    }
}

export default new CompanyCategoriesRequest()
