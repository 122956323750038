<template>
    <div class="row">
        <div class="col-8" v-if="!isForgot">
            <div class="form-group row" style="position: relative" v-if="!isVerify">
                <label for="f_old_password" class="margin-tb-auto col-sm-3">{{ $t('oldPassword')}}</label>
                <input :type="hideOldPass ? 'password' : 'text'" id="f_old_password" name="old_password"
                       class="col-sm-9 form-control" v-model="oldPassword">
                <span class="toggle-password" @click="hideOldPass = !hideOldPass">
                <i class="fa" :class="hideOldPass ? 'fa-eye' : 'fa-eye-slash'"></i>
            </span>
            </div>
            <div class="form-group row" style="position: relative">
                <label for="f_password" class="margin-tb-auto col-sm-3">{{ $t('newPassword')}}</label>
                <input :type="hidePass ? 'password' : 'text'" id="f_password" name="password"
                       class="col-sm-9 form-control" v-model="password" @focus="showValidate = true" @input="checkValidate()">
                <span class="toggle-password" @click="hidePass = !hidePass">
                <i class="fa" :class="hidePass ? 'fa-eye' : 'fa-eye-slash'"></i>
            </span>
            </div>
            <div class="form-group" v-if="showValidate">
                <div class="col-sm-9 offset-3" :class="errorCharacter === null ? 'normal-color' : (errorCharacter ? 'error' : 'success-color')">{{$t('ruleNormal')}} <i class="far fa-check-circle"></i></div>
                <div class="col-sm-9 offset-3" :class="errorCharacterUppercase === null ? 'normal-color' : (errorCharacterUppercase ? 'error' : 'success-color')">{{$t('ruleUppercase')}} <i class="far fa-check-circle"></i></div>
                <div class="col-sm-9 offset-3" :class="errorLength === null  ? 'normal-color' : (errorLength ? 'error' : 'success-color')">8-16 ký tự. <i class="far fa-check-circle"></i></div>
            </div>
            <div class="form-group row" style="position: relative">
                <label for="f_confirm_password" class="margin-tb-auto col-sm-3">{{ $t('reTypePassword')}}</label>
                <input :type="hideConfirmPass ? 'password' : 'text'" id="f_confirm_password" name="confirm_password"
                       class="col-sm-9 form-control" v-model="confirmPassword">
                <span class="toggle-password" @click="hideConfirmPass = !hideConfirmPass">
                <i class="fa" :class="hideConfirmPass ? 'fa-eye' : 'fa-eye-slash'"></i>
            </span>
            </div>
            <div class="form-group d-flex-center" v-if="errors.passwordError">
                <span class="error">{{ errors.passwordError }}</span>
            </div>
            <div class="form-group row">
                <button class="col-sm-3 offset-sm-3 submit-btn" @click="isVerify ? saveWithoutOldPassword() : save()">{{ $t('updatePassword')}}</button>
            </div>
        </div>
        <div class="col-4" v-if="!isForgot && !isVerify">
            <div class="form-group" style="margin: auto">
                <a href="#" @click="isForgot = true">{{ $t('forgotPassword')}}</a>
            </div>

        </div>
        <div class="col-12 reset-password" v-if="isForgot">
            <div class="reset-title d-flex mb-3" v-if="!isSentSMS && !isSentMail">
                <a href="javascript:;" class="ml-0" v-if="!chooseType" @click="onBack(1)">
                    <i class="fas fa-long-arrow-alt-left"></i>
                </a>
                <a href="javascript:;" class="ml-0" v-if="chooseType" @click="onBack(2)">
                    <i class="fas fa-long-arrow-alt-left"></i>
                </a>

                <label class="m-auto">{{ $t('resetPassword')}}</label>
            </div>
            <div class="reset-title d-flex mb-3" v-if="isSentMail || isSentSMS">
                <a href="javascript:;" class="ml-0" @click="onBack(3)">
                    <i class="fas fa-long-arrow-alt-left"></i>
                </a>

                <label class="m-auto">{{ $t('verifyAccount')}}</label>
            </div>
            <div v-if="!chooseType && !isSentSMS && !isSentMail">
                <span class="mb-3 username-des">{{ $t('chooseTypeResetPwd')}}</span>
                <div class="col-md-5 col-sm-12 form-group" v-if="user.email" @click="toggleType('email')">
                    <i class="far fa-envelope mr-2"></i><span id="m_email" v-text="'Email (' + replaceCenterInfo(user.email, 'email') + ')'"> </span>
                </div>
                <div class="col-md-5 col-sm-12 form-group" v-if="user.phone" @click="toggleType('phone')">
                    <i class="fas fa-phone mr-2"></i><span id="m_phone" v-text="$t('phoneNumber') + '(' + replaceCenterInfo(user.phone, 'phone') + ')'"> </span>
                </div>
                <div class="d-flex-center" v-if="!user.phone && !user.email">
                    <span class="error">{{ $t('hasNotInfo')}} <br />{{ $t('typeInfoAndReload')}}</span>
                </div>
                <div class="d-flex-center" v-if="errors.errorInfo">
                    <span class="error">{{ errors.errorInfo }}</span>
                </div>
            </div>
            <div v-if="chooseType && !isSentSMS && !isSentMail">
                <div v-if="type === 'email'">
                    <img class="mb-3" src="/assets/img/profile/email.png">
                    <div>
                        <span class="mb-3 username-des">{{ $t('verifyCodeSentToEmail')}}</span>
                    </div>
                    <div>
                        <span class="mb-3 username-des" style="font-weight: bold" v-text="replaceCenterInfo(user.email, 'email')"></span>
                    </div>
                    <div>
                        <span class="mb-3 username-des">{{ $t('pleaseVerify')}}</span>
                    </div>

                </div>
                <div v-if="type === 'phone'">
                    <img class="mb-3" src="/assets/img/profile/email.png">
                    <div>
                        <span class="mb-3 username-des">{{ $t('verifyCodeSentToPhone')}}</span>
                    </div>
                    <div>
                        <span class="mb-3 username-des" style="font-weight: bold" v-text="replaceCenterInfo(user.phone, 'phone')"></span>
                    </div>
                    <div>
                        <span class="mb-3 username-des">{{ $t('pleaseVerify')}}</span>
                    </div>

                </div>
                <div class="col-md-3 col-sm-12" style="margin: 20px auto">
                    <button type="button" class="btn confirm-btn" @click="sendVerify()">OK</button>
                </div>
            </div>
            <div v-if="isSentMail || isSentSMS">
                <div>
                    <div>
                        <span class="mb-3 username-des" v-text="type === 'email' ? $t('verifyCodeSentToEmail') : $t('verifyCodeSentToPhone')"></span>
                    </div>
                    <div>
                        <span class="mb-3 username-des" style="font-weight: bold" v-text="type === 'email' ? replaceCenterInfo(user.email, 'email') : replaceCenterInfo(user.phone, 'phone')"></span>
                    </div>
                </div>
                <div id="otp" class="col-6 inputs d-flex flex-row justify-content-center input-verify">
                    <input class="m-2 text-center form-control" type="text" id="box_1" maxlength="1"
                           v-model="code_1" @keyup="onKeyUpEvent(1, $event)" @focus="onFocusEvent(1)"/>
                    <input class="m-2 text-center form-control" type="text" id="box_2" maxlength="1"
                           v-model="code_2" @keyup="onKeyUpEvent(2, $event)" @focus="onFocusEvent(2)"/>
                    <input class="m-2 text-center form-control" type="text" id="box_3" maxlength="1"
                           v-model="code_3" @keyup="onKeyUpEvent(3, $event)" @focus="onFocusEvent(3)"/>
                    <input class="m-2 text-center form-control" type="text" id="box_4" maxlength="1"
                           v-model="code_4" @keyup="onKeyUpEvent(4, $event)" @focus="onFocusEvent(4)"/>
                    <input class="m-2 text-center form-control" type="text" id="box_5" maxlength="1"
                           v-model="code_5" @keyup="onKeyUpEvent(5, $event)" @focus="onFocusEvent(5)"/>
                    <input class="m-2 text-center form-control" type="text" id="box_6" maxlength="1"
                           v-model="code_6" @keyup="onKeyUpEvent(6, $event)" @focus="onFocusEvent(6)"/>
                </div>
                <div class="d-flex-center" v-if="errors.codeError">
                    <span class="error">{{ errors.codeError }}</span>
                </div>
                <div class="col-3" style="margin: 20px auto">
                    <button type="button" class="btn confirm-btn" @click="applyVerifyCode()">{{ $t('verify')}}</button>
                </div>
                <div class="title-verify">
                    <p style="margin-bottom: 0">{{ $t('notReceiveCode')}}
                        <a href="javascript:;" @click="sendVerify()" class="font-weight-bold" style="color: #db0000">&nbsp; {{ $t('send')}}
                            lại
                        </a>
                    </p>
                    <p>{{ $t('timeCancelCode')}}</p>
                </div>
            </div>
        </div>
        <div class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-hidden="true" id="successUpdated">
            <div class="modal-dialog" style="max-width: 400px; top: 15%">
                <div class="modal-content">
                    <div class="form-group text-center">
                        <img class="mb-3 mt-3" src="/assets/img/profile/success.png">
                        <div style="margin-bottom: 16px">
                            <span>{{ $t('updateSuccess')}}</span>
                        </div>
                        <button type="button" class="btn btn-normal" data-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import PasswordRequest from "@/api/requests/PasswordRequest";
import {cloneObject} from "@/lib/utils";
import store from "@/store";
import AuthRequest from "@/api/requests/AuthRequest";

export default {
    name: "ChangePassword",
    data() {
        return {
            oldPassword: '',
            password: '',
            confirmPassword: '',
            hideOldPass: true,
            hidePass: true,
            hideConfirmPass: true,
            showValidate: false,
            errorCharacterUppercase: null,
            errorCharacter: null,
            errorLength: null,
            isForgot: false,
            chooseType: false,
            isSentMail: false,
            isSentSMS: false,
            verifyCode: '',
            code_1: '',
            code_2: '',
            code_3: '',
            code_4: '',
            code_5: '',
            code_6: '',
            type: '',
            isVerify: false,

            errors: {
                passwordError: '',
                errorInfo: '',
                codeError: ''
            },
            loading: false,
            user: cloneObject(store.state.Auth.user) || {
                phone: '',
                email: ''
            }
        }
    },
    methods: {
        validate() {
            if (!this.oldPassword) {
                this.errors.passwordError = 'Vui lòng nhập mật khẩu cũ';
                return true;
            }
            if (!this.password) {
                this.errors.passwordError = 'Vui lòng nhập mật khẩu mới';
                return true;
            } else if (this.password.length < 6 || this.password.length > 18) {
                this.errors.passwordError = 'Mật khẩu phải tối từ 6-18 kí tự';
                return true;
            } else if (this.password.search(/[a-z]/) < 0 || this.password.search(/[A-Z]/) < 0) {
                this.errors.passwordError = 'Mật khẩu phải bao gồm it nhất 1 ký tự viết hoa và viết thường';
                return true;
            }
            if (!this.confirmPassword) {
                this.errors.passwordError = 'Vui lòng nhập Xác nhận mật khẩu';
                return true;
            }
            if (this.password && this.confirmPassword && this.password !== this.confirmPassword) {
                this.errors.passwordError = 'Mật khẩu xác nhận không khớp';
                return true;
            }
            return false;
        },
        validateWithoutOldPassword() {
            if (!this.password) {
                this.errors.passwordError = 'Vui lòng nhập mật khẩu mới';
                return true;
            } else if (this.password.length < 6 || this.password.length > 18) {
                this.errors.passwordError = 'Mật khẩu phải tối từ 6-18 kí tự';
                return true;
            } else if (this.password.search(/[a-z]/) < 0 || this.password.search(/[A-Z]/) < 0) {
                this.errors.passwordError = 'Mật khẩu phải bao gồm it nhất 1 ký tự viết hoa và viết thường';
                return true;
            }
            if (!this.confirmPassword) {
                this.errors.passwordError = 'Vui lòng nhập Xác nhận mật khẩu';
                return true;
            }
            if (this.password && this.confirmPassword && this.password !== this.confirmPassword) {
                this.errors.passwordError = 'Mật khẩu xác nhận không khớp';
                return true;
            }
            return false;
        },
        checkValidate() {
            if (this.password.search(/[a-z]/) < 0) {
                this.errorCharacter = true;
            } else {
                this.errorCharacter = false;
            }
            if (this.password.search(/[A-Z]/) < 0) {
                this.errorCharacterUppercase = true;
            } else {
                this.errorCharacterUppercase = false;
            }
            if (this.password.length < 8 || this.password.length > 16) {
                this.errorLength = true;
            } else {
                this.errorLength = false;
            }
        },
        showModal() {
            window.$('#modalPassword').modal('show');
        },
        async save() {
            if (this.loading) {
                return false;
            }
            if (this.validate()) {
                return false;
            }

            this.loading = true;
            const res = await PasswordRequest.changePassword(this.password, this.oldPassword);
            this.loading = false;
            if (res.err_code !== 0) {
                this.errors.passwordError = res.message;
                return false;
            }

            this.oldPassword = '';
            this.password = '';
            this.confirmPassword = '';
            this.errors = {};
            this.showValidate = false;
            this.errorLength = null;
            this.errorCharacterUppercase = null;
            this.errorCharacter = null;
            window.$('#successUpdated').modal('show');
            return true;
        },
        async saveWithoutOldPassword() {
            if (this.loading) {
                return false;
            }
            if (this.validateWithoutOldPassword()) {
                return false;
            }

            this.loading = true;
            const res = await PasswordRequest.changeWithoutOldPassword(this.type, this.password, this.verifyCode)
            this.loading = false;
            if (res.err_code !== 0) {
                this.errors.passwordError = res.message;
                return false;
            }
            this.password = '';
            this.confirmPassword = '';
            this.isVerify = false;
            this.showValidate = false;
            this.errorLength = null;
            this.errorCharacterUppercase = null;
            this.errorCharacter = null;
            this.errors = {};
            window.$('#successUpdated').modal('show');
            return true;
        },
        replaceCenterInfo(text, type) {
            let sLength = text.length;
            if (type === 'phone') {
                return text.replace(text.substring(0,sLength - 3), '*******');
            }
            return text.replace(text.substring(2,sLength - 10), '*********');

        },

        toggleType(type) {
            if  (type) {
                this.type = type;
            }
            this.chooseType = true;
        },
        async sendMail() {
            if (!this.user.email) {
                this.errors.errorInfo = 'Chưa có thông tin về email';
                return;
            }

            const res = await PasswordRequest.forgot(this.user.email);

            if (res.err_code !== 0) {
                this.errors.errorInfo = res.message;
                return;
            } else {
                this.isSentMail = true;
                this.errors.errorInfo = '';
            }

        },
        async sendSMS() {
            if (!this.user.phone) {
                this.errors.errorInfo = 'Chưa có thông tin về số điện thoại';
                return;
            }

            const res = await AuthRequest.checkPhoneToSendVerify(this.user.phone);
            if (res.err_code !== 0) {
                this.errors.errorInfo = res.message;
                return;
            } else {
                this.isSentSMS = true;
                this.errors.errorInfo = '';
            }
        },

        sendVerify() {
            if (this.type === 'phone') {
                this.sendSMS();
            } else {
                this.sendMail();
            }
        },
        onBack(type) {
            if (type === 1) {
                this.isForgot = false;
            } else if (type === 2) {
                this.chooseType = false;
                this.type = '';
            } else {
                this.type = '';
                this.isSentMail = false;
                this.isSentSMS = false;
            }

        },
        getCodeBoxElement(index) {
            return document.getElementById('box_' + index);
        },
        onKeyUpEvent(index, event) {
            const eventCode = event.which || event.keyCode;
            if (this.getCodeBoxElement(index).value.length === 1) {
                if (index !== 6) {
                    this.getCodeBoxElement(index+ 1).focus();
                } else {
                    this.getCodeBoxElement(index).blur();
                }
            }
            if (eventCode === 8 && index !== 1) {
                this.getCodeBoxElement(index - 1).focus();
            }
        },
        onFocusEvent(index) {
            for (let item = 1; item < index; item++) {
                const currentElement = this.getCodeBoxElement(item);
                if (!currentElement.value) {
                    currentElement.focus();
                    break;
                }
            }
        },

        async applyVerifyCode() {

            let value = '';
            if (!this.code_1 || !this.code_2 || !this.code_3 || !this.code_4 || !this.code_5 || !this.code_6) {
                this.errors.codeError = 'Vui lòng nhập mã xác nhận trước khi tiếp tục';
                return;
            }

            this.verifyCode = this.code_1 + this.code_2 + this.code_3 + this.code_4 + this.code_5 + this.code_6;

            if (this.type === 'email') {
                value = this.user.email;
            } else {
                value = this.user.phone;
            }
            const res = await PasswordRequest.applyVerifyCode(this.type, value, this.verifyCode);
            if (res.err_code === 0) {
                this.isVerify = true;
                this.isForgot = false;
                this.isSentSMS = false;
                this.isSentMail = false;
                this.errors.codeError = '';
            } else {
                this.errors.codeError = res.message;
            }
        },
    }
}
</script>

<style scoped lang="scss">
</style>
