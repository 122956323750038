<template>
    <div class="row" id="shop-detail--product">
        <div class="col-3">
            <div class="filter-box">
<!--                <div class="title">-->
<!--                    Danh mục sản phẩm-->
<!--                </div>-->
<!--                <ul id="place-filter">-->
<!--                    <li>-->
<!--                        <label class="custom-input-checkbox">Electronics-->
<!--                            <input type="checkbox" checked="checked">-->
<!--                            <span class="checkmark"></span>-->
<!--                        </label>-->
<!--                        <label class="filter-quantity">28</label>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <label class="custom-input-checkbox">Sport Equipments-->
<!--                            <input type="checkbox">-->
<!--                            <span class="checkmark"></span>-->
<!--                        </label>-->
<!--                        <label class="filter-quantity">28</label>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <label class="custom-input-checkbox">Furnuture-->
<!--                            <input type="checkbox">-->
<!--                            <span class="checkmark"></span>-->
<!--                        </label>-->
<!--                        <label class="filter-quantity">28</label>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <label class="custom-input-checkbox">Software Solutions-->
<!--                            <input type="checkbox">-->
<!--                            <span class="checkmark"></span>-->
<!--                        </label>-->
<!--                        <label class="filter-quantity">28</label>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <label class="custom-input-checkbox">Food & Grossery-->
<!--                            <input type="checkbox">-->
<!--                            <span class="checkmark"></span>-->
<!--                        </label>-->
<!--                        <label class="filter-quantity">28</label>-->
<!--                    </li>-->

<!--                </ul>-->

                <div class="title" style="color: #383c4f; margin-top: 32px">
                    Giá
                </div>

                <ul id="price-filter">
                    <li>
                        <label class="custom-input-radio">{{ $t('all')}}
                            <input type="radio" name="price" checked="checked">
                            <span class="checkmark"></span>
                        </label>
                        <label class="filter-quantity">2074</label>
                    </li>
                    <li>
                        <label class="custom-input-radio">{{ $t('upto500')}}
                            <input type="radio" name="price">
                            <span class="checkmark"></span>
                        </label>
                        <label class="filter-quantity">1403</label>
                    </li>
                    <li>
                        <label class="custom-input-radio">500.000đ - 1.000.000đ
                            <input type="radio" name="price">
                            <span class="checkmark"></span>
                        </label>
                        <label class="filter-quantity">580</label>
                    </li>
                    <li>
                        <label class="custom-input-radio">{{ $t('above1000')}}
                            <input type="radio" name="price">
                            <span class="checkmark"></span>
                        </label>
                        <label class="filter-quantity">85</label>
                    </li>
                    <li style="display: unset; justify-content: unset">
                        <div class="price-between">
	                        {{ $t('between')}}
                        </div>
                        <div class="input-box">
                            <input type="text" name="price_min" placeholder="Từ">
                            <span>-</span>
                            <input type="text" name="price_max" placeholder="đến">
                        </div>
                    </li>

                </ul>

<!--                <div class="title" style="color: #383c4f; margin-top: 32px">-->
<!--                    Sizes-->
<!--                </div>-->
<!--                <ul id="size-filter">-->
<!--                    <li class="active"><a href="javascript:;">XS</a></li>-->
<!--                    <li><a href="javascript:;">S</a></li>-->
<!--                    <li><a href="javascript:;">M</a></li>-->
<!--                    <li class="active"><a href="javascript:;">L</a></li>-->
<!--                    <li><a href="javascript:;">XL</a></li>-->
<!--                </ul>-->

<!--                <div class="title" style="color: #383c4f; margin-top: 32px">-->
<!--                    Color-->
<!--                </div>-->
<!--                <ul id="color-filter">-->
<!--                    <li class="active">-->
<!--                        <div>-->
<!--                            <a href="javascript:;" style="background: #F64E60"></a>-->
<!--                        </div>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <div>-->
<!--                            <a href="javascript:;" style="background: #3699FF"></a>-->
<!--                        </div>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <div>-->
<!--                            <a href="javascript:;" style="background: #1BC5BD"></a>-->
<!--                        </div>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <div>-->
<!--                            <a href="javascript:;" style="background: #8950FC"></a>-->
<!--                        </div>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <div>-->
<!--                            <a href="javascript:;" style="background: #1B283F"></a>-->
<!--                        </div>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <div>-->
<!--                            <a href="javascript:;" style="background: #D6D6E0"></a>-->
<!--                        </div>-->
<!--                    </li>-->
<!--                </ul>-->

                <div style="text-align: center;">
                    <button type="button" class="reset-btn">{{ $t('reOrder')}}</button>
                </div>

            </div>
        </div>
        <div class="col-9">
            <div class="product-box">
                <div class="list-filter">
                    <span>
                        {{ $t('orderBy')}}
                    </span>
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#view" id="hot_view">Xem nhiều</a>
                        </li>
                    </ul>
                </div>
                <div class="tab-content">
                    <div id="view" class="tab-pane active">
                        <div class="row" style="margin-left: 10px">
                            <div class="col-xl-3 col-sm-4" v-for="(product, index) in shop.products"
                                 :key="index + 1">
                                <product-item :product="product" :height="260" :show-detail="true"></product-item>
                            </div>
                        </div>
                        <div class="paginate mt-3">
                            <Paginate :value="paginate" :pagechange="onPageChange"></Paginate>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import ProductItem from "@/components/ProductItem";
import Paginate from "@/components/Paginate";

export default {
    name: "ShopNewProduct",
    props: ['shop', 'categories', 'paginate'],
    components: {
        ProductItem,
        Paginate
    },
    data () {
        return {
            page: this.$route.query.page || 1,
            sort: this.$route.query.sort || 3,
            type: this.$route.query.type || 2
        }
    },
    methods: {
        onPageChange(page) {
            const shopId = this.shop.id;

            this.$router.push({
                path: `/cua-hang/${shopId}`,
                query: {page: page, sort: this.sort, type: this.type}
            });
        },
    }

}
</script>

<style lang="scss">
@import '../../assets/css/shopDetail';
</style>
