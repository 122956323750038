import { createStore } from 'vuex'
import storage from "@/lib/storage";

// Create a new store instance.
const initialState: GlobalState = {
    Auth: storage.get('Auth'),
    CountCartItem: 0,
    CountNotification: 0,
    showChatBar: true,
    hasAuthTemp: false,
    showChatBox: true,
    locale: localStorage.getItem('elimLang') || 'vi'
}

const store = createStore({
    state () {
        return initialState;
    },
    mutations: {
        SetAuth(state: GlobalState, value: AuthData | null) {
            state.Auth = value;
            storage.set('Auth', value);
        },
        SetCountCartItem(state: GlobalState, value: number) {
            state.CountCartItem = value;
        },
        SetShowChatBar(state: GlobalState, value: boolean) {
            state.showChatBar = value;
        },
        SetHasAuthTemp(state: GlobalState, value: boolean) {
            state.hasAuthTemp = value;
        },
        SetShowChatBox(state: GlobalState, value: boolean) {
            state.showChatBox = value;
        },
        SetUserData(state: GlobalState, value: any) {
            if (state.Auth) {
                state.Auth.user = value;
                storage.set('Auth', state.Auth);
            }
        },
        RemoveAuth(state: GlobalState) {
            storage.remove('Auth');
            state.Auth = null;
        },

        SetLocale(state: GlobalState,value: any) {
            state.locale = value;
            localStorage.setItem('elimLang', value);
        },
    }
})
export default store;
