<template>
    <div class="row" id="shop-detail--discount">
        <div class="col-12">
            <div class="top-content">
                <div class="row" style="align-items: center">
                    <div class="col-lg-4 col-sm-12">
                        <div class="left-box">
                            <div class="discount-title">
                                <h1>{{ $t('saleTo')}}</h1>
                            </div>
                            <div class="sub-title">
	                            {{ $t('saleTime')}}
                            </div>
                            <div class="countdown-time">
                                <div class="time-box" style="margin-left: auto; margin-right: 20px">02</div>
                                <div class="time-box">16</div> <span> :</span>
                                <div class="time-box">24</div> <span> :</span>
                                <div class="time-box" style="margin-right: auto;">58</div>
                            </div>
                            <div class="view-btn">
                                <button>{{ $t('viewMore')}}</button>
                            </div>
                        </div>

                    </div>
                    <div class="col-lg-8 col-sm-12">
                        <div class="view-more">
                            <a href="javascript:;">{{ $t('viewMore')}}</a></div>
                        <div class="product-items">
                            <div class="discount-product-box" v-for="(product, index) in 4" :key="'product-' + index">
                                <product-item :product="product" :show-detail="true"></product-item>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-12">
            <div class="product-box">
                <div class="title">
                    <img src="/assets/img/shop/discount.png" style="margin-right: 10px" alt=""> {{ $t('saleProduct')}}
                </div>
                <div class="row" style="margin-left: 10px">
                    <div class="col-xl-2 col-md-3 col-sm-4" v-for="(product, index) in 25"
                         :key="index + 1">
                        <product-item :product="product" :show-detail="true"></product-item>
                    </div>
                </div>
                <div class="paginate">
                    <nav aria-label="Page navigation">
                        <ul class="pagination justify-content-center">
                            <li class="page-item"><a class="page-link" href="javascript:;"><img src="/assets/img/category/arrow-left.png" alt=""></a></li>
                            <li class="page-item"><a class="page-link" href="javascript:;">1</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:;">2</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:;">3</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:;">4</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:;">5</a></li>
                            <li class="page-item"><a class="page-link" href="javascript:;"><img src="/assets/img/category/arrow-right.png" alt=""></a></li>
                        </ul>
                    </nav>
                </div>
            </div>

        </div>


    </div>
</template>

<script>
import ProductItem from "@/components/ProductItem";
export default {
    name: "ShopDiscount",
    components: {
        ProductItem
    }

}
</script>

<style lang="scss">
@import '../../assets/css/shopDetail';
</style>