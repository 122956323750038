import BaseRequest from "../BaseRequest";

class B2bCategoriesRequest extends BaseRequest {
    constructor() {
        super('B2bCategoriesRequest');
    }
    async getAllCategory(page: string, search: string, searchInput: string) {
        return super.dispatch('getAllCategory', page, search, searchInput);
    }

}

export default new B2bCategoriesRequest()
