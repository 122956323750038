<template>
    <div class="wrap-content" v-for="(item, i) in shops" :key="'shop_' + i" id="product-with-shop">
        <div class="row-header">
            <div class="checkbox">
                <img src="/assets/img/cart/ic_check.svg" alt=""/>
            </div>
            <p class="header-label">
                {{ item.shop ? item.shop.name : item.name }}
                <i class="fas fa-angle-right"></i>
            </p>
            <a class="header-text" v-if="item.items">
                <img src="/assets/img/cart/ic_chat.svg" alt=""/>
                <p>{{ $t('chatWithShop')}}</p>
            </a>
        </div>
        <div class="row-item" v-if="item.items">
            <template  v-for="(product, index) in item.items" :key="'product-' + index">
                <div class="checkbox active">
                    <img src="/assets/img/cart/ic_check.svg" alt=""/>
                </div>
                <div class="col-product">
                    <img :src="product.product.thumb.uri" alt=""/>
                    <p class="product-name">
                        {{ product.product.name }}
                    </p>
                </div>
                <div class="col-price">
                    <p class="text-price">{{ numberFormat(product.product.price) }}đ</p>
                    <p class="text-price-discount">{{ product.product.price_discount > 0 ? numberFormat(product.product.price_origin) + ' đ' : '' }}</p>
                </div>
                <div class="col-quantity">
                    <div class="product-quantity">
                        <a class="btn-quantity" @click="updateQuantity(product, product.quantity-1)">
                            <img src="/assets/img/cart/ic_minus.svg" alt=""/>
                        </a>
                        <input
                                v-model="product.quantity"
                                type="text"
                                maxlength="6"
                                pattern="[0-9]*"
                                placeholder="">
                        <a class="btn-quantity dark" @click="updateQuantity(product, product.quantity+1)">
                            <img src="/assets/img/cart/ic_plus.svg" alt=""/>
                        </a>
                    </div>
                </div>
                <div class="col-total">
                    <p class="text-price danger">{{product.price_origin > product.price ? numberFormat(product.quantity * product.price_origin)
                            : numberFormat(product.quantity * product.price)}} đ</p>
                </div>
                <a @click="removeItem(product, index)" class="delete-item">
                    <img src="/assets/img/cart/ic_trash.svg" alt=""/>
                </a>
            </template>

        </div>
        <div class="row-item" v-if="item.OrderItem">
            <template  v-for="(product, index) in item.OrderItem" :key="'product-' + index">
                <div class="col-product">
                    <img :src="product.thumb.uri" alt=""/>
                    <p class="product-name">
                        {{ product.name }}
                    </p>
                </div>
                <div class="col-price">
                    <p class="text-price-discount">{{ product.price_discount > 0 ? numberFormat(product.price_origin) + ' đ' : '' }}</p>
                </div>
                <div class="col-total">
                    <p class="text-price danger">{{product.price_origin > product.price ? numberFormat(product.quantity * product.price_origin)
                            : numberFormat(product.quantity * product.price)}} đ</p>
                </div>
            </template>

        </div>
        <div class="row-footer" v-if="item.items">
            <div class="shop-amount">
                <span class="footer-title">{{ $t('totalAmountInShop')}} </span>
                <span class="footer-text"> {{ numberFormat(item.totalAmount ? item.totalAmount : item.amount_total) }} đ</span>
            </div>
        </div>
        <div class="row-footer" v-if="item.OrderItem">
            <div class="shop-amount">
                <span class="footer-title">{{ $t('totalAmountInShop')}} </span>
                <span class="footer-text"> {{ numberFormat(item.amount_total) }} đ</span>
            </div>

            <div class="footer-btn">
                <button class="buy-btn">{{ $t('buy')}}</button>
                <button class="contact-shop">{{ $t('contactSupplier')}}</button>
            </div>
        </div>
    </div>

</template>

<script>
import CartRequest from "@/api/requests/CartRequest";
import {numberFormat, stringTruncate} from "@/lib/utils";

export default {
name: "ProductInShop",
    props: ['shops', 'items'],
    methods: {
        numberFormat,
        async removeItem(item, index) {
            if (!window.confirm('Xóa bản ghi'+ item.product.name + " ?")) {
                return;
            }

            const res = await CartRequest.remove(item.id)

            if (res.err_code === 0) {
                alert(res.message);
                await this.getCart();
            } else {
                alert(res.message);
            }
        },
        updateQuantity(item, value) {
            value = Math.max(parseInt(value) || 1, 1);
            item.quantity = value;
            item.amount = item.quantity * item.price;

            if (this.timeoutIDs[item.id]) {
                clearTimeout(this.timeoutIDs[item.id]);
            }

            this.timeoutIDs[item.id] = setTimeout(async () => {
                const res = await CartRequest.updateQuantity(
                    item.id,
                    item.quantity
                );
                if (res.err_code === 0) {
                    item.price = res.price;
                    item.price_origin = res.price_origin;
                    this.amountOrigin = res.amount_origin;
                    this.amountTotal = res.amount;
                    await this.getCart();

                } else {
                    alert(res.message);
                    item.quantity = 1;
                }
            }, 300)

        },
    }
}
</script>

<style scoped lang="scss">
#product-with-shop {
  font-family: 'Inter', sans-serif;
  font-style: normal;
}
.wrap-content {
  background: #FFFFFF;
  box-shadow: 0px 8px 32px -4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 0 20px;
  margin-bottom: 20px;
  overflow: hidden;
}
.row-header {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #EDEDEF;

  .header-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #383C4F;
    margin-bottom: 0;
    padding-right: 15px;
    cursor: pointer;
    flex: 1;

    i {
      font-size: 16px;
    }
  }

  .header-text {
    cursor: pointer;
    display: flex;
    align-items: center;
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #5D5FEF;
      margin-bottom: 0;
      padding-left: 10px;
    }

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      margin-bottom: 0;
      padding-left: 10px;
    }
    .status-title {
      font-weight: 400;
      color: #626574;
      padding-right: 10px;
      border-right: solid 1px #EDEDEF;
    }
    .status-name {
      font-weight: 500;
      color: #DB0000;
    }
  }
}

.row-footer {
  background: #F8F8F8;
  align-items: center;
  padding: 15px 20px;
  margin: 0 -20px;
  text-align: right;
  .shop-amount {
    margin-bottom: 10px;

    .footer-title {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      margin-right: 10px;
      color: #626574;
    }

    .footer-text {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #383C4F;
    }

  }
  .footer-btn {
    .buy-btn {
      color: #ffffff;
      background: #DB0000;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      padding: 12px 20px;
      margin-right: 10px;
      border-radius: 6px;
      border: unset;

      &:hover {
        cursor: pointer;
      }
    }
    .contact-shop {
      padding: 10px 20px;
      border: 1px solid #A7A9B1;
      box-sizing: border-box;
      border-radius: 6px;
      background: #ffffff;
      font-size: 16px;
      font-weight: 500;
      color: #383C4F;

      &:hover {
        cursor: pointer;
      }

    }
  }
}
.wrap-content {
  background: #FFFFFF;
  box-shadow: 0 8px 32px -4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 0 20px;
  margin-bottom: 20px;
  overflow: hidden;
}
.row-item {
  display: flex;
  align-items: center;
  padding: 15px 0;

  .col-product {
    flex: 2;
    display: flex;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
      border-radius: 5px;
    }

    .product-name {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #383C4F;
      margin-bottom: 0;
      padding-left: 10px;
    }
  }

  .col-price, .col-total {
    flex: 1;
    padding: 0 15px;

    .text-price {
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      color: #383C4F;
      margin-bottom: 0;
      text-align: right;

      &.danger {
        color: #DB0000;
      }
    }

    .text-price-discount {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-decoration-line: line-through;
      color: #A7A9B1;
      margin-bottom: 0;
    }
  }

  .col-quantity {
    width: 156px;

    .product-quantity {
      display: flex;
      align-items: center;
      border: 1px solid #DADBDE;
      border-radius: 6px;
      overflow: hidden;

      .btn-quantity {
        cursor: pointer;
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border-right: 1px solid #DADBDE;

        &.dark {
          background: #EDEDEF;
          border-left: 1px solid #DADBDE;

        }
      }

      input {
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #3F4254;
        border: none;
        width: 80px;

        &:focus {
          outline: none;
        }
      }
    }
  }
}

</style>