<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-store-craft-images">
                    <Splide :options="sliderMainOption" ref="mainSlider" class="text-center">
                        <SplideSlide style="background: #dbdada;" v-for="(item, index) in imageModal" :key="index">
                            <img class="image-modal" :src="item.link.uri" alt="">
                            <div class="numbertext">{{ index + 1 }} / {{ imageModal.length }}</div>
                        </SplideSlide>
                    </Splide>

                    <div class="thumb-slider-wrap">
                        <div class="total">{{$t('all')}} ({{ imageModal.length }})</div>

                        <Splide :options="sliderThumbOptions" ref="thumbSlider">
                            <SplideSlide v-for="(item, index) in imageModal" :key="index" class="box-thumb-image">
                                <img :src="item.link.uri" alt="">
                            </SplideSlide>
                        </Splide>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Splide, SplideSlide} from '@splidejs/vue-splide';
import '@splidejs/vue-splide/dist/css/splide.min.css';

export default {
    components: {
        Splide,
        SplideSlide,
    },
    data() {
        return {
            imageModal: [],
            modal: 1,

            sliderMainOption: {
                type: 'fade',
                perPage: 1,
                perMove: 1,
                rewind: true,
                rewindByDrag: true,
                paginationKeyboard: false,
                pagination: false,

                autoplay: true,
                // interval: 500,
                // pauseOnHover: true,

                // autoScroll: {
                //     speed: 1,
                // },
            },
            sliderThumbOptions: {
                type: 'slide',
                rewind: true,
                gap: '1em',
                isNavigation : true,
                perPage: 7,
                perMove: 1,
                focus: 'center',
                arrows: false,
                paginationKeyboard: false,
            },

        }
    },
    mounted() {
        const mainSlider = this.$refs.mainSlider;
        const thumbSliderSplide = this.$refs.thumbSlider?.splide || null;

        if (thumbSliderSplide) {
            mainSlider.sync(thumbSliderSplide);
        }
    },
    methods: {
        show(data) {
            this.imageModal = data;
            window.$(this.$refs.modal).modal('show');
        },
    }
}
</script>

<style lang="scss" scoped>
.modal-store-craft-images {
    border-radius: 10px;

    .image-modal {
        height: 550px;
    }

    .box-thumb-image {
        img {
            border-radius: 10px;
            width: 100%;
            height: 90px;
        }
    }

    .thumb-slider-wrap {
        padding: 10px;

        .splide__slide.is-active {
            border: none;

            img {
                border: 3px solid white;
            }
        }
    }

    .numbertext {
        color: #f2f2f2;
        font-size: 16px;
        padding: 10px 25px;
        background: #000000;
        text-align: right;
    }

    .thumb-slider-wrap {
        background: rgb(42, 42, 58);

        .total {
            color: #ffffff;
            padding: 15px 15px 5px;
            margin-bottom: 10px;
            margin-left: 15px;
            border-bottom: 2px solid;
            display: inline-block;
            font-size: 16px;
        }
    }
}

@media (min-width: 992px) {
    .modal-lg {
        max-width: 1200px;
    }
}

.modal-content {
    border-radius: 12px;
}
</style>