<template>
    <div>
        <div class="slider-banner" ref="banner" style="display: none">
            <a href="#" v-for="(slide, index) in sliders" :key="'slide-' + index">
                <img  :src="slide.link.uri" alt="">
            </a>
        </div>
        <div class="slider-category" ref="category" style="display: none">
            <div class="slider-items">
                <a href="#">
                    <span class="icon"><img src="img/data/slider-ctg-1.png" alt=""></span>
                    <span class="ctg-name">Hàng hóa</span>
                </a>
            </div>
            <div class="slider-items">
                <a href="#">
                    <span class="icon"><img src="img/data/slider-ctg-2.png" alt=""></span>
                    <span class="ctg-name">Sp mới</span>
                </a>
            </div>
            <div class="slider-items">
                <a href="#">
                    <span class="icon"><img src="img/data/slider-ctg-3.png" alt=""></span>
                    <span class="ctg-name">Độc quyền</span>
                </a>
            </div>
            <div class="slider-items">
                <a href="#">
                    <span class="icon"><img src="img/data/slider-ctg-1.png" alt=""></span>
                    <span class="ctg-name">Hàng hóa</span>
                </a>
            </div>
            <div class="slider-items">
                <a href="#">
                    <span class="icon"><img src="img/data/slider-ctg-2.png" alt=""></span>
                    <span class="ctg-name">Sp mới</span>
                </a>
            </div>
            <div class="slider-items">
                <a href="#">
                    <span class="icon"><img src="img/data/slider-ctg-3.png" alt=""></span>
                    <span class="ctg-name">Độc quyền</span>
                </a>
            </div>
            <div class="slider-items">
                <a href="#">
                    <span class="icon"><img src="img/data/slider-ctg-1.png" alt=""></span>
                    <span class="ctg-name">Hàng hóa</span>
                </a>
            </div>
            <div class="slider-items">
                <a href="#">
                    <span class="icon"><img src="img/data/slider-ctg-2.png" alt=""></span>
                    <span class="ctg-name">Sp mới</span>
                </a>
            </div>
            <div class="slider-items">
                <a href="#">
                    <span class="icon"><img src="img/data/slider-ctg-3.png" alt=""></span>
                    <span class="ctg-name">Độc quyền</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    const $ = window.jQuery;

    export default {
        props: ['sliders'],
        name: "SlickSlider",
        watch: {

        },
        updated() {
            this.initSlider();
        },
        methods: {
            initSlider() {
                $(this.$refs.banner).show().slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    //dots: true,
                    arrows: true
                });
                $(this.$refs.category).show().slick({
                    slidesToShow: 7,
                    slidesToScroll: 7,
                    arrows:true
                });
            }
        },
        mounted() {

        }
    }
</script>

<style scoped>

</style>
