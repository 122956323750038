<template>
    <div class="fa-3x loading" v-if="isLoading">
        <i class="fas fa-spinner fa-spin"/>
    </div>

</template>

<script>
export default {
    name: "Loading",
    props: ['isLoading']
}
</script>

<style scoped>

</style>