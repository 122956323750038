<template>
    <div class="wrap-container" v-if="shop.banners">
        <div class="banner-shop-decoration">
            <template v-for="template in shop.banners" :key="template.id">
                <div v-if="(template.id == 7 || template.id == 8) && template.productList && template.productList.length ">
                    <div class="template-header mt-2">
                        <div class="d-flex align-items-center">
                            <h4 v-text="template.title"></h4>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-lg-2 col-md-3 col-4" style="overflow: hidden"
                             v-for="product in template.productList" :key="product.id">
                            <product-item :product="product"></product-item>
                        </div>
                    </div>
                </div>
                <div v-else-if="template.id == 9 && template.categoryList && template.categoryList.length">
                    <div class="row mt-2">
                        <a target="_blank" href="javascript:void(0)" class="banner-url banner-cate"
                        v-for="category in template.categoryList" :key="category.id">
                            <img class="banner-2" :src="category.iconImg?.uri"
                                 style="width: -webkit-fill-available; border-radius: 50%; margin-right: auto" />
                        </a>
                    </div>
                </div>
                <div v-else-if="(template.id != 7 && template.id != 8 && template.id != 9) || (template.id == 9 && template.typeCategory == 2)" v-html="template.content"></div>
            </template>
            <div v-if="showEdit" style="position: relative; bottom: 15px" @click="decorationShop()">
                <label for="upload-avatar1" class="row banner-btn">
                    <i class="fas fa-camera" style="font-size: 20px" />
                    <p style="margin: 0">{{ $t('bannerDesign')}}</p>
                </label>
            </div>
        </div>
    </div>

</template>

<script>
import ProductItem from "@/components/ProductItem";
const $ = window.jQuery;
export default {
    name: "ShopBanner",
    components: {ProductItem},
    props: ['shop', 'showEdit'],
    created() {
        this.$nextTick(() => {
            this.initSlider();
            this.changeText();
            $('.banner-url[href="javascript:void(0)"]').css('pointer-events', 'none');
        });
    },
    updated() {

        this.$nextTick(() => {
            this.initSlider();
            this.changeText();
        });
    },
    methods: {
        changeText() {
            let hasText = false;
            if (this.shop.templates) {
                this.shop.templates.forEach((template, index) => {
                    if (template.id == 6) {
                        hasText = true;
                    }
                });
                if (hasText) {
                    document.getElementById('text-banner').innerHTML = this.shop.textBanner;
                }
            }
        },
        decorationShop() {
            if (this.authData) {
                const shopUrl = process.env.VUE_APP_SHOP_URL || 'http://shop.elim.vn';
                window.open(shopUrl + '/manager/shop-decoration/index', '_blank');
            }
        },
        initSlider() {
            $(".bannerSlider").slick({
                dots: false
                , autoplay: true
                , infinite: true
                , slidesToShow: 1
                , slidesToScroll: 1
                , arrows: false
            });
        },
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/shopBanner";
.template-header {
    display: flex;
    align-items: center;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #EDF1F7;
    justify-content: space-between;

    h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        text-transform: uppercase;
        color: #383C4F;
        flex: 1;
        padding: 0 0 0 15px;
        margin-bottom: 0;
    }
}
</style>