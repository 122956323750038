<template>
  <div class="bg-white p-3 rounded-circle">
    <div v-if="!!products.length" class="content-right">
      <div v-for="(item,index) in products" :key="item.id">
        <item :item="item" :index="index"/>
      </div>
    </div>
    <div v-else>
      <template v-if="!isLoading">{{ $t('notFoundProduct')}}</template>
    </div>
    <div class="paginate">
      <Paginate
          :value="paginate"
          :pagechange="onProductPageChange"
      ></Paginate>
    </div>
  </div>
</template>

<script>
import data from "@/lib/data.json";
import CrawlRequest from "@/api/requests/CrawlRequest";
import Tabs from "@/components/tabs/Tabs";
import Filter from "@/components/classified_advertising/Filter";
import Item from "@/components/classified_advertising/Item";
import Paginate from "@/components/Paginate";
import Breadcrumb from "@/components/Breadcrumb";
import HeaderClassifiedAdvertising from "@/components/classified_advertising/HeaderClassifiedAdvertising";

export default {
  name: "NewsWithUser",
  components: {
    Tabs,
    Filter,
    Item,
    Paginate,
    Breadcrumb,
    HeaderClassifiedAdvertising,
  },
  props: ['property', 'id'],
  data() {
    return {
      provinces: [],
      page: 1,
      isLoading: true,
      products: [],
      hasNextPage: false,
      paginate: {
        currentPage: 1,
        lastPage: 0,
      },
    }
  },
  methods: {

    async load(keyword, categories) {
      this.isLoading = true;

      console.log("-> this.id", this.id);

      let option = {
        page: this.page,
        limit: 10
      }
      const res = await CrawlRequest.get(this.id, categories, keyword, this.property, option);
      console.log("-> res", res);

      this.products = res.data;
      this.paginate = res.paginate;

      this.isLoading = false;
    },
    onProductPageChange(page) {
      window.scrollTo(0, 0);
      this.page = page
      this.paginate.page = page;
      this.load();
    },

  },

  watch: {
    'id'(value) {
      this.load();

    }
  },
  mounted() {
    document.title = "Thông tin người đăng";
    if (this.id) {
      this.load();

    }

  }
}
</script>

<style scoped>

</style>
