<template >
  <router-link :to="item && item.id ? '/thong-tin-rao-vat/' + item.id : ''">
    <div class="container-fluid news-content ">
      <div  class="row padding-mobile my-2 py-2 pl-2"
           :class="[index%2 !=0?'bg-gray':'bg-white']">
        <img v-if="item.image && item.image.src" :src="item?.image?.src" :style="{height:'50px',width:'50px'}" @error="loadImgErr"/>
        <img v-else :src="defaultImg" alt="" :style="{height:'50px',width:'50px'}"/>
        <div class="col-5">
          <div class="text-title ml-2 mr-2">{{ item.title }}</div>
        </div>
        <div class="col-3 text-center">{{ formatDate(item.created_at) }}</div>
        <div>
        <div class=" ">{{ $t('postBy')}}: <span class="font-weight-bolder">{{ item?.from?.name }}</span></div>
        <div class="font-weight-bolder">{{ formatTime(item.updated_at) }}</div>

        </div>
      </div>
    </div>
  </router-link>
</template>
<script>
import {defaultImg, numberFormat, stringTruncate} from "@/lib/utils";
import moment from "moment";
import store from "@/store";
import "moment/locale/vi";
import config from "@/config";
export default {
  props: ["item",'index'],
  data() {
    return {
      image: this.item?.image?.src,
      defaultImg: defaultImg()
    };
  },
  methods: {
    subStringTitle(title){
      return stringTruncate(title,100)
    },
    formatDate(date) {
      if (!date) return "";
      return moment(date).format('DD/MM/YYYY');
    },
    formatTime(date) {
      if (!date) return "";
      return moment(date).fromNow();
    },
    renderTextContent(html) {
      var div = document.createElement("div");
      div.innerHTML = html;
      var text = div.textContent || div.innerText || "";

      return text;
    },
    numberFormat,
    async setFavorite(product, isFavorite) {
      const authData = store.state.Auth;
      if (this.inter) clearInterval(this.inter);
      product.is_favorite = !product.is_favorite;
      if (authData) {
        // const res = await ProductRequest.setFavoriteProduct(
        //   product.id,
        //   isFavorite
        // );
        // product.is_favorite = isFavorite;
      } else {
        // alert("Vui lòng đăng nhập để thêm sản phẩm yêu thích!");
      }
    },
    onPlayImage() {
      this.count = 0;
      this.inter = setInterval(() => {
        if (this.count >= this.item?.subImage.length) {
          this.count = 0;
        }
        this.image = this.item?.subImage?.[this.count]?.media?.image?.src;

        this.count++;
      }, 1500);
    },
    onStopImage() {
      this.count = 0;
      if (this.inter) clearInterval(this.inter);
    },
    loadImgErr(e) {
      e.currentTarget.src = defaultImg();
    }
  },
  unmounted() {
    if (this.inter) clearInterval(this.inter);
  },
};
</script>
<style lang="scss">
@import "@/assets/css/classifiedAdvertising";


</style>
