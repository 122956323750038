<template>
    <div id="user-profile--info">
        <div class="content-box" style="padding: 24px">
            <div class="title">
	            {{ $t('listFollowShop')}}
            </div>
            <div class="shop-box">
                <div class="row">
                    <div class="col-xl-4 col-sm-4" v-for="(shop, index) in data.shops"
                         :key="index + 1">
                        <Shop :shop="shop"></Shop>
                    </div>
                    <div v-if="!isFollowed" class="ml-3">
	                    {{ $t('notFollowShop')}}
                    </div>
                </div>
                <div class="paginate mt-3">
                    <Paginate :value="data.paginate" :pagechange="onPageChange"></Paginate>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import store from "@/store";
import ShopRequest from "@/api/requests/ShopRequest";
import Shop from '@/components/shop/Shop';
import Paginate from "@/components/Paginate";

export default {
    name: "ShopFollow",
    props: ['data'],
    components: {Shop, Paginate},
    data() {
        return {
            isFollowed: false,
            page: this.$route.query.page || 1,
        }

    },
    mounted() {
    },
    methods: {
        onPageChange(page) {
            this.page = page;
            this.loadShopFollows();
        },
        async loadShopFollows() {
            const res = await ShopRequest.getShopFollow(this.auth.user.id, this.page);

            if (!res.err_code) {
                let data = {
                    shops: res.shops,
                    paginate: res.paginate
                }

                this.$emit('loadShopFollows', data);
            }
        },

    },
    watch: {
        'data.shops': function (newShops) {
            if(newShops.length > 0) {
                this.isFollowed = true
            } else {
                this.isFollowed = false;
            }
        }
    }
}
</script>

<style lang="scss">
@import '../../assets/css/userInfo';
</style>
