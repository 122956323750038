<template>
  <div class="classified-advertising">
    <div class="wrap-container">
      <div class="">
        <div>
          <tabs class="w-11/12 lg:w-10/12 mx-auto mb-16" :tabList="tabList">
          </tabs>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import TabNews from "@/components/classified_advertising/TabNews";
import Tabs from "@/components/tabs/Tabs";

export default {
  components: {
    TabNews,
    Tabs,
  },
  data() {
    return {
      tabList: [
        {
          label: this.$t('wantToSell'), // cần bán
          property: 2
        },
        {
          label: this.$t('wantToBuy'), // cần mua
          property: 1
        },
        {
          label: this.$t('b2b'), // b2b
          property: 3
        },
      ],
    };
  },
};
</script>


<style scoped lang="scss">
@import "../assets/css/classifiedAdvertising";

.line-gray {
  height: 1px;
  flex: 1;
  background-color: gray
}

.line-red {
  height: 3px;
  width: 30px;
  background-color: red
}
.title-categories{
  font-size: 17px;
  font-weight: 500;
}
@media screen and (max-width: 768px) {
  .hide-mobile {
    display: none;
  }
}
</style>
