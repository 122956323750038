import BaseRequest from "@/api/BaseRequest";

class EmailRequest extends BaseRequest {
    constructor() {
        super('EmailRequest');
    }

    async sendMailContact(email: string): Promise<any> {
        return super.dispatch('sendMailContact', email);
    }


}

export default new EmailRequest()
