<template>
    <div id="campaignDetail">
        <div class="campaign-bg" v-if="campaign.end_date && campaign.thumb">
            <img v-if="campaign.thumb && campaign.thumb.uri" :src="campaign.thumb.uri" alt="bg-campaign">
            <img v-else src="/assets/img/campaign/fake_bg.jpg" alt="bg-campaign">
            <div class="campaign-time">
                <CountDown :end="d(campaign.end_date)" :showUnit="true"/>
            </div>
        </div>
        <div class="wrap-container">
            <div class="widget-head">
                <div class="widget-head-left">
                    <p class="widget-title">{{campaign['title_' + Language] || campaign.title}}</p>
                </div>
            </div>
            <div class="content" id="infinite-list">
                <div class="widget mb-3">
                    <div class="widget-body" v-if="products.length">
                        <div class="row">
                            <div class="col-xl-2 col-sm-3 col-6" v-for="product in products" :key="'product-' + product.id">
                                <product-item :product="product" :show-detail="false"></product-item>
                            </div>
                        </div>
                        <div class="loading" v-show="isLoading" style="text-align: center; color: #db0000">
                            <span class="fa fa-spinner fa-spin"></span>
                        </div>
<!--                        <div class="paginate">-->
<!--                            <Paginate :value="paginate" :pagechange="onPageChange"></Paginate>-->
<!--                        </div>-->
                    </div>
                    <div v-else>
                        <template v-if="!isLoading">{{ $t('noProductInCategory')}}</template>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import CategoryRequest from "@/api/requests/CategoryRequest";
    import ProductItem from "@/components/ProductItem";
    import CountDown from "@/components/CountDown";
    import Paginate from "@/components/Paginate";
    import moment from 'moment'
    import store from "@/store";
    import {getOption, siteSetTitle} from "@/lib/utils";

    export default {
        name: "CampaignDetail",
        props: ['id', 'name', 'slug'],
        components: {ProductItem, CountDown, Paginate},
        data() {
            return {
                isLoading: false,
                products: [],
                campaign: {title: ''},
                page: this.$route.query.page || 1,
                isLoadNext: true,
                paginate: {
                    currentPage: 1,
                    lastPage: 1
                },
                Language: store.state.locale,
            }
        },
        mounted() {

            this.getData();
            window.onscroll = () => {
                let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight - 300;

                if (bottomOfWindow && this.isLoadNext && !this.isLoading) {
                    this.page += 1;
                    this.getData();
                }
            }

        },
        methods: {
            async getData() {
                if (this.isLoadNext) {
                    this.isLoading = true;
                    const res = await CategoryRequest.getCampaignProducts(this.slug, this.page);
                    if (res.code !== 200) {
                        this.$router.replace({name: 'PageNotFound'});
                        this.isLoading = false;
                        return;
                    }
                    if (res && res.products && res.products.length == 0) {
                        this.isLoadNext = false;
                        this.isLoading = false;
                        return;
                    }

                    if (this.products.length > 0) {
                        this.products = this.products.concat(res.products);
                    } else {
                        this.products = res.products;
                    }

                    this.paginate = res.paginate;
                    this.campaign = res.campaign;
                    this.isLoading = false;
                    siteSetTitle(this.campaign.title);
                }

            },
            d(date) {
                let a = moment(date)
                return a.unix()*1000
            },
            onPageChange(page) {
                this.page = page;
                this.$router.push({query: { page:page }});
                this.getData();
            },
        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/css/campaign";

    .widget-title {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: #383C4F;
        margin-bottom: 15px;
    }
    .paginate {
        width: fit-content;
        margin: auto;
    }
</style>
