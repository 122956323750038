<template>
    <div class="row" id="shop-detail--product">
        <div class="col-lg-12">
            <div class="product-box">
                <div class="list-filter">
                    <span>
                        {{ $t('orderBy')}}
                    </span>
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link active" @click="sortBy(1)" data-toggle="tab" href="#new" id="product_new">{{ $t('newest')}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="sortBy(2)" data-toggle="tab" href="#hotdeal" id="hot_deal">{{ $t('bestSeller')}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="sortBy(3)" data-toggle="tab" href="#view" id="hot_view">{{ $t('viewMany')}}</a>
                        </li>
<!--                        <li class="nav-item">-->
<!--                            <a class="nav-link" data-toggle="tab" href="#increase" id="filter_increase">Tăng dần</a>-->
<!--                        </li>-->
                        <li class="nav-item ml-1">
                            <div class="dropdown">
                                <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
	                                {{ $t('filter')}}
                                </button>
                                <div class="dropdown-menu" id="dropdown-menu-id" aria-labelledby="dropdownMenuButton">
                                    <div class="title" style="color: #383c4f;">
	                                    {{ $t('priceMoney')}}
                                    </div>

                                    <ul id="price-filter">
                                        <li v-for="(item, index) in data.searchPrice" :key="index">
                                            <label class="custom-input-radio" @change="searchData">{{ item.name}}
                                                <input type="radio" name="price" :checked="search.price == item.value" :value="item.value" v-model="search.price">
                                                <span class="checkmark"></span>
                                            </label>
                                        </li>
                                        <li style="display: unset; justify-content: unset">
                                            <div class="price-between">
	                                            {{ $t('between')}}
                                            </div>
                                            <div class="input-box">
                                                <input type="number" name="price_from" v-model="search.price_from" placeholder="Từ" @keydown.enter="searchData">
                                                <span>-</span>
                                                <input type="number" name="price_to" v-model="search.price_to" placeholder="đến" @keydown.enter="searchData">
                                            </div>
                                        </li>

                                    </ul>
                                    <div style="text-align: center;">
                                        <button type="button" class="reset-btn" @click="resetFilterPrice">Đặt lại</button>
                                    </div>
                                </div>
                            </div>
<!--                            <a class="nav-link" data-toggle="tab" href="#decrease" id="filter_decrease">Bộ lọc giá</a>-->
                        </li>
                    </ul>
                </div>
                <div class="tab-content">
                    <div id="new" class="tab-pane active">
                        <div v-if="products.length > 0">
                            <div class="row">
                                <div class="col-xl-2 col-sm-3 col-6 padding-mobile" v-for="(product, index) in products"
                                     :key="index + 1">
                                    <product-item :product="product" :height="260" :show-detail="true"></product-item>
                                </div>
                            </div>
                            <div class="paginate mt-3">
                                <Paginate :value="paginate" :pagechange="onPageChange"></Paginate>
                            </div>
                        </div>
                        <div v-else>{{ $t('noProduct')}}</div>

                    </div>
                    <div id="hotdeal" class="tab-pane">
                        <div v-if="products.length > 0">
                            <div class="row" style="margin-left: 10px">
                                <div class="col-md-3 col-sm-4 col-6 padding-mobile" v-for="(product, index) in products"
                                     :key="index + 1">
                                    <product-item :product="product" :show-detail="true"></product-item>
                                </div>
                            </div>
                            <div class="paginate mt-3">
                                <Paginate :value="paginate" :pagechange="onPageChange"></Paginate>
                            </div>
                        </div>
                        <div v-else>
	                        {{ $t('noProduct')}}
                        </div>
                    </div>
                    <div id="view" class="tab-pane">
                        <div v-if="products.length > 0">
                            <div class="row" style="margin-left: 10px">
                                <div class="col-md-3 col-sm-4 col-6 padding-mobile" v-for="(product, index) in products"
                                     :key="index + 1">
                                    <product-item :product="product" :show-detail="true"></product-item>
                                </div>
                            </div>
                            <div class="paginate mt-3">
                                <Paginate :value="paginate" :pagechange="onPageChange"></Paginate>
                            </div>
                        </div>
                        <div v-else>
	                        {{ $t('noProduct')}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProductItem from "@/components/ProductItem";
import Paginate from "@/components/Paginate";
import ShopRequest from "@/api/requests/ShopRequest";
import {isEmpty} from "@/lib/utils";
import store from "@/store";
import toastr from 'toastr';
import {getSearchQuery} from "@/lib/utils";

const $ = window.jQuery;

export default {
    name: "ShopProduct",
    props: ['count','data', 'type'],
    data () {
        return {
            page: this.$route.query.page || 1,
            sort: this.$route.query.sort || 1,
            products: [],
            brandSelected: [],
            paginate: {
                currentPage: 1,
                lastPage: 1
            },
            avatarShop: '/assets/img/shop/user.png',
            search: {
                price: this.$route.query.price || "",
                price_from: this.$route.query.price_from || "",
                price_to: this.$route.query.price_to || "",
                name: this.$route.query.name || "",
                category_id: this.$route.query.category_id,
                brand_ids: this.$route.query.brand_ids,
            },
            authData: store.state.Auth
        }
    },
    components: {
        ProductItem,
        Paginate
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);

        $(document).on('click', '.dropdown-menu', function (e) {
            e.stopPropagation();
        });

        if (!this.search.price && !this.search.price_from && !this.search.price_to) {
            this.search.price = 'all';
        }
        if (this.sort == 1) {
            document.getElementById('product_new').classList.add('active');
        } else if (this.sort == 2) {
            document.getElementById('hot_deal').classList.add('active');
        } else if (this.sort == 3) {
            document.getElementById('hot_view').classList.add('active');
        }

        if (this.search.brand_ids) {
            this.brandSelected = this.search.brand_ids.split(',');
        }
    },
    methods: {
        onPageChange(page) {
            this.page = page;
            this.updateQuery();

            setTimeout(() => {
                window.jQuery("html, body").animate({ scrollTop: 0 }, 300);
            }, 500);
        },
        async sortBy(sort) {
            this.sort = sort;
            this.updateQuery()
        },
        async loadChild() {
            const userId = this.authData ? this.authData.user.id: '';

            const res = await ShopRequest.getHomeDataWeb(
                this.data.shop.slug,
                this.page,
                this.sort,
                this.type,
                this.search,
                userId
            );

            if (!res.err_code) {
                let data = {
                    shop: res.shop,
                    paginate: res.paginate
                }

                this.$emit('loadChild', data);
            }
        },
        async searchData(event = null) {
            const shopId = this.data.shop.slug;
            let query = {page: this.page, sort: this.sort, type: this.type};

            let priceFrom = this.search.price_from;
            let priceTo = this.search.price_to;

            if (event && event.keyCode === 13 && priceFrom && priceTo) {
                if (priceFrom > priceTo) {
                    alert('Khoảng giá bắt đầu không được nhỏ hơn khoảng giá kết thúc')
                }

                this.search.price = '';
                query.price_from = priceFrom;
                query.price_to = priceTo;
            } else if (this.search.price) {
                query.price = this.search.price;
                this.search.price_from = '';
                this.search.price_to = '';
            }

            if (this.$route.query.category_id) {
                query.category_id = this.$route.query.category_id;
                this.search.category_id = this.$route.query.category_id;
            }

            if (this.search.brand_ids) {
                query.brand_ids = this.search.brand_ids;
            }

            if (this.$route.query.name) {
                query.name = this.$route.query.name;
                this.search.name = this.$route.query.name;
            }

            this.$router.push({
                path: `/cua-hang/${shopId}`,
                query: query
            });

            this.loadChild();
        },
        updateQuery(){
            const shopId = this.data.shop.slug;

            let query = {page: this.page, sort: this.sort, type: this.type};

            if (this.$route.query.price) {
                query.price = this.$route.query.price;
                this.search.price = this.$route.query.price;
            }

            if (this.$route.query.price_from) {
                query.price_from = this.$route.query.price_from;
                this.search.price_from = this.$route.query.price_from;
            }

            if (this.$route.query.price_to) {
                query.price_to = this.$route.query.price_to;
                this.search.price_to = this.$route.query.price_to;
            }

            if (this.$route.query.name) {
                query.name = this.$route.query.name;
                this.search.name = this.$route.query.name;
            }

            if (this.$route.query.category_id) {
                query.category_id = this.$route.query.category_id;
                this.search.category_id = this.$route.query.category_id;
            }

            if (this.$route.query.brand_ids) {
                query.brand_ids = this.$route.query.brand_ids;
                this.search.brand_ids = this.$route.query.brand_ids;
            }

            this.$router.push({
                path: `/cua-hang/${shopId}`,
                query: query
            });

            this.loadChild();
        },
        resetFilterPrice(){
            const shopId = this.data.shop.slug;
            let query = {page: this.page, sort: this.sort, type: this.type};
            this.search.price = 'all';
            if (this.$route.query.name) {
                query.name = this.$route.query.name;
                this.search.name = this.$route.query.name;
            }

            this.search.category_id = '';
            this.search.brand_ids = '';
            this.brandSelected = '';

            this.$router.push({
                path: `/cua-hang/${shopId}`,
                query: query
            });

            this.loadChild();

            $("#dropdown-menu-id").removeClass('show');
        },
        filterCategory(id) {
            this.search.category_id = id;

            this.searchData();
        },
        chooseBrand() {
            this.search.brand_ids = this.brandSelected.length > 0 ? this.brandSelected.toString() : "";

            this.searchData();
        },
        handleScroll() {
            let bannerLength = 450;
            if (this.data.shop.banner) {
                bannerLength = 952;
            }

            if (window.scrollY > bannerLength) {
                $("#dropdown-menu-id").removeClass('show');
            }

        }

    },
    watch: {
        'data.shop': {
            immediate: true,
            handler(value) {
                this.products = value.products;
            }
        },
        'data.paginate' : {
            immediate: true,
            handler(paginate) {
                this.paginate.lastPage = paginate.lastPage;
                this.paginate.lastPage = paginate.lastPage;
            }
        }
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>

<style lang="scss">
@import '../../assets/css/shopDetail';
</style>
