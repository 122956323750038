<template>
    <div>
        <div data-toggle="modal" data-target="#addressModal">
            <a class="add-address">{{ $t('changeAddress1')}}</a>
        </div>
        <div class="modal fade" id="addressModal" tabindex="-1" role="dialog"
             aria-labelledby="addressModal" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">{{ $t('myAddress')}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="addresses">
                            <div class="address-list">
                                <div :key="address.id" class="address-item" v-for="address in addresses" @click="changeAddress(address)">
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <label>{{ $t('fullName')}}</label>
                                        </div>
                                        <div class="col-6">
                                            <span class="address-name" v-text="address.name"></span>
                                            <span class="address-default" v-if="address.is_default">{{ $t('default')}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <label>{{ $t('phoneNumber')}}</label>
                                        </div>
                                        <div class="col-6">
                                            <span v-text="address.phone"></span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <label>{{ $t('address')}}</label>
                                        </div>
                                        <div class="col-6">
                                            <span v-text="address.decoded"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <address-form-component></address-form-component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AddressFormComponent from "@/components/carts/AddressFormComponent";
import store from "@/store";
import AddressRequest from "@/api/requests/AddressRequest";
export default {
    name: "ModalAddress",
    components: {AddressFormComponent},
    data() {
        return {
            addresses: [],
            user: store.state.Auth || {}
        }
    },
    mounted() {
        this.getAddress();
    },
    methods: {
        async getAddress() {
            const data = await AddressRequest.get();

            if (!data) {
                alert('Vui lòng thêm địa chỉ để tiếp tục!');
                return;
            }
            this.addresses = data;
        },
        async changeAddress(address) {
            this.addresses.forEach((a) => {
                a.is_default = (a.id === address.id);
            });
            const res = await AddressRequest.setDefaultAddress(address.id.toString());
            this.$emit('setAddress', address);
            window.$('#addressModal').modal('hide');
        }
    }
}
</script>

<style scoped lang="scss">
.add-address {
    cursor: pointer;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #5D5FEF !important;
    margin-bottom: 15px;
}
.address-title {
    font-size: 16px;
    font-weight: bold;
    border-bottom: 0.5px solid #EDEDEF;
}
.address-item {
    padding: 15px 0;
    border-bottom: solid 1px #EDEDEF;
    &:hover {
        background-color: #c0c0c0;
        cursor: pointer;
    }
}
.address-name {
    font-weight: bold;
    font-size: 16px;
}
.address-default {
    margin-left: 16px;
    font-size: 16px;
    padding: 3px 8px;
    background: #20c997;
    color: #ffffff;
    border-radius: 6px;
}

</style>