<template>
  <div class="card-product d-flex">
    <span>Danh mục sản phẩm</span>
    <div class="view-all">
        <a href="#">Xem tất cả danh mục</a>
    </div>
  </div>
  <div class="row p-4 layout-category container">
    <div
      class="col-2 border text-center p-3 box-shodow"
      v-for="item in categorys"
      :key="item.id"
    >
      <img :src="item.thumb['uri']" style="width: 80px; height: 80px" alt="" />
      <div class="title mt-2">{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
import ProductRequest from "@/api/requests/ProductRequest";
export default {
  name: "CampaignDetail",
  data() {
    return {
      categorys: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await ProductRequest.getCampaignProducts();
      this.categorys = res.categorys;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-product {
    justify-content: space-between;
  & span {
    overflow: hidden;
    font-size: 22px;
    color: #424242;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .view-all{
    & a{
        color: blue;
    }
  }

}
.box-shodow:hover {
  box-shadow: 0px 0px 1px 1px #ccc;
}
.layout-category{
    margin: 0 auto;
}
</style>