<template>
    <div id="shop-detail--info" class=" pb-100">
        <div class="row">
<!--            <div class="col-lg-3" ref="sidebarCol">-->
<!--                <div class="content-nav box-content pd-15" ref="sidebar">-->
<!--                    <div class="item-nav" @click="navigate(tab, index)" :class="[tab.id, {active: tab.id===activeTabId}]" v-for="(tab,index) in tabs" :key="tab.id">-->
<!--                        {{tab.name}}-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
            <div class="col-lg-12" id="shop-info">
                <div class="box-content">
                    <div class="box-header" >
                        <h4>{{ $t('companyOverview')}}</h4>

                    </div>
                    <div class="box-main-content" style="margin-bottom: 0">
                        <p class="sub-title" v-if="profile.thumbs.length > 0">{{ $t('companyImage')}}
                            <img class="ml-1 mr-1" src="/assets/img/shop/gallery.png">
                            <span v-text="profile.thumbs.length"></span>
                        </p>
                        <div v-if="profile.thumbs.length > 0" class="slider-category" ref="companySlider">
                            <div class="slider-items" v-for="(thumb, index) in profile.thumbs" :key="index">
                                <div class="slider-box">
                                    <img :src="thumb.uri" alt="img-shop">
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <p class="sub-title">{{ $t('companyProcedure')}}</p>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="company-description">
                                    <p v-text="profile.description"></p>
                                </div>
                            </div>
                            <div class="col-sm-6 company-info">
                                <div class="company-rating">
                                    <p class="rating-score"><span>4.8</span> / 5</p>
                                    <p class="rating-text">{{ $t('verySatisfy')}}</p>
                                    <p class="rating-text">{{ $t('review')}}</p>
                                </div>
                                <div>
                                    <table class="table review-table-info">
                                        <tbody>
                                        <tr>
                                            <th>{{ $t('supplierIndex')}}</th>
                                            <td>4.8</td>
                                        </tr>
                                        <tr>
                                            <th>{{ $t('product')}}</th>
                                            <td>{{data.shop.productCount}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{ $t('customer')}}</th>
                                            <td>310.000+</td>
                                        </tr>
                                        <tr>
                                            <th>{{ $t('timeApply')}}</th>
                                            <td v-text="'<4h'"></td>
                                        </tr>
                                        <tr>
                                            <th>{{ $t('responseRate')}}</th>
                                            <td>96,96%</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="company-detail-info">
                            <table class="table table-detail-info">
                                <tbody>
                                <tr>
                                    <th>{{ $t('companyName')}}</th>
                                    <td>
                                        <div class="row-detail-info">
                                            <p v-text="profile.shop_name"></p>
                                            <img src="/assets/img/profile/verify.svg">
                                        </div>
                                    </td>
                                    <th>{{ $t('companyAddress')}}</th>
                                    <td>
                                        <div class="row-detail-info">
                                            <p>{{ profile.address}}</p>
                                            <img src="/assets/img/profile/verify.svg">
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{{ $t('businessType')}}</th>
                                    <td>
                                        <div class="row-detail-info">
                                            <p v-text="profile.type_name"></p>
                                            <img src="/assets/img/profile/verify.svg">
                                        </div>
                                    </td>
                                    <th>{{ $t('establishYear')}}</th>
                                    <td>
                                        <div class="row-detail-info">
                                            <p v-text="profile.founded_year"></p>
                                            <img src="/assets/img/profile/verify.svg">
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{{ $t('mainCategory')}}</th>
                                    <td>
                                        <div class="row-detail-info">
                                            <p v-text="profile.category_name"></p>
                                            <img src="/assets/img/profile/verify.svg">
                                        </div>
                                    </td>
                                    <th>{{ $t('businessLicense')}}</th>
                                    <td>
                                        <div class="row-detail-info">
                                            <p></p>
                                            <img src="/assets/img/profile/verify.svg">
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{{ $t('mainProduct')}}</th>
                                    <td>
                                        <div class="row-detail-info">
                                            <p v-text="profile.main_product"></p>
                                            <img src="/assets/img/profile/verify.svg">
                                        </div>
                                    </td>
                                    <th>{{ $t('totalEmployee')}}</th>
                                    <td>
                                        <div class="row-detail-info">
                                            <p v-text="profile.total_employees_name"></p>
                                            <img src="/assets/img/profile/verify.svg">
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{{ $t('mainMarket')}}</th>
                                    <td>
                                        <div class="row-detail-info">
                                            <p v-text="profile.main_marketing_name"></p>
                                            <img src="/assets/img/profile/verify.svg">
                                        </div>
                                    </td>
                                    <th>{{ $t('officeSize')}}</th>
                                    <td>
                                        <div class="row-detail-info">
                                            <p v-text="profile.office_size_name"></p>
                                            <img src="/assets/img/profile/verify.svg">
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="box-content"  id="item-2">
                    <div class="box-header">
                        <h4>{{ $t('productCapacity')}}</h4>
                    </div>
                    <div class="box-main-content">
                        <p class="sub-title" v-if="profile.shop_profile_thumbs.length > 0">{{ $t('factoryImage')}}</p>
                        <div class="slider-category" ref="categorySlider">
                            <div class="slider-items" v-for="(thumb, index) in profile.shop_profile_thumbs" :key="index">
                                <div class="slider-box">
                                    <img :src="thumb.thumb.uri" alt="img-shop">
                                </div>
                                <div class="slider-text">
                                    <div class="name">
                                        {{thumb.title}}
                                    </div>
                                    <div class="description">
                                        {{thumb.description}}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="box-main-content">
                        <p class="sub-title" v-if="profile.shop_machines.length > 0">{{ $t('mainFactory')}}</p>
                        <table class="table shop-table-detail" v-if="profile.shop_machines.length > 0">
                            <thead>
                            <tr>
                                <th>{{ $t('name')}}</th>
                                <th>{{ $t('quantity')}}</th>
                                <th>{{ $t('verified')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(shopMachine, index) in profile.shop_machines" :key="index">
                                <td v-text="shopMachine.machine_name"></td>
                                <td v-text="shopMachine.quantity"></td>
                                <td><img src="/assets/img/profile/verify.svg"></td>
                            </tr>
                            </tbody>
                        </table>
                        <p class="sub-title">{{ $t('factoryInfo')}}</p>
                        <table class="table shop-table-info">
                            <tbody>
                            <tr>
                                <th>{{ $t('factoryAddress')}}</th>
                                <td v-text="profile.factory_location">
                                </td>
                                <td>
                                    <img src="/assets/img/profile/verify.svg">
                                </td>
                            </tr>
                            <tr>
                                <th>{{ $t('manufacturingContract')}}</th>
                                <td v-text="profile.factory_service_name">

                                </td>
                                <td>
                                    <img src="/assets/img/profile/verify.svg">
                                </td>
                            </tr>
                            <tr>
                                <th>{{ $t('manufacturingEmployees')}}</th>
                                <td v-text="profile.staff_manufacture_name">
                                </td>
                                <td>
                                    <img src="/assets/img/profile/verify.svg">
                                </td>
                            </tr>
                            <tr>
                                <th>{{ $t('factorySize')}}</th>
                                <td v-text="profile.factory_size_name"></td>
                                <td>
                                    <img src="/assets/img/profile/verify.svg">
                                </td>
                            </tr>
                            <tr>
                                <th>{{ $t('dailyManufacturingPlan')}}</th>
                                <td v-text="profile.production_shift + ' ca'"></td>
                                <td>
                                    <img src="/assets/img/profile/verify.svg">
                                </td>
                            </tr>
                            <tr>
                                <th>{{ $t('numberOfProdLine')}}</th>
                                <td v-text="profile.production_line_name">
                                </td>
                                <td>
                                    <img src="/assets/img/profile/verify.svg">
                                </td>
                            </tr>
                            </tbody>

                        </table>
                        <p class="sub-title" v-if="profile.shop_capacities.length > 0">{{ $t('productAbility')}}</p>
                        <table class="table shop-table-detail" v-if="profile.shop_capacities.length > 0">
                            <thead>
                            <tr>
                                <th>{{ $t('productName')}}</th>
                                <th>{{ $t('quantityInYear')}}</th>
                                <th>{{ $t('unit')}}</th>
                                <th>{{ $t('verified')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(shopCapacity, index) in profile.shop_capacities" :key="index">
                                <td v-text="shopCapacity.product_name"></td>
                                <td v-text="shopCapacity.quantity"></td>
                                <td v-text="shopCapacity.unit"></td>
                                <td><img src="/assets/img/profile/verify.svg"></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="box-content">
                    <div class="box-header">
                        <h4>{{ $t('RDAbility')}}</h4>
                    </div>
                    <div class="box-main-content">
                        <p class="sub-title" v-if="profile.design_dept_name">{{ $t('designAbility')}}</p>
                        <p v-text="profile.design_dept_name"></p>
                    </div>
                    <div class="box-main-content">
                        <p class="sub-title" v-if="profile.staff_creative_name">{{ $t('RBEmployees')}}</p>
                        <p v-text="profile.staff_creative_name"></p>
                    </div>
                    <div class="box-main-content">
                        <p class="sub-title" v-if="profile.shop_profile_thumb_designs.length > 0">{{ $t('productImage')}}</p>

                        <div class="slider-category" ref="categorySlider">
                            <div class="slider-items" v-for="(thumb, index) in profile.shop_profile_thumb_designs" :key="index">
                                <div class="slider-box">
                                    <img :src="thumb.thumb.uri" alt="img-shop">
                                </div>
                                <div class="slider-text">
                                    <div class="name">
                                        {{thumb.title}}
                                    </div>
                                    <div class="description">
                                        {{thumb.description}}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="clearfix"></div>
<!--                    <div class="box-main-content mt-2">-->
<!--                        <p class="sub-title">Chứng nhận sản xuất</p>-->
<!--                        <table class="table shop-table-detail">-->
<!--                            <thead>-->
<!--                            <tr>-->
<!--                                <th>Hình ảnh</th>-->
<!--                                <th>Tên chứng nhận</th>-->
<!--                                <th>Được cấp bởi</th>-->
<!--                                <th>Phạm vi kinh doanh</th>-->
<!--                                <th>Ngày cấp</th>-->
<!--                                <th>Đã xác minh</th>-->
<!--                            </tr>-->
<!--                            </thead>-->
<!--                            <tbody>-->
<!--                            <tr>-->
<!--                                <td>-->
<!--                                    <img src="/assets/img/shop/chung-nhan.png" alt="">-->
<!--                                </td>-->
<!--                                <td>GS</td>-->
<!--                                <td>SGS</td>-->
<!--                                <td>Sản xuất đồng hồ Casino</td>-->
<!--                                <td>2020-07-14</td>-->
<!--                                <td><img src="/assets/img/profile/verify.svg"></td>-->
<!--                            </tr>-->
<!--                            </tbody>-->
<!--                        </table>-->
<!--                        <p class="sub-title">Chứng nhận khác</p>-->
<!--                        <table class="table shop-table-detail">-->
<!--                            <thead>-->
<!--                            <tr>-->
<!--                                <th>Hình ảnh</th>-->
<!--                                <th>Tên chứng nhận</th>-->
<!--                                <th>Được cấp bởi</th>-->
<!--                                <th>Phạm vi kinh doanh</th>-->
<!--                                <th>Ngày cấp</th>-->
<!--                                <th>Đã xác minh</th>-->
<!--                            </tr>-->
<!--                            </thead>-->
<!--                            <tbody>-->
<!--                            <tr>-->
<!--                                <td>-->
<!--                                    <img src="/assets/img/shop/chung-nhan.png" alt="">-->
<!--                                </td>-->
<!--                                <td>GS</td>-->
<!--                                <td>SGS</td>-->
<!--                                <td>Sản xuất đồng hồ Casino</td>-->
<!--                                <td>2020-07-14</td>-->
<!--                                <td><img src="/assets/img/profile/verify.svg"></td>-->
<!--                            </tr>-->
<!--                            </tbody>-->
<!--                        </table>-->
<!--                    </div>-->
                </div>

                <div class="box-content">
                    <div class="box-header">
                        <h4>{{ $t('marketAbility')}}</h4>
                    </div>
                    <div class="box-main-content">
                        <p class="sub-title" v-if="profile.main_marketing_arr.length > 0">Thị trường chính</p>
                        <table class="table shop-table-detail" v-if="profile.main_marketing_arr.length > 0">
                            <thead>
                            <tr>
                                <th>{{ $t('marker')}}</th>
                                <th>{{ $t('verified')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(mainMkt, index) in profile.main_marketing_arr" :key="index">
                                <td v-text="mainMkt"></td>
                                <td><img src="/assets/img/profile/verify.svg"></td>
                            </tr>
                            </tbody>
                        </table>
                        <p class="sub-title">{{ $t('dealAbility')}}</p>
                        <table class="table shop-table-info">
                            <tbody>
                            <tr>
                                <th>{{ $t('languageDeal')}}</th>
                                <td>
                                    <div v-if="profile.language_name">
                                        <span v-for="(lang, index) in profile.language_name" :key="index">
                                            {{lang}} ,
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <img src="/assets/img/profile/verify.svg">
                                </td>
                            </tr>
                            <tr>
                                <th>{{ $t('employeeXNKQuantity')}}</th>
                                <td v-text="profile.staff_ops_name"></td>
                                <td>
                                    <img src="/assets/img/profile/verify.svg">
                                </td>
                            </tr>
                            <tr>
                                <th>{{ $t('saleEmployees')}}</th>
                                <td v-text="profile.staff_sale_name"></td>
                                <td>
                                    <img src="/assets/img/profile/verify.svg">
                                </td>
                            </tr>
<!--                            <tr>-->
<!--                                <th>Thời gian chuẩn bị đơn hàng trung bình</th>-->
<!--                                <td>-->
<!--                                    Dịch vụ OEM được cung cấp Dịch vụ thiết kế được cung cấp Nhãn người mua được cung cấp-->
<!--                                </td>-->
<!--                                <td>-->
<!--                                    <img src="/assets/img/profile/verify.svg">-->
<!--                                </td>-->
<!--                            </tr>-->
                            </tbody>

                        </table>
                        <p class="sub-title" v-if="profile.shop_branch_offices.length > 0">Văn phòng tại nước ngoài</p>
                        <table class="table shop-table-detail" v-if="profile.shop_branch_offices.length > 0">
                            <tbody>
                            <div v-for="(branchOff, index) in profile.shop_branch_offices" :key="index">
                                <tr >
                                    <td>{{ $t('outsideOfficeADdress')}}</td>
                                    <td v-text="branchOff.street + ', ' + branchOff.province + ', ' + branchOff.country"></td>
                                    <td>{{ $t('contact')}}</td>
                                    <td v-text="branchOff.phone_ofice"></td>
                                </tr>
                                <tr >
                                    <td>{{ $t('mainMission')}}</td>
                                    <td v-text="branchOff.mission_name"></td>
                                    <td>{{ $t('employees')}}</td>
                                    <td v-text="branchOff.number_employees"></td>
                                </tr>
                            </div>
                            </tbody>
                        </table>
                    </div>
                    <div class="box-main-content">
                        <p class="sub-title" v-if="profile.shop_branch_offices.length > 0">{{ $t('officeImage')}}</p>
                        <div class="slider-category" ref="categorySlider">
                            <div v-for="(branchOff, index) in profile.shop_branch_offices" :key="index">
                                <div class="slider-items" v-for="(thumb, index) in branchOff.thumbs" :key="index">
                                    <div class="slider-box">
                                        <img :src="thumb.thumb.uri" alt="img-shop">
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="box-main-content mt-4">
                        <p class="sub-title">{{ $t('businessCondition')}}</p>
                        <table class="table shop-table-info">
                            <tbody>
                            <tr>
                                <th>{{ $t('languageDeal')}}</th>
                                <td>
                                    <div v-if="profile.language_name">
                                        <span v-for="(lang, index) in profile.language_name" :key="index">
                                            {{lang}} ,
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <img src="/assets/img/profile/verify.svg">
                                </td>
                            </tr>
                            <tr>
                                <th>{{ $t('employeeXNKQuantity')}}</th>
                                <td v-text="profile.staff_ops_name"></td>
                                <td>
                                    <img src="/assets/img/profile/verify.svg">
                                </td>
                            </tr>
                            <tr>
                                <th>{{ $t('saleEmployees')}}</th>
                                <td v-text="profile.staff_sale_name"></td>
                                <td>
                                    <img src="/assets/img/profile/verify.svg">
                                </td>
                            </tr>
                            </tbody>

                        </table>
                        <p class="sub-title" v-if="profile.shop_exhibitions.length > 0">{{ $t('tradeExhibitionNameEnjoy')}}</p>
                        <table class="table shop-table-detail" v-if="profile.shop_exhibitions.length > 0">
                            <thead>
                            <tr>
                                <th>{{ $t('tradeExhibitionName')}}</th>
                                <th>{{ $t('dateEnjoy')}}</th>
                                <th>{{ $t('contentDes')}}</th>
                                <th>{{ $t('verified')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(exhibition, index) in profile.shop_exhibitions" :key="index">
                                <td v-text="exhibition.name"></td>
                                <td v-text="exhibition.month +'/'+ exhibition.year"></td>
                                <td v-text="exhibition.description"></td>
                                <td><img src="/assets/img/profile/verify.svg"></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
<!--                <div class="box-content">-->
<!--                    <div class="box-header">-->
<!--                        <h4>Hiệu quả hoạt động</h4>-->
<!--                    </div>-->

<!--                    <div class="box-main-content">-->
<!--                        <p class="sub-title">Tương tác người mua</p>-->
<!--                        <p>Tên nhà máy</p>-->
<!--                        <table class="table shop-table-detail">-->
<!--                            <thead>-->
<!--                            <tr>-->
<!--                                <th>Tên sản phẩm</th>-->
<!--                                <th>Sản lượng hàng năm</th>-->
<!--                                <th>Đơn vị</th>-->
<!--                                <th>Đã xác minh</th>-->
<!--                            </tr>-->
<!--                            </thead>-->
<!--                            <tbody>-->
<!--                            <tr>-->
<!--                                <td>SGS</td>-->
<!--                                <td>Sản xuất đồng hồ Casino</td>-->
<!--                                <td>2020-07-14</td>-->
<!--                                <td><img src="/assets/img/profile/verify.svg"></td>-->
<!--                            </tr>-->
<!--                            </tbody>-->
<!--                        </table>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="box-content">-->
<!--                    <div class="box-header">-->
<!--                        <h4>Báo cáo thẩm định</h4>-->
<!--                    </div>-->

<!--                    <div class="box-main-content">-->
<!--                        <p class="sub-title">Tương tác người mua</p>-->
<!--                        <p>Tên nhà máy</p>-->
<!--                        <table class="table shop-table-detail">-->
<!--                            <thead>-->
<!--                            <tr>-->
<!--                                <th>Tên sản phẩm</th>-->
<!--                                <th>Sản lượng hàng năm</th>-->
<!--                                <th>Đơn vị</th>-->
<!--                                <th>Đã xác minh</th>-->
<!--                            </tr>-->
<!--                            </thead>-->
<!--                            <tbody>-->
<!--                            <tr>-->
<!--                                <td>SGS</td>-->
<!--                                <td>Sản xuất đồng hồ Casino</td>-->
<!--                                <td>2020-07-14</td>-->
<!--                                <td><img src="/assets/img/profile/verify.svg"></td>-->
<!--                            </tr>-->
<!--                            </tbody>-->
<!--                        </table>-->
<!--                    </div>-->
<!--                </div>-->
                <!--                <div class="box-content">-->
                <!--                    <div class="box-header">-->
                <!--                        <h4 style="text-transform: unset">Gửi tin nhắn của bạn đến nhà cung cấp này</h4>-->
                <!--                    </div>-->
                <!--                    <div class="row">-->
                <!--                        <div class="col-md-6">-->
                <!--                            <label class="label-input">Họ và tên <span>*</span></label>-->
                <!--                            <input class="form-input" type="text"/>-->
                <!--                        </div>-->
                <!--                        <div class="col-md-6">-->
                <!--                            <label class="label-input">Số điện thoại <span>*</span></label>-->
                <!--                            <input class="form-input" type="text"/>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                    <div class="row">-->
                <!--                        <div class="col-md-6">-->
                <!--                            <label class="label-input">Email</label>-->
                <!--                            <input class="form-input" type="text"/>-->
                <!--                        </div>-->
                <!--                        <div class="col-md-6">-->
                <!--                            <label class="label-input">Sản phẩm <span>*</span></label>-->
                <!--                            <input class="form-input" style="background: #EDEDEF;" value="Skmei 1283 Thời gian kép relojes hombre chống thấm..." type="text"/>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                    <div class="row">-->
                <!--                        <div class="col-md-12">-->
                <!--                            <label class="label-input">Nội dung</label>-->
                <!--                            <textarea class="form-input"/>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                    <div class="row justify-content-center">-->
                <!--                        <a class="btn-submit">Gửi đi</a>-->
                <!--                    </div>-->
                <!--                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
import ProductItem from "@/components/ProductItem";
import Paginate from "@/components/Paginate";
import ShopRequest from "@/api/requests/ShopRequest";
import {isElementHidden, isScrolledIntoView} from "@/lib/utils";
import store from "@/store";
const $ = window.jQuery;
let sideBarWidth, offsets = [];

export default {
    name: "ShopInfo",
    props: ['data', 'type'],
    components: {
        ProductItem,
        Paginate
    },
    data () {
        return {
            activeTabId: 'item-1',
            tabs: [
                {name: 'Tổng Quan Công Ty', id: 'item-1', scrollY: 152},
                {name: 'Năng lực sản xuất', id: 'item-2', scrollY: 932},
                {name: 'Năng lực R&D', id: 'item-3', scrollY: 1789},
                {name: 'Năng lực thương mại', id: 'item-4', scrollY: 2089},
                // {name: 'Hiệu quả hoạt động', id: 'item-5', scrollY: 3777},
                // {name: 'Báo cáo thẩm định', id: 'item-6', scrollY: 3751},
            ],

            page: this.$route.query.page || 1,
            sort: this.$route.query.sort || 1,
            profile: {},
            search: {
                name: this.$route.query.name || "",
            },
            authData: store.state.Auth
        }
    },
    watch: {
        'data.shopProfile': {
            immediate: true,
            handler(newVal, oldVal) {
                this.profile = newVal;
            }
        }
    },

    methods: {
        onPageChange(page) {
            this.page = page;
            this.updateQuery();
        },
        updateQuery(){
            const shopId = this.data.shop.slug;
            let query = {page: this.page, type: this.type};

            if (this.$route.query.name) {
                query.name = this.$route.query.name;
                this.search.name = this.$route.query.name;
            }

            this.$router.push({
                path: `/cua-hang/${shopId}`,
                query: query
            });

            this.loadChild();
        },
        async loadChild() {
            const userId = this.authData ? this.authData.user.id: '';
            const res = await ShopRequest.getHomeDataWeb(this.data.shop.slug, this.page, this.sort, this.type, this.search, userId);
            if (!res.err_code) {
                let data = {
                    shop: res.shop,
                    categories: res.categories,
                    searchPrice: res.search_price,
                    paginate: res.paginate
                }

                this.$emit('loadChild', data);
            }
        },
        initSlider() {
            $(this.$refs.companySlider).show().slick({
                infinite: true,
                autoplay: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                arrows:true,
                prevArrow: "<img style='height:100%; z-index: 100; left: 0' class='prev slick-prev' src='/assets/img/shop/prev-arrow.png'>",
                nextArrow: "<img style='height:100%; z-index: 100; right: 0' class='next slick-next' src='/assets/img/shop/next-arrow.png'>"
            });
            $(this.$refs.categorySlider).show().slick({
                infinite: true,
                autoplay: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                arrows:true,
                prevArrow: "<img style='height:100%; z-index: 100; left: 0' class='prev slick-prev' src='/assets/img/shop/prev-arrow.png'>",
                nextArrow: "<img style='height:100%; z-index: 100; right: 0' class='next slick-next' src='/assets/img/shop/next-arrow.png'>"
            });
        },
        navigate(tab, index) {
            const el = this.boxItems[index];

            if (el) {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(el).offset().top - 120
                }, 500);
            }

        },
        onResize() {
            // this.onScroll();
        },
        // onScroll() {
        //     const len = this.boxItems.length;
        //     for (let i = 0; i < len; i++) {
        //          const b = this.boxItems[i];
        //          const top = b.getBoundingClientRect().top;
        //          if (top < 140 && top >= 0) {
        //              this.activeTabId = 'item-' + (i+1);
        //              break;
        //          }
        //     }
        //
        //     let bannerLength = 326;
        //     let removeSidebar = 300;
        //     if (this.data.shop.banner) {
        //         bannerLength = 802;
        //         removeSidebar = 700;
        //     }
        //
        //     if (window.scrollY >= bannerLength) {
        //         sideBarWidth = (this.$refs.sidebarCol.offsetWidth - 30) + 'px';
        //         this.$refs.sidebar.setAttribute('style', 'position: fixed; top:138px; width:' + sideBarWidth)
        //     } else {
        //         this.$refs.sidebar.removeAttribute('style')
        //     }
        //     let shopInfoHeight = document.getElementById('shop-info').offsetHeight;
        //
        //     if (window.scrollY > shopInfoHeight + removeSidebar) {
        //         this.$refs.sidebar.removeAttribute('style');
        //     }
        // },
    },
    beforeUnmount() {
        // window.removeEventListener('scroll', this.onScroll);
        // window.removeEventListener('resize', this.onResize);
    },
    mounted() {
        this.boxItems = document.querySelectorAll('.box-header');
        // window.addEventListener('scroll', this.onScroll);
        // window.addEventListener('resize', this.onResize);
        this.initSlider();
    },
}
</script>

<style scoped lang="scss">
@import "../../assets/css/shopDetail";
</style>
