<template>
    <div class="wrap-container" id="post-list">
        <div class="row post-title">
            <div class="col-lg-2 title-text">
	            {{ $t('newsEvent')}}
            </div>
            <div class="col-lg-10" style="margin: auto">
                <div class="title-line"></div>
            </div>
        </div>
        <div class="row row-post">
            <div class="col-lg-3 col-md-3 col-6" v-for="(post) in posts" :key="post.id">
                <router-link :to="{name: 'PostDetail', params: {id: post.slug}}">
                    <div class="post">
                        <div class="post-image">
                            <img v-lazy="post.thumbnail ? post.thumbnail.uri : '/assets/img/post.png'" alt="thumb-post">
                        </div>
                        <div class="post-content">
                            <div class="title">
                                {{post.title}}
                            </div>
                            <div class="publish-time">
                                {{d(post.created_at)}}
                            </div>
                        </div>

                    </div>
                </router-link>
            </div>
        </div>
        <div style="text-align: center; margin-top: 15px">
            <router-link :to="{name: 'PostList'}">
                <button class="view-more" type="button">
	                {{ $t('viewMore')}}
                </button>
            </router-link>
        </div>
    </div>

</template>

<script>
    import moment from 'moment'

export default {
    name: "ListPost",
    props: ['posts'],
    methods: {
        d(tzTime) {
            if (!tzTime) {
                return '';
            }
            const m =  moment(tzTime);
            // if (m.format('YYYY') == (new Date).getFullYear()) {
            //     return m.format('DD/MM HH:mm')
            // }

            return m.format('DD/MM/YYYY HH:mm')
        },
    }
}
</script>

<style scoped lang="scss">
@media (min-width: 1200px) {
    .container {
        max-width: 1190px;
    }

}

#post-list {
    font-family: 'Inter', sans-serif;
    margin-bottom: 32px;

    .post-title {
        margin-bottom: 24px;

        .title-text {
            font-size: 18px;
            line-height: 35px;
            font-weight: 700;
            color: #000000;
            text-transform: uppercase;
        }

        .title-line {
            background: rgba(0, 0, 0, 0.1);
            padding: unset;
            border-radius: 6px;
            height: 5px;
            margin: auto;
        }
    }

    .post {
        background: #ffffff;
        box-shadow: 0 8px 32px -4px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        overflow: hidden;
        cursor: pointer;

        .post-image {
            /*max-height: 200px;*/
            border-radius: 8px 8px 0 0;
            margin-bottom: 11px;

            img {
                width: 100%;
                min-height: 200px;
                object-fit: cover;
            }

        }

        .post-content {
            padding: 11px 16px 17px;

            .title {
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: rgba(22, 22, 22, 0.86);
                /*padding-left: 16px;*/
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-bottom: 4px;
            }

            .publish-time {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: rgba(22, 22, 22, 0.38);
            }
        }
    }

    .row-post {
        flex-wrap: nowrap;
        overflow: hidden;
    }

    .view-more {
        font-size: 16px;
        font-weight: 500;
        border: 1px solid #DB0000;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 10px 48px;
        background: #ffffff;
        color: #db0000;
        margin-top: 5px;
        margin-bottom: 32px;

        &:hover {
            color: #ffffff;
            background: #db0000;
        }
    }
}
</style>
