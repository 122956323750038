import BaseRequest from "../BaseRequest";

class CraftVillageRequest extends BaseRequest {
    constructor() {
        super('CraftVillageRequest');
    }

    async getCraftVillageAll(page: string) {
        return super.dispatch('getCraftVillageAll', page)
    }
    async getCraftVillageHome() {
        return super.dispatch('getCraftVillageHome')
    }
    async getCraftVillageById(id: string, page: string): Promise<any> {
        return super.dispatch('getCraftVillageById', id, page)
    }
    async getStoreCraftVillageById(id: string, page: string): Promise<any> {
        return super.dispatch('getStoreCraftVillageById', id, page)
    }
}

export default new CraftVillageRequest()
