<template>
    <div class="wrap-container" id="cart-checkout">
        <div class="row">
            <div class="col-lg-9">
                <div class="wrap-content">
                    <div class="row-header">
                        <p class="header-label">
                            {{ $t('chooseAddress')}}
                        </p>
                    </div>
                    <div class="addresses">
                        <div class="address-list">
                            <div :key="address.id" class="address-item" v-for="address in addresses">
                                <div class="row" style="padding: 15px;">
                                    <div class="col-lg-10 col-md-10 col-12 mb-mobile">
                                        <div class="row">
                                            <div class="col-2 padding-address" style="">
                                                <input type="radio" name="radio" style="width: 25px; height: 25px"
                                                       @change="toggleDefaultAddr(address)"
                                                       :checked="address.is_default"/>
                                                <span class="checkmark"/>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-10">
                                                <div>
                                                    <strong> {{ address.name }}</strong>
                                                </div>
                                                <div>
                                                    {{ address.phone }}
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-12">
                                                <em>
                                                    {{ address.decoded }}
                                                </em>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-2 col-md-2 col-12" style="display: flex; justify-content: end;">
                                        <address-form-component :id="address.id"></address-form-component>
                                        <a class="btn-delete" @click="removeAddress(address)" style="margin-left: 20px">
                                            <img src="/assets/img/cart/ic_trash.svg" alt=""/>
                                        </a>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <address-form-component></address-form-component>
                    </div>
                </div>

                <div class="wrap-content">
                    <div class="price-tab-bar">
                        <div class="tab-bar" :class="{ active: tabPriceIndex === 1 }" @click="setTabPrice(1)">
                            <p>{{ $t('tabSPND')}}</p>
                        </div>
                        <div class="tab-bar" :class="{ active: tabPriceIndex === 2 }" @click="setTabPrice(2)">
                            <p>{{ $t('tabSPQT')}}</p>
                        </div>
                    </div>
                </div>
                <div class="wrap-content">
                    <div class="row-item">
                        <div class="checkbox">
                            <img src="/assets/img/cart/ic_check.svg" alt=""/>
                        </div>
                        <div class="col-product">
                            <p class="col-title">{{ $t('product')}}</p>
                        </div>
                        <div class="col-price">
                            <p class="col-title">{{ $t('price')}}</p>
                        </div>
                        <div class="col-quantity">
                            <p class="col-title">{{ $t('quantity')}}</p>
                        </div>
                        <div class="col-price" v-if="tabPriceIndex == 2">
                            <p class="col-title">{{ $t('vat')}}</p>
                        </div>
                        <div class="col-price" v-if="tabPriceIndex == 2">
                            <p class="col-title">{{ $t('shipQT')}}</p>
                        </div>
                        <div class="col-total">
                            <p class="col-title">{{ $t('totalAmount')}}</p>
                        </div>
                        <img src="/assets/img/cart/ic_trash.svg" alt=""/>
                    </div>
                </div>
                <div class="wrap-content" v-for="(item, index) in groupMap" :key="index +1">
                    <div class="row-header">
<!--                        <div class="checkbox">-->
<!--                            <img src="/assets/img/cart/ic_check.svg" alt=""/>-->
<!--                        </div>-->
                        <label class="custom-input-checkbox">
                            <input type="checkbox" :value="item.id" v-model="shopChecked" @change="checkAllShop(item)">
                            <span class="checkmark"></span>
                        </label>
                        <a class="header-label" :href="'/cua-hang/' + item.shop.slug">
                            {{ lang == 'cn' && item.name_cn ? item.name_cn : lang == 'en' && item.name_en ? item.name_en : item.name }}
                            <i class="fas fa-angle-right"></i>
                        </a>
                        <a class="header-text" @click="showChat(item.shop)">
                            <img src="/assets/img/cart/ic_chat.svg" alt=""/>
                            <p>{{ $t('chatWithShop')}}</p>
                        </a>
                    </div>
                    <div class="row-item row-item-product" v-for="(product, index) in item.items" :key="'product-' + index">
                        <div class="row-mobile">
                            <label class="custom-input-checkbox">
                                <input type="checkbox" :value="product.id" v-model="item.selected" @change="checkItem(item, product)">
                                <span class="checkmark"></span>
                            </label>
                            <div class="col-product">
                                <a :href="'/san-pham/' + product.product.slug" target="_blank">
                                    <img class="img-cart" :src="product.product.type == 2 && product.sku ? product.sku.image : product.product.thumb.uri" alt=""/>
                                </a>
                                <div>
                                    <p class="product-name">
                                        <span style="color: #db0000; font-size: 13px" v-if="tabPriceIndex == 2" v-text="product.type_international == 2 ? '(' + $t('tabNKTM') + ')' : '(' + $t('tabNKTD') + ')'"></span><br/>
                                        <a :href="'/san-pham/' + product.product.slug" target="_blank">
                                            {{ lang == 'cn' && product.product.name_cn ? product.product.name_cn : lang == 'en' && product.product.name_en ? product.product.name_en : product.product.name }}
                                        </a>
                                    </p>
                                    <template v-if="product.packs && product.packs.length > 0">
                                        <p class="product-pack" v-for="pack in product.packs" :key="'pack' + pack.id">
                                            {{ pack.type ? pack.type : ''}} - {{ pack.name ? pack.name : '' }}

                                        </p>
                                    </template>
                                    <template v-if="product.sku && product.sku.attributes">
                                        <p class="product-pack" v-for="sku in product.sku.attributes" :key="'sku' + sku.id">
                                            {{ lang == 'cn' && sku.name_cn ? sku.name_cn : lang == 'en' && sku.name_en ? sku.name_en : sku.name ?? ''}} - {{ lang == 'cn' && sku.value_cn ? sku.value_cn : lang == 'en' && sku.value_en ? sku.value_en : sku.value ?? '' }}

                                        </p>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="row-mobile-price">
                            <div class="col-price">
                                <p class="text-price" v-text="numberFormat(product.price) + ' đ'"></p>
                                <p class="text-price-discount" v-text="product.price < product.price_origin ? numberFormat(product.price_origin) + ' đ' : ''"></p>
                            </div>
                            <div class="col-quantity">
                                <div class="product-quantity">
                                    <a class="btn-quantity" @click="updateQuantity(product, intVal(product.quantity)-1)">
                                        <img src="/assets/img/cart/ic_minus.svg" alt=""/>
                                    </a>
                                    <input
                                            v-model="product.quantity"
                                            type="text"
                                            maxlength="6"
                                            pattern="[0-9]*"
                                            placeholder=""
                                            @input="updateQuantity(product, product.quantity)"
                                    >
                                    <a class="btn-quantity dark" @click="updateQuantity(product, intVal(product.quantity)+1)">
                                        <img src="/assets/img/cart/ic_plus.svg" alt=""/>
                                    </a>
                                </div>
                            </div>
                            <div class="col-vat" v-if="tabPriceIndex == 2">
                                <p class="text-price">{{numberFormat(product.quantity * product.vat)}} đ</p>
                            </div>
                            <div class="col-ship-qt" v-if="tabPriceIndex == 2">
                                <p class="text-price">{{numberFormat(product.quantity * product.ship_quocte)}} đ</p>
                            </div>
                            <div class="col-total">
                                <p class="text-price danger">{{numberFormat(product.quantity * product.price)}} đ</p>
                            </div>
                        </div>

                        <a @click="removeItem(product, index)" class="delete-item">
                            <img src="/assets/img/cart/ic_trash.svg" alt=""/>
                        </a>
                    </div>
                    <div class="row-footer">
                        <span class="footer-title">{{ $t('totalAmountInShop')}} </span>
                        <span class="footer-text"> {{ numberFormat(item.totalAmount) }} đ</span>
                    </div>
                </div>

                <div class="wrap-content">
                    <div class="row-header">
                        <p class="header-label">
	                        {{ $t('voucher')}}
                        </p>
                        <a class="header-text">
<!--                            <p @click="getVouchers">Chọn Voucher</p>-->
                        </a>
                    </div>
                    <div class="row-voucher">
                        <div class="row-input">
                            <img src="/assets/img/cart/ic_voucher.svg" alt=""/>
                            <input
                                    type="text"
                                    v-model="order.voucher_code"
                                    :placeholder="$t('typeVoucher')"
                            >
                        </div>
                        <a class="btn-action" href="javascript:;" @click="applyVoucher(order.voucher_code)">
                            <span>{{ $t('apply')}}</span>
                        </a>
                    </div>
                </div>

                <div class="wrap-content">
                    <div class="row-header">
                        <img src="/assets/img/cart/ic_info.svg" alt="" style="margin-right: 15px">
                        <p class="header-label">
	                        {{ $t('calTimeShip')}}
                        </p>
                        <a class="header-text" @click="showChat(groupMap[0].shop)" v-if="groupMap && groupMap[0] && groupMap[0].shop">
                            <img src="/assets/img/cart/ic_chat.svg" alt=""/>
                            <p>{{ $t('chatWithShop')}}</p>
                        </a>
                    </div>
                    <div class="row row-shipping">
                        <div class="col-md-3 mb-3">
                            <p class="content-text">{{ $t('shipType')}}</p>
                        </div>
                        <div class="col-md-9">
                            <p class="content-text text-bold">{{ $t('flashShip')}}</p>
                        </div>
                        <div class="col-md-3">
                            <p class="content-text">{{ $t('shipProcess')}}</p>
                        </div>
                        <div class="col-md-9">
                            <div class="content-shipping">
                                <div class="content-item">
                                    <p class="content-text text-bold">{{ $t('payment')}}</p>
                                    <div class="content-mark">
                                        <span></span>
                                    </div>
                                    <p class="content-text">Today</p>
                                </div>
                                <div class="content-info">
                                    <p class="content-text">{{ $t('prepareProduct')}}</p>
                                </div>
                                <div class="content-item">
                                    <p class="content-text text-bold">{{ $t('ship')}}</p>
                                    <div class="content-mark">
                                        <span></span>
                                    </div>
                                    <p class="content-text"></p>
                                </div>
                                <div class="content-info">
                                    <p class="content-text"></p>
                                </div>
                                <div class="content-item">
                                    <p class="content-text text-bold">Nhận</p>
                                    <div class="content-mark">
                                        <span></span>
                                    </div>
                                    <p class="content-text"></p>
                                </div>
                                <div class="line-mark">

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row-footer">
                        <span class="footer-title">{{ $t('shipFee')}}</span>
                        <span class="footer-text">{{ $t('deal')}}</span>
                    </div>
                </div>

                <div class="wrap-content">
                    <div class="row-header">
                        <p class="header-label">
	                        {{ $t('paymentMethod')}}
                        </p>
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="col-payment">
                            <div class="row-payment">
                                <div class="checkbox circle active">
                                    <img src="/assets/img/cart/ic_check.svg" alt=""/>
                                </div>
                                <p class="payment-text active">{{ $t('shipCOD')}}</p>
                            </div>
<!--                            <div class="row-payment">-->
<!--                                <div class="checkbox circle">-->
<!--                                    <img src="/assets/img/cart/ic_check.svg" alt=""/>-->
<!--                                </div>-->
<!--                                <p class="payment-text">Thanh toán bằng thẻ quốc tế Visa, Master, JCB</p>-->
<!--                                <img class="logo-payment" src="/assets/img/cart/img_visa.png" alt=""/>-->
<!--                                <img class="logo-payment" src="/assets/img/cart/img_card.png" alt=""/>-->
<!--                            </div>-->
<!--                            <div class="row-payment">-->
<!--                                <div class="checkbox circle">-->
<!--                                    <img src="/assets/img/cart/ic_check.svg" alt=""/>-->
<!--                                </div>-->
<!--                                <p class="payment-text">Thanh toán bằng Paypal</p>-->
<!--                                <img class="logo-payment" src="/assets/img/cart/img_paypal.png" alt=""/>-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>

                <div class="wrap-content">
                    <div class="row-header">
                        <p class="header-label">
	                        {{ $t('note')}}
                        </p>
                    </div>
                    <textarea class="text-message" v-model="order.note" :placeholder="$t('typeNote')">

                    </textarea>
                </div>

                <div class="d-flex align-items-center">
                    <div class="checkbox active">
                        <img src="/assets/img/cart/ic_check.svg" alt=""/>
                    </div>
                    <p class="title-term">{{ $t('agreeShare')}}p </p>
                </div>

            </div>
            <div class="col-lg-3">
                <div class="payment-count">
                    <div>
                        <div class="d-flex justify-content-between">
                            <p class="payment-label">{{ $t('preCalculate')}}</p>
                            <p class="payment-info" v-text="numberFormat(amountTotal) + ' đ'"></p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p class="payment-label">{{ $t('discount')}}</p>
                            <p class="payment-info"> {{ discounted > 0 ? numberFormat(discounted) : 0}} đ</p>
                        </div>
                    </div>
                    <div class="payment-footer">
                        <div class="d-flex justify-content-between">
                            <p class="payment-label">{{ $t('sum')}}</p>
                            <p class="payment-total" v-text="numberFormat(amountTotal - discounted) + ' đ'"></p>
                        </div>
                        <div style="text-align: center;">
                            <loading v-if="isLoading" :is-loading="true"></loading>
                        </div>

                        <a class="btn-buy" @click="save">
                            <p>{{ $t('buy')}}</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" tabindex="-1" role="dialog" ref="SuccessModal" id="SuccessModal">
            <div class="modal-dialog modal-position" role="document">
                <div class="modal-content" style="margin: auto; border-radius: 30px; padding: 30px">
                    <div class="checkout-result">
                        <div class="result-title">
                            <i class="far fa-check-circle" style="color: #27AE60; font-size: 6rem"></i>
                            <div class="result-title-success">{{ $t('orderSuccess')}}</div>
<!--                            <div class="order-code">-->
<!--                               Mã đơn hàng: <span>{{ orderCode }}</span>-->
<!--                            </div>-->

                        </div>
                        <div class="result-content">
                            <p v-if="lang == 'vi'">
                                Cảm ơn Quý khách hàng đã sử dụng dịch vụ của <span style="font-weight: 700; font-size: 16px; color: #DB0000">Elim</span> !
                            </p>
	                        <p v-else>
		                        {{ $t('thankYou')}}
	                        </p>
                            <p v-if="lang == 'vi'">
                                Mọi thắc mắc vui lòng liên hệ tổng đài
                                <a :href="'tel: 096.274.1789'" style="font-weight: 700">096.274.1789</a> để được giải đáp
                            </p>
	                        <p v-else>
		                        {{ $t('contactContent')}}
	                        </p>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div class="modal" tabindex="-1" role="dialog" ref="FailedModal" id="FailedModal">
            <div class="modal-dialog modal-position" role="document">
                <div class="modal-content" style="margin: auto; border-radius: 30px; padding: 30px">
                    <div class="checkout-result">
                        <div class="result-title">
                            <i class="fas fa-times-circle" style="color: #DB0000; font-size: 6rem"></i>
                            <div class="result-title-failed">
	                            {{ $t('failureOrder')}}
                            </div>
                            <div class="error-message">
                                {{ errorMessage }}
                            </div>
                        </div>
                        <div class="result-content">
	                        <p v-if="lang == 'vi'">
		                        Cảm ơn Quý khách hàng đã sử dụng dịch vụ của <span style="font-weight: 700; font-size: 16px; color: #DB0000">Elim</span> !
	                        </p>
	                        <p v-else>
		                        {{ $t('thankYou')}}
	                        </p>
	                        <p v-if="lang == 'vi'">
		                        Mọi thắc mắc vui lòng liên hệ tổng đài
		                        <a :href="'tel: 096.274.1789'" style="font-weight: 700">096.274.1789</a> để được giải đáp
	                        </p>
	                        <p v-else>
		                        {{ $t('contactContent')}}
	                        </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <chat ref="chatBox" v-if="showChatBox"/>
</template>

<script>
import CartRequest from "@/api/requests/CartRequest";
import Loading from "@/includes/Loading";
import {isEmpty, numberFormat, stringTruncate, intVal} from "@/lib/utils";
import AddressSelect from "@/views/AddressSelect";
import AddressRequest from "@/api/requests/AddressRequest";
import AddressForm from "@/views/AddressForm";
import AddressFormComponent from "@/components/carts/AddressFormComponent";
import OrderRequest from "@/api/requests/OrderRequest";
import VoucherRequest from "@/api/requests/VoucherRequest";
import store from "@/store";
import Chat from "@/components/Chat";
import toastr from 'toastr';
import router from "@/router";

export default {

    name: "Cart",
    components: {
        AddressFormComponent,
        AddressForm,
        AddressSelect,
        Loading,
        Chat
    },
    computed: {
        authData: () => store.state.Auth,
        showChatBox: () => store.state.showChatBox
    },
    data() {
        let query = router.currentRoute.value.query;
        return {
            items: [],
            timeoutIDs: [],
            readonly: false,
            isLoading: false,
            amountOrigin: 0,
            amountTotal: 0,
            discounted: 0,
            isCheckVoucher: false,
            cart: {},
            groupMap: [],
            addresses: [],
            vouchers: [],
            shopChecked: [],
            order: {
                addressId: '',
                receipt_type: 0,
                payment_method: 0,
                voucher_code: '',
                discounted: 0,
                note: '',
                type: 1
            },
            orderCode: '',
            errorMessage: '',
	        lang: store.state.locale,
            tabPriceIndex: query.type ? intVal(query.type) : 1,
        }
    },
    mounted() {
        document.title = this.$t('cart');
        this.getCart();
        this.getAddress();

    },
    methods: {
        numberFormat, stringTruncate, intVal,
        async getCart() {
            this.isLoading = true;
            const res = await CartRequest.get(this.tabPriceIndex);
            this.isLoading = false;
            this.items = res.items;
            this.cart = res.cart;
            this.amountOrigin = res.cart.amount_origin;
            this.amountTotal = res.cart.amount;
            this.groupMap = this.getItemGroup(this.items);
            await this.checkAllItem();
            store.commit('SetCountCartItem', res.items.length);

        },
        async getAddress() {
            const data = await AddressRequest.get();

            if (!data) {
                alert('Vui lòng thêm địa chỉ để tiếp tục!');
                return;
            }
            this.addresses = data;
        },

        showChat(shop) {
            store.commit('SetShowChatBox', false);
            // if (!this.authData) {
            //     if (confirm('Bạn cần đăng nhập để có thể gửi tin nhắn đến shop!')) {
            //         this.$router.push('/dang-nhap');
            //     }
            // }
            setTimeout(() => {
                store.commit('SetShowChatBox', true);
            }, 100);
            setTimeout(() => {
                this.$refs.chatBox.createConversation(shop);
            }, 100);
        },

        getItemGroup(items) {
            let groups = [];
            items.forEach(item => {
                if (!groups[item.shop.id]) {
                    groups[item.shop.id] = {
                        items: [],
                        id: item.shop.id,
                        totalAmount: 0,
                        shop: item.shop,
                        name: item.shop.name,
                        name_en: item.shop.name_en,
                        name_cn: item.shop.name_cn,
                        selected: []
                    }
                }

                groups[item.shop.id].items.push(item);

                if (groups[item.shop.id].items.length > 0) {
                    let totalAmount = 0;
                    let selected = [];
                    groups[item.shop.id].items.forEach(product => {
                        totalAmount += (product.quantity * product.price + product.quantity * product.vat + product.quantity * product.ship_quocte);

                        if (product.selected === 1) {
                            selected.push(product.id);
                        }
                    })
                    groups[item.shop.id].totalAmount = totalAmount;
                    groups[item.shop.id].selected = selected;
                }
            })
            return Object.values(groups);
        },
        updateQuantity(item, value) {
            value = Math.max(parseInt(value) || 1, 1);
            item.quantity = value;
            item.amount = item.quantity * item.price + item.quantity * item.vat + item.quantity * item.ship_quocte;

            if (this.timeoutIDs[item.id]) {
                clearTimeout(this.timeoutIDs[item.id]);
            }

            this.timeoutIDs[item.id] = setTimeout(async () => {
                const res = await CartRequest.updateQuantity(
                    item.id,
                    item.quantity,
                    this.tabPriceIndex
                );
                if (res.err_code === 0) {
                    item.price = res.price;
                    item.price_origin = res.price_origin;
                    this.amountOrigin = res.amount_origin;
                    this.amountTotal = res.amount;
                    await this.getCart();

                } else {
                    alert(res.message);
                    // item.quantity = 1;
                }
            }, 300)

        },
        async applyVoucher(code) {

            if (!code) {
                toastr.error('Bạn chưa nhập mã giảm giá');
                return;
            }
            const res = await CartRequest.applyVoucher(
                code
            );
            if (res.err_code === 0) {
                this.discounted = res.discounted;
                this.isCheckVoucher = true;
                this.order.discounted = res.discounted;
                toastr.success('Áp dụng mã giảm giá thành công');

            } else {
                this.discounted = 0;
                this.isCheckVoucher = false;
                toastr.error(res.messages);
            }

        },
        async removeItem(item, index) {
            if (!window.confirm('Xóa sản phẩm: ' + item.product.name + " ?")) {
                return;
            }

            const res = await CartRequest.remove(item.id, this.tabPriceIndex)

            if (res.err_code === 0) {
                toastr.success('Đã xóa sản phẩm');
                await this.getCart();
            } else {
                toastr.error(res.message);
            }
        },
        checkToBuy() {
            if (this.items.length === 0) {
                alert('Bạn vui lòng chọn ít nhất 1 sản phẩm trước khi đến bước thanh toán');
                return;
            } else {
                location.href = '/thong-tin-nhan-hang'
            }
        },
        async toggleDefaultAddr(addr) {
            this.addresses.forEach((a) => {
                a.is_default = (a.id === addr.id);
            });

            const res = await AddressRequest.setDefaultAddress(addr.id.toString())

        },
        async getDefaultAddress() {
            const data = await AddressRequest.get();
            if (data && data[0]) {
                let address = data[0];
                this.order.addressId = address.id
            }

        },
        async save() {
            this.isLoading = true;
            if (!this.items || this.items.length === 0) {
                toastr.error('Vui lòng chọn hoặc thêm sản phẩm trước khi thanh toán');
                this.isLoading = false;
                return;
            }
            await this.getDefaultAddress();

            if (!this.order.addressId) {
                toastr.error('Vui lòng thêm địa chỉ nhận hàng trước khi thanh toán');
                this.isLoading = false;
                return;
            }
            if (this.order.voucher_code && !this.isCheckVoucher) {
                toastr.error('Vui lòng bấm áp dụng mã giảm giá trước khi đặt hàng');
                this.isLoading = false;
                return;
            }
            let hasErrorQuantity = false;
            let hasInventory = true;
            this.items.forEach((item, index) => {
                if (item.selected == 1) {
                    let quantity = item.quantity;

                    if (item.packs && item.packs.length > 0) {
                        item.packs.forEach((pack, idx) => {
                            if (pack.inventory_type == 2 && pack.inventory_quantity < quantity) {
                                toastr.error("Không đủ số lượng sản phẩm "+ item.product.name +". Bạn vui lòng chọn sản phẩm khác!");
                                hasInventory = false;
                            }
                        });
                    }

                    if (item.product) {
                        if (!isEmpty(item.product.priceByQuantities) && (!item.product.price_discount || item.product.price == item.product.price_discount)) {
                            let minQuantity = item.product.priceByQuantities[0].quantity_from;
                            if (quantity < minQuantity) {
                                toastr.error("Vui lòng đặt tối thiểu " + minQuantity + " " + item.product.unit + " với sản phẩm " + item.product.name);
                                hasErrorQuantity = true;
                            }
                        }

                        if (hasInventory && item.product.priceByQuantities && item.product.priceByQuantities.length) {
                            item.product.priceByQuantities.forEach((price, idx) => {
                                if (quantity >= price.quantity_from && (quantity <= price.quantity_to || idx == item.product.priceByQuantities.length - 1)) {
                                    if (price.inventory_type ==2 && price.inventory_quantity < quantity) {
                                        toastr.error("Không đủ số lượng sản phẩm "+ item.product.name +". Bạn vui lòng chọn sản phẩm khác!");
                                        hasInventory = false;
                                    }
                                }
                            });
                        }
                    }
                }
            })

            if (hasErrorQuantity || !hasInventory) {
                this.isLoading = false;
                return;
            }
            this.order.type = this.tabPriceIndex;

            const res = await OrderRequest.createV4(this.order);
            this.isLoading = false;
            if (res.err_code === 0) {
                this.order = res.order;
                this.errorMessage = '';

                // toastr.success('Đặt hàng thành công');
                window.$('#SuccessModal').modal('show');

                setTimeout(function(){
                    if (this.authData) {
                        location.href = '/trang-ca-nhan?tabMain=don-hang-cua-toi';
                    } else {
                        location.href = '/';
                    }

                }, 2000);
            } else {
                this.errorMessage = res.message;
                window.$('#FailedModal').modal('show');
            }

        },
        async removeAddress(address) {
            if (address.id && window.confirm('Xóa địa chỉ này?')) {
                const res = await AddressRequest.remove(address.id);
                if (res.err_code === 0) {
                    toastr.success('Đã xóa');
                    await this.getAddress();
                } else {
                    toastr.error('Vui lòng kiểm tra lại');
                }
            }
        },
        async getVouchers() {
            const data = await VoucherRequest.get();

            if (data.vouchers && data.vouchers.length === 0) {
                alert('Hiện tại không có chương trình khuyến mãi nào dành cho quý khách!');
                return;
            }
            this.vouchers = data.vouchers;
        },
        async checkAllShop(shop) {
            if (this.shopChecked.includes(shop.id)) {
                shop.items.forEach(i => {
                    if (!shop.selected.includes(i.id)) {
                        shop.selected.push(i.id);
                        i.selected = 1;
                    }
                })

            } else {
                shop.selected = [];
                shop.items.forEach(i => {
                    i.selected = 0;
                })
            }

            const res = await CartRequest.updateItems(JSON.stringify(shop.items), this.tabPriceIndex);

            if (res.err_code !== 0) {
                toastr.error(res.message);
            } else {
                this.items = res.items;
                this.cart = res.cart;
                this.amountOrigin = res.cart.amount_origin;
                this.amountTotal = res.cart.amount;
                this.groupMap = this.getItemGroup(this.items);
            }
        },
        async checkItem(shop, item) {
            if (shop.selected.includes(item.id)) {
                item.selected = 1;
            } else {
                item.selected = 0;
            }

            const res = await CartRequest.updateItems(JSON.stringify(shop.items), this.tabPriceIndex);

            if (res.err_code !== 0) {
                toastr.error(res.message);
            } else {
                this.items = res.items;
                this.cart = res.cart;
                this.amountOrigin = res.cart.amount_origin;
                this.amountTotal = res.cart.amount;
                this.groupMap = this.getItemGroup(this.items);
            }
        },
        async checkAllItem() {
            if (this.groupMap && this.groupMap.length > 0) {
                this.groupMap.forEach((item) => {
                    this.checkAllShop(item);
                })
            } else {
                this.amountOrigin = 0;
                this.amountTotal = 0;
            }
        },
        async setTabPrice(index) {
            this.tabPriceIndex = index;
            await this.getCart();
        },
    }

}
</script>

<style scoped lang="scss">
@import "../assets/css/address";
@import "../assets/css/cart";
@import "../assets/css/product";

.cart-total {
  position: relative;
  max-width: unset;
  padding: 10px 30px 10px 50px;
}

.title-bar {
  display: flex;
  background: #e02e24;;
  height: 50px;
  padding: 10px;

  .title-bar-back {
    margin-right: 10px;
  }

  .title-bar-content {
    font-size: 18px;
    color: #ffffff;
    font-weight: bold;
    margin: auto;
  }
}
.delete-item {
    &:hover {
        cursor: pointer;
    }
}
.btn-buy {
    &:hover {
        cursor: pointer;
    }
}
.btn-delete {
    &:hover {
        cursor: pointer;
    }
}
.result-title {
  text-align: center;
}
.result-title-success {
    color: #27AE60;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 15px;
}
.result-title-failed {
    color: #DB0000;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 15px;
}
.order-code {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  span {
    font-weight: 700;
    color: #DB0000;
  }
}
.result-content {
  text-align: center;
  p {
    font-weight: 500;
    margin-bottom: 5px;
  }
}

.modal-position {
    max-width: 40%;
    top: 15%;
}

@media screen and (max-width: 768px){
  .modal-position {
    max-width: unset;
    top: 20%;
  }

}

.price-tab-bar {
    display: flex;
    align-items: center;
    padding: 0;
    margin-top: 25px;
    width: 70%;

    .tab-bar {
        padding: 12px 15px;
        background: #EDEDEF;
        //border-top: 1px solid #DADBDE;
        box-sizing: border-box;
        box-shadow: 0px 8px 32px -4px rgba(0, 0, 0, 0.05);
        cursor: pointer;
        width: 50%;
        text-align: center;

        p {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 0;
            color: #626574;
        }

        &.active {
            background: #FFFFFF;
            box-shadow: 0px 8px 32px -4px rgba(0, 0, 0, 0.05);
            border-bottom: 2px solid #DB0000;


            p {
                color: #DB0000;
            }
        }
    }
}
</style>
