<template>
  <div id="product-detail">
    <div class="top-category">
      <!--      <Breadcrumb-->
      <!--          :routers="[-->
      <!--          { name: 'Rao vặt', to: '/rao-vat' },-->
      <!--          { name: product?.title, to: `/thong-tin-rao-vat/${product?.id}` },-->
      <!--        ]"-->
      <!--      />-->
    </div>
    <div id="contents">
      <div class="wrap-container">
        <div class="row ml-0 mr-0">
          <div class="col-xl-9 col-sm-12 col-12 product-img bg-white">
            <div class="slider-category mt-2" id="product-for">
              <div
                  class="slider-items"
                  v-for="(thumb, key) in product.subImage"
                  :key="'pfor-' + key"
              >
                <div class="large-image">
                  <img :src="thumb ? thumb?.media?.image?.src : ''" alt="" @error="loadImgErr"/>
                </div>
              </div>
            </div>
            <div class="slider-category mt-2" id="product-nav">
              <div
                  class="slider-items"
                  v-for="(thumb, key) in product.subImage"
                  :key="'pnav-' + key"
              >
                <div class="slider-box">
                  <img class="slider-image" :src="thumb?.media?.image?.src" alt="" @error="loadImgErr"/>
                </div>
              </div>
            </div>
            <h1 class="product-name mt-4 mb-4">
              {{ subStringTitle(product.title) }}
            </h1>
            <!--            <div class="row mb-4 no-margin-hoz container-price">-->
            <!--              <div>Giá:</div>-->
            <!--              <div class="txt-price">{{ numberFormat(1000000) }}đ</div>-->
            <!--            </div>-->
            <div class="row no-margin-hoz">
              <div class="col-xl-9 detail-tab-bar">
                <p>{{ $t('detailProdInfo')}}</p>
              </div>
              <div class="col-xl-9 detail-tab-content">
                <div class="tab-content">
                  <p v-html="product.title"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-12 col-12 mt-xl-0 mt-sm-2 container-product-info">
            <div class="row ml-0 mr-0 bg-white product-info">
              <!--              <div class="col-xl-12 text-center">-->
              <!--                <img-->
              <!--                    class="img-avatar"-->
              <!--                    src="https://avi.edu.vn/wp-content/uploads/2019/11/london-2393098.jpg"-->
              <!--                />-->
              <!--              </div>-->
              <div class="col-xl-12 text-center mt-4">{{ $t('postBy')}}</div>
              <div class="col-xl-12 text-center font-weight-bold">
                <router-link :to="{path:product?.id ? '/thong-tin-nguoi-dang/'+product?.from.id:'',query:{
                   currentTab:currentTab
                }}">
                  {{ product.from?.name }}

                </router-link>
              </div>
              <!--              <div class="col-xl-12 text-center font-weight-bold">-->
              <!--                {{ product?.userCreate?.phone }}-->
              <!--              </div>-->
              <!--              <button class="col-xl-12 mt-4 btn-contact">Liên hệ</button>-->
            </div>
          </div>
        </div>

        <div class="bg-white mt-3 rounded-lg">
          <h3 class="ml-3 pt-3">{{ $t('newestPost')}}</h3>
          <div v-if="!!products.length" class="bg-white p-3 rounded-circle">
            <div class="content-right">
              <div v-for="(item,index) in products" :key="item.id">
                <item :item="item" :index="index"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <chat ref="chatBox" v-if="showChatBox"/>
</template>

<script>
import $ from "jquery";
// import "slick-carousel";
// import "slick-carousel/slick/slick-theme.css";
import ProductRequest from "@/api/requests/ProductRequest";
import CartRequest from "@/api/requests/CartRequest";
import ReviewerRequest from "@/api/requests/ReviewerRequest";
import store from "@/store";
import ProductItem from "@/components/ProductItem";
import Breadcrumb from "@/components/Breadcrumb";
import {
  defaultImg,
  getOption,
  isEmpty,
  numberFormat,
  siteSetTitle,
  stringTruncate,
} from "@/lib/utils";
import moment from "moment";
import Paginate from "@/components/Paginate";
import Chat from "@/components/Chat";
import toastr from "toastr";
import ModalAddress from "@/views/ModalAddress";
import data from "@/lib/data.json";
import CrawlRequest from "@/api/requests/CrawlRequest";
import Item from "@/components/classified_advertising/Item";
import config from "@/config";

export default {
  name: "ProductDetail",
  props: ["id"],
  components: {
    Chat, ProductItem, Paginate, ModalAddress, Breadcrumb, Item,
  },
  data() {
    return {
      product: {},
      currentTab: this.$route.query.currentTab,
      products: [],
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    console.log("-> this.product", this.product);
    this.load();
    this.loadNews();
  },
  updated() {
    this.initSlider();
  },
  computed: {
    authData: () => store.state.Auth,
    showChatBox: () => store.state.showChatBox,

    isInCart: function () {
      let packIds = this.getPackIds();
      if (this.product.cartItems && this.product.cartItems.length > 0) {
        return this.product.cartItems.find((item) => item.pack_ids == packIds);
      }

      return false;
    },
  },
  methods: {
    subStringTitle(title) {
      return stringTruncate(title, 100)
    },
    isEmpty,
    numberFormat,
    stringTruncate,
    async load() {
      this.isLoading = true;
      let check = 0;
      const res = await CrawlRequest.getDetail(this.id, check);
      this.product = res;
      document.title = res.title;

      this.isLoading = false;
    },
    async loadNews() {
      this.isLoading = true;

      let res = await CrawlRequest.get('', '', '', '', {
        limit: 5,
        except_id: this.id
      });
      console.log("-> res", res);
      this.products = res.data;

      this.isLoading = false;
    },
    initSlider() {
      $(function () {
        $("#product-for").not(".slick-initialized").show().slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          fade: true,
          asNavFor: "#product-nav",
        });
        $("#product-nav").not(".slick-initialized").show().slick({
          slidesToShow: 8,
          slidesToScroll: 1,
          asNavFor: "#product-for",
          centerMode: true,
          focusOnSelect: true,
        });
      });
    },
    async setFavorite(product, isFavorite) {
      product.is_favorite = isFavorite;
    },
    loadImgErr(e) {
      e.currentTarget.src = defaultImg();
    }
  },
};
</script>

<style scoped lang="scss">
@import "../assets/css/classifiedAdvertisingDetail";

</style>
