<template>
    <div id="contents">
        <div class="login-screen">
            <div class="login-header">
                <router-link class="header-logo" :to="{name: 'Home'}">
                    <img src="/assets/img/header/logo.svg" alt="">
                </router-link>
            </div>
            <div class="login-body" :style="{background: background}">
                <div class="form-wrap-login">
                    <div class="text-center p-2">
                        <img src="/assets/img/header/logo.svg" alt="" style="height: 100px; width: auto">
                    </div>

                    <div class="login-title"><label>{{ $t('signIn')}}</label></div>


                    <div style="text-align: center;">
                        <loading v-if="isLoading" :is-loading="true"></loading>
                    </div>
                    <div class="form-group p-3 mt-2">
                        <login-lim-id redirect="/"></login-lim-id>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getOption} from "@/lib/utils";
import Loading from "@/includes/Loading";
import LoginLimId from "@/components/login/LoginLimId";

export default {
    name: "Login",
    components: {
        LoginLimId,
        Loading
    },
    data() {
        const banner = getOption('banner_login_page', {});

        return {
            background: `url("${banner ? banner.uri : ''}") no-repeat fixed`,
            isLoading: false,

        }
    },
    mounted() {
        document.title = this.$t('signInElim');
    },
    methods: {

    },

}
</script>
<style scoped lang="scss">
@media screen and (max-width: 768px){
  .form-wrap-login {
    padding: 30px 20px!important;
  }
}

</style>
