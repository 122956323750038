<template>
    <div id="shop-detail--video">
        <div class="row row-content">
            <div class="col-lg-3 col-md-4 col-item">
                <div class="content-item">
                    <div class="content-video-wrap">
                        <img class="content-video" src="/assets/img/shop/fake_video.png" alt="video-thumb">
                        <div class="play-time">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                            <span>00:15</span>
                        </div>
                        <a class="btn-play" @click="showModal()">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                        </a>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p class="product-price">160.000đ</p>
                            <div class="d-flex align-items-center">
                                <span class="product-price-discount">320.000đ</span>
                                <span class="product-discount">-50%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-video-wrap">
                        <img class="content-video" src="/assets/img/shop/fake_video.png" alt="video-thumb">
                        <div class="play-time">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                            <span>00:15</span>
                        </div>
                        <a class="btn-play" @click="showModal()">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                        </a>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p class="product-price">160.000đ</p>
                            <div class="d-flex align-items-center">
                                <span class="product-price-discount">320.000đ</span>
                                <span class="product-discount">-50%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-video-wrap">
                        <img class="content-video" src="/assets/img/shop/fake_video.png" alt="video-thumb">
                        <div class="play-time">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                            <span>00:15</span>
                        </div>
                        <a class="btn-play" @click="showModal()">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                        </a>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p class="product-price">160.000đ</p>
                            <div class="d-flex align-items-center">
                                <span class="product-price-discount">320.000đ</span>
                                <span class="product-discount">-50%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-video-wrap">
                        <img class="content-video" src="/assets/img/shop/fake_video.png" alt="video-thumb">
                        <div class="play-time">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                            <span>00:15</span>
                        </div>
                        <a class="btn-play" @click="showModal()">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                        </a>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p class="product-price">160.000đ</p>
                            <div class="d-flex align-items-center">
                                <span class="product-price-discount">320.000đ</span>
                                <span class="product-discount">-50%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-video-wrap">
                        <img class="content-video" src="/assets/img/shop/fake_video.png" alt="video-thumb">
                        <div class="play-time">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                            <span>00:15</span>
                        </div>
                        <a class="btn-play" @click="showModal()">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                        </a>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p class="product-price">160.000đ</p>
                            <div class="d-flex align-items-center">
                                <span class="product-price-discount">320.000đ</span>
                                <span class="product-discount">-50%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-item">
                <div class="content-item">
                    <div class="content-video-wrap">
                        <img class="content-video" src="/assets/img/shop/fake_video.png" alt="video-thumb">
                        <div class="play-time">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                            <span>00:15</span>
                        </div>
                        <a class="btn-play" @click="showModal()">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                        </a>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p class="product-price">160.000đ</p>
                            <div class="d-flex align-items-center">
                                <span class="product-price-discount">320.000đ</span>
                                <span class="product-discount">-50%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-video-wrap">
                        <img class="content-video" src="/assets/img/shop/fake_video.png" alt="video-thumb">
                        <div class="play-time">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                            <span>00:15</span>
                        </div>
                        <a class="btn-play" @click="showModal()">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                        </a>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p class="product-price">160.000đ</p>
                            <div class="d-flex align-items-center">
                                <span class="product-price-discount">320.000đ</span>
                                <span class="product-discount">-50%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-video-wrap">
                        <img class="content-video" src="/assets/img/shop/fake_video.png" alt="video-thumb">
                        <div class="play-time">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                            <span>00:15</span>
                        </div>
                        <a class="btn-play" @click="showModal()">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                        </a>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p class="product-price">160.000đ</p>
                            <div class="d-flex align-items-center">
                                <span class="product-price-discount">320.000đ</span>
                                <span class="product-discount">-50%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-video-wrap">
                        <img class="content-video" src="/assets/img/shop/fake_video.png" alt="video-thumb">
                        <div class="play-time">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                            <span>00:15</span>
                        </div>
                        <a class="btn-play" @click="showModal()">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                        </a>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p class="product-price">160.000đ</p>
                            <div class="d-flex align-items-center">
                                <span class="product-price-discount">320.000đ</span>
                                <span class="product-discount">-50%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-video-wrap">
                        <img class="content-video" src="/assets/img/shop/fake_video.png" alt="video-thumb">
                        <div class="play-time">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                            <span>00:15</span>
                        </div>
                        <a class="btn-play" @click="showModal()">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                        </a>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p class="product-price">160.000đ</p>
                            <div class="d-flex align-items-center">
                                <span class="product-price-discount">320.000đ</span>
                                <span class="product-discount">-50%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-item">
                <div class="content-item">
                    <div class="content-video-wrap">
                        <img class="content-video" src="/assets/img/shop/fake_video.png" alt="video-thumb">
                        <div class="play-time">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                            <span>00:15</span>
                        </div>
                        <a class="btn-play" @click="showModal()">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                        </a>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p class="product-price">160.000đ</p>
                            <div class="d-flex align-items-center">
                                <span class="product-price-discount">320.000đ</span>
                                <span class="product-discount">-50%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-video-wrap">
                        <img class="content-video" src="/assets/img/shop/fake_video.png" alt="video-thumb">
                        <div class="play-time">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                            <span>00:15</span>
                        </div>
                        <a class="btn-play" @click="showModal()">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                        </a>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p class="product-price">160.000đ</p>
                            <div class="d-flex align-items-center">
                                <span class="product-price-discount">320.000đ</span>
                                <span class="product-discount">-50%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-video-wrap">
                        <img class="content-video" src="/assets/img/shop/fake_video.png" alt="video-thumb">
                        <div class="play-time">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                            <span>00:15</span>
                        </div>
                        <a class="btn-play" @click="showModal()">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                        </a>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p class="product-price">160.000đ</p>
                            <div class="d-flex align-items-center">
                                <span class="product-price-discount">320.000đ</span>
                                <span class="product-discount">-50%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-video-wrap">
                        <img class="content-video" src="/assets/img/shop/fake_video.png" alt="video-thumb">
                        <div class="play-time">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                            <span>00:15</span>
                        </div>
                        <a class="btn-play" @click="showModal()">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                        </a>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p class="product-price">160.000đ</p>
                            <div class="d-flex align-items-center">
                                <span class="product-price-discount">320.000đ</span>
                                <span class="product-discount">-50%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-video-wrap">
                        <img class="content-video" src="/assets/img/shop/fake_video.png" alt="video-thumb">
                        <div class="play-time">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                            <span>00:15</span>
                        </div>
                        <a class="btn-play" @click="showModal()">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                        </a>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p class="product-price">160.000đ</p>
                            <div class="d-flex align-items-center">
                                <span class="product-price-discount">320.000đ</span>
                                <span class="product-discount">-50%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-item item-4">
                <div class="content-item">
                    <div class="content-video-wrap">
                        <img class="content-video" src="/assets/img/shop/fake_video.png" alt="video-thumb">
                        <div class="play-time">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                            <span>00:15</span>
                        </div>
                        <a class="btn-play" @click="showModal()">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                        </a>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p class="product-price">160.000đ</p>
                            <div class="d-flex align-items-center">
                                <span class="product-price-discount">320.000đ</span>
                                <span class="product-discount">-50%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-video-wrap">
                        <img class="content-video" src="/assets/img/shop/fake_video.png" alt="video-thumb">
                        <div class="play-time">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                            <span>00:15</span>
                        </div>
                        <a class="btn-play" @click="showModal()">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                        </a>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p class="product-price">160.000đ</p>
                            <div class="d-flex align-items-center">
                                <span class="product-price-discount">320.000đ</span>
                                <span class="product-discount">-50%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-video-wrap">
                        <img class="content-video" src="/assets/img/shop/fake_video.png" alt="video-thumb">
                        <div class="play-time">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                            <span>00:15</span>
                        </div>
                        <a class="btn-play" @click="showModal()">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                        </a>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p class="product-price">160.000đ</p>
                            <div class="d-flex align-items-center">
                                <span class="product-price-discount">320.000đ</span>
                                <span class="product-discount">-50%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-video-wrap">
                        <img class="content-video" src="/assets/img/shop/fake_video.png" alt="video-thumb">
                        <div class="play-time">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                            <span>00:15</span>
                        </div>
                        <a class="btn-play" @click="showModal()">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                        </a>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p class="product-price">160.000đ</p>
                            <div class="d-flex align-items-center">
                                <span class="product-price-discount">320.000đ</span>
                                <span class="product-discount">-50%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-video-wrap">
                        <img class="content-video" src="/assets/img/shop/fake_video.png" alt="video-thumb">
                        <div class="play-time">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                            <span>00:15</span>
                        </div>
                        <a class="btn-play" @click="showModal()">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                        </a>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p class="product-price">160.000đ</p>
                            <div class="d-flex align-items-center">
                                <span class="product-price-discount">320.000đ</span>
                                <span class="product-discount">-50%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-item item-5">
                <div class="content-item">
                    <div class="content-video-wrap">
                        <img class="content-video" src="/assets/img/shop/fake_video.png" alt="video-thumb">
                        <div class="play-time">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                            <span>00:15</span>
                        </div>
                        <a class="btn-play" @click="showModal()">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                        </a>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p class="product-price">160.000đ</p>
                            <div class="d-flex align-items-center">
                                <span class="product-price-discount">320.000đ</span>
                                <span class="product-discount">-50%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-video-wrap">
                        <img class="content-video" src="/assets/img/shop/fake_video.png" alt="video-thumb">
                        <div class="play-time">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                            <span>00:15</span>
                        </div>
                        <a class="btn-play" @click="showModal()">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                        </a>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p class="product-price">160.000đ</p>
                            <div class="d-flex align-items-center">
                                <span class="product-price-discount">320.000đ</span>
                                <span class="product-discount">-50%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-video-wrap">
                        <img class="content-video" src="/assets/img/shop/fake_video.png" alt="video-thumb">
                        <div class="play-time">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                            <span>00:15</span>
                        </div>
                        <a class="btn-play" @click="showModal()">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                        </a>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p class="product-price">160.000đ</p>
                            <div class="d-flex align-items-center">
                                <span class="product-price-discount">320.000đ</span>
                                <span class="product-discount">-50%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-video-wrap">
                        <img class="content-video" src="/assets/img/shop/fake_video.png" alt="video-thumb">
                        <div class="play-time">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                            <span>00:15</span>
                        </div>
                        <a class="btn-play" @click="showModal()">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                        </a>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p class="product-price">160.000đ</p>
                            <div class="d-flex align-items-center">
                                <span class="product-price-discount">320.000đ</span>
                                <span class="product-discount">-50%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item">
                    <div class="content-video-wrap">
                        <img class="content-video" src="/assets/img/shop/fake_video.png" alt="video-thumb">
                        <div class="play-time">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                            <span>00:15</span>
                        </div>
                        <a class="btn-play" @click="showModal()">
                            <img src="/assets/img/header/ic_play_white.svg" alt="">
                        </a>
                    </div>
                    <div class="content-product">
                        <img src="/assets/img/shop/fake_item.png" alt="product-image">
                        <div class="product-info">
                            <label>Đồng hồ Casino</label>
                            <p class="product-price">160.000đ</p>
                            <div class="d-flex align-items-center">
                                <span class="product-price-discount">320.000đ</span>
                                <span class="product-discount">-50%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- The Modal -->
        <div class="modal" id="modalShopVideo">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">

                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title">Chi tiết video</h4>
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                    </div>

                    <!-- Modal body -->
                    <div class="modal-body">
                        <video preload="metadata"
                               class="shop-video"
                               loop=""
                               controlslist="nodownload nofullscreen noremoteplayback"
                               controls="">
                            <source src="//cloud.video.alibaba.com/play/u/2153292369/p/1/e/6/t/1/d/hd/291191873817.mp4" type="video/mp4">
                        </video>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ShopRequest from "@/api/requests/ShopRequest";
    const $ = window.jQuery;

    export default {
        name: "ShopVideo",
        // mounted() {
        //     this.getData();
        // },
        methods: {
            // async getData() {
            //     const res = await ShopRequest.getHomeData(this.id);
            //     if (!res.err_code) {
            //         this.shop = res.shop;
            //         this.categories = res.categories;
            //     }
            // },
            showModal() {
                // window.preventDefault();
                $("#modalShopVideo").modal()
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/css/shopDetail";

    .shop-video {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        display: block;
        background: #000;
    }
</style>
