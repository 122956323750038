<template>
  <div id="product-detail">
    <div id="contents">
      <div class="wrap-container">
        <div class="col-xl-12 col-sm-12 col-12  container-product-info pl-0">
          <div class="row bg-white product-info ">
            <div class=" text-center">
              <img
                  class="img-avatar"
                  src="/assets/img/ic_user.png"
              />
            </div>
            <div class="">
              <a :href="'https://facebook.com/'+product.from?.id" target="_blank">
                <h4 class="col-xl-12 text-center font-weight-bold">
                  {{ product.from?.name }}
                </h4>
              </a>
            </div>
          </div>
        </div>

        <div class="mt-3 ">
          <div>
<!--            <tabs class=" mx-auto mb-16" :variant="'horizontal'" :tabList="tabList" :tab-default="currentTab">-->
<!--              <template v-slot:tabPanel-1>-->
<!--                <news-with-user property="2" :id="product.from?.id"/>-->
<!--              </template>-->
<!--              <template v-slot:tabPanel-2>-->
<!--                <news-with-user property="1" :id="product.from?.id"/>-->
<!--              </template>-->
<!--              <template v-slot:tabPanel-3>-->
<!--                <news-with-user property="3" :id="product.from?.id"/>-->
<!--              </template>-->
<!--            </tabs>-->
            <tabs class="w-11/12 lg:w-10/12 mx-auto mb-16" :tabList="tabList">
            </tabs>
          </div>
        </div>
      </div>
    </div>
  </div>

  <chat ref="chatBox" v-if="showChatBox"/>
</template>

<script>
import $ from "jquery";
// import "slick-carousel";
// import "slick-carousel/slick/slick-theme.css";
import ProductRequest from "@/api/requests/ProductRequest";
import CartRequest from "@/api/requests/CartRequest";
import ReviewerRequest from "@/api/requests/ReviewerRequest";
import store from "@/store";
import ProductItem from "@/components/ProductItem";
import Breadcrumb from "@/components/Breadcrumb";
import {
  getOption,
  isEmpty,
  numberFormat,
  siteSetTitle,
  stringTruncate,
} from "@/lib/utils";
import moment from "moment";
import Paginate from "@/components/Paginate";
import Chat from "@/components/Chat";
import toastr from "toastr";
import ModalAddress from "@/views/ModalAddress";
import data from "@/lib/data.json";
import CrawlRequest from "@/api/requests/CrawlRequest";
import Item from "@/components/classified_advertising/Item";
import Tabs from "@/components/tabs/Tabs";
import TabNews from "@/components/classified_advertising/TabNews";
import NewsWithUser from "@/components/classified_advertising/NewsWithUser";

export default {
  name: "PostInformation",
  components: {
    NewsWithUser,
    TabNews,
    Tabs,
    Chat, ProductItem, Paginate, ModalAddress, Breadcrumb, Item,
  },
  props: ['id'],
  data() {
    console.log("-> currentTab", this.$route.query.currentTab);
    return {
      product: {},
      products: [],
      currentTab: this.$route.query.currentTab,
      tabList: [
        {
          label: this.$t('wantToSell'), // cần bán
          property: 2
        },
        {
          label: this.$t('wantToBuy'), // cần mua
          property: 1
        },
        {
          label: this.$t('b2b'), // b2b
          property: 3
        },
      ],
      // tabList: [this.$t('wantToSell'), this.$t('wantToBuy'),this.$t('b2b')]
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    // document.title = this.product?.name;
    this.load();
  },
  updated() {
    this.initSlider();
  },
  computed: {
    authData: () => store.state.Auth,
    showChatBox: () => store.state.showChatBox,

    isInCart: function () {
      let packIds = this.getPackIds();
      if (this.product.cartItems && this.product.cartItems.length > 0) {
        return this.product.cartItems.find((item) => item.pack_ids == packIds);
      }

      return false;
    },
  },
  methods: {
    subStringTitle(title) {
      return stringTruncate(title, 100)
    },
    isEmpty,
    numberFormat,
    stringTruncate,
    async load() {
      this.isLoading = true;
      let check = 1;
      const res = await CrawlRequest.getDetail(this.id, check);
      this.product = res;
      document.title = res.from?.name
      this.isLoading = false;
    },
    initSlider() {
      $(function () {
        $("#product-for").not(".slick-initialized").show().slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          fade: true,
          asNavFor: "#product-nav",
        });
        $("#product-nav").not(".slick-initialized").show().slick({
          slidesToShow: 8,
          slidesToScroll: 1,
          asNavFor: "#product-for",
          centerMode: true,
          focusOnSelect: true,
        });
      });
    },
    async setFavorite(product, isFavorite) {
      product.is_favorite = isFavorite;
    },
  },
};
</script>

<style scoped lang="scss">
.container-product-info {
  .product-info {
    margin-left: 2px;
    border-radius: 4px;
    padding: 20px 10px 50px 10px;

    .img-avatar {
      height: 100px;
      width: 100px;
      border-radius: 75px;
      object-fit: cover;
    }

    .btn-contact {
      border: none;
      background-color: #5d5fef;
      padding: 10px;
      border-radius: 15px;
      color: #fff;
      font-size: 15px;
      font-weight: 500;
    }
  }
}
</style>
