<template>
    <div>
        <OrderList v-if="currentComponent === 'OrderList'"></OrderList>
        <OrderDetail v-if="currentComponent === 'OrderDetail'" :code="code"></OrderDetail>
    </div>
</template>

<script>
import OrderList from "@/views/OrderList";
import OrderDetail from "@/views/OrderDetail";

export default {
name: "TabOrder",
    components: {
        OrderList, OrderDetail
    },
    data() {
        return {
            currentComponent: 'OrderList',
            code: null,
        }
    },
    methods: {
        changeTab(component, code) {
            this.currentComponent = component;
            if (code) {
                this.code = code;
            }
        },
    },
}
</script>

<style scoped>

</style>