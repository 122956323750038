import {deepFreeze} from "./lib/deepfreeze";

const env = process.env;

const originConfig = {
    App: {
        debug: env.VUE_APP_DEBUG === 'true',
        baseUri: process.env.VUE_APP_API_URL,
        imageBaseUrl: env.VUE_APP_IMAGE_URL,
        v1ApiUrl: env.VUE_APP_V1_API_URL || 'http://localhost:10003/api.php',
        v1LoginLimUrl: env.VUE_APP_LOGIN_LIM_URL || 'http://localhost:7005/loginLim.php',
        selfApiUrl: env.VUE_APP_SELF_API_URL,
        chatUrl: 'https://chat.elim.vn',
        avatarUrl: env.VUE_APP_AVATAR_URL,
        hotline: '096.274.1789 ',
        defaultImg: "/img/logo.png"
    },
    Service: {
        GoogleClientID: env.VUE_APP_GOOGLE_CLIENT_ID,
    },
    Message: {
        shipNote: 'Hỗ trợ 50,000đ phí vận chuyển cho đơn hàng trên 1,000,000đ'
    },
    Brands: [
        {
            name: "Lupi",
            link: "https://lupi.vn/",
            img: "https://elim.vn/img/lupi.jpg",
            label: "Lupi.vn",
            description: "Phân tích dữ liệu shopee"
        },
        {
            name: "Golink",
            link: "https://golink.vn/",
            img: "https://golink.vn/images/logo/fav.png",
            label: "Golink.vn",
            description: "Nền tảng giao nhận xuyên biên giới"
        },
        {
            name: "Goship",
            link: "https://goship.io/",
            img: "https://goship.io/wp-content/uploads/2022/03/cropped-favico-192x192.png",
            label: "Goship.io",
            description: "Nền tảng kết nối giao nhận"
        },
        {
            name: "Elim",
            link: "https://elim.vn/",
            img: "https://elim.vn/favicon.png",
            label: "Elim.vn",
            description: "Sàn b2b việt nam"
        },
        {
            name: "GMV",
            link: "https://gmv.live/",
            img: "https://gmv.live/logo.svg",
            label: "Gmv.live",
            description: "Phân tích dữ liệu bán hàng tiktok"
        },
        {
            name: "Gobox",
            link: "https://gobox.asia/",
            img: "https://gobox.asia/wp-content/uploads/2022/09/cropped-Frame-35-192x192.png",
            label: "Gobox.asia",
            description: "Nền tảng erp , fullfilment kinh doanh online"
        },
        {
            name: "Tabcut",
            link: "https://www.tabcut.com/vi-VN/workbench",
            img: "https://elim.vn/img/tabcut.png",
            label: "Tabcut.com",
            description: "Phân tích dữ liệu tiktok"
        }
    ]
};

if (originConfig.App.debug) {
    console.log('APP running in DEBUG mode');
}

deepFreeze(originConfig);

export default originConfig;
