<template >
  <div v-click-outside="onBlur" class="container-dropdown">
    <div @click="onShow" class="container-title-dropdown">
      <div class="txt-title">
        <label>{{ title }}</label>
        <img src="assets/img/ic_dropdown.svg" class="icon-dropdown" />
      </div>
      <div class="text-left txt-value">
        <div v-if="value?.length">
          <span v-for="item in value" :key="item">
            <div>{{ item.name }},</div>
          </span>
        </div>
        <div v-else>{{ titleShowAll || "Tất cả" }}</div>
      </div>
    </div>
    <div v-if="isShow" class="container-content">
      <ul class="place-filter">
        <li v-if="titleShowAll">
          <label
            :class="[
              type == 'checkbox' ? 'custom-input-checkbox' : 'custom-input-radio',
            ]"
            >{{ titleShowAll || "Tất cả" }}
            <input
              :type="type || 'checkbox'"
              :value="{ id: 'all', name: titleShowAll || 'Tất cả' }"
              @change="onSelected('all')"
              v-model="inputVal"
            />
            <span class="checkmark"></span>
          </label>
        </li>
        <li v-for="category in options" :key="category.id">
          <label
            :class="[
              type == 'checkbox' ? 'custom-input-checkbox' : 'custom-input-radio',
            ]"
            >{{ category.name }}
            <input
              :type="type || 'checkbox'"
              :value="category"
              @change="onSelected('all')"
              v-model="inputVal"
            />
            <span class="checkmark"></span>
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "title",
    "options",
    "value",
    "isShowAll",
    "titleShowAll",
    "onSelected",
    "type",
  ],
  data() {
    return {
      isShow: false,
    };
  },
  computed: {
    inputVal: {
      get() {
        console.log("this.value: ", this.value);
        return this.value;
      },
      set(val) {
        console.log("val: ", val);
        this.$emit("update:input", val);
      },
    },
    shouldBeChecked() {
      console.log("this.value: 1111", this.value);
      if (this.value instanceof Array) {
        return this.value[0].id == "all";
      }
      return false;
    },
  },
  methods: {
    onShow() {
      this.isShow = !this.isShow;
    },
    onBlur() {
      this.isShow = false;
    },
    getNameSelected(id) {
      console.log("id: ", id);
      console.log("this.options: ", this.options);
      // if (this.options instanceof Array) {
      //   let obj = this.options.find((e) => e.id == id);
      //   return obj.name;
      // }
      return "";
    },
    updateInput(event) {
      let isChecked = event.target.checked;
      console.log("isChecked: ", isChecked);

      console.log("event.target.value: ", event.target.value);
      if (this.value instanceof Array) {
        let newValue = [...this.value];
        console.log("newValue: ", newValue);

        if (event.target.value == "all") {
          if (isChecked) {
            newValue = [event.target.value];
          } else {
            newValue = [];
          }
        } else {
          console.log("event.target.value: ", event.target.value);
          if (isChecked) {
            newValue.push(event.target.value);
          } else {
            newValue.splice(newValue.indexOf(event.target.value), 1);
          }
        }
        this.$emit("update:input", newValue);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/dropdown";
</style>