<template>
    <div class="modal" tabindex="-1" role="dialog" ref="modal" id="modal-image">
        <div class="modal-dialog" role="document" style="max-width: 60%">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('viewImage')}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
              <div class="modal-body d-flex justify-content-center" style="max-height: 75vh; overflow-y: auto">
                  <div class="main-image" v-if="images[index]">
                      <img :src="images[index].url"/>
                  </div>
              </div>
              <div class="modal-footer" v-if="images.length > 1">
                  <ul class="images-list">
                      <li v-for="(img,idx) in images" @click="select(idx)" :class="{active: index === idx}" :key="idx">
                          <img :src="img.url"/>
                      </li>
                  </ul>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ChatModalImage",
    data() {
        return {
            index: 0,
            images: []
        }
    },
    methods: {
        select(idx) {
            this.index = idx;
        },
        show(images, index) {
            if (!images[index]) {
              index = 0;
            }

            this.images = images;
            this.index = index;

            window.$(this.$refs.modal).modal('show');
        }
    }
}
</script>

<style scoped lang="scss">
@import '../../assets/css/chat';
</style>