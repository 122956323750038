<template>
  <div class="container layout-detai-craft">
      <div v-if="CraftVillage?.images?.[0].link && CraftVillage?.images?.[0].link.uri">
        <div class="slider slider-nav" ref="sliderNav" style="text-align: -webkit-center; background: #dbdada;">
          <div v-for="item in CraftVillage.images" :key="item.id">
            <img class="image-modal" :src="item.link.uri" alt="">
          </div>
        </div>
        <div class="slider slider-for" ref="sliderFor" v-if="CraftVillage.images.length > 1">
          <div  class="box-image-tow p-1" v-for="item in CraftVillage.images" :key="item.id">
            <img class="image-tow" :src="item.link.uri" alt="">
          </div>
        </div>
      </div>
    <div class="title-craft mt-3">
      <div class="name-craft">
        <h3>{{ CraftVillage.name }}</h3>
      </div>
      <star-rating v-if="CraftVillage.rating" :rating="CraftVillage.rating" v-bind:star-size="25" :read-only="true"
        :show-rating="false"></star-rating>
      <div class="address mt-3">
        <div class="locattion d-flex">
          <i class="fa fa-globe text-red fs-3" style="line-height: 24px;" aria-hidden="true"></i>
          <h5 class="ml-2 text-red">{{$t('address')}}</h5>
        </div>
        <span class="text ml-4">{{ CraftVillage.address }}</span>
      </div>
      <div class="contract">
        <div class="phone d-flex">
          <i class="fa fa-phone text-red fs-3" style="line-height: 24px;" aria-hidden="true"></i>
          <h5 class="ml-2 text-red">{{$t('concat')}}</h5>
        </div>
        <span class="text ml-4">{{ CraftVillage.phone }}</span>
      </div>
      <div class="description mt-2">
        <div class="introduce d-flex">
          <i class="fa fa-graduation-cap text-red fs-3" style="line-height: 24px;" aria-hidden="true"></i>
          <h5 class="ml-2 text-red">{{$t('introduce')}}</h5>
        </div>
        <p class="text ml-4" v-html="CraftVillage.description"></p>
      </div>
      <div class="description mt-2">
        <div class="introduce d-flex">
          <i class="fa fa-home text-red fs-3" style="line-height: 24px;" aria-hidden="true"></i>
          <h5 class="ml-2 text-red">{{$t('belong_village')}}</h5>
        </div>
        <span class="text ml-4">{{ CraftVillage.craftvillages?.name }}</span>
        <a :href="'/thong-tin-lang-nghe/' + CraftVillage.craft_village_id" class="ml-3" style="color: blue;">{{$t('see_detail')}}</a>
      </div>
      <div class="mt-5 mb-5">
        <h3>{{$t('shop_village')}}</h3>
        <div class="box-store">
          <div v-for="store in relate" :key="store.id">
            <div class="shop mb-2 d-flex mt-2">
              <div class="baner-shop" v-if="store.images && store.images[0].link && store.images[0].link.uri"
                @click="openModalSlickSlider(store.images)">
                <img v-lazy="store.images[0].link.uri" alt="">
                <div class="baner-child d-flex">
                  <div class="content" v-for="(image, index) in store.images" :key="image.id">
                    <img v-if="index < 4 && store.images.length > 1" v-lazy="image.link.uri" alt="">
                    <div v-if="index == 3" class="centered">{{$t('see_all')}}</div>
                  </div>
                </div>
              </div>
              <div class="baner-shop" v-else>
                <img class="logo-elim" v-lazy="'/assets/img/header/logo.png'" alt="">
              </div>
              <div class="name-shop p-3">
                <div :class="!store.rating ? 'box-title-shop-top' : ''">
                  <div class="title"><a :href="'/thong-tin-lang-nghe/cua-hang/'+ store.id">{{ store.name }}</a></div>
                  <div class="address">
                    {{$t('address')}}: {{ store.address }}
                  </div>
                  <div class="contract">
                    {{$t('concat_info')}}: {{ store.phone }}
                  </div>
                </div>
                <div class="feddback d-flex" v-if="store.rating">
                  <div class="title-feedback">
                    {{$t('evaluate')}}:
                  </div>
                  <div class="icon-feedback ml-3">
                    <star-rating :rating="store.rating" v-bind:star-size="25" :show-rating="false"
                      :read-only="true"></star-rating>
                  </div>
                </div>
                <a :href="'/thong-tin-lang-nghe/cua-hang/' + store.id" class="btn btn-danger custom-butom-detail">{{$t('see_detail')}}</a>
              </div>
            </div>
          </div>

        </div>
        <div class="paginate p-4">
          <Paginate :value="paginate" :pagechange="onPageChange"></Paginate>
        </div>
      </div>
    </div>
    <!-- modal -->
    <modal-slick-slider ref="modalSlickSlider"></modal-slick-slider>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating'
import ModalSlickSlider from './ModalSlickSlider.vue';
import Paginate from "@/components/Paginate";
import CraftVillageRequest from '@/api/requests/CraftVillageRequest';
import $ from 'jquery';
import { cloneObject } from '@/lib/utils';
export default {
  components: { Paginate, StarRating, ModalSlickSlider },
  props: ["id"],
  name: 'StoreCraftVillage',
  data() {
    return {
      page: this.$route.query.page || 1,
      CraftVillage: {},
      relate: {},
      paginate: {
        currentPage: 1,
        lastPage: 1
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      const res = await CraftVillageRequest.getStoreCraftVillageById(this.id, this.page);
      if (res.code === 200) {
        this.CraftVillage = res.data
        this.relate = res.relate
        this.paginate = res.paginate
        setTimeout(() => {
          this.SliderShop()
        }, 100);
      }
    },
    onPageChange(page) {
      this.page = page;
      this.$router.push({ query: { page: page } });
      this.getData();
      setTimeout(() => {
        window.jQuery("html, body").animate({ scrollTop: 1200 }, 300);
      }, 500);
    },
    openModalSlickSlider(image) {
      this.$refs.modalSlickSlider.show(cloneObject(image));
    },
    SliderShop() {
      $(this.$refs.sliderFor).slick({
        slidesToShow: 7,
        slidesToScroll: 1,
        arrows: false,
        asNavFor: '.slider-nav',
        leftMode: true,
        focusOnSelect: true,
      })
      $(this.$refs.sliderNav).slick({
        slidesToShow: 1,
        asNavFor: '.slider-for',
        slidesToScroll: 1,
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: 'linear'
      })
    },
  }
}
</script>

<style lang="scss" scoped>
h3 {
  font-weight: 600;
}

.text {
  font-size: 20px;
}

.fs-3 {
  font-size: 20px;
}

.box-store {
  margin-top: 2rem;

  .shop {
    border: 1px solid #ccc;
    // height: 250px;
    border-radius: 5px;
    box-shadow: 2px 2px 2px 2px #d0d0d0;

    .baner-shop {
      .logo-elim {
        margin-top: 75px;
        height: 100px;
        min-width: 300px;
      }

      img {
        cursor: pointer;
        border-top-left-radius: 5px;
        width: 300px;
        height: 170px;
      }

      .baner-child {
        text-align: center;
        cursor: pointer;

        img {
          padding: 1px;
          height: 45px;
          width: 50px;
        }

        .content {
          position: relative;
          color: white;
          background-size: cover;

          img {
            width: 75px;
          }

          .centered {
            position: relative;
            font-size: 13px;
            top: -50%;
            color: #ffffff;
            padding: 13px 5px 10px 8px;
            cursor: pointer;
            background: rgba(0, 0, 0, 0.5);
          }
        }
      }
    }

    .name-shop {
      .box-title-shop-top {
        height: 124px;
      }

      .title {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 1px !important;
        font-weight: 600;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* số dòng hiển thị */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .address {
        margin-top: 10px;
        font-size: 14px;
        color: #616161;
      }

      .contract {
        margin-top: 10px;
        font-size: 14px;
        color: #616161;
      }

      .feddback {
        margin-top: 10px;
      }

      .custom-butom-detail {
        margin-top: 31px;
        height: 43px;
        line-height: 31px;
      }
    }
  }
}


.image-modal {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  max-width: 100%;
  height: 550px;
}

.box-image-tow {
  height: 150px;

  img {
    border-radius: 10px;
    width: 100%;
    height: 90px;
  }
}
</style>