<template>
    <div class="row" id="chat-box">
        <template v-if="isLoading">
          <div class="d-flex align-items-center justify-content-center" style="width: 100%">
            <i class="fa fa-spinner fa-spin fa-3x elim-loading"></i>
          </div>
        </template>
        <template v-else>
            <div class="col-7 mess" v-if="showMessage">
                <ChatMessages ref="chatMessage" :default-cid="currentCid" :shop-id="shopId"
                              :shop-name="shopName" :shop-avatar="shopAvatar"/>
            </div>
            <div :class="showMessage ? 'col-5' : 'col-12'">
                <ChatConversations ref="chatConversation" :show-message="showMessage" :default-cid="currentCid" :shop-id="shopId"
                                   :shop-name="shopName" :shop-avatar="shopAvatar" @change="onConversationChange" @showMess="showMess" @hidden="hiddenPopup()"
                @hiddenMess="hiddenMessage()"/>
            </div>
        </template>

    </div>
</template>

<script>
import ChatConversations from "@/components/chat_box/ChatConversations";
import ChatMessages from "@/components/chat_box/ChatMessages";
// import $router from "../../lib/SimpleRouter";
import {chat$post, chatAddMessageListener} from "@/api/requests/ChatRequest";
import {initFirebase} from "@/lib/fcm";
import {getDeviceId} from "@/lib/utils";

function getDeviceInfo(token) {
    return {
        token,
        device_id: getDeviceId(),
        version_code: 1,
        version_name: '1.0',
        package: location.host,
        agent: navigator.userAgent,
        os: 'web'
    }
}

export default {
    name: "ChatIndex",
    props: ['shopId', 'shopName', 'shopAvatar', 'showMessage'],
    data() {
        return {
            currentCid: null,
            isLoading: false,
        }
    },
    components: {ChatMessages, ChatConversations},
    mounted() {
        let currentConversation = localStorage.getItem('current_conversation');
        if (! this.shopId && JSON.parse(currentConversation)) {
          this.$emit('showMess', JSON.parse(currentConversation));
        }

        const sound = new Audio('/assets/notify.mp3');
        const onMessage = (message) => {
            if (message.type === 'in') {
                this.$refs.chatConversation.increaseBadge(message.cid);
                try {
                    sound.play();
                } catch (e) {
                    console.log(e);
                }

            }

            this.$refs.chatMessage.load(1);
            this.$refs.chatConversation.setLastMessage(message.cid, message.message);
        };

        const onReload = (currentCid) => {
            const $ref = this.$refs.chatMessage;

            if (currentCid === $ref.getCid()) {
                $ref.load(1);
                console.log('Some messages exists but not showing. Trying refresh message');
            }
        };

        chatAddMessageListener(onMessage);

        this.registerToken();
    },
    methods: {
        registerToken() {
            initFirebase({
                onError(err) {
                    console.log('FireBase Init Error: ', err);
                },
                onReceived(token) {
                    chat$post('/v1/config/register-token',  getDeviceInfo(token))
                }
            });
        },
        showMess(conversation) {
            this.$emit('showMess', conversation);
        },
        onConversationChange(conversation) {
            this.$refs.chatConversation.setCid(conversation.cid);
            this.$refs.chatMessage.setCid(conversation.cid);
        },
        hiddenPopup() {
            this.$emit('hidden');
        },
        hiddenMessage() {
            this.$emit('hiddenMess');
        }
    }
}
</script>

<style scoped lang="scss">
@import '../../assets/css/chatBox';
</style>
