<template>
  <div id="header-home">
    <div class="header-menu-wrap ">
      <div class="main-content" style="padding-top: 0; padding-right: 180px">
        <div class="header header-menu-content " v-if="!isCart">
          <a class="header-link" @click="onBecomeShop" v-if="authData">
            <div class="to-shop">
              <img class="logo-header" src="/assets/img/header/ic_store.svg" alt="">{{ $t('saleChannel') }}
            </div>
          </a>
          <div class="classified-advertising-menu">
            <router-link to="/rao-vat">{{ $t('raoVat') }}</router-link>
          </div>
          <div class="quick-menu">
            <router-link to="/tin-tuc">{{ $t('news') }}</router-link>
          </div>

          <div class="dropdown" style="margin-left: auto; display: flex">
              <div class="brand-carousel-pc">
                  <div id="brandSlider">
                      <a v-for="brand in brands"
                         :key="brand.name"
                         :href="brand.link"
                         target="_blank"
                         style="padding: 4px"
                      >
                          <div class="brand-btn vertical-align-middle"
                               v-text="brand.name"
                          ></div>
                      </a>
                  </div>

                  <div class="brand-carousel-hover">
                      <img :src="currentBrand.img" alt="">
                      <a :href="currentBrand.link" target="_blank">{{ currentBrand.label }}</a>
                      <p style="margin-top: 5px">{{ currentBrand.description }}</p>
                  </div>
              </div>

			          <span data-toggle="dropdown" class="btn btn-language" aria-expanded="true" style="cursor: pointer">
			          <img style="width: 30px; height: 30px; border-radius: 20px "
                     :src="currentLanguage == 'en' ? '/assets/img/lang/en.png' : (currentLanguage == 'cn' ? '/assets/img/lang/cn.png' : '/assets/img/lang/vi.png')"
                     alt="">
                  <span
                      class="mx-1 text-language">{{
                      currentLanguage == 'en' ? 'ENGLISH' : (currentLanguage == 'cn' ? 'CHINESE' : 'VIETNAMESE')
                    }}</span>
                  <i class="fa fa-caret-down" aria-hidden="true"></i>
			          </span>
            <div class="dropdown-menu dropdown-menu-center" x-placement="bottom-end"
                 style="position: absolute; transform: translate3d(-5px, 26px, 0px); top: 0px; left: 0px; will-change: transform;">
              <ul>
                <li class="dropLanguage"><a href="javascript:;" @click="changeLanguage('vi')">
                  <img src="/assets/img/lang/vi.png" style="width: 50px; height: 30px" alt=""/>
                </a></li>
                <li class="dropLanguage"><a href="javascript:;" @click="changeLanguage('cn')">
                  <img src="/assets/img/lang/cn.png" style="width: 50px; height: 30px" alt=""/>
                </a></li>
                <li class="dropLanguage"><a href="javascript:;" @click="changeLanguage('en')">
                  <img src="/assets/img/lang/en.png" style="width: 50px; height: 30px" alt=""/>
                </a></li>

              </ul>
            </div>
          </div>


        </div>
      </div>
    </div>


    <div class="header-wrap" id="myHeader">
      <div class="container">
        <div class="header ">
          <a v-if="!useRouterLink" class="header-logo" href="/">
            <img src="/assets/img/header/logo.png" alt="Elim.vn">
          </a>
          <router-link v-else class="header-logo" to="/">
            <img  src="/assets/img/header/logo.png" alt="Elim.vn">
          </router-link>
          <div class="search-wrap cart-wrap" v-if="isCart">
            {{ $t('cart') }}
          </div>
          <div class="search-wrap" v-else>
            <div class="search-wrap-input">
              <i class="fas fa-search search-icon"></i>
              <input @keydown.enter="search(keyword)"
                     v-model="keyword" class="search-input"
                     type="text"
                     :placeholder="$t('searchProductCategory')">
              <button class="search-btn" type="button" @click="search(keyword)">
                <span class="search-text">{{ $t('search') }}</span>
                <i class="fas fa-search search-icon search-icon-show"></i>
              </button>
            </div>
          </div>

          <router-link :to="{name: 'UserInfo'}" class="header-link text-center" @click="setBackUrl()">
            <img class="logo-header" src="/assets/img/header/ic_user.svg" alt="">
            <p class="menu-text" v-text="(authData && authData.user) ? authData.user.name : $t('account')"></p>
          </router-link>

          <router-link class="header-link" :to="{name: 'UserInfo', query: {tabMain: 'tin-nhan'}}" @click="changeTab()">
            <img class="logo-header" src="/assets/img/header/ic_message.svg" alt="">
            <p class="menu-text">{{ $t('message') }}</p>
          </router-link>
          <!--              <a class="header-link" v-if="authData">-->
          <!--                  <div class="badge-wrap">-->
          <!--&lt;!&ndash;                      <div class="item-badge">&ndash;&gt;-->
          <!--&lt;!&ndash;                          <span>11</span>&ndash;&gt;-->
          <!--&lt;!&ndash;                      </div>&ndash;&gt;-->
          <!--                      <img class="logo-header" src="/assets/img/header/ic_bell.svg" alt="">-->
          <!--                  </div>-->
          <!--                  <p class="menu-text">Thông báo</p>-->
          <!--              </a>-->
          <router-link class="header-link" to="/gio-hang">
            <div class="badge-wrap">
              <div class="item-badge" v-if="cartCount">
                <span v-text="cartCount"></span>
              </div>
              <img class="logo-header" src="/assets/img/header/ic_cart.svg" alt="">
            </div>
            <p class="menu-text">{{ $t('cart') }}</p>
          </router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import store from "@/store";
import CartRequest from "@/api/requests/CartRequest";
import {getOption, getJsonData} from "@/lib/utils";
import i18n from '@/main';
import config from "@/config";

export default {
  name: "Header",
  props: ['isCart'],
  data() {
    let menus = [];

    try {
      menus = getOption('menu_left', [])[0].items
    } catch (e) {
      console.warn('Can not get menu')
    }

    return {
      useRouterLink: true,
      menus,
      keyword: '',
      products: [],
      rKeyword: '',
      currentMenu: null,
      showCategory: false,
      currentLanguage: localStorage.getItem('elimLang') || 'vi',

      brands: config.Brands || [],
        currentBrand: {},
    }
  },
  computed: {
    authData: () => store.state.Auth,
    cartCount: () => store.state.CountCartItem,
  },
  mounted() {
    this.handleBrands();

    setTimeout(() => {
      this.useRouterLink = false;
    }, 60000 * 15)
    var header = document.getElementById("myHeader");
    var sticky = header.offsetTop;
    window.addEventListener("scroll", function (event) {
      let scroll = this.scrollY;
      if (scroll > sticky) {
        header.classList.add("fixed");
      } else {
        header.classList.remove("fixed");
      }
    });
  },
  methods: {
    getJsonData,
    handleBrands() {
        let app = this;

        window.$("#brandSlider").slick({
            autoplay: true,
            dots: false,
            arrows: false,
            vertical: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 1500,
            infinite: true,
            // autoplaySpeed: 5000
        })

        window.$('#brandSlider').on('mouseenter', '.slick-slide', function (e) {
            let currentIndex = window.$(e.currentTarget).data('slick-index');

            app.currentBrand = app.brands[currentIndex];
            window.$(".brand-carousel-hover").show();
            window.$("#brandSlider").attr('data-brandmouseon', 1);
        });

        window.$("#brandSlider").mouseleave(function () {
            window.$(this).attr('data-brandmouseon', 0)
            let brandCarousel = window.$(this);
            setTimeout(function () {
                let brandCarouselHover = brandCarousel.siblings('.brand-carousel-hover');
                if (brandCarouselHover.attr('data-brandmouseon') != 1) {
                    brandCarouselHover.hide();
                }
            }, 100);
        });
        window.$(".brand-carousel-hover").mouseenter(function () {
            window.$(this).attr('data-brandmouseon', 1)
            window.$(this).show();
        });
        window.$(".brand-carousel-hover").mouseleave(function () {
            window.$(this).attr('data-brandmouseon', 0)
            window.$(this).hide();
        });
    },
    checkUrl(menu, $event) {
      if (!menu.url) {
        alert(this.$t('plsSetUrl'));
        $event.preventDefault();
      }
    },
    search(keyword) {
      this.$router.push({name: 'Search', params: {keyword: keyword}, query: {keyword}});
    },
    onBecomeShop() {
      if (this.authData) {
        if (confirm(this.$t('changeToSaleChannel'))) {
          console.log(process.env.VUE_APP_SHOP_URL)
          const shopUrl = process.env.VUE_APP_SHOP_URL || 'http://shop.elim.vn';
          window.open(shopUrl + '?token=' + this.authData.token, '_blank');
        }
      }
    },
    mouseover(menu) {
      this.currentMenu = menu;
    },
    mouseleave() {
    },
    changeTab() {
      store.commit('SetShowChatBar', false);
    },
    setBackUrl() {
      if (!this.authData) {
        let currentUrl = this.$route.fullPath;
        localStorage.setItem("ElimBackUrl", currentUrl);
      }
    },
    changeLanguage(locale) {
      this.currentLanguage = locale;
      store.commit('SetLocale', locale);
      localStorage.setItem("elimLang", locale);
      location.reload();
    }
  },
}
</script>

<style scoped lang="scss">
@import "../assets/css/header";

.dropLanguage {
  min-width: 34%;
  padding: 3px;
}

.dropdown-menu {
  background: #f5f5f5;
  min-width: 80px;
  padding: 4px 0;
  border: none;
  text-align: center;
  position: absolute;
  will-change: transform;
  top: 20px;
  left: 0;
  -webkit-transform: translate3d(-5px, 26px, 0);
  transform: translate3d(-5px, 26px, 0);
}

.brand-carousel-pc {
    height:44px;
    width: 150px;

    #brandSlider {
        height: 44px;
        overflow: hidden;
    }

    .brand-btn {
        border: 1px solid #dc3545;
        color: #262626;
        border-radius: 5px;
        padding:10px 15px;
        line-height: 1;
        text-align: center;
    }
}

.brand-carousel-hover {
    top: 50px;
    display: none;
    padding: 10px;
    width: 240px;
    border-radius: 5px;
    box-shadow: 0 0 1px 1px #cccccc85;
    position: absolute;
    background: white;
    left: -10px;
    line-height: 1.5;
    white-space: normal;
    word-wrap: break-word;
    z-index: 10;

    img {
        height: 25px;
        width: 25px;
    }

    a {
        margin-left: 10px;
        text-decoration: underline;
        font-size: 17px;
        color: #50a1ff;
    }

    p {
        font-size: 12px;
        margin-bottom: 0;
        line-height: 1.5;
    }
}
</style>
