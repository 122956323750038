<template>
    <div>
        <div id="shop-detail" style="background: #fff">
            <div class="w-100 banner-padding">
            </div>
            <div class="wrap-container over-unset shop-detail__wrap-container" style="background: #fff;padding-top: 10px;padding-bottom: 5px">
                <div class="shop-banner__detail">
                    <div class="shop-banner__detail__left">
                        <div class="shop-banner__detail__background" :style="'background: url( ' + data.shop.avatar + ')'">
                        </div>
                        <div class="shop-banner__detail__background-mask"></div>
                        <div class="shop-banner__detail_main">
                            <div class="shop-banner__info">
                                <a class="shop-ava">
                                    <div class="avatar shop-avatar _1a-fH5">
                                        <img v-if="data.shop.avatar" :src="data.shop.avatar" alt="avatar">
                                        <div v-if="showEdit">
                                            <input class="upload-img" accept="image/*" type="file" id="upload-avatar" @change="uploadAvatar($event, 2)"/>
                                            <label for="upload-avatar" class="avatar-btn">
                                                <i class="fas fa-camera" style="font-size: 20px"></i>
                                            </label>
                                        </div>
                                        <div class="btn-edit" v-if="showBtnEdit && !showEdit" @click="showEditImage()"><p>Sửa</p></div>
                                        <div class="btn-edit" v-if="showBtnEdit && showEdit" @click="quickUpdateImage()"><p>Lưu</p></div>
                                    </div>
                                </a>

                                <div class="shop-info">
                                    <h4>{{data.shop ? data.shop.name : ''}}</h4>
                                    <p>
                                        <span v-if="data.shop.address">{{data.shop.address + ',' ?? ''}}</span>
                                        <span v-if="data.shopProfile && data.shopProfile.founded_year_name">
                                    {{data.shopProfile ? data.shopProfile.founded_year_name : ''}}
                                </span>
                                    </p>
                                </div>
                            </div>
                            <div class="shop-banner__button">
                                <button v-if="isFollowed" class="btn-outline btn-followed" @click="follow(false)">
	                                {{ $t('following')}}
                                </button>
                                <button v-else class="btn-follow btn-outline" @click="follow(true)">
                                    <i class="fas fa-plus mr-1"></i>
	                                {{ $t('follow')}}
                                </button>
                                <button class="btn-contact btn-outline" @click="showChat()">
                                    <span class="shop-detail__icon-chat"><svg viewBox="0 0 16 16" class="shop__svg-icon"><g fill-rule="evenodd"><path d="M15 4a1 1 0 01.993.883L16 5v9.932a.5.5 0 01-.82.385l-2.061-1.718-8.199.001a1 1 0 01-.98-.8l-.016-.117-.108-1.284 8.058.001a2 2 0 001.976-1.692l.018-.155L14.293 4H15zm-2.48-4a1 1 0 011 1l-.003.077-.646 8.4a1 1 0 01-.997.923l-8.994-.001-2.06 1.718a.5.5 0 01-.233.108l-.087.007a.5.5 0 01-.492-.41L0 11.732V1a1 1 0 011-1h11.52zM3.646 4.246a.5.5 0 000 .708c.305.304.694.526 1.146.682A4.936 4.936 0 006.4 5.9c.464 0 1.02-.062 1.608-.264.452-.156.841-.378 1.146-.682a.5.5 0 10-.708-.708c-.185.186-.445.335-.764.444a4.004 4.004 0 01-2.564 0c-.319-.11-.579-.258-.764-.444a.5.5 0 00-.708 0z"></path></g></svg></span>
                                    CHAT
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="shop-banner__detail__center">
                        <div class="shop-info">
                            <div class="item">
                                <div class="title">
                                    <span class="title__icon"><svg enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0" stroke-width="0" class="svg-icon"><path d="m13 1.9c-.2-.5-.8-1-1.4-1h-8.4c-.6.1-1.2.5-1.4 1l-1.4 4.3c0 .8.3 1.6.9 2.1v4.8c0 .6.5 1 1.1 1h10.2c.6 0 1.1-.5 1.1-1v-4.6c.6-.4.9-1.2.9-2.3zm-11.4 3.4 1-3c .1-.2.4-.4.6-.4h8.3c.3 0 .5.2.6.4l1 3zm .6 3.5h.4c.7 0 1.4-.3 1.8-.8.4.5.9.8 1.5.8.7 0 1.3-.5 1.5-.8.2.3.8.8 1.5.8.6 0 1.1-.3 1.5-.8.4.5 1.1.8 1.7.8h.4v3.9c0 .1 0 .2-.1.3s-.2.1-.3.1h-9.5c-.1 0-.2 0-.3-.1s-.1-.2-.1-.3zm8.8-1.7h-1v .1s0 .3-.2.6c-.2.1-.5.2-.9.2-.3 0-.6-.1-.8-.3-.2-.3-.2-.6-.2-.6v-.1h-1v .1s0 .3-.2.5c-.2.3-.5.4-.8.4-1 0-1-.8-1-.8h-1c0 .8-.7.8-1.3.8s-1.1-1-1.2-1.7h12.1c0 .2-.1.9-.5 1.4-.2.2-.5.3-.8.3-1.2 0-1.2-.8-1.2-.9z"></path></svg></span>Sản Phẩm:
                                </div>
                                <div class="value text-center text-red">
                                    {{data.shop.productCount ? data.shop.productCount : '0' }}
                                </div>
                            </div>
                            <div class="item">
                                <div class="title">
                                    <span class="title__icon"><svg enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0" class="svg-icon"><g><circle cx="7" cy="4.5" fill="none" r="3.8" stroke-miterlimit="10"></circle><line fill="none" stroke-linecap="round" stroke-miterlimit="10" x1="12" x2="12" y1="11.2" y2="14.2"></line><line fill="none" stroke-linecap="round" stroke-miterlimit="10" x1="10.5" x2="13.5" y1="12.8" y2="12.8"></line><path d="m1.5 13.8c0-3 2.5-5.5 5.5-5.5 1.5 0 2.9.6 3.9 1.6" fill="none" stroke-linecap="round" stroke-miterlimit="10"></path></g></svg></span>Đang Theo Dõi:

                                </div>
                                <div class="value text-center text-red">
                                    {{countUserFollows}}
                                </div>
                            </div>
                            <div class="item">
                                <div class="title">
                                    <span class="title__icon"><svg enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0" class="svg-icon"><g><polygon fill="none" points="14 10.8 7 10.8 3 13.8 3 10.8 1 10.8 1 1.2 14 1.2" stroke-linejoin="round" stroke-miterlimit="10"></polygon><circle cx="4" cy="5.8" r="1" stroke="none"></circle><circle cx="7.5" cy="5.8" r="1" stroke="none"></circle><circle cx="11" cy="5.8" r="1" stroke="none"></circle></g></svg></span>Tỉ Lệ Phản Hồi Chat
                                </div>
                                <div class="value text-center text-red">
                                    100%
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="shop-banner__detail__right">
                        <div class="shop-info">
                            <div class="item">
                                <div class="title">
                                    <span class="title__icon"><svg enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0" class="svg-icon"><g><circle cx="5.5" cy="5" fill="none" r="4" stroke-miterlimit="10"></circle><path d="m8.4 7.5c.7 0 1.1.7 1.1 1.6v4.9h-8v-4.9c0-.9.4-1.6 1.1-1.6" fill="none" stroke-linejoin="round" stroke-miterlimit="10"></path><path d="m12.6 6.9c.7 0 .9.6.9 1.2v5.7h-2" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></path><path d="m9.5 1.2c1.9 0 3.5 1.6 3.5 3.5 0 1.4-.9 2.7-2.1 3.2" fill="none" stroke-linecap="round" stroke-miterlimit="10"></path></g></svg></span>Người Theo Dõi:
                                </div>
                                <div class="value text-center text-red">
                                    {{data.shop.productCount}}
                                </div>
                            </div>
                            <div class="item">
                                <div class="title">
                                    <span class="title__icon"><svg enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0" class="svg-icon icon-rating"><polygon fill="none" points="7.5 .8 9.7 5.4 14.5 5.9 10.7 9.1 11.8 14.2 7.5 11.6 3.2 14.2 4.3 9.1 .5 5.9 5.3 5.4" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></polygon></svg></span>Đánh Giá:

                                </div>
                                <div class="value text-center text-red">
                                    {{countUserFollows}}
                                </div>
                            </div>
                            <div class="item">
                                <div class="title">
                                    <span class="title__icon"><svg enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0" class="svg-icon"><g><circle cx="6.8" cy="4.2" fill="none" r="3.8" stroke-miterlimit="10"></circle><polyline fill="none" points="9.2 12.5 11.2 14.5 14.2 11" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></polyline><path d="m .8 14c0-3.3 2.7-6 6-6 2.1 0 3.9 1 5 2.6" fill="none" stroke-linecap="round" stroke-miterlimit="10"></path></g></svg></span>Tham Gia:
                                </div>
                                <div class="value text-center text-red">
                                <span v-if="data.shopProfile && data.shopProfile.founded_year_name">
                                    {{data.shopProfile ? data.shopProfile.founded_year_name : ''}}
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mobile-detail-info">
                        <div class="shop-banner__detail__center-mobile">
                            <div class="shop-info">
                                <div class="item">
                                    <div class="title">
                                        <span class="title__icon"><svg enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0" stroke-width="0" class="svg-icon"><path d="m13 1.9c-.2-.5-.8-1-1.4-1h-8.4c-.6.1-1.2.5-1.4 1l-1.4 4.3c0 .8.3 1.6.9 2.1v4.8c0 .6.5 1 1.1 1h10.2c.6 0 1.1-.5 1.1-1v-4.6c.6-.4.9-1.2.9-2.3zm-11.4 3.4 1-3c .1-.2.4-.4.6-.4h8.3c.3 0 .5.2.6.4l1 3zm .6 3.5h.4c.7 0 1.4-.3 1.8-.8.4.5.9.8 1.5.8.7 0 1.3-.5 1.5-.8.2.3.8.8 1.5.8.6 0 1.1-.3 1.5-.8.4.5 1.1.8 1.7.8h.4v3.9c0 .1 0 .2-.1.3s-.2.1-.3.1h-9.5c-.1 0-.2 0-.3-.1s-.1-.2-.1-.3zm8.8-1.7h-1v .1s0 .3-.2.6c-.2.1-.5.2-.9.2-.3 0-.6-.1-.8-.3-.2-.3-.2-.6-.2-.6v-.1h-1v .1s0 .3-.2.5c-.2.3-.5.4-.8.4-1 0-1-.8-1-.8h-1c0 .8-.7.8-1.3.8s-1.1-1-1.2-1.7h12.1c0 .2-.1.9-.5 1.4-.2.2-.5.3-.8.3-1.2 0-1.2-.8-1.2-.9z"></path></svg></span>Sản Phẩm:
                                    </div>
                                    <div class="value text-center text-red">
                                        {{data.shop.productCount ? data.shop.productCount : '0' }}
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="title">
                                        <span class="title__icon"><svg enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0" class="svg-icon"><g><circle cx="7" cy="4.5" fill="none" r="3.8" stroke-miterlimit="10"></circle><line fill="none" stroke-linecap="round" stroke-miterlimit="10" x1="12" x2="12" y1="11.2" y2="14.2"></line><line fill="none" stroke-linecap="round" stroke-miterlimit="10" x1="10.5" x2="13.5" y1="12.8" y2="12.8"></line><path d="m1.5 13.8c0-3 2.5-5.5 5.5-5.5 1.5 0 2.9.6 3.9 1.6" fill="none" stroke-linecap="round" stroke-miterlimit="10"></path></g></svg></span>Đang Theo Dõi:

                                    </div>
                                    <div class="value text-center text-red">
                                        {{countUserFollows}}
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="title">
                                        <span class="title__icon"><svg enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0" class="svg-icon"><g><polygon fill="none" points="14 10.8 7 10.8 3 13.8 3 10.8 1 10.8 1 1.2 14 1.2" stroke-linejoin="round" stroke-miterlimit="10"></polygon><circle cx="4" cy="5.8" r="1" stroke="none"></circle><circle cx="7.5" cy="5.8" r="1" stroke="none"></circle><circle cx="11" cy="5.8" r="1" stroke="none"></circle></g></svg></span>Tỉ Lệ Phản Hồi Chat
                                    </div>
                                    <div class="value text-center text-red">
                                        100%
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="shop-banner__detail__right-mobile">
                            <div class="shop-info">
                                <div class="item">
                                    <div class="title">
                                        <span class="title__icon"><svg enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0" class="svg-icon"><g><circle cx="5.5" cy="5" fill="none" r="4" stroke-miterlimit="10"></circle><path d="m8.4 7.5c.7 0 1.1.7 1.1 1.6v4.9h-8v-4.9c0-.9.4-1.6 1.1-1.6" fill="none" stroke-linejoin="round" stroke-miterlimit="10"></path><path d="m12.6 6.9c.7 0 .9.6.9 1.2v5.7h-2" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></path><path d="m9.5 1.2c1.9 0 3.5 1.6 3.5 3.5 0 1.4-.9 2.7-2.1 3.2" fill="none" stroke-linecap="round" stroke-miterlimit="10"></path></g></svg></span>Người Theo Dõi:
                                    </div>
                                    <div class="value text-center text-red">
                                        {{data.shop.productCount}}
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="title">
                                        <span class="title__icon"><svg enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0" class="svg-icon icon-rating"><polygon fill="none" points="7.5 .8 9.7 5.4 14.5 5.9 10.7 9.1 11.8 14.2 7.5 11.6 3.2 14.2 4.3 9.1 .5 5.9 5.3 5.4" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></polygon></svg></span>Đánh Giá:

                                    </div>
                                    <div class="value text-center text-red">
                                        {{countUserFollows}}
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="title">
                                        <span class="title__icon"><svg enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0" class="svg-icon"><g><circle cx="6.8" cy="4.2" fill="none" r="3.8" stroke-miterlimit="10"></circle><polyline fill="none" points="9.2 12.5 11.2 14.5 14.2 11" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></polyline><path d="m .8 14c0-3.3 2.7-6 6-6 2.1 0 3.9 1 5 2.6" fill="none" stroke-linecap="round" stroke-miterlimit="10"></path></g></svg></span>Tham Gia:
                                    </div>
                                    <div class="value text-center text-red">
                                <span v-if="data.shopProfile && data.shopProfile.founded_year_name">
                                    {{data.shopProfile ? data.shopProfile.founded_year_name : ''}}
                                </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="shop-detail-main">
            <div class="shop-menu" id="shopMenu">
                <div class="wrap-container over-unset" style="padding-top: 5px; padding-bottom: 0">
                    <div class="list-menu">
                        <div class="list-filter">
                            <ul class="nav nav-tabs">
                                <li class="nav-item">
                                    <a class="nav-link" :class="type == 1 ? 'active': ''" @click="changeType(1, 1)" data-toggle="tab" href="#allProduct">Sản phẩm </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" :class="type == 4 ? 'active': ''" @click="changeType(4, 1)" data-toggle="tab" href="#shopInfo">Thông tin cửa hàng</a>
                                </li>
                                <!--                            <li class="nav-item">-->
                                <!--                                <a class="nav-link" :class="type == 2 ? 'active': ''" @click="changeType(2, 2)" data-toggle="tab" href="#hotDeal">Liên hệ</a>-->
                                <!--                            </li>-->
                                <!--                            <li class="nav-item">-->
                                <!--                                <a class="nav-link" :class="type == 3 ? 'active': ''" @click="changeType(3, 1)" data-toggle="tab" href="#newProduct">Khuyến mại</a>-->
                                <!--                            </li>-->
                                <!--                            <li class="nav-item">-->
                                <!--                                <a class="nav-link" :class="type == 5 ? 'active': ''" @click="changeType(5)" data-toggle="tab" href="#shopFeedback">Phản hồi</a>-->
                                <!--                            </li>-->
                                <!--                            <li class="nav-item">-->
                                <!--                                <a class="nav-link" data-toggle="tab" href="#itemVideo">Video</a>-->
                                <!--                            </li>-->
                                <!--                            <li class="nav-item">-->
                                <!--                                <a class="nav-link" :class="type == 6? 'active': ''" data-toggle="tab" @click="changeType(5)" href="#itemDiscount"><img src="/assets/img/shop/discount.png" style="margin-right: 10px" alt="">Khuyến mại</a>-->
                                <!--                            </li>-->
                            </ul>
                        </div>
                    </div>
                    <div class="search-span" style="padding-bottom: 5px">
                        <div class="search-box">
                            <img src="/assets/img/shop/search-icon.png" alt="">
                            <input class="search-input" :placeholder="$t('searchInShop')" v-model="search.name" @keydown.enter="searchData">
                        </div>
                    </div>
                </div>
            </div>
            <!--        <div class="shop-menu">-->
            <!--            <div class="search-category" v-if="type == 1">-->
            <!--                <div class="wrap-container over-unset search-category__list">-->
            <!--                    <button class="search-category__btn" :style="search.category_id == category.id ? 'background-color: #EDEDEF': ''" v-for="(category, index) in categories" :key="index" @click="filterCategory(category.id)">-->
            <!--                        {{category.name}}-->
            <!--                    </button>-->
            <!--                </div>-->
            <!--            </div>-->
            <!--        </div>-->
            <ShopBanner v-if="type == 1" :shop="data.shop" :showEdit="showEdit" />

            <!--        <div class="wrap-container over-unset" id="shop-product">-->
            <!--            <div class="tab-content">-->
            <!--                <div class="tab-pane active" id="allProduct">-->
            <!--                    <shop-product :count="countUserFollows" v-if="type == 1 || type == 2 || type == 3" v :data="data" v-on:loadChild="loadChild" :type="type"></shop-product>-->
            <!--                </div>-->
            <!--                <div class="tab-pane" :class="type == 4 ? 'active': ''" id="shopInfo">-->
            <!--                    <shop-info v-if="data.shopProfile" :data="data" :type="type" v-on:loadChild="loadChild"></shop-info>-->
            <!--                </div>-->
            <!--                &lt;!&ndash;                <div class="tab-pane fade" id="shopFeedback">&ndash;&gt;-->
            <!--                &lt;!&ndash;                    <shop-feedback></shop-feedback>&ndash;&gt;-->
            <!--                &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--                &lt;!&ndash;                <div class="tab-pane fade" id="itemVideo">&ndash;&gt;-->
            <!--                &lt;!&ndash;                    <shop-video></shop-video>&ndash;&gt;-->
            <!--                &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--                &lt;!&ndash;                <div class="tab-pane fade" id="itemDiscount">&ndash;&gt;-->
            <!--                &lt;!&ndash;                    <shop-discount></shop-discount>&ndash;&gt;-->
            <!--                &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--            </div>-->


            <!--        </div>-->
            <div class="wrap-container over-unset">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-12 hide-mobile" v-if="type == 1">
                        <div class="filter-box">
                            <div class="title">
	                            {{ $t('allCategory')}}
                            </div>
                            <div class="just-padding">
                                <div class="list-group list-group-root well" v-for="(category, index) in categories" :key="index">
                                    <a :href="'#item-'+category.id" class="list-group-item" data-toggle="collapse" @click="filterCategory(category.id)"
                                       :style="search.category_id == category.id ? 'color: #DB0000': ''" style="padding: 5px 10px">
                                        {{ category.name }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div :class="type == 1 ? 'col-lg-9 col-md-9 col-12' : 'col-12'">
                        <div class="tab-content">
                            <div class="tab-pane active" id="allProduct">
                                <shop-product :count="countUserFollows" v-if="type == 1 || type == 2 || type == 3" v :data="data" v-on:loadChild="loadChild" :type="type"></shop-product>
                            </div>
                            <div class="tab-pane" :class="type == 4 ? 'active': ''" id="shopInfo">
                                <shop-info v-if="data.shopProfile" :data="data" :type="type" v-on:loadChild="loadChild"></shop-info>
                            </div>
                            <!--                <div class="tab-pane fade" id="shopFeedback">-->
                            <!--                    <shop-feedback></shop-feedback>-->
                            <!--                </div>-->
                            <!--                <div class="tab-pane fade" id="itemVideo">-->
                            <!--                    <shop-video></shop-video>-->
                            <!--                </div>-->
                            <!--                <div class="tab-pane fade" id="itemDiscount">-->
                            <!--                    <shop-discount></shop-discount>-->
                            <!--                </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <chat ref="chatBox" v-if="showChatBox"/>
</template>

<script>
import ShopRequest from "@/api/requests/ShopRequest";
import ProductItem from "@/components/ProductItem";
import ShopFeedback from "@/components/shop/ShopFeedback";
import ShopInfo from "@/components/shop/ShopInfo";
import ShopProduct from "@/components/shop/ShopProduct";
import ShopDiscount from "@/components/shop/ShopDiscount";
import ShopVideo from "@/components/shop/ShopVideo";
import ShopNewProduct from "@/components/shop/ShopNewProduct";
import Chat from "@/components/Chat";
import store from "@/store";
import toastr from 'toastr';
import {isEmpty, siteSetTitle} from "@/lib/utils";
import {getSearchQuery} from "@/lib/utils";
import {chat$upload} from "@/api/requests/ChatRequest";
import ShopBanner from "@/components/shop/ShopBanner";
import i18n from "@/main";

export default {
    name: "ShopDetail",
    props: ['id'],
    components: {
        ShopBanner,
        ShopNewProduct,
        ShopDiscount,
        ProductItem,
        ShopFeedback,
        ShopInfo,
        ShopProduct,
        ShopVideo,
        Chat
    },
    data() {
        return {
            page: this.$route.query.page || 1,
            sort: this.$route.query.sort || 1,
            type: this.$route.query.type || 1,
            search: {
                name: this.$route.query.name || "",
                price: this.$route.query.price || "",
                price_from: this.$route.query.price_from || "",
                price_to: this.$route.query.price_to || "",
                category_id: this.$route.query.category_id || "",
                brand_ids: this.$route.query.brand_ids || ""
            },
            categories: [],
            data: {
                shop: {},
                shopProfile: {},
                brands: [],
                searchPrice: [],
                paginate: {
                    currentPage: 1,
                    lastPage: 1
                }
            },
            // authData: store.state.Auth,
            shopBackground: '',
            isFollowed: false,
            countUserFollows: 0,
            showBtnEdit: false,
            avatar: '',
            banner: '',
            showEdit: false
        }
    },
    created() {
        this.getData();
        this.handleScroll();
    },
    computed: {
        authData: () => store.state.Auth,
        showChatBox: () => store.state.showChatBox,
    },
    methods: {
        async getData() {
            const userId = this.authData ? this.authData.user.id: '';

            const res = await ShopRequest.getHomeDataWeb(
                this.id,
                this.page,
                this.sort,
                this.type,
                this.search,
                userId
            );

            if (!res.err_code) {
                this.data.shop = res.shop;
                this.categories = res.categories;
                this.data.searchPrice = res.search_price;
                this.data.paginate = res.paginate;
                this.data.brands = res.brands;
                this.data.shopProfile = res.shop_profile;
                this.showBtnEdit = userId && userId == res.shop.user_id;
                siteSetTitle(res.shop.name)
            }  else if (res.redirect) {
                location.replace(res.redirect);
            } else if (res.code === 1) {
                this.$router.push('/404');
            }
        },
        changeType(type, sort = null) {

            if (sort) {
                this.sort = sort;
            }

            this.search.name = '';

            this.page = 1;
            this.sort = 1;

            this.type = type;

            this.$router.push({
                path: `/cua-hang/${this.id}`,
                query: {page: this.page, sort: this.sort, type: this.type}
            });

            this.getData();
        },
        loadChild(data) {
            this.data.shop = data.shop;
            this.data.paginate = data.paginate;
            this.data.hasNextPage = data.hasNextPage;
        },
        searchData() {
            let query = getSearchQuery();

            if (this.search.name) {
                query.name = this.search.name
            }
            if (this.search.category_id) {
                query.category_id = this.search.category_id;
            }

            this.$router.push({
                path: `/cua-hang/${this.id}`,
                query: query
            });

            this.getData();
        },
        filterCategory(id) {
            this.search.category_id = id;

            this.searchData();
            this.scrollToProduct();
        },
        async follow(value) {
            if (!this.authData) {
                if (confirm(this.$t('loginToFollowShop'))) {
                    location.href = '/dang-nhap';
                }
            } else {
                const res = await ShopRequest.follow(this.id, this.authData.user.id, value);

                if (res.err_code) {
                    toastr.errors(res.message);
                    return;
                }

                this.isFollowed = value;

                if (res.code == 200) {
                    this.countUserFollows = res.user_follows;

                }

            }
        },
        showChat() {
            store.commit('SetShowChatBox', false);
            // if (!this.authData) {
            //     if (confirm('Bạn cần đăng nhập để có thể gửi tin nhắn đến shop!')) {
            //         this.$router.push('/dang-nhap');
            //     }
            // }
            setTimeout(() => {
                store.commit('SetShowChatBox', true);
            }, 100);
            setTimeout(() => {
                this.$refs.chatBox.createConversation(this.data.shop);
            }, 100);
        },

        async uploadAvatar(ev, type) {
            const files = ev.target.files;
            if (files.length === 0) {
                toastr.error(this.$t('plsChooseImg'));
                return;
            }

            const res = await chat$upload('/v1/uploads', files[0]);
            ev.target.value = '';

            if (res.code !== 200) {
                toastr.success(this.$t('uploadSuccess'));
                return;
            }

            let avatar = {
                uri: ''
            };
            avatar.uri = res.image ? res.image.url : '';
            if (type == 1) {
                this.banner = res.image;
                this.shopBackground = avatar.uri;
            } else {
                this.avatar = res.image;
                this.data.shop.avatar = avatar.uri;
            }

            // this.errorAvatar = '';
        },
        showEditImage() {
            this.showEdit = true;
        },
        async quickUpdateImage() {
            let data = {
                shopId: this.data.shop.id,
                avatar: this.avatar,
                banner: this.banner
            }

            const res = await ShopRequest.quickUpdateImage(data);
            if (!res.err_code) {
                toastr.success(this.$t('updateSuccess'));
                return;
            }
        },
        handleScroll() {
            window.addEventListener('scroll', this.onScroll);
        },
        onScroll() {
            let header = document.getElementById("shopMenu");
            let sticky = header.offsetTop;

            if (window.pageYOffset > sticky) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }
        },
        scrollToProduct() {
            let element = window.$("#shop-product").offset().top;
            window.scroll({
                top: element,
                left: 0,
                behavior: 'smooth'
            });
        },

    },
    watch: {
        'data.shop': function (newVal) {
            if (!isEmpty(newVal)) {
                this.isFollowed = newVal.is_followed;
                this.shopBackground = newVal.banner ?? '';
                this.countUserFollows = newVal.number_follows ?? 0;
            }
        },
    }
}
</script>

<style scoped lang="scss">
@import "../assets/css/shopDetail";
.filter-box {
    padding: 24px;
    background: #ffffff;
    border-radius: 8px;
    .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #181c32;
        margin-bottom: 17px;
    }
    .parent-category {
        cursor: pointer;
        margin-left: 32px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #383c4f;
        margin-bottom: 12px;

        &:hover {
            color: #db0000;
        }
    }
    .list-group-item {
        border: none;
    }
}
.sticky {
    position: fixed;
    top: 115px;
    width: 100%;
    z-index: 100;
}

@media screen and (max-width: 768px){
    .hide-mobile {
        display: none;
    }
    .sticky {
        top: 140px;
    }
    .list-menu {
        width: 100%!important;
    }
}

</style>
