<template>
    <div class="row">
        <div class="col-lg-7 col-md-7 col-12" style="padding-right: 35px">
            <div class="error-message" v-if="errors.length">
                <ul>
                    <li v-for="(error, index) in errors" v-text="error" :key="'error-' + index"></li>
                </ul>
            </div>
            <div class="form-group row">
                <label for="f_username" class="margin-tb-auto col-sm-4">{{ $t('username')}}</label>
                <input id="f_username" name="name" class="col-sm-8 form-control" v-model="user.username" v-if="!user.username || !user.is_change_username">
                <span class="col-sm-8" v-text="user.username" v-if="user.username && user.is_change_username"></span>
                <span class="col-sm-8 offset-sm-4 username-des">{{ $t('onceChangeUsername')}}</span>
            </div>
            <div class="form-group row">
                <label for="f_name" class="margin-tb-auto col-sm-4">{{ $t('typeName')}}</label>
                <input id="f_name" name="name" class="col-sm-8 form-control" v-model="user.name">
            </div>
            <div class="form-group row">
                <label for="f_phone" class="margin-tb-auto col-sm-4">{{ $t('typePhoneNumber')}}</label>
                <span id="f_phone" class="col-sm-8" v-text="user.phone ? replaceCenterInfo(user.phone, 'phone') : ''" v-if="user.phone"></span>
                <a v-else href="javascript:;" @click="showForm('phone')">{{ $t('addPhone')}}</a>
            </div>
            <div class="form-group row">
                <label for="f_email" class="margin-tb-auto col-sm-4">{{ $t('yourEmail')}}</label>
                <span id="f_email" class="col-sm-6" v-text="user.email ? replaceCenterInfo(user.email, 'email') : ''"> </span>
                <a href="javascript:;" @click="showForm('email')">{{ $t('changeEmail')}}</a>
            </div>
            <div class="form-group row">
                <label class="margin-tb-auto col-sm-4">{{ $t('gender')}}Giới tính</label>
                <div class="col-sm-8 row">
                    <div>
                        <input type="radio" id="f_gender_male" v-model="user.gender" value="1" class="margin-tb-auto">
                        <label for="f_gender_male" class="margin-tb-auto pl-2">{{ $t('male')}}</label>
                    </div>

                    <div class="ml-4">
                        <input type="radio" id="f_gender_female" v-model="user.gender" value="2" class="margin-tb-auto">
                        <label for="f_gender_female" class="margin-tb-auto pl-2">{{ $t('female')}}</label>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="margin-tb-auto col-sm-4">{{ $t('birthday')}}</label>
                <input type="date" class="col-sm-8 form-control" v-model="user.birthday">
            </div>
            <!--            <div class="col-4 col-sm-2 pl-0 pr-2">-->
            <!--                <select class="form-control" v-model="birthday.day">-->
            <!--                    <option :key="'day-' + day" v-for="day in 31" :value="day" v-text="day"></option>-->
            <!--                </select>-->
            <!--            </div>-->
            <!--            <div class="col-4 col-sm-2 pl-0 pr-0">-->
            <!--                <select class="form-control" v-model="birthday.day">-->
            <!--                    <option :key="'month-' + month" v-for="month in 12" :value="month"-->
            <!--                            v-text="'Tháng ' + month"></option>-->
            <!--                </select>-->
            <!--            </div>-->
            <!--            <div class="col-4 col-sm-2 pl-2 pr-0">-->
            <!--                <select class="form-control" v-model="birthday.day">-->
            <!--                    <option :key="'year-' + year" v-for="year in (init.currentYear - 1950)"-->
            <!--                            :value="1950 + parseInt(year)" v-text="1950 + parseInt(year)"></option>-->
            <!--                </select>-->
            <!--            </div>-->
        </div>
        <div class="col-lg-5 col-md-5 col-12 padding-info" style="margin: auto">
            <div class="form-group">
                <div class="avatar">
<!--                    <img :src="user.avatar ? user.avatar.uri : '/assets/img/profile/demo_avatar.png'" alt="">-->
                    <img v-if="!user.avatar"
                         src="/assets/img/profile/demo_avatar.png" alt="">
                    <img v-if="user.avatar" :src="user.avatar.uri"
                         alt=""/>
                </div>
                <div class="text-center">
                    <input class="upload-img" accept="image/*" type="file" id="upload-avatar" @change="uploadAvatar($event)"/>
                    <label for="upload-avatar" class="avatar-btn">
                        <i class="fas fa-camera" style="font-size: 20px"></i>
                    </label>
                    <div class="avatar-text">
	                    {{ $t('fileSize')}} <br/> {{ $t('fileFormat')}}
                    </div>
                </div>
                <div class="text-center" v-if="errorAvatar">
                    <span class="error">{{ errorAvatar }}</span>
                </div>
            </div>
        </div>
        <div class="col-12">
            <button class="col-sm-3 offset-sm-2 submit-btn" @click="save">{{ $t('confirm')}}</button>
        </div>
        <div class="modal" tabindex="-1" role="dialog" id="modalInfo" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-lg" role="document" style="top: 15%">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" v-text="addEmail === true ?  $t('changeEmail'):  $t('addPhone')"></h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" style="display: grid">
                        <span class="col-sm-12 mb-3 username-des"
                              v-text="addEmail === true ? $t('toUpdateEmail') :
                               $t('followToAddPhone')"></span>
                        <div class="form-group row" v-if="addEmail === true">
                            <label for="m_email" class="margin-tb-auto col-sm-4">Email </label>
                            <span id="m_email" class="col-sm-6" v-text="replaceCenterInfo(user.email, 'email')"> </span>
                        </div>
                        <div class="form-group row" v-if="!isCorrectPassword && addEmail === true">
                            <label for="m_password" class="margin-tb-auto col-sm-4">{{ $t('password')}} </label>
                            <input id="m_password" type="password" class="form-control col-sm-6" v-model="password">
                        </div>
                        <div class="form-group row" v-if="isCorrectPassword && addEmail === true">
                            <label for="m_new_email" class="margin-tb-auto col-sm-4">{{ $t('typeNewEmail')}} </label>
                            <input id="m_new_email" class="form-control col-sm-6" :placeholder="$t('typeNewEmail')" v-model="newEmail">
                        </div>
                        <div class="form-group row" v-if="addPhone === true">
                            <label for="m_phone" class="margin-tb-auto col-sm-4">{{ $t('newPhone')}} </label>
                            <input id="m_phone" class="form-control col-sm-6" :readonly="readonlyPhone" v-model="newPhone">
                        </div>
                        <div class="form-group row" v-if="addPhone === true">
                            <label for="m_code" class="margin-tb-auto col-sm-4">{{ $t('verifyCode')}} </label>
                            <input id="m_code" placeholder="Mã xác minh" type="text" class="form-control col-sm-4" v-model="verifyCode">
                            <button class="col-sm-2 btn btn-send" type="button" @click="verifyPhone()">{{ $t('sendVerifyCode')}}</button>
                        </div>
                        <div class="form-group row" v-if="errorChangeInfo.errorEmail">
                            <span class="error offset-4">{{ errorChangeInfo.errorEmail }}</span>
                        </div>
                        <div class="form-group row" v-if="errorChangeInfo.errorPhone">
                            <span class="error offset-4">{{ errorChangeInfo.errorPhone }}</span>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn submit-btn" :disabled="addPhone === true && (!newPhone || !verifyCode)" @click="selectAction()">{{ $t('confirm')}}</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('close')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from "@/store";
import {cloneObject} from "@/lib/utils";
import AuthRequest from "@/api/requests/AuthRequest";
import config from "@/config";
import {chat$upload} from "@/api/requests/ChatRequest";
import toastr from 'toastr';

export default {
    name: "ProfileTab",
    data() {
        return {
            user: cloneObject(store.state.Auth.user) || {
                username: '',
                name: '',
                phone: '',
                email: '',
                gender: '',
                birthday: '',
                avatar: {
                    uri: ''
                }
            },
            addPhone: false,
            addEmail: false,
            showModal: false,
            isCorrectPassword: false,
            newEmail: '',
            newPhone: '',
            password: '',
            verifyCode: '',
            readonlyPhone: false,
            errors: [],
            errorAvatar: '',
            errorChangeInfo: {
                errorEmail: '',
                errorPhone: ''
            },
            profile: {
                img: ''
            },
            loading: false
        }
    },
    async mounted() {

    },
    methods: {
        validate() {
            const errors = [];
            if (!this.user.name) {
                errors.push('Vui lòng nhập họ tên!');
            }

            if (!this.user.email) {
                errors.push('Vui lòng nhập Email!');
            }

            if (this.user.phone) {
                const isPhone = /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/g.test(this.user.phone);
                if (!isPhone) {
                    errors.push('Số điện thoại không đúng định dạng!');
                }
            }

            this.errors = errors;

            return !!errors.length;
        },
        async save() {

            if (this.loading) {
                return false;
            }

            if (this.validate()) {
                toastr.error('Có lỗi xảy ra, vui lòng kiểm tra lại');
                return false;
            }

            this.loading = true;
            const res = await AuthRequest.updateProfile(this.user);

            this.loading = false;
            if (res.err_code) {
                this.errors = [res.message];
                toastr.error('Có lỗi xảy ra, vui lòng kiểm tra lại');
                return false;
            }

            this.user = res.user;
            store.commit('SetUserData', res.user);
            toastr.success("Thành công");

            return true;
        },
        showForm(type) {
            if (type === 'phone') {
                this.addPhone = true;
            } else {
                this.addEmail = true;
            }
            window.$('#modalInfo').modal('show');
        },

        async uploadAvatar(ev) {
            const files = ev.target.files;
            if (files.length === 0) {
                toastr.error('Vui lòng chọn ảnh đại diện');
                return;
            }

            const res = await chat$upload('/v1/uploads', files[0]);
            ev.target.value = '';

            if (res.code !== 200) {
                toastr.success("Tải lên thành công");
                return;
            }

            let avatar = {
                uri: ''
            };
            avatar.uri = res.image ? res.image.url : '';

            this.user.avatar = avatar;
            this.errorAvatar = '';
        },

        async checkPasswordToContinue() {
            if (!this.password) {
                this.errorChangeInfo.errorEmail = 'Vui lòng nhập mật khẩu';
                return;
            }
            const res = await AuthRequest.checkEmailToChangeInfo(this.user.email, this.password);

            if (res.err_code === 2) {
                this.errorChangeInfo.errorEmail = res.message;
                return false;
            } else {
                this.errorChangeInfo.errorEmail = '';
                this.isCorrectPassword = true;
            }

        },
        async changeEmail(newEmail) {
            if (!newEmail) {
                this.errorChangeInfo.errorEmail = 'Vui lòng nhập hộp thư mới';
                return;
            }
            if (newEmail === this.user.email) {
                this.errorChangeInfo.errorEmail = 'Email mới không được trùng với email hiện tại';
                return;
            }
            const res = await AuthRequest.changeEmail(this.user.email, newEmail);

            if (res.err_code === 2) {
                this.errorChangeInfo.errorEmail = res.message;
                toastr.error(res.message);
                return false;
            } else {
                this.errorChangeInfo.errorEmail = '';
                this.isCorrectPassword = false;
                this.user = res.user;
                store.commit('SetUserData', res.user);
                toastr.success(res.message);

                window.$('#modalInfo').modal('hide');
            }

        },

        replaceCenterInfo(text, type) {
            let sLength = text.length;
            if (type === 'phone') {
                return text.replace(text.substring(0,sLength - 3), '*******');
            }
            return text.replace(text.substring(2,sLength - 10), '*********');

        },
        async verifyPhone() {
            if (!this.newPhone) {
                this.errorChangeInfo.errorPhone = 'Vui lòng nhập số điện thoại mới';
                return;
            }
            if (this.newPhone) {
                const isPhone = /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/g.test(this.newPhone);
                if (!isPhone) {
                    this.errorChangeInfo.errorPhone = 'Số điện thoại không đúng định dạng';
                    return;
                }
            }

            const res = await AuthRequest.phoneCheck(this.newPhone);
            if (res.err_code !== 0) {
                this.errorChangeInfo.errorPhone = res.message;
                return;
            } else {
                this.showVerifyPhone = true;
                this.readonlyPhone = true;
                this.errorChangeInfo.errorPhone = '';
            }

        },
        async addNewPhone() {
            if (!this.newPhone) {
                this.errorChangeInfo.errorPhone = 'Vui lòng nhập số điện thoại mới';
                return;
            }
            const res = await AuthRequest.addNewPhone(this.newPhone, this.verifyCode);

            if (res.err_code === 2) {
                this.errorChangeInfo.errorPhone = res.message;
                return false;
            } else {
                this.errorChangeInfo.errorPhone = '';
                this.isCorrectPassword = false;
                this.user = res.user;
                store.commit('SetUserData', res.user);
                this.addPhone = false;
                this.newPhone = '';
                this.verifyCode = '';

                window.$('#modalInfo').modal('hide');
            }

        },

        selectAction() {
            if (this.addEmail === true) {
                if (!this.isCorrectPassword) {
                    this.checkPasswordToContinue()
                } else {
                    this.changeEmail(this.newEmail);
                }
            } else {
                this.addNewPhone();
            }
        }
    }
}
</script>

<style scoped lang="scss">
#upload-avatar {
    opacity: 0;
    position: absolute;
    z-index: -1;

}
.avatar {
  position: relative;

}
.avatar-btn {
  position: absolute;
  padding: 6px 10px;
  background: #EDEDEF;
  border-radius: 50%;
  bottom: 30%;
  right: 35%;
  cursor: pointer;
}
</style>
