<template>
  <div class="wrap-container">
    <div class="row  row-content no-margin-hoz ">

      <div class="col-lg-6 col-sm-12 no-padding border-between">
        <div class="news-item left bg-white mb-3">
          <div class="news-header">
            <h3 v-text="$t('sellPost')"></h3>
            <router-link :to="{name: 'ClassifiedAdvertising', query: {property: 2, page: 1}}">
              - {{ $t('viewMore') }} -
            </router-link>
          </div>

          <div class=" news-content ">
            <auto-scroll>
              <div v-for="(item,index) in newsCart" :key="item.id"
                   class="row padding-mobile my-2 py-2 px-4 align-items-center"
                   :class="[index%2 !=0?'bg-gray':'bg-white']">
                <div>
                  <img v-if="item.image && item.image.src" v-lazy="item?.image?.src" :style="{height:'40px',width:'40px',borderRadius:'4px'}"
                       @error="loadImgErr"/>
                  <img v-else v-lazy="defaultImg" alt="" class="default-image"/>
                </div>
                <div class="col-9">
                  <router-link :to="item && item.id ? '/thong-tin-rao-vat/' + item.id : ''">
                    <div class="text-title ml-2 mr-2">{{ item.title }}</div>

                  </router-link>
                </div>
                <div class=" mb-2">{{ formatDate(item.created_at) }}</div>
              </div>
            </auto-scroll>

          </div>
        </div>

      </div>

      <div class="col-lg-6 col-sm-12 no-padding">
        <div class="news-item right bg-white">
          <div class="news-header">
            <h3 v-text="'Tin cần mua'"></h3>
            <router-link :to="{name: 'ClassifiedAdvertising', query: {property: 1,page: 1}}">
              - {{ $t('viewMore') }} -
            </router-link>
          </div>

          <div class="news-content ">
            <auto-scroll>
              <div v-for="(item,index) in newsBuy" :key="item.id"
                   class="row padding-mobile my-2 py-2 px-4 align-items-center"
                   :class="[index%2 !=0?'bg-gray':'bg-white']">
                <div>
                  <img v-if="item.image && item.image.src" v-lazy="item?.image?.src" :style="{height:'40px',width:'40px',borderRadius:'4px'}"
                       @error="loadImgErr"/>
                  <img v-else v-lazy="defaultImg" alt="" class="default-image"/>
                </div>
                <div class="col-9">
                  <router-link :to="item && item.id ? '/thong-tin-rao-vat/' + item.id : ''">
                    <div class="text-title ml-2 mr-2">{{ item.title }}</div>

                  </router-link>
                </div>
                <div class=" mb-2">{{ formatDate(item.created_at) }}</div>
              </div>
            </auto-scroll>
          </div>

        </div>

      </div>

    </div>

  </div>
</template>

<script>
import {getOption} from "@/lib/utils";
import {defaultImg, numberFormat, stringTruncate} from "@/lib/utils";

const $ = window.jQuery;

import CategoryHome from "@/components/home/CategoryHome";
import CrawlRequest from "@/api/requests/CrawlRequest";
import moment from 'moment'
import AutoScroll from "@/components/AutoScroll";

export default {
  name: "NewsHome",
  props: ['sliders'],
  components: {AutoScroll, CategoryHome},
  updated() {
  },
  data() {

    return {
      newsBuy: [],
      newsCart: [],
      defaultImg: defaultImg()
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    loadImgErr(e) {
      e.currentTarget.src = defaultImg();
    },
    async getNewsBuy() {
      let res = await CrawlRequest.get('', '', '', '1', {
        limit: 50
      });
      this.newsBuy = res.data
      console.log("-> res", res);
    },
    async getNewsCart() {
      let res = await CrawlRequest.get('', '', '', '2', {
        limit: 50
      });
      this.newsCart = res.data
      console.log("-> res", res);
    },
    initSlider() {
      $(this.$refs.bannerHomeSlider).show().slick({
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      });
    },
  },
  mounted() {
    this.getNewsCart()
    this.getNewsBuy()
  }
}
</script>

<style scoped lang="scss">
.news-item {
  background: #FFFFFF;
  box-shadow: 0 8px 32px -4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  height: 100%;

  @media screen and (max-width: 768px) {
    padding: 15px 5px;
  }

  &.left {
    margin-right: 12px;
  }

  &.center {
    margin-left: 8px;
    margin-right: 8px;
  }

  &.right {
    margin-left: 12px;
  }

  @media screen and (max-width: 991px) {
    &.left {
      margin-right: 0;
    }

    &.right {
      margin-left: 0;
      margin-top: 20px;

    }
  }

}

.border-between {
  border-right: #efefef 1px solid;
}

.news-header {
  display: flex;
  align-items: center;
  border-bottom: #d4d4d4 1px solid;
  justify-content: space-between;

  h3 {
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #5d6177;
    margin-bottom: 0;
    padding: 10px 0;
    border-bottom: #f12525 2px solid;
  }

  a {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #5D5FEF;
    padding-left: 15px;

    &:hover {
      color: #DB0000;
    }
  }

  h4 {
    font-style: italic;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    color: #FF1010;
    text-transform: uppercase;
    margin-bottom: 0;
    padding: 0 15px 0 0;
  }

  @media screen and (max-width: 540px) {
    h3 {
      font-size: 16px !important;
    }
  }
}

.news-content {
  //border: 1px #d9d9d9 solid;
  border-radius: 7px;
  margin-top: 20px;

  ul {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }

  .default-image {
    height: 40px;
    width: 40px;
    border: #d4d4d4 1px solid;
    border-radius: 4px;
  }

  .text-title {
    width: 100%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;

  }

  .text-content {
    color: #acaebb;
    font-size: 13px;

  }

  .text-name {
    font-size: 14px;
    color: #333;
    margin: 0;
    text-transform: capitalize;
    width: 100%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .text-time {

  }

  .bg-gray {
    background-color: #bbbbbb50;
    //border-bottom: 1px #bbbbbb solid;
    //border-top: 1px #bbbbbb solid;
  }
}
</style>
