<template>
    <div class="form-root">
        <div class="d-flex" style="justify-content: right">
            <i class="fas fa-times close-box" style="font-size: 20px; margin-right: 10px" @click="hiddenPopup()"></i>
        </div>
        <div>
            <h4 style="text-align: center">{{ $t('register')}}</h4>
            <form>
                <div class="form-group">
                    <label for="name" class="label-input ml-2">{{ $t('fullName')}}</label>
                    <input v-model="name" name="name" type="text" id="name"
                           :placeholder="$t('typeName')"
                           class="form-control input-error col-sm-11 ml-2" style="width: fit-content">
                </div>
                <div class="form-group">
                    <label for="phone-number" class="label-input ml-2">{{ $t('phoneNumber')}}</label>
                    <input v-model="phone" name="phone" type="text" id="phone-number"
                           :placeholder="$t('typePhoneToRegister')"
                           class="form-control input-error col-sm-11 ml-2" style="width: fit-content">
                </div>
                <div class="form-group">
                    <label for="email" class="label-input ml-2">Email</label>
                    <input v-model="email" name="email" type="text" id="email"
                           :placeholder="$t('typeEmail')"
                           class="form-control input-error col-sm-11 ml-2" style="width: fit-content">
                </div>
                <div style="display: flex; justify-content: center; align-items: center">
                    <div class="btn-contact" @click="save()"><p>{{ $t('save')}}</p></div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import toastr from "toastr";
import AuthRequest from "@/api/requests/AuthRequest";
import store from "@/store";

export default {
    name: "QuickAddTempAccount",
    data() {
        return {
            name: '',
            phone: '',
            email: '',
        }
    },
    methods: {
        async save() {

            if (!this.name) {
                toastr.error('Vui lòng nhập họ tên!');
                return;
            }

            if (!this.email && ! this.phone) {
                toastr.error('Vui lòng nhập Email hoặc số điện thoại!');
                return;
            }

            if (this.phone) {
                const isPhone = /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/g.test(this.phone);
                if (!isPhone) {
                    toastr.error('Số điện thoại không đúng định dạng!');
                    return;
                }
            }

            if (this.email && !this.validateEmail(this.email)) {
                toastr.error('Hãy nhập email đúng định dạng! VD: abc@mail.com');
                return;
            }

            const params = {
                email: this.email,
                phone: this.phone,
                name: this.name
            };

            const res = await AuthRequest.createTempAccount(params);
            if (res.err_code !== 0) {
                toastr.error(res.message);
                return;
            }

            localStorage.setItem('auth_temp', JSON.stringify(res));
            store.commit('SetHasAuthTemp', true);
        },

        validateEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },

        hiddenPopup() {
            this.$emit('hidden');
        },
    }
}
</script>

<style scoped lang="scss">
@import '../assets/css/quickAddTempAccount';
</style>