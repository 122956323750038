<template>
    <div class="row special-campaign no-margin-hoz">
        <div class="campaign-header col-12">
            <div class="d-flex align-items-center">
                <h4 v-text="campaign.title"></h4>
                <img src="/assets/img/header/ic_bolt.svg" alt="">
            </div>
            <router-link :to="{name: 'CampaignDetail', params: {slug: campaign.slug, id: campaign.id, name: campaign.title}}">
                {{$t('viewAll')}}
            </router-link>
        </div>
        <div class="container-fluid">
            <div class="row campaign-content">
                <div class="col-sm-6 col-md-3 col-xl-4 col-12 padding-mobile" style="position: relative">
                    <img v-if="campaign.sub_thumb && campaign.sub_thumb.uri" v-lazy="campaign.sub_thumb.uri" alt="bg-campaign" class="campaign-thumb">
                    <img v-else src="/assets/img/header/bg_campaign.png" alt="bg-campaign" class="campaign-thumb">
                    <div class="campaign-description">
                        <h1>{{$t('saleFrom')}}</h1>
                        <p>{{$t('saleTime')}}</p>
                        <CountDown :end="d(campaign.end_date)" :showUnit="false"/>
                        <router-link :to="{name: 'CampaignDetail', params: {slug: campaign.slug, id: campaign.id, name: campaign.title}}">
                            <h3>{{$t('viewMore')}}</h3>
                        </router-link>
                    </div>
                </div>
                <div class="col-xl-2 col-md-3 col-6 padding-mobile" v-for="(product) in campaign.products" :key="product.id">
                    <product-item :product="product" :show-detail="false"></product-item>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import ProductItem from "@/components/ProductItem";
    import CountDown from "@/components/CountDown";
    import moment from "moment";
    export default {
        name: "HotCampaign",
        components: {CountDown, ProductItem},
        props: ['campaign'],
        methods: {
            d(date) {
                let a = moment(date)
                return a.unix()*1000
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/css/topCampaign";
    @import "../../assets/css/productItem";

    .video-info {
        background: rgba(0, 0, 0, 0.38);;
        border-radius: 4px;
        position: absolute;
        padding: 2px 5px;
        top: 15px;
        left: 15px;
        z-index: 5;

        span {
            padding-left: 5px;
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            color: #FFFFFF;
        }
    }

    .video-play-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
        z-index: 5;
        background: rgba(0, 0, 0, 0.38);;
        border: 1px solid #FFFFFF;
        border-radius: 20px;

        img {
            width: 20px;
            height: 20px;
            object-fit: contain;
        }
    }
</style>
