<template>
    <div class="addresses">
        <div class="address-title">
	        {{ $t('myAddress')}}
        </div>
        <div class="address-list">
            <div :key="address.id" class="address-item" v-for="address in addresses">
                <div class="row">
                    <div class="col-3 text-right">
                        <label>{{ $t('fullName')}}</label>
                    </div>
                    <div class="col-6">
                        <span class="address-name" v-text="address.name"></span>
                        <span class="address-default" v-if="address.is_default">{{ $t('default')}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3 text-right">
                        <label>{{ $t('phoneNumber')}}</label>
                    </div>
                    <div class="col-6">
                        <span v-text="address.phone"></span>
                    </div>
                    <div class="col-3 d-flex" style="padding-right: 35px">
                        <address-form-component style="margin-left: auto" :id="address.id"></address-form-component>
                        <a class="btn-delete" @click="removeAddress(address)" style="margin-left: 20px">
                            <img src="/assets/img/cart/ic_trash.svg" alt=""/>
                        </a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3 text-right">
                        <label>{{ $t('address')}}</label>
                    </div>
                    <div class="col-6">
                        <span v-text="address.decoded"></span>
                    </div>
                    <div class="col-3">
                        <button type="button" class="btn btn-normal" :disabled="address.is_default"
                        @click="toggleDefaultAddr(address)">{{ $t('setDefault')}}</button>
                    </div>
                </div>


            </div>
        </div>
        <address-form-component></address-form-component>
    </div>
</template>

<script>
import AddressFormComponent from "@/components/carts/AddressFormComponent";
import AddressRequest from "@/api/requests/AddressRequest";
import store from "@/store";
import toastr from 'toastr';

export default {
name: "AddressTab",
    components: {
        AddressFormComponent
    },
    data() {
        return {
            addresses: [],
            user: store.state.Auth || {}
        }
    },
    mounted() {
        this.getAddress();
    },
    methods: {
        async getAddress() {
            const data = await AddressRequest.get();

            if (!data) {
                return;
            }
            this.addresses = data;
        },
        async toggleDefaultAddr(addr) {
            this.addresses.forEach((a) => {
                a.is_default = (a.id === addr.id);
            });
            const res = await AddressRequest.setDefaultAddress(addr.id.toString());
            await this.getAddress();

        },
        async removeAddress(address) {
            if (address.id && window.confirm('Xóa địa chỉ này?')) {
                const res = await AddressRequest.remove(address.id);
                if (res.err_code === 0) {
                    toastr.success('Đã xóa');
                    await this.getAddress();
                } else {
                    toastr.errors('Vui lòng kiểm tra lại');
                }
            }
        },
    }
}
</script>

<style scoped lang="scss">
.address-title {
  font-size: 16px;
  font-weight: bold;
  border-bottom: 0.5px solid #EDEDEF;
}
.address-item {
  padding: 15px 0;
  border-bottom: solid 1px #EDEDEF;
  .address-name {
    font-weight: bold;
    font-size: 16px;
  }
  .address-default {
    margin-left: 16px;
    font-size: 16px;
    padding: 3px 8px;
    background: #20c997;
    color: #ffffff;
    border-radius: 6px;
  }
  .btn-delete {
    &:hover {
      cursor: pointer;
    }


  }
}

</style>